import { useMutation, useSubscription } from "@apollo/client";
import dayjs from "dayjs";
import React, { Fragment, useContext, useEffect, useState } from "react";
import ImageGallery from "react-image-gallery";
import Skeleton from "react-loading-skeleton";
import NumberFormat from "react-number-format";
import { Link, Redirect, useParams } from "react-router-dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import defaultImage from "../../../../images/service-preview-default.png";
import { getThumbNail, PreviewMap } from "../../../../shared/helpers/utils";
import { useTranslatedLabel } from "../../../../shared/hooks";
import { AuthLayout } from "../../components";
import { PATHS } from "../../config";
import { GET_PROVIDER_MATCHES, GET_SERVICE_OFFER_BY_ID, INSERT_TRADE_VIEWS, UPDATE_ONE } from "../../gql";
import { AuthContext } from "../../store";
import { ServiceOfferList } from "./offer-list";

const ViewServiceOffer = ({
	history,
	component = false,
	offer_id = "",
	inAdmin = false,
	pagination,
	noFilter = false,
	limit = null,
	component_filters = {},
}) => {
	const { t } = useTranslatedLabel();
	const [newpath, setNewPath] = useState("");
	const { user_role, user } = useContext(AuthContext);

	const [showMap, setShowMap] = useState(false);
	const [offer, setOffer] = useState(null);
	const { offerId } = useParams();

	const id = component ? offer_id : offerId;

	const { loading, data, error } = useSubscription(GET_SERVICE_OFFER_BY_ID, {
		variables: { id: id },
		onSubscriptionComplete: () => {
			debugger
			console.log(error, data, loading)
		}
	});

	const [updateProviderService] = useMutation(UPDATE_ONE("provider_services"));
	const images = offer?.pictures || [];

	// Populate location
	const [location_data, setLocationData] = useState();
	useEffect(() => {
		if (offer?.location) {
			setLocationData({
				...offer?.location,
				name: offer?.location?.name,
				fullAddress: `${offer?.location?.name},${offer?.location?.city ?? " "},${offer?.location?.state ?? " "},${offer?.location?.country ?? " "
					}`,
				mapCenter: {
					lat: offer?.location?.lat || 0,
					lng: offer?.location?.lng || 0,
				},
				markerPosition: {
					lat: offer?.location?.lat || 0,
					lng: offer?.location?.lng || 0,
				},
			});
		}
	}, [offer]);

	useEffect(() => {
		if (!loading && data?.object && !error) setOffer(data?.object);
	}, [data, loading, error]);

	const [matches, setMatches] = useState([]);

	const { data: matchesData, loading: matchesLoading, error: matchesError } = useSubscription(GET_PROVIDER_MATCHES, {
		variables: { user_id: inAdmin ? null : user.id, offer_id: id },
	});

	useEffect(() => {
		if (!inAdmin) {
			if (!matchesLoading && matchesData && matchesData.objects && !matchesError) {
				setMatches(matchesData.objects);
			}
		}
	}, [matchesData, matchesError, matchesLoading, inAdmin]);

	const [insertViews] = useMutation(INSERT_TRADE_VIEWS);
	useEffect(() => {
		if (!inAdmin) {
			if (offer && offer.user && offer.user.id !== user.id)
				insertViews({
					variables: {
						request_id: id,
						user_id: user.id,
						date: dayjs().format("YYYY-MM-DD"),
					},
				}).catch((e) => e);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [offer, inAdmin]);

	// if error (e.g offer no longer available, or invalid provider_service id)
	if (!inAdmin && !loading && error) return <Redirect to={PATHS.services} />;

	const Wrapper = component ? Fragment : AuthLayout;

	return (
		<>
			<div id="main-wrapper" style={component ? { marginTop: "0px" } : {}}>
				<Wrapper showBottom={true}>
					{newpath && <Redirect to={newpath} />}
					<div className="offer-details-page bg-light">
						<div className="container pb-4 pt-5">
							{!component && (
								<div className="row">
									<div>
										<h3 className="page-title display-4" style={{ padding: "0px" }}>
											{t("offer")} # {offer?.uid}
										</h3>
									</div>
									<div className="ml-auto">
										{!inAdmin && offer?.user?.id === user?.id && offer?.active ? (
											<div className="">
												<div
													onClick={() => setNewPath(`${PATHS.service_edit_offer}/${offer?.id}`)}
													className="btn btn-sm btn-outline-primary ml-3"
												>
													{t("edit")}
												</div>
												<div
													onClick={() =>
														Swal.fire({
															title: t("are_you_sure?"),
															text: t("you_wont_be_able_to_revert!"),
															icon: "warning",
															showCancelButton: true,
															confirmButtonText: t("yes"),
														}).then((x) => {
															if (x.value) {
																updateProviderService({
																	variables: {
																		id: offer?.id,
																		object: {
																			active: false,
																			status: "completed",
																		},
																	},
																});
																setNewPath(`${PATHS.service_offer_history}`);
															}
														})
													}
													className="btn btn-sm btn-outline-primary ml-3"
												>
													{t("close")}
												</div>
											</div>
										) : (
												!inAdmin &&
												user_role !== "provider" && (
													<Link
														to={`${PATHS.service_new_order}/${offer?.id}`}
														className="btn btn-sm btn-outline-primary font-weight-bold float-right"
													>
														{t("place_order")}
													</Link>
												)
											)}
									</div>
								</div>
							)}

							<div className="offer-details pb-3">
								<div className="offer-main bg-white p-4">
									{loading && (
										<div className="mb-2">
											<Skeleton height={30} />
										</div>
									)}

									{loading && (
										<div className="d-flex align-items-center mb-3 w-50">
											<div className="mr-2">
												<Skeleton circle={true} height={25} width={25} />
											</div>
											<div className="flex-grow-1">
												<Skeleton height={25} />
											</div>
										</div>
									)}
									{!loading && offer?.id && (
										<div className="d-flex justify-content-between bg-white p-2 mb-3">
											<div className="text-left font-success">
												<h3 className="d-inline-flex align-items-center">
													<span className="">{`${t(
														offer?.service_type?.service?.id,
														offer?.service_type?.service?.name
													)}, ${t(offer?.service_type?.id, offer?.service_type?.name)} `}</span>
												</h3>
												<br />
												<p className="d-inline-flex align-items-center">
													<img
														src={
															offer?.user?.photo_url ||
															"https://res.cloudinary.com/ampcome/image/upload/c_thumb,e_colorize:100,g_face,q_100,w_200/v1595014484/mahaseel/avatar.png"
														}
														style={{ height: "30px" }}
														alt=""
													/>
													<span className="ml-3 mr-3">{offer?.user?.name}</span>
												</p>
											</div>
											<div className="text-right font-danger">
												<h3 className="d-inline-flex align-items-center">
													<span className="ml-3">
														<NumberFormat
															value={parseFloat(offer?.price_per_unit).toFixed(2)}
															displayType={"text"}
															thousandSeparator={true}
															prefix={"$ "}
														/>
													</span>
												</h3>
												<br />
												{offer?.user?.id === user?.id && (
													<p className="d-inline-flex align-items-center">
														{`${t("viewed")} : ${offer?.views?.aggregate?.sum?.views || 0} | ${t("searched")}: ${offer?.views?.aggregate?.sum?.searches || 0
															}`}
													</p>
												)}
											</div>
										</div>
									)}
									{loading && (
										<div className="mb-3">
											<Skeleton height={300} />
										</div>
									)}

									{!loading && offer?.id && matches.length > 0 && user_role !== "provider" && (
										<div className="grower-description">
											<hr />
											<div className="h4 font-weight-bold mb-3">
												{user_role === "grower" ? t("matching_farms") : t("matching_locations")}
											</div>
											{matches.map((m, i) => (
												<div key={i} className="row mb-1 border-top">
													<div className="col-sm-12 col-md-8 col-lg-8">{`${m?.location?.name}`}</div>
													<div className="col-sm-12 col-md-4 col-lg-4">
														<span className="float-right">{`${m?.distance?.toFixed(2)} Kms`}</span>
													</div>
												</div>
											))}
										</div>
									)}

									{loading && !component && (
										<div className="mb-3">
											<Skeleton height={300} />
										</div>
									)}
									{!loading && !component && offer?.id && images && images.length > 0 && (
										<div className="mb-3">
											<hr />
											<div className="h4 font-weight-bold mb-3">{t("images")}</div>
											{!loading && offer && !offer?.pictures && (
												<div
													className="offer-image"
													style={{
														backgroundImage: `url(${defaultImage})`,
														height: "20rem",
													}}
												/>
											)}
											{!loading && offer && (
												<ImageGallery
													items={images.map((picture) => ({
														original: picture,
														thumbnail: getThumbNail(picture),
													}))}
													thumbnailPosition="bottom"
													showBullets={false}
													showNav={false}
													showPlayButton={false}
													showFullscreenButton={!false}
												/>
											)}
										</div>
									)}
									{false && !loading && offer?.id && offer.user && offer.user.id !== user.id && (
										<div className="grower-description">
											<hr />
											<h4 className="font-weight-bold mb-3">
												{offer?.type === "sell_offer" ? t("about_the_grower") : t("about_the_buyer")}
											</h4>
											<div className="d-flex align-items-center">
												<img
													src={
														offer?.user?.photo_url ||
														"https://res.cloudinary.com/ampcome/image/upload/c_thumb,e_colorize:100,g_face,q_100,w_200/v1595014484/mahaseel/avatar.png"
													}
													alt=""
													className="mr-4"
												/>
												<div>
													<p className="mb-1">
														<span className="font-weight-bold text-muted">{offer?.user?.name}</span>
													</p>
													{user_role &&
														((user_role === "buyer" && offer?.type === "sell_offer") ||
															(user_role === "grower" && offer?.type === "buy_provider_service")) && (
															<p>
																<Link to={"/messages"} className="btn btn-outline-primary">
																	{t("contact_me")}
																</Link>
															</p>
														)}
												</div>
											</div>
										</div>
									)}
								</div>

								<aside className="offer-sidebar">
									{loading && (
										<div className="mb-2 p-4 bg-white">
											<Skeleton height={500} />
										</div>
									)}
									{!loading && offer?.id && (
										<div className="bg-white mb-2 px-4 pb-4 pt-3 border">
											<div className="mb-2">
												<span className="text-muted mb-0">{t("service")}</span>
												<span className="font-weight-bold mb-0 float-right">
													{t(offer?.service_type?.service?.id, offer?.service_type?.service?.name)}
												</span>
											</div>
											<div className="mb-2">
												<span className="text-muted mb-0">{t("category")}</span>
												<span className="font-weight-bold mb-0 float-right">
													{t(offer?.service_type?.id, offer?.service_type?.name)}
												</span>
											</div>
											<hr />
											<div className="mb-2">
												<span className="text-muted mb-0">{t("price_per_unit")}</span>
												<span className="font-weight-bold mb-0 float-right">
													<NumberFormat
														value={parseFloat(offer?.price_per_unit).toFixed(2)}
														displayType={"text"}
														thousandSeparator={true}
														prefix={"$ "}
													/>
												</span>
											</div>
											<div className="mb-3">
												<p className="text-muted mb-0">
													{`${t("location")}`}
													<span
														onClick={() => setShowMap(!showMap)}
														className="btn link text-primary float-right ml-3"
													>
														{showMap ? t("hide_map") : t("show_map")}
													</span>
												</p>
												{user?.id === offer?.user?.id && (
													<p className="font-weight-bold mb-0">{offer?.location?.name}</p>
												)}
												<p className="font-weight-bold mb-0">
													{offer?.location?.address_line_1}, {offer?.location?.city}, {offer?.location?.state},{" "}
													{offer?.location?.country} {offer?.location?.postcode}
												</p>
											</div>
											{showMap && (
												<PreviewMap
													data={location_data}
													isMarkerShown
													loadingElement={<div style={{ height: `20rem` }} />}
													containerElement={<div style={{ height: `20rem` }} />}
													mapElement={<div style={{ height: `100%` }} />}
												/>
											)}
											{!loading && component && offer?.id && images && images.length > 0 && (
												<div className="mb-3">
													<hr />
													<div className="h4 font-weight-bold mb-3">{t("images")}</div>
													{!loading && offer && !offer?.pictures && (
														<div
															className="offer-image"
															style={{
																backgroundImage: `url(${defaultImage})`,
																height: "20rem",
															}}
														/>
													)}
													{!loading && offer && (
														<ImageGallery
															items={images.map((picture) => ({
																original: picture,
																thumbnail: getThumbNail(picture),
															}))}
															thumbnailPosition="bottom"
															showBullets={false}
															showNav={false}
															showPlayButton={false}
															showFullscreenButton={!false}
														/>
													)}
												</div>
											)}
										</div>
									)}
								</aside>
							</div>
						</div>

						{!inAdmin && (
							<>
								{loading && (
									<div className="mb-3">
										<Skeleton height={100} />
									</div>
								)}

								{offer?.user?.id !== user?.id && (
									<ServiceOfferList
										offers={offer?.similar_services_by_service_range}
										title={`${t("similar_offers_for")} ${t(offer?.service_type?.id, offer?.service_type?.name)}`}
									/>
								)}
								{loading && (
									<div className="mb-3">
										<Skeleton height={100} />
									</div>
								)}

								{offer?.user?.id !== user?.id && (
									<ServiceOfferList
										offers={offer?.similar_services_by_user_range}
										title={`${t("more_offers_by_same_provider")}`}
									/>
								)}
							</>
						)}
					</div>
				</Wrapper>
			</div>
		</>
	);
};

export default ViewServiceOffer;
