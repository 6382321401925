import React from "react";
import { Modal, Form, Col, Button } from "react-bootstrap";

export const InvitePeople = ({ show, onClose }) => {
  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={onClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>Invite a New User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Row>
            <Col md={7}>
              <Form.Group controlId="formBasicPassword">
                <Form.Label>Email Address</Form.Label>
                <Form.Control type="email" />
              </Form.Group>
            </Col>
            <Col md={5}>
              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label>Role</Form.Label>
                <Form.Control as="select">
                  <option>Agent</option>
                  <option>Moderator</option>
                  <option>Admin</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Form.Row>
          <Form.Row>
            <Col>
              <Button block onClick={onClose}>
                Send Invitation Now
              </Button>
            </Col>
          </Form.Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
