import { StyleSheet, Text, View } from "@react-pdf/renderer";
import React, { Fragment } from "react";

const styles = StyleSheet.create({
	invoiceNoContainer: {
		flexDirection: "row",
		marginTop: 36,
		justifyContent: "flex-end",
	},
	invoiceDateContainer: {
		flexDirection: "row",
		justifyContent: "flex-end",
	},
	invoiceDate: {
		fontSize: 12,
		fontStyle: "bold",
	},
	label: {
		width: 60,
	},
});

const InvoiceNo = ({ invoice, service }) => (
	<Fragment>
		<View style={styles.invoiceNoContainer}>
			<Text style={styles.label}>Invoice No:</Text>
			<Text style={styles.invoiceDate}>{invoice.invoice_no}</Text>
		</View>
		<View style={styles.invoiceDateContainer}>
			<Text>Date: </Text>
			<Text>{invoice.trans_date}</Text>
		</View>
	</Fragment>
);

export default InvoiceNo;
