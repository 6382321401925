import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";
import GreenMarker from "../../../../images/marker-green.png";
import RedMarker from "../../../../images/marker-red.png";
import YellowMarker from "../../../../images/marker-yellow.png";
import { MapView } from "../../../../shared/components";
import { useDocTitle } from "../../../../shared/helpers";
import { useTranslatedLabel } from "../../../../shared/hooks";
import { FilterDropdown, Layout } from "../../components";
import { PATHS } from "../../config";
import { useAggregateSubscription } from "../../hooks/aggregateSubscription";
import { useCountSubscription } from "../../hooks/countSubscription";
import { TinyLineChart } from "./tiny-line-chart";

const map_fields = `id location { id name city country lat lng  is_farm user { id buyer_access grower_access provider_access name }  name enum_country { value comment } }`;

export const Dashboard = ({ component = false, default_filters = {} }) => {
	useDocTitle("dashboard");
	const { t } = useTranslatedLabel();
	const [date_range, setDateRange] = useState("last_6_months");
	const [filters, setFilters] = useState({ ...default_filters });
	useEffect(() => {
		let new_filters = { ...filters };
		switch (date_range) {
			case "today":
				new_filters.from_date = dayjs().startOf("day").toISOString();
				new_filters.to_date = dayjs().endOf("day").toISOString();
				break;
			case "last_7_days":
				new_filters.from_date = dayjs().subtract(7, "day").format("YYYY-MM-DD");
				new_filters.to_date = dayjs().format("YYYY-MM-DD");
				break;
			case "last_30_days":
				new_filters.from_date = dayjs().subtract(30, "day").format("YYYY-MM-DD");
				new_filters.to_date = dayjs().format("YYYY-MM-DD");
				break;
			case "last_3_months":
				new_filters.from_date = dayjs().subtract(3, "month").format("YYYY-MM-DD");
				new_filters.to_date = dayjs().format("YYYY-MM-DD");
				break;
			case "last_6_months":
				new_filters.from_date = dayjs().subtract(6, "month").format("YYYY-MM-DD");
				new_filters.to_date = dayjs().format("YYYY-MM-DD");
				break;
			case "this_week":
				new_filters.from_date = dayjs().startOf("week").format("YYYY-MM-DD");
				new_filters.to_date = dayjs().endOf("week").format("YYYY-MM-DD");
				break;
			case "this_month":
				new_filters.from_date = dayjs().startOf("month").format("YYYY-MM-DD");
				new_filters.to_date = dayjs().endOf("month").format("YYYY-MM-DD");
				break;
			case "this_year":
				new_filters.from_date = dayjs().startOf("year").format("YYYY-MM-DD");
				new_filters.to_date = dayjs().endOf("year").format("YYYY-MM-DD");
				break;
			case "last_week":
				new_filters.from_date = dayjs().subtract(1, "week").startOf("week").format("YYYY-MM-DD");
				new_filters.to_date = dayjs().subtract(1, "week").endOf("week").format("YYYY-MM-DD");
				break;
			case "last_month":
				new_filters.from_date = dayjs().subtract(1, "month").startOf("month").format("YYYY-MM-DD");
				new_filters.to_date = dayjs().subtract(1, "month").endOf("month").format("YYYY-MM-DD");
				break;
			case "last_year":
				new_filters.from_date = dayjs().subtract(1, "year").startOf("year").format("YYYY-MM-DD");
				new_filters.to_date = dayjs().subtract(1, "year").endOf("year").format("YYYY-MM-DD");
				break;
			default:
				break;
		}
		setFilters(new_filters);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [date_range]);

	const active_sell_offers_count = useCountSubscription("requests", {
		request_id: { _is_null: true },
		type: { _eq: "sell_offer" },
		created_at: { _gte: filters.from_date, _lte: filters.to_date },
	});
	const active_sell_offers_amount = useAggregateSubscription(
		"requests",
		{
			type: { _eq: "sell_offer" },
			request_id: { _is_null: true },
			created_at: { _gte: filters.from_date, _lte: filters.to_date },
		},
		"total_amount"
	);
	const total_sell_offers_count = useCountSubscription("requests", {
		type: { _eq: "sell_offer" },
		request_id: { _is_null: true },
	});

	const total_sell_offers_amount = useAggregateSubscription(
		"requests",
		{ type: { _eq: "sell_offer" }, request_id: { _is_null: true } },
		"total_amount"
	);

	const active_buy_offers_count = useCountSubscription("requests", {
		request_id: { _is_null: true },
		type: { _eq: "buy_request" },
		created_at: { _gte: filters.from_date, _lte: filters.to_date },
	});
	const active_buy_offers_amount = useAggregateSubscription(
		"requests",
		{
			type: { _eq: "buy_request" },
			request_id: { _is_null: true },
			created_at: { _gte: filters.from_date, _lte: filters.to_date },
		},
		"total_amount"
	);
	const total_buy_offers_count = useCountSubscription("requests", {
		type: { _eq: "buy_request" },
		request_id: { _is_null: true },
	});

	const total_buy_offers_amount = useAggregateSubscription(
		"requests",
		{ type: { _eq: "buy_request" }, request_id: { _is_null: true } },
		"total_amount"
	);

	const active_service_offers_count = useCountSubscription("provider_services", {
		created_at: { _gte: filters.from_date, _lte: filters.to_date },
	});
	const active_service_offers_amount = useAggregateSubscription(
		"provider_services",
		{ created_at: { _gte: filters.from_date, _lte: filters.to_date } },
		"price_per_unit"
	);
	const total_service_offers_count = useCountSubscription("provider_services", {});
	const total_service_offers_amount = useAggregateSubscription("provider_services", {}, "price_per_unit");

	const active_buyers_count = useCountSubscription("users", {
		buyer_access: { _eq: true },
		created_at: { _gte: filters.from_date, _lte: filters.to_date },
	});
	const total_buyers_count = useCountSubscription("users", {
		buyer_access: { _eq: true },
	});

	const active_sellers_count = useCountSubscription("users", {
		grower_access: { _eq: true },
		created_at: { _gte: filters.from_date, _lte: filters.to_date },
	});
	const total_sellers_count = useCountSubscription("users", {
		grower_access: { _eq: true },
	});

	const active_providers_count = useCountSubscription("users", {
		provider_access: { _eq: true },
		created_at: { _gte: filters.from_date, _lte: filters.to_date },
	});
	const total_providers_count = useCountSubscription("users", {
		provider_access: { _eq: true },
	});

	const active_service_orders_count = useCountSubscription("service_orders", {
		created_at: { _gte: filters.from_date, _lte: filters.to_date },
	});
	const active_service_orders_amount = useAggregateSubscription(
		"service_orders",
		{ created_at: { _gte: filters.from_date, _lte: filters.to_date } },
		"total_amount"
	);
	const total_service_orders_count = useCountSubscription("service_orders");
	const total_service_orders_amount = useAggregateSubscription("service_orders", {}, "total_amount");

	const active_trade_orders_count = useCountSubscription("trade_orders", { created_at: { _gte: filters.from_date, _lte: filters.to_date } });
	const active_trade_orders_amount = useAggregateSubscription(
		"trade_orders",
		{ created_at: { _gte: filters.from_date, _lte: filters.to_date } },
		"total_amount"
	);
	const total_trade_orders_count = useCountSubscription("trade_orders");
	const total_trade_orders_amount = useAggregateSubscription("trade_orders", {}, "total_amount");

	const active_trade_customers_count = useCountSubscription("users", {
		_or: [
			{
				grower_access: { _eq: true },
				tradeOrdersBySellerId: { id: { _is_null: false } },
			},
			{
				buyer_access: { _eq: true },
				tradeOrdersByBuyerId: { id: { _is_null: false } },
			},
		],
		created_at: { _gte: filters.from_date, _lte: filters.to_date },
	});
	const total_trade_customers_count = useCountSubscription("users", {
		_or: [
			{
				grower_access: { _eq: true },
				tradeOrdersBySellerId: { id: { _is_null: false } },
			},
			{
				buyer_access: { _eq: true },
				tradeOrdersByBuyerId: { id: { _is_null: false } },
			},
		],
	});

	const active_service_customers_count = useCountSubscription("users", {
		_or: [
			{
				grower_access: { _eq: true },
				serviceOrdersByBuyerId: { id: { _is_null: false } },
			},
			{
				buyer_access: { _eq: true },
				serviceOrdersByBuyerId: { id: { _is_null: false } },
			},
			{
				provider_access: { _eq: true },
				serviceOrdersBySellerId: { id: { _is_null: false } },
			},
		],
		created_at: { _gte: filters.from_date, _lte: filters.to_date },
	});
	const total_service_customers_count = useCountSubscription("users", {
		_or: [
			{
				grower_access: { _eq: true },
				serviceOrdersByBuyerId: { id: { _is_null: false } },
			},
			{
				buyer_access: { _eq: true },
				serviceOrdersByBuyerId: { id: { _is_null: false } },
			},
			{
				provider_access: { _eq: true },
				serviceOrdersBySellerId: { id: { _is_null: false } },
			},
		],
	});

	return (
		<Layout title={t("dashboard")}>
			<div className="dashboard font-roboto-condensed ">
				<div className="row">
					<div className="col">
						<FilterDropdown
							data={[
								{ name: "Today", value: "today" },
								{ name: "This Week", value: "this_week" },
								{ name: "This Month", value: "this_month" },
								{ name: "This Year", value: "this_year" },
								{ name: "Last Week", value: "last_week" },
								{ name: "Last Month", value: "last_month" },
								{ name: "Last Year", value: "last_year" },
								{ name: "Last 7 Days", value: "last_7_days" },
								{ name: "Last 30 Days", value: "last_30_days" },
								{ name: "Last 3 Months", value: "last_3_months" },
								{ name: "Last 6 Months", value: "last_6_months" },
							].map((e) => {
								let newObj = { ...e };
								newObj.name = `${t(e.value, e.name)}`;
								return newObj;
							})}
							title={t(date_range)}
							nameField="name"
							name="date_range"
							value={date_range}
							valueField="value"
							onChange={(e) => setDateRange(e.target.value)}
						/>
					</div>
				</div>
				<div className="row p-2 bg-light ">
					<div className="col-lg-4 col-md-6 col-sm-12 dashboard-item bg-light p-1">
						<div className="bg-white p-3">
							<div className="d-flex justify-content-between align-items-center mb-3">
								<h3 className="mb-0 font-roboto-condensed">Sell Offers</h3>
								<p className="mb-0">
									<Link to={PATHS.sellOffers} className="sm-text d-flex align-items-center">
										<i className="ml-1 las la-long-arrow-alt-right"></i> <span>View All</span>
									</Link>
								</p>
							</div>

							<div className="mb-2">
								<div className="row">
									<div className="col-6 px-0">
										<h5 className="mb-0">New Offers</h5>
									</div>
									<div className="col-3 px-0">
										<h5 className="mb-0 text-center">
											<NumberFormat
												value={active_sell_offers_amount}
												displayType={"text"}
												thousandSeparator={true}
												prefix={"$ "}
											/>
										</h5>
									</div>
									<div className="col-3 px-0">
										<h5 className="mb-0 text-right">
											<NumberFormat
												value={parseFloat(
													(active_sell_offers_amount * 100) /
													(active_sell_offers_amount + active_buy_offers_amount + active_service_offers_amount)
												).toFixed(1)}
												displayType={"text"}
												suffix=" %"
											/>
										</h5>
									</div>
								</div>
								<div className="row">
									<div className="col-6 px-0">
										<p className="sm-text text-greyed mb-0">Count of New Offers</p>
									</div>
									<div className="col-3 px-0">
										<p className="sm-text text-greyed mb-0 text-center">
											<NumberFormat value={active_sell_offers_count} displayType={"text"} thousandSeparator={true} />
										</p>
									</div>
									<div className="col-3 px-0">
										<p className="sm-text text-greyed mb-0 text-right">
											<NumberFormat
												value={parseFloat(
													(active_sell_offers_count * 100) /
													(active_sell_offers_count + active_buy_offers_count + active_service_offers_count)
												).toFixed(1)}
												displayType={"text"}
												suffix=" %"
											/>
										</p>
									</div>
								</div>
							</div>

							<div className="mb-2">
								<div className="row">
									<div className="col-6 px-0">
										<h5 className="mb-0">All Offers</h5>
									</div>
									<div className="col-3 px-0">
										<h5 className="mb-0 text-center">
											<NumberFormat
												value={total_sell_offers_amount}
												displayType={"text"}
												thousandSeparator={true}
												prefix={"$ "}
											/>
										</h5>
									</div>
									<div className="col-3 px-0">
										<h5 className="mb-0 text-right">
											<NumberFormat
												value={parseFloat(
													(total_sell_offers_amount * 100) /
													(total_sell_offers_amount + total_buy_offers_amount + total_service_offers_amount)
												).toFixed(1)}
												displayType={"text"}
												suffix=" %"
											/>
										</h5>
									</div>
								</div>
								<div className="row">
									<div className="col-6 px-0">
										<p className="sm-text text-greyed mb-0">Count of All Offers</p>
									</div>
									<div className="col-3 px-0">
										<p className="sm-text text-greyed mb-0 text-center">
											<NumberFormat value={total_sell_offers_count} displayType={"text"} thousandSeparator={true} />
										</p>
									</div>
									<div className="col-3 px-0">
										<p className="sm-text text-greyed mb-0 text-right">
											<NumberFormat
												value={parseFloat(
													(total_sell_offers_count * 100) /
													(total_sell_offers_count + total_buy_offers_count + total_service_offers_count)
												).toFixed(1)}
												displayType={"text"}
												suffix=" %"
											/>
										</p>
									</div>
								</div>
							</div>

							<div className="mb-2">
								<div className="row">
									<div className="col-6 px-0">
										<h5 className="mb-0">New Growers</h5>
									</div>
									<div className="col-3 px-0">
										<h5 className="mb-0 text-center">
											<NumberFormat value={active_sellers_count} displayType={"text"} thousandSeparator={true} />
										</h5>
									</div>
									<div className="col-3 px-0">
										<h5 className="mb-0 text-right">
											<NumberFormat
												value={parseFloat(
													(active_sellers_count * 100) /
													(active_sellers_count + active_buyers_count + active_providers_count)
												).toFixed(1)}
												displayType={"text"}
												suffix=" %"
											/>
										</h5>
									</div>
								</div>
								<div className="row">
									<div className="col-6 px-0">
										<p className="sm-text text-greyed mb-0">All Growers</p>
									</div>
									<div className="col-3 px-0">
										<p className="sm-text text-greyed mb-0 text-center">
											<NumberFormat value={total_sellers_count} displayType={"text"} thousandSeparator={true} />
										</p>
									</div>
									<div className="col-3 px-0">
										<p className="sm-text text-greyed mb-0 text-right">
											<NumberFormat
												value={parseFloat(
													(total_sellers_count * 100) /
													(total_sellers_count + total_buyers_count + total_providers_count)
												).toFixed(1)}
												displayType={"text"}
												suffix=" %"
											/>
										</p>
									</div>
								</div>
							</div>
							<hr />
							<div className="chart-container ">
								<TinyLineChart
									filters={{ date: { _gte: filters.from_date, _lte: filters.to_date } }}
									title="Sell Offers"
									entity="z_sell_offers"
									userEntity="z_sellers"
									userColumn="Growers"
								/>
							</div>
						</div>
					</div>
					<div className="col-lg-4 col-md-6 col-sm-12 dashboard-item bg-light p-1">
						<div className="bg-white p-3">
							<div className="d-flex justify-content-between align-items-center mb-3">
								<h3 className="mb-0 font-roboto-condensed">Buy Offers</h3>
								<p className="mb-0">
									<Link to={PATHS.buyOffers} className="sm-text d-flex align-items-center">
										<i className="ml-1 las la-long-arrow-alt-right"></i> <span>View All</span>
									</Link>
								</p>
							</div>

							<div className="mb-2">
								<div className="row">
									<div className="col-6 px-0">
										<h5 className="mb-0">New Offers</h5>
									</div>
									<div className="col-3 px-0">
										<h5 className="mb-0 text-center">
											<NumberFormat
												value={active_buy_offers_amount}
												displayType={"text"}
												thousandSeparator={true}
												prefix={"$ "}
											/>
										</h5>
									</div>
									<div className="col-3 px-0">
										<h5 className="mb-0 text-right">
											<NumberFormat
												value={parseFloat(
													(active_buy_offers_amount * 100) /
													(active_sell_offers_amount + active_buy_offers_amount + active_service_offers_amount)
												).toFixed(1)}
												displayType={"text"}
												suffix=" %"
											/>
										</h5>
									</div>
								</div>
								<div className="row">
									<div className="col-6 px-0">
										<p className="sm-text text-greyed mb-0">Count of New Offers</p>
									</div>
									<div className="col-3 px-0">
										<p className="sm-text text-greyed mb-0 text-center">
											<NumberFormat value={active_buy_offers_count} displayType={"text"} thousandSeparator={true} />
										</p>
									</div>
									<div className="col-3 px-0">
										<p className="sm-text text-greyed mb-0 text-right">
											<NumberFormat
												value={parseFloat(
													(active_buy_offers_count * 100) /
													(active_sell_offers_count + active_buy_offers_count + active_service_offers_count)
												).toFixed(1)}
												displayType={"text"}
												suffix=" %"
											/>
										</p>
									</div>
								</div>
							</div>

							<div className="mb-2">
								<div className="row">
									<div className="col-6 px-0">
										<h5 className="mb-0">All Offers</h5>
									</div>
									<div className="col-3 px-0">
										<h5 className="mb-0 text-center">
											<NumberFormat
												value={total_buy_offers_amount}
												displayType={"text"}
												thousandSeparator={true}
												prefix={"$ "}
											/>
										</h5>
									</div>
									<div className="col-3 px-0">
										<h5 className="mb-0 text-right">
											<NumberFormat
												value={parseFloat(
													(total_buy_offers_amount * 100) /
													(total_sell_offers_amount + total_buy_offers_amount + total_service_offers_amount)
												).toFixed(1)}
												displayType={"text"}
												suffix=" %"
											/>
										</h5>
									</div>
								</div>
								<div className="row">
									<div className="col-6 px-0">
										<p className="sm-text text-greyed mb-0">Count of All Offers</p>
									</div>
									<div className="col-3 px-0">
										<p className="sm-text text-greyed mb-0 text-center">
											<NumberFormat value={total_buy_offers_count} displayType={"text"} thousandSeparator={true} />
										</p>
									</div>
									<div className="col-3 px-0">
										<p className="sm-text text-greyed mb-0 text-right">
											<NumberFormat
												value={parseFloat(
													(total_buy_offers_count * 100) /
													(total_sell_offers_count + total_buy_offers_count + total_service_offers_count)
												).toFixed(1)}
												displayType={"text"}
												suffix=" %"
											/>
										</p>
									</div>
								</div>
							</div>

							<div className="mb-2">
								<div className="row">
									<div className="col-6 px-0">
										<h5 className="mb-0">New Buyers</h5>
									</div>
									<div className="col-3 px-0">
										<h5 className="mb-0 text-center">
											<NumberFormat value={active_buyers_count} displayType={"text"} thousandSeparator={true} />
										</h5>
									</div>
									<div className="col-3 px-0">
										<h5 className="mb-0 text-right">
											<NumberFormat
												value={parseFloat(
													(active_buyers_count * 100) /
													(active_sellers_count + active_buyers_count + active_providers_count)
												).toFixed(1)}
												displayType={"text"}
												suffix=" %"
											/>
										</h5>
									</div>
								</div>
								<div className="row">
									<div className="col-6 px-0">
										<p className="sm-text text-greyed mb-0">All Buyers</p>
									</div>
									<div className="col-3 px-0">
										<p className="sm-text text-greyed mb-0 text-center">
											<NumberFormat value={total_buyers_count} displayType={"text"} thousandSeparator={true} />
										</p>
									</div>
									<div className="col-3 px-0">
										<p className="sm-text text-greyed mb-0 text-right">
											<NumberFormat
												value={parseFloat(
													(total_buyers_count * 100) /
													(total_sellers_count + total_buyers_count + total_providers_count)
												).toFixed(1)}
												displayType={"text"}
												suffix=" %"
											/>
										</p>
									</div>
								</div>
							</div>
							<hr />
							<div className="chart-container ">
								<TinyLineChart
									filters={{ date: { _gte: filters.from_date, _lte: filters.to_date } }}
									title="Buy Offers"
									entity="z_buy_offers"
									userEntity="z_buyers"
									userColumn="Buyers"
								/>
							</div>
						</div>
					</div>
					<div className="col-lg-4 col-md-6 col-sm-12 dashboard-item bg-light p-1">
						<div className="bg-white p-3">
							<div className="d-flex justify-content-between align-items-center mb-3">
								<h3 className="mb-0 font-roboto-condensed">Service Offers</h3>
								<p className="mb-0">
									<Link to={PATHS.serviceOffers} className="sm-text d-flex align-items-center">
										<i className="ml-1 las la-long-arrow-alt-right"></i> <span>View All</span>
									</Link>
								</p>
							</div>

							<div className="mb-2">
								<div className="row">
									<div className="col-6 px-0">
										<h5 className="mb-0">New Offers</h5>
									</div>
									<div className="col-3 px-0">
										<h5 className="mb-0 text-center">
											<NumberFormat
												value={active_service_offers_amount}
												displayType={"text"}
												thousandSeparator={true}
												prefix={"$ "}
											/>
										</h5>
									</div>
									<div className="col-3 px-0">
										<h5 className="mb-0 text-right">
											<NumberFormat
												value={parseFloat(
													(active_service_offers_amount * 100) /
													(active_sell_offers_amount + active_buy_offers_amount + active_service_offers_amount)
												).toFixed(1)}
												displayType={"text"}
												suffix=" %"
											/>
										</h5>
									</div>
								</div>
								<div className="row">
									<div className="col-6 px-0">
										<p className="sm-text text-greyed mb-0">Count of New Offers</p>
									</div>
									<div className="col-3 px-0">
										<p className="sm-text text-greyed mb-0 text-center">
											<NumberFormat value={active_service_offers_count} displayType={"text"} thousandSeparator={true} />
										</p>
									</div>
									<div className="col-3 px-0">
										<p className="sm-text text-greyed mb-0 text-right">
											<NumberFormat
												value={parseFloat(
													(active_service_offers_count * 100) /
													(active_sell_offers_count + active_buy_offers_count + active_service_offers_count)
												).toFixed(1)}
												displayType={"text"}
												suffix=" %"
											/>
										</p>
									</div>
								</div>
							</div>

							<div className="mb-2">
								<div className="row">
									<div className="col-6 px-0">
										<h5 className="mb-0">All Offers</h5>
									</div>
									<div className="col-3 px-0">
										<h5 className="mb-0 text-center">
											<NumberFormat
												value={total_service_offers_amount}
												displayType={"text"}
												thousandSeparator={true}
												prefix={"$ "}
											/>
										</h5>
									</div>
									<div className="col-3 px-0">
										<h5 className="mb-0 text-right">
											<NumberFormat
												value={parseFloat(
													(total_service_offers_amount * 100) /
													(total_sell_offers_amount + total_buy_offers_amount + total_service_offers_amount)
												).toFixed(1)}
												displayType={"text"}
												suffix=" %"
											/>
										</h5>
									</div>
								</div>
								<div className="row">
									<div className="col-6 px-0">
										<p className="sm-text text-greyed mb-0">Count of All Offers</p>
									</div>
									<div className="col-3 px-0">
										<p className="sm-text text-greyed mb-0 text-center">
											<NumberFormat value={total_service_offers_count} displayType={"text"} thousandSeparator={true} />
										</p>
									</div>
									<div className="col-3 px-0">
										<p className="sm-text text-greyed mb-0 text-right">
											<NumberFormat
												value={parseFloat(
													(total_service_offers_count * 100) /
													(total_sell_offers_count + total_buy_offers_count + total_service_offers_count)
												).toFixed(1)}
												displayType={"text"}
												suffix=" %"
											/>
										</p>
									</div>
								</div>
							</div>

							<div className="mb-2">
								<div className="row">
									<div className="col-6 px-0">
										<h5 className="mb-0">New Providers</h5>
									</div>
									<div className="col-3 px-0">
										<h5 className="mb-0 text-center">
											<NumberFormat value={active_providers_count} displayType={"text"} thousandSeparator={true} />
										</h5>
									</div>
									<div className="col-3 px-0">
										<h5 className="mb-0 text-right">
											<NumberFormat
												value={parseFloat(
													(active_providers_count * 100) /
													(active_sellers_count + active_buyers_count + active_providers_count)
												).toFixed(1)}
												displayType={"text"}
												suffix=" %"
											/>
										</h5>
									</div>
								</div>
								<div className="row">
									<div className="col-6 px-0">
										<p className="sm-text text-greyed mb-0">All Providers</p>
									</div>
									<div className="col-3 px-0">
										<p className="sm-text text-greyed mb-0 text-center">
											<NumberFormat value={total_providers_count} displayType={"text"} thousandSeparator={true} />
										</p>
									</div>
									<div className="col-3 px-0">
										<p className="sm-text text-greyed mb-0 text-right">
											<NumberFormat
												value={parseFloat(
													(total_providers_count * 100) /
													(total_sellers_count + total_buyers_count + total_providers_count)
												).toFixed(1)}
												displayType={"text"}
												suffix=" %"
											/>
										</p>
									</div>
								</div>
							</div>
							<hr />
							<div className="chart-container ">
								<TinyLineChart
									filters={{ date: { _gte: filters.from_date, _lte: filters.to_date } }}
									title="Service Offers"
									entity="z_service_offers"
									userEntity="z_providers"
									userColumn="Providers"
								/>
							</div>
						</div>
					</div>
					<div className="col-lg-4 col-md-6 col-sm-12 dashboard-item bg-light p-1">
						<div className="bg-white p-3">
							<div className="d-flex justify-content-between align-items-center mb-3">
								<h3 className="mb-0 font-roboto-condensed">Trade Orders</h3>
								<p className="mb-0">
									<Link to={PATHS.orders} className="sm-text d-flex align-items-center">
										<i className="ml-1 las la-long-arrow-alt-right"></i> <span>View All</span>
									</Link>
								</p>
							</div>
							<div>
								<div className="mb-2">
									<div className="row">
										<div className="col-6 px-0">
											<h5 className="mb-0">New Orders</h5>
										</div>
										<div className="col-3 px-0">
											<h5 className="mb-0 text-center">
												<NumberFormat
													value={active_trade_orders_amount}
													displayType={"text"}
													thousandSeparator={true}
													prefix={"$ "}
												/>
											</h5>
										</div>
										<div className="col-3 px-0">
											<h5 className="mb-0 text-right">
												<NumberFormat
													value={parseFloat(
														(active_trade_orders_amount * 100) /
														(active_trade_orders_amount + active_service_orders_amount)
													).toFixed(1)}
													displayType={"text"}
													suffix=" %"
												/>
											</h5>
										</div>
									</div>
									<div className="row">
										<div className="col-6 px-0">
											<p className="sm-text text-greyed mb-0">Count of New Orders</p>
										</div>
										<div className="col-3 px-0">
											<p className="sm-text text-greyed mb-0 text-center">
												<NumberFormat value={active_trade_orders_count} displayType={"text"} thousandSeparator={true} />
											</p>
										</div>
										<div className="col-3 px-0">
											<p className="sm-text text-greyed mb-0 text-right">
												<NumberFormat
													value={parseFloat(
														(active_trade_orders_count * 100) /
														(active_trade_orders_count + active_service_orders_count)
													).toFixed(1)}
													displayType={"text"}
													suffix=" %"
												/>
											</p>
										</div>
									</div>
								</div>

								<div className="mb-2">
									<div className="row">
										<div className="col-6 px-0">
											<h5 className="mb-0">All Orders</h5>
										</div>
										<div className="col-3 px-0">
											<h5 className="mb-0 text-center">
												<NumberFormat
													value={total_trade_orders_amount}
													displayType={"text"}
													thousandSeparator={true}
													prefix={"$ "}
												/>
											</h5>
										</div>
										<div className="col-3 px-0">
											<h5 className="mb-0 text-right">
												<NumberFormat
													value={parseFloat(
														(total_trade_orders_amount * 100) /
														(total_trade_orders_amount + total_service_orders_amount)
													).toFixed(1)}
													displayType={"text"}
													suffix=" %"
												/>
											</h5>
										</div>
									</div>
									<div className="row">
										<div className="col-6 px-0">
											<p className="sm-text text-greyed mb-0">Count of All Orders</p>
										</div>
										<div className="col-3 px-0">
											<p className="sm-text text-greyed mb-0 text-center">
												<NumberFormat value={total_trade_orders_count} displayType={"text"} thousandSeparator={true} />
											</p>
										</div>
										<div className="col-3 px-0">
											<p className="sm-text text-greyed mb-0 text-right">
												<NumberFormat
													value={parseFloat(
														(total_trade_orders_count * 100) /
														(total_trade_orders_count + total_service_orders_count)
													).toFixed(1)}
													displayType={"text"}
													suffix=" %"
												/>
											</p>
										</div>
									</div>
								</div>

								<div className="mb-2">
									<div className="row">
										<div className="col-6 px-0">
											<h5 className="mb-0">New Customers</h5>
										</div>
										<div className="col-3 px-0">
											<h5 className="mb-0 text-center">
												<NumberFormat
													value={active_trade_customers_count}
													displayType={"text"}
													thousandSeparator={true}
												/>
											</h5>
										</div>
										<div className="col-3 px-0">
											<h5 className="mb-0 text-right">
												<NumberFormat
													value={parseFloat(
														(active_trade_customers_count * 100) /
														(active_trade_customers_count + active_service_customers_count)
													).toFixed(1)}
													displayType={"text"}
													suffix=" %"
												/>
											</h5>
										</div>
									</div>
									<div className="row">
										<div className="col-6 px-0">
											<p className="sm-text text-greyed mb-0">All Customers</p>
										</div>
										<div className="col-3 px-0">
											<p className="sm-text text-greyed mb-0 text-center">
												<NumberFormat
													value={total_trade_customers_count}
													displayType={"text"}
													thousandSeparator={true}
												/>
											</p>
										</div>
										<div className="col-3 px-0">
											<p className="sm-text text-greyed mb-0 text-right">
												<NumberFormat
													value={parseFloat(
														(total_trade_customers_count * 100) /
														(total_trade_customers_count + total_service_customers_count)
													).toFixed(1)}
													displayType={"text"}
													suffix=" %"
												/>
											</p>
										</div>
									</div>
								</div>
								<hr />
								<div className="chart-container">
									<TinyLineChart
										filters={{ date: { _gte: filters.from_date, _lte: filters.to_date } }}
										title="Trade Orders"
										entity="z_trade_orders"
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-4 col-md-6 col-sm-12 dashboard-item bg-light p-1">
						<div className="bg-white p-3">
							<div className="d-flex justify-content-between align-items-center mb-3">
								<h3 className="mb-0 font-roboto-condensed">Service Orders</h3>
								<p className="mb-0">
									<Link to={PATHS.serviceOrders} className="sm-text d-flex align-items-center">
										<i className="ml-1 las la-long-arrow-alt-right"></i> <span>View All</span>
									</Link>
								</p>
							</div>
							<div>
								<div className="mb-2">
									<div className="row">
										<div className="col-6 px-0">
											<h5 className="mb-0">New Orders</h5>
										</div>
										<div className="col-3 px-0">
											<h5 className="mb-0 text-center">
												<NumberFormat
													value={active_service_orders_amount}
													displayType={"text"}
													thousandSeparator={true}
													prefix={"$ "}
												/>
											</h5>
										</div>
										<div className="col-3 px-0">
											<h5 className="mb-0 text-right">
												<NumberFormat
													value={parseFloat(
														(active_service_orders_amount * 100) /
														(active_trade_orders_amount + active_service_orders_amount)
													).toFixed(1)}
													displayType={"text"}
													suffix=" %"
												/>
											</h5>
										</div>
									</div>
									<div className="row">
										<div className="col-6 px-0">
											<p className="sm-text text-greyed mb-0">Count of New Orders</p>
										</div>
										<div className="col-3 px-0">
											<p className="sm-text text-greyed mb-0 text-center">
												<NumberFormat
													value={active_service_orders_count}
													displayType={"text"}
													thousandSeparator={true}
												/>
											</p>
										</div>
										<div className="col-3 px-0">
											<p className="sm-text text-greyed mb-0 text-right">
												<NumberFormat
													value={parseFloat(
														(active_service_orders_count * 100) /
														(active_trade_orders_count + active_service_orders_count)
													).toFixed(1)}
													displayType={"text"}
													suffix=" %"
												/>
											</p>
										</div>
									</div>
								</div>

								<div className="mb-2">
									<div className="row">
										<div className="col-6 px-0">
											<h5 className="mb-0">All Orders</h5>
										</div>
										<div className="col-3 px-0">
											<h5 className="mb-0 text-center">
												<NumberFormat
													value={total_service_orders_amount}
													displayType={"text"}
													thousandSeparator={true}
													prefix={"$ "}
												/>
											</h5>
										</div>
										<div className="col-3 px-0">
											<h5 className="mb-0 text-right">
												<NumberFormat
													value={parseFloat(
														(total_service_orders_amount * 100) /
														(total_trade_orders_amount + total_service_orders_amount)
													).toFixed(1)}
													displayType={"text"}
													suffix=" %"
												/>
											</h5>
										</div>
									</div>
									<div className="row">
										<div className="col-6 px-0">
											<p className="sm-text text-greyed mb-0">Count of All Orders</p>
										</div>
										<div className="col-3 px-0">
											<p className="sm-text text-greyed mb-0 text-center">
												<NumberFormat
													value={total_service_orders_count}
													displayType={"text"}
													thousandSeparator={true}
												/>
											</p>
										</div>
										<div className="col-3 px-0">
											<p className="sm-text text-greyed mb-0 text-right">
												<NumberFormat
													value={parseFloat(
														(total_service_orders_count * 100) /
														(total_trade_orders_count + total_service_orders_count)
													).toFixed(1)}
													displayType={"text"}
													suffix=" %"
												/>
											</p>
										</div>
									</div>
								</div>

								<div className="mb-2">
									<div className="row">
										<div className="col-6 px-0">
											<h5 className="mb-0">New Customers</h5>
										</div>
										<div className="col-3 px-0">
											<h5 className="mb-0 text-center">
												<NumberFormat
													value={active_service_customers_count}
													displayType={"text"}
													thousandSeparator={true}
												/>
											</h5>
										</div>
										<div className="col-3 px-0">
											<h5 className="mb-0 text-right">
												<NumberFormat
													value={parseFloat(
														(active_service_customers_count * 100) /
														(active_trade_customers_count + active_service_customers_count)
													).toFixed(1)}
													displayType={"text"}
													suffix=" %"
												/>
											</h5>
										</div>
									</div>
									<div className="row">
										<div className="col-6 px-0">
											<p className="sm-text text-greyed mb-0">All Customers</p>
										</div>
										<div className="col-3 px-0">
											<p className="sm-text text-greyed mb-0 text-center">
												<NumberFormat
													value={total_service_customers_count}
													displayType={"text"}
													thousandSeparator={true}
												/>
											</p>
										</div>
										<div className="col-3 px-0">
											<p className="sm-text text-greyed mb-0 text-right">
												<NumberFormat
													value={parseFloat(
														(total_service_customers_count * 100) /
														(total_trade_customers_count + total_service_customers_count)
													).toFixed(1)}
													displayType={"text"}
													suffix=" %"
												/>
											</p>
										</div>
									</div>
								</div>
								<hr />
								<div className="chart-container">
									<TinyLineChart
										filters={{ date: { _gte: filters.from_date, _lte: filters.to_date } }}
										title="Service Orders"
										entity="z_service_orders"
									/>
								</div>
							</div>
						</div>
					</div>

					<div className="col-lg-4 col-md-6 col-sm-12 dashboard-item bg-light p-1">
						<div className="bg-white p-3">
							<div className="d-flex justify-content-between align-items-center mb-3">
								<h3 className="mb-0 font-roboto-condensed">
									<span className="d-inline-flex align-items-center">
										<img src={GreenMarker} alt="" />
										<span className="ml-3 mr-3">Growers</span>
									</span>
									<span className="d-inline-flex align-items-center">
										<img src={RedMarker} alt="" />
										<span className="ml-3 mr-3">Buyers</span>
									</span>
									<span className="d-inline-flex align-items-center">
										<img src={YellowMarker} alt="" />
										<span className="ml-3 mr-3">Providers</span>
									</span>
								</h3>
							</div>
							<div className="map-container">
								<MapView
									zoom={1}
									height={350}
									entity="users"
									where={{ active: { _eq: true } }}
									order_by={{ uid: "desc" }}
									query_fields={map_fields}
									map_field="location"
									filters={{ created_at: { _gte: filters.from_date, _lte: filters.to_date } }}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
};
