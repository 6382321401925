import React from "react";
import Sugar from "react-preloaders/lib/Sugar/Sugar";
import { ToastProvider } from "react-toast-notifications";
import AdminRoot from "./root";
import AdminGlobalProvider from "./store";

(async () => {
	if (process.env.REACT_APP_ENV === "admin") {
		await import("react-datepicker/dist/react-datepicker.css");
		await import("antd/lib/table/style/index.css");
		await import("antd/lib/button/style/index.css");
		await import("antd/lib/drawer/style/index.css");
		await import("antd/lib/pagination/style/index.css");
		await import("antd/lib/select/style/index.css");
		await import("antd/lib/modal/style/index.css");
		await import("antd/lib/spin/style/index.css");
		await import("react-date-range/dist/styles.css");
		await import("react-date-range/dist/theme/default.css");
		await import("react-input-range/lib/css/index.css");
		await import("react-image-gallery/styles/css/image-gallery.css");
		await import("./css/pretty-checkbox.min.css");
		await import("./css/bid-chat.css");
		await import("./css/custom.css");
		await import("./css/responsive.css");
	}
})();

const Admin = () => {
	return (
		<AdminGlobalProvider>
			<div className="app font-roboto-condensed ">
				<ToastProvider placement="bottom-right" autoDismissTimeout={2500} autoDismiss>
					<AdminRoot />
					<Sugar color={"#ccd92b"} time={999} />
				</ToastProvider>
			</div>
		</AdminGlobalProvider>
	);
};

export default Admin;
