import { useMutation } from "@apollo/client";
import React, { useContext, useState } from "react";
import { Button } from "react-bootstrap";
import { AntdTable } from "../../../../shared/components";
import { useDocTitle } from "../../../../shared/helpers";
import { useTranslatedLabel } from "../../../../shared/hooks";
import { UPDATE_ONE } from "../../gql";
import { GeneralContext } from "../../store";
import { ServiceTypeModal } from "./modal-service-types";

const data_fields = `active uid name id service_id service {id name } created_at updated_at pictures translations { id map_id language text }`;

export const ServiceTypes = ({ component_filters = {}, hideColumns = [], service_id, ...props }) => {
	useDocTitle("service_types");
	const { general_languages } = useContext(GeneralContext);
	const { t } = useTranslatedLabel();

	const [filters] = useState({});
	const [updateOne] = useMutation(UPDATE_ONE("service_types"));
	const LANGUAGES = general_languages.map((l) => ({
		title: l.comment,
		key: l.value,
		render: (data) => data[l.value] ?? "",
		sorter: (a, b) => a && b && (a[l.value] ?? "").localeCompare(b[l.value] ?? ""),
	}));
	const COLUMNS = [
		// {
		// 	title: t("id"),
		// 	key: "uid",
		// 	dataIndex: "uid",
		// 	sorter: AntdTable.GenericSorter("uid"),
		// },
		{
			title: t("name"),
			key: "name",
			render: AntdTable.NameRenderer,
			sorter: AntdTable.TextSorter("name"),
		},
		{
			title: t("pictures"),
			key: "pictures",
			dataIndex: "pictures",
			render: (text) => (
				<div>
					{text &&
						text.length > 0 &&
						text.map((p, i) => <img key={i} style={{ height: "20px", paddingRight: "10px" }} src={p} alt="" />)}
				</div>
			),
		},
		...LANGUAGES,

		// {
		// 	title: t("created_at"),
		// 	key: "created_at",
		// 	render: AntdTable.DateRenderer("created_at"),
		// 	sorter: AntdTable.TextSorter("created_at"),
		// },
		// {
		// 	title: t("updated_at"),
		// 	key: "updated_at",
		// 	render: AntdTable.DateRenderer("updated_at"),
		// 	sorter: AntdTable.TextSorter("updated_at"),
		// },
		{
			title: t("service"),
			key: "service_id",
			render: (row) => row?.service.name,
			sorter: (a, b) => a.service.name.localeCompare(b.service.name),
		},
		{
			title: <ServiceTypeModal entity="service_types" service_id={service_id} />,
			key: "id",
			dataIndex: "id",
			render: (text, row) => (
				<>
					<ServiceTypeModal key={`${row.id}_x`} entity="service_types" current={row} refetch={row.refetch} service_id={service_id} />
					<Button
						size="sm"
						onClick={() => updateOne({ variables: { id: row.id, object: { active: !row.active } } }).then(() => row?.refetch() ).catch((e) => console.warn(e))}
						className="mr-2 ml-2 btn-primary"
					>
						{row.active ? "De-Activate" : "Activate"}
					</Button>
				</>
			),
		},
	];

	return (
		<>
			{/* <Filters onChange={(e) => setFilters(e)} fields={["service_type_id", "service_id"]} /> */}
			<AntdTable
				entity="service_types"
				columns={COLUMNS}
				order_by={{ uid: "desc" }}
				filters={{ ...filters, ...component_filters }}
				query_fields={data_fields}
				search_fields={["name"]}
				{...props}
			/>
		</>
	);
};
