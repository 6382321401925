import { useSubscription } from "@apollo/client";
import React, { useContext, useEffect } from "react";
import { Sugar } from "react-preloaders";
import { Redirect } from "react-router-dom";
import { useLocalStorage } from "../../../../shared/helpers";
import { PATHS } from "../../config";
import { GET_STAFF_BY_ID } from "../../gql/queries";
import { AuthContext } from "../../store";

export const AuthRedirect = ({ location }) => {
	const { authenticateUser, setUserData, setUserRole, user, id_token } = useContext(AuthContext);
	const [local_email] = useLocalStorage("email");

	const { loading, error, data } = useSubscription(GET_STAFF_BY_ID, {
		variables: { email: local_email },
	});

	useEffect(() => {
		if (!loading) {
			if (!error && data?.object?.active && id_token) {
				setUserData(data?.object);
				if (data?.object?.moderator_access) setUserRole("moderator");
				else setUserRole("agent");
				authenticateUser(true);
			}

			if (error || data?.object === null || !id_token) authenticateUser(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, id_token]);

	//  redirect to dashboard.
	if (!loading && user && user.active)
		return <Redirect to={location?.state?.from?.pathname ? location.state.from.pathname : PATHS.dashboard} />;

	//  if error, redirect to signin
	if (!loading && (error || data?.object === null || !id_token)) return <Redirect to={PATHS.signin} />;

	return <Sugar color={"#ccd92b"} time={0} customLoading={true} />;
};
