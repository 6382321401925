import { useMutation } from "@apollo/client";
import React, { useContext, useState } from "react";
import { Button } from "react-bootstrap";
import { AntdTable } from "../../../../shared/components";
import { useDocTitle } from "../../../../shared/helpers";
import { useTranslatedLabel } from "../../../../shared/hooks";
import { UPDATE_ONE } from "../../gql";
import { GeneralContext } from "../../store";
// import { ServiceTypeModal } from "./modal-service-types";
import { ServiceModal } from "./modal-services";
import { ServiceTypes } from "./service-types";

const data_fields = `active uid name id created_at updated_at pictures service_types_aggregate { aggregate { count } } translations { id map_id language text }`;

export const Services = ({ component_filters = {}, hideColumns = [], ...props }) => {
	useDocTitle("services");
	const { general_languages } = useContext(GeneralContext);
	const { t } = useTranslatedLabel();

	const [filters] = useState({});
	const [updateOne] = useMutation(UPDATE_ONE("services"));
	const LANGUAGES = general_languages.map((l) => ({
		title: l.comment,
		key: l.value,
		render: (data) => data[l.value] ?? "",
		sorter: (a, b) => a && b && (a[l.value] ?? "").localeCompare(b[l.value] ?? ""),
	}));
	const COLUMNS = [
		// {
		// 	title: t("id"),
		// 	key: "uid",
		// 	dataIndex: "uid",
		// 	sorter: AntdTable.GenericSorter("uid"),
		// },
		{
			title: t("name"),
			key: "name",
			render: AntdTable.NameRenderer,
			sorter: AntdTable.TextSorter("name"),
		},
		{
			title: t("pictures"),
			key: "pictures",
			dataIndex: "pictures",
			render: (text) => (
				<div>
					{text &&
						text.length > 0 &&
						text.map((p, i) => <img key={i} style={{ height: "20px", paddingRight: "10px" }} src={p} alt="" />)}
				</div>
			),
		},
		...LANGUAGES,

		{
			title: t("categories"),
			key: "service_types_aggregate",
			render: AntdTable.AggregateCountRenderer("service_types_aggregate"),
		},

		// {
		// 	title: t("created_at"),
		// 	key: "created_at",
		// 	render: AntdTable.DateRenderer("created_at"),
		// 	sorter: AntdTable.TextSorter("created_at"),
		// },
		// {
		// 	title: t("updated_at"),
		// 	key: "updated_at",
		// 	render: AntdTable.DateRenderer("updated_at"),
		// 	sorter: AntdTable.TextSorter("updated_at"),
		// },
		{
			title: <ServiceModal entity="services" />,
			key: "id",
			dataIndex: "id",
			render: (text, row) => (
				<>
					<ServiceModal key={`${row.id}_x`} entity="services" current={row} refetch={row.refetch} />
					{/* <ServiceTypeModal
						key={`${row.id}_y`}
						entity="service_types"
						service_id={row.id}
						refetch={row.refetch}
						title={`Add Category`}
					/> */}
					<Button
						size="sm"
						variant="primary"
						onClick={() => updateOne({ variables: { id: row.id, object: { active: !row.active } } }).then(() => row?.refetch() ).catch((e) => console.warn(e))}
						className="mr-2 ml-2 btn-primary"
					>
						{row.active ? "De-Activate" : "Activate"}
					</Button>
				</>
			),
		},
	];

	return (
		<>
			{/* <Filters onChange={(e) => setFilters(e)} fields={["service_type_id", "service_id"]} /> */}
			<AntdTable
				entity="services"
				columns={COLUMNS}
				order_by={{ uid: "desc" }}
				filters={{ ...filters, ...component_filters }}
				query_fields={data_fields}
				search_fields={["name"]}
				expandable={{
					expandedRowRender: (data) => (
						<div className="p-2 bg-white">
							<ServiceTypes component_filters={{ service_id: { _eq: data.id } }} service_id={data.id} pagination={false} />
						</div>
					),
					defaultExpandAllRows: true,
				}}
				{...props}
			/>
		</>
	);
};
