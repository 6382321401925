import { gql, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useCountSubscription } from "./countSubscription";

export const useMapQuery = (props) => {
	const { where = {}, filters = {}, order_by = {}, entity = "locations", field, distinct, query_fields = " id " } = props;
	const [objects, setObjects] = useState([]);
	const total = useCountSubscription(entity, { ...where, ...filters }, field, distinct);

	const QUERY = gql`
		query get_${entity}($where: ${entity}_bool_exp, $order_by: [${entity}_order_by!])
		{ objects: ${entity}(where: $where, order_by: $order_by) { ${query_fields} } }
	`;
	const { data, error, loading, refetch } = useQuery(QUERY, {
		variables: { where: { ...where, ...filters }, order_by },
	});
	useEffect(() => {
		if (!loading && !error && data && data.objects) setObjects(data?.objects);
		else if (error) console.error(error);
	}, [data, error, loading]);

	useEffect(() => {
		refetch();
	}, [refetch, total]);
	return { data, objects, loading, refetch, total };
};
