import React from "react";
import { transformAPIResultToMarketPlaceOfferObj } from "../../../../shared/helpers";
import { TradeOfferListItem } from "./offer-list-item";

export const TradeOfferList = ({ offers, title }) => {
	return (
		<>
			{offers && offers?.length > 0 && (
				<div className="mt-5">
					<div className="container">
						<h3 className="mb-3 px-3">{title}</h3>
						<div className="row mb-1">
							{offers.map((item, index) => (
								<TradeOfferListItem key={index} data={transformAPIResultToMarketPlaceOfferObj(item)} />
							))}
						</div>
					</div>
				</div>
			)}
		</>
	);
};
