import { RichText } from "prismic-reactjs";
import React from "react";
import { Link } from "react-router-dom";
import { linkResolver } from "../../../config/prismic";
import FirstParagraph from "./FirstParagraph";

// const dateFormat = {
// 	month: "short",
// 	day: "2-digit",
// 	year: "numeric",
// };

/**
 * Post list item component
 */
const PostItem = ({ post }) => {
	const title = RichText.asText(post.data.title) ? RichText.asText(post.data.title) : "Untitled";

	return (
		<>
			<div>
				<Link to={linkResolver(post)}>
					<b>{title}</b>
				</Link>

				<div className="post-text">
					<FirstParagraph sliceZone={post.data.body} textLimit={100} />
				</div>
			</div>
		</>
	);
};

export default PostItem;
