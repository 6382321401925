import React, { createContext, useReducer } from "react";
import storage from "store2";

const initialAuthState = {
	is_authenticated: false,
	user: undefined,
	id_token: storage.has("id_token") ? storage.get("id_token") : "",
	user_role: storage.get("role", "anonymous"),
	allowed_roles: [],
};

const authActionTypes = {
	SET_ID_TOKEN: "SET_ID_TOKEN",
	AUTHENTICATE_USER: "AUTHENTICATE_USER",
	SET_USER_ROLE: "SET_USER_ROLE",
	SET_USER_DATA: "SET_USER_DATA",
	LOGOUT_USER: "LOGOUT_USER",
};

const authReducer = (state, action) => {
	switch (action.type) {
		case authActionTypes.AUTHENTICATE_USER:
			return {
				...state,
				is_authenticated: action.payload,
			};
		case authActionTypes.SET_ID_TOKEN:
			storage.set("id_token", action.payload);
			return {
				...state,
				id_token: action.payload,
			};
		case authActionTypes.SET_USER_ROLE:
			storage.set("role", action.payload);
			return {
				...state,
				user_role: action.payload,
			};
		case authActionTypes.SET_USER_DATA:
			return {
				...state,
				user: action.payload,
			};
		case authActionTypes.LOGOUT_USER:
			storage.remove("id_token");
			storage.remove("role");
			storage.remove("id");
			return {
				...state,
				is_authenticated: false,
				user: undefined,
				user_role: "anonymous",
			};
		default:
			return state;
	}
};

const AuthContext = createContext(initialAuthState);
const AuthProvider = ({ children }) => {
	const [state, dispatch] = useReducer(authReducer, initialAuthState);

	const dispatches = {
		authenticateUser(validAuthentication) {
			dispatch({
				type: authActionTypes.AUTHENTICATE_USER,
				payload: validAuthentication,
			});
		},
		setIdToken(token) {
			dispatch({
				type: authActionTypes.SET_ID_TOKEN,
				payload: token,
			});
		},
		setUserRole(role) {
			dispatch({
				type: authActionTypes.SET_USER_ROLE,
				payload: role,
			});
		},
		setUserData(data) {
			dispatch({
				type: authActionTypes.SET_USER_DATA,
				payload: data,
			});
		},
		logoutUser() {
			dispatch({
				type: authActionTypes.LOGOUT_USER,
			});
		},
	};

	return <AuthContext.Provider value={{ ...state, ...dispatches }}>{children}</AuthContext.Provider>;
};

export { AuthContext, AuthProvider };
