import { AUTH_CONFIG, webAuth } from "../config";

export const register = ({ fullName, email, password, phoneNumber }) => {
	return new Promise((resolve, reject) => {
		webAuth.signup(
			{
				email,
				password,
				connection: "Username-Password-Authentication",
				userMetadata: {
					phone_number: phoneNumber,
					full_name: fullName,
					app_type: "client",
				},
			},
			(err) => {
				if (err) {
					reject(err);
					return;
				} else {
					login({ email, password }).then(() => {

						resolve();
					});
				}
			}
		);
		return;
	});
};

export const sendOTP = ({ connection, email, phone }) => {
	let variables = { connection, send: "code" };
	if (connection === "sms") variables.phoneNumber = `+${Number(phone)}`;
	else variables.email = email;
	return new Promise((resolve, reject) => {
		webAuth.passwordlessStart(variables, (err, res) => {
			if (err) {
				reject(err)
			} else {
				resolve()
			}
		});
	})
};

export const verifyOTP = ({ connection, phone, otp, email, name }) => {
	let variables = { connection, send: "code", verificationCode: otp };
	if (connection === "sms") variables.phoneNumber = `+${Number(phone)}`;
	else variables.email = email;
	return new Promise((resolve, reject) => {
		webAuth.passwordlessLogin(variables, (err) => {
			if (err) {
				reject(err)
			} else {
				resolve()
			}
		});
	})
};

export const login = ({ email, password }) => {
	return new Promise((resolve, reject) => {
		webAuth.login(
			{
				email,
				password,
				realm: "Username-Password-Authentication",
				responseType: "token",
			},
			(err, result) => {
				if (err) {
					reject(err);
					return;
				}
				resolve(result);
			}
		);
		return;
	});
};

export const logout = () => {
	webAuth.logout({
		returnTo: AUTH_CONFIG.returnTo,
		clientID: AUTH_CONFIG.clientID,
	});
};
