import { APP_CONFIG } from '../app_config'

export const uploadImagesToCloudinary = (files) => {
    const formData = new FormData();
    for (const file of files) {
        formData.append("image", file);
    }
    return fetch(`${APP_CONFIG.REACT_APP_IMAGES_UPLOAD_URL}`, {
        method: "POST",
        body: formData,
    });
};


export const uploadImages = async (images, setUploading) => {
    let pictures = []
    const uploadble = images.filter(img => typeof img?.file !== 'string').map(img => img.file)
    const existingImgs = images.filter(img => typeof img?.file === 'string').map(img => img.file)
    if (uploadble.length > 0) {
        const response = await uploadImagesToCloudinary(uploadble);
        const data = await response.json();
        pictures = data.map((upload) => upload.secure_url);
    }
    pictures = [...existingImgs, ...pictures];
    setUploading(false);
    return pictures
}