import { useMutation } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Modal } from "react-bootstrap";
import { useTranslatedLabel } from "../../../../shared/hooks";
import { DropDown } from "../../components";
import { EDIT_OFFER } from "../../gql";
import { GeneralContext } from "../../store";

export const OfferEditModal = ({ show, onClose, offer }) => {
	const { t } = useTranslatedLabel();
	const baseState = {
		product_id: "",
		product_type_id: "",
		status: "",
		type: "",
		price_per_unit: 0,
		number_of_units: 0,
		from_date: "",
		to_date: "",
		advance_payment_percentage: 0,
	};
	const [input, setInput] = useState(baseState);
	const { general_request_status, general_products, general_product_types, general_request_types } = useContext(GeneralContext);

	//   const [getVarieties, { data: variety, loading: varLoadding }] = useLazyQuery(
	//     GET_PRODUCT_TYPES
	//   );
	const [updateOffer] = useMutation(EDIT_OFFER, {
		onCompleted: (data) => {
			onClose(data.update_requests_by_pk);
		},
	});

	useEffect(() => {
		if (show && offer) {
			const { product_type, status, type, price_per_unit, number_of_units, from_date, to_date, advance_payment_percentage } = offer;

			setInput((state) => ({
				product_id: product_type?.product?.id,
				product_type_id: product_type?.id,
				status,
				type,
				price_per_unit,
				number_of_units,
				from_date,
				to_date,
				advance_payment_percentage,
			}));
		}
	}, [show, offer]);

	//   useEffect(() => {
	//     if (input.product_id) {
	//       setInput((state) => {
	//         const { product_id } = state;
	//         const  =
	//         return { ...state, product_type_id: current?.id };
	//       });
	//     }
	//     // eslint-disable-next-line react-hooks/exhaustive-deps
	//   }, [input.product_id]);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setInput((state) => ({ ...state, [name]: value }));
	};

	const onSubmit = () => {
		input.total_amount = input.number_of_units * input.price_per_unit;
		input.advance_amount = (input.advance_payment_percentage / 100) * input.total_amount;
		updateOffer({
			variables: {
				pk_col: { id: offer.id },
				payload: input,
			},
		});
	};

	return (
		<Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={show} onHide={onClose}>
			<Modal.Header closeButton>
				<Modal.Title>Edit Offer</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form>
					<Form.Row>
						<Col>
							<DropDown
								dataSource={{
									data: general_products,
									text: "name",
									value: "id",
								}}
								name="product_id"
								label={t("product")}
								value={input.product_id}
								onChange={handleChange}
							/>
						</Col>

						<Col>
							<DropDown
								dataSource={{
									data: input.product_id
										? general_product_types.filter((item) => item.product_id === input.product_id)
										: [{ name: "No Data Available", id: "" }],
									text: "name",
									value: "id",
								}}
								label={t("variety")}
								name="product_type_id"
								disabled={!input.product_id}
								value={input.product_type_id}
								onChange={handleChange}
							/>
						</Col>

						<Col>
							<DropDown
								dataSource={{
									data: general_request_types,
									text: "comment",
									value: "value",
								}}
								name="type"
								value={input.type}
								label="Offer Type"
								onChange={handleChange}
							/>
						</Col>
					</Form.Row>

					<Form.Row>
						<Col>
							<DropDown
								dataSource={{
									data: general_request_status,
									text: "comment",
									value: "value",
								}}
								name="status"
								value={input.status}
								label={t("status")}
								onChange={handleChange}
							/>
						</Col>
						<Col>
							<Form.Group controlId="formBasicPassword">
								<Form.Label>{t("start_date")}</Form.Label>
								<Form.Control type="date" value={input.from_date} name="from_date" onChange={handleChange} />
							</Form.Group>
						</Col>
						<Col>
							<Form.Group controlId="formBasicPassword">
								<Form.Label>{t("end_date")}</Form.Label>
								<Form.Control type="date" value={input.to_date} name="to_date" onChange={handleChange} />
							</Form.Group>
						</Col>
					</Form.Row>

					<Form.Row>
						<Col>
							<Form.Group controlId="formBasicPassword">
								<Form.Label>{t("price_per_unit")}</Form.Label>
								<Form.Control type="number" value={input.price_per_unit} name="price_per_unit" onChange={handleChange} />
							</Form.Group>
						</Col>
						<Col>
							<Form.Group controlId="formBasicPassword">
								<Form.Label>{t("quantity")}</Form.Label>
								<Form.Control type="number" value={input.number_of_units} name="number_of_units" onChange={handleChange} />
							</Form.Group>
						</Col>
						<Col>
							<Form.Group controlId="formBasicPassword">
								<Form.Label>{t("advance")} %</Form.Label>
								<Form.Control
									type="number"
									value={input.advance_payment_percentage}
									name="advance_payment_percentage"
									onChange={handleChange}
								/>
							</Form.Group>
						</Col>
					</Form.Row>

					<Button block onClick={onSubmit}>
						Submit
					</Button>
				</Form>
			</Modal.Body>
		</Modal>
	);
};
