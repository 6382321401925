import { useSubscription } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useDocTitle } from "../../../../shared/helpers";
import { useTranslatedLabel } from "../../../../shared/hooks";
import { GET_USER_BY_ID } from "../../../client/gql";
import { Layout } from "../../components";
import { Farms } from "../locations/farms";
import { Locations } from "../locations/locations";
import { BuyOffers } from "../transactions/buy-offers";
import { Logistics } from "../transactions/logistics";
import { Orders } from "../transactions/orders";
import { Payments } from "../transactions/payments";
import { SellOffers } from "../transactions/sell-offers";
import { ServiceOffers } from "../transactions/service-offers";
import { ServiceOrders } from "../transactions/service-orders";

export const UserDetails = () => {
	useDocTitle("user");
	const { t } = useTranslatedLabel();
	const { id } = useParams();
	const [user, setUser] = useState({});
	const { data, error, loading } = useSubscription(GET_USER_BY_ID, { variables: { id } });
	useEffect(() => {
		if (!loading && !error && data) setUser(data?.object);
	}, [data, error, loading, setUser]);

	const content = (
		<>
			<Tabs defaultKey="" style={{ paddingTop: "0px", marginTop: "0px" }}>
				{user?.grower_access ? (
					<Tab eventKey="farms" title={t("farms")}>
						<Farms component={true} component_filters={{ user_id: { _eq: id } }} />
					</Tab>
				) : user?.buyer_access ? (
					<Tab eventKey="locations" title={t("locations")}>
						<Locations component={true} component_filters={{ user_id: { _eq: id }, user: { buyer_access: { _eq: true } } }} />
					</Tab>
				) : (
					<Tab eventKey="locations" title={t("locations")}>
						<Locations component={true} component_filters={{ user_id: { _eq: id }, user: { provider_access: { _eq: true } } }} />
					</Tab>
				)}
				{user?.grower_access ? (
					<Tab eventKey="sell_offers" title={t("sell_offers")}>
						<SellOffers component={true} component_filters={{ user_id: { _eq: id } }} />
					</Tab>
				) : user?.buyer_access ? (
					<Tab eventKey="buy_offers" title={t("buy_offers")}>
						<BuyOffers component={true} component_filters={{ user_id: { _eq: id } }} />
					</Tab>
				) : (
					<Tab eventKey="service_offers" title={t("service_offers")}>
						<ServiceOffers component={true} component_filters={{ user_id: { _eq: id } }} />
					</Tab>
				)}
				{user?.grower_access ? (
					<Tab eventKey="trade_orders" title={t("trade_orders")}>
						<Orders component={true} component_filters={{ seller_id: { _eq: id } }} />
					</Tab>
				) : user?.buyer_access ? (
					<Tab eventKey="trade_orders" title={t("trade_orders")}>
						<Orders component={true} component_filters={{ buyer_id: { _eq: id } }} />
					</Tab>
				) : (
					""
				)}
				{user?.provider_access ? (
					<Tab eventKey="service_orders" title={t("service_orders")}>
						<ServiceOrders component={true} component_filters={{ seller_id: { _eq: id } }} />
					</Tab>
				) : (
					<Tab eventKey="service_orders" title={t("service_orders")}>
						<ServiceOrders component={true} component_filters={{ buyer_id: { _eq: id } }} />
					</Tab>
				)}
				<Tab eventKey="payments" title={t("payments")}>
					<Payments component={true} component_filters={{ _or: [{ buyer_id: { _eq: id } }, { seller_id: { _eq: id } }] }} />
				</Tab>
				<Tab eventKey="logistics" title={t("logistics")}>
					<Logistics component={true} component_filters={{ _or: [{ buyer_id: { _eq: id } }, { seller_id: { _eq: id } }] }} />
				</Tab>
			</Tabs>
		</>
	);

	return (
		<Layout title={`${user?.name || ""} | ${user?.phone_number || ""} | ${user?.email || ""} `}>
			<div>{user?.id ? content : ""}</div>
		</Layout>
	);
};
