import { gql, useSubscription } from "@apollo/client";
import { useEffect, useState } from "react";

export const useCountSubscription = (entity = "products", where = {}, field = "id", distinct = true) => {
	const [total, setTotal] = useState(0);
	const { data, error, loading } = useSubscription(
		gql(`subscription ${entity}_count($where: ${entity}_bool_exp) {
				totals: ${entity}_aggregate(where: $where) {
					aggregate { count(columns: ${field}, distinct: ${distinct ? "true" : "false"}) }
				}
			}`),
		{ variables: { where } }
	);

	useEffect(() => {
		if (entity && !loading && !error && data?.totals?.aggregate?.count !== total) setTotal(data?.totals?.aggregate?.count ?? 0);
	}, [data, entity, error, loading, total]);

	return total;
};
