import { StyleSheet, Text, View } from "@react-pdf/renderer";
import React from "react";

const styles = StyleSheet.create({
	titleContainer: {
		flexDirection: "row",
		marginTop: 12,
	},
	reportTitle: {
		fontSize: 12,
		textAlign: "center",
		textTransform: "uppercase",
	},
});

const InvoiceThankYouMsg = ({ service }) => (
	<View style={styles.titleContainer}>
		<Text style={styles.reportTitle}>Thank you for your business</Text>
	</View>
);

export default InvoiceThankYouMsg;
