import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useTranslatedLabel } from "../../../../shared/hooks";
import { DatePickerChild, FilterDropdown } from "../../components";
import { GeneralContext } from "../../store";

export const Filters = ({
	entity,
	onChange = () => {},
	fields = [],
	number_fields = [],
	default_filters = {},
	users = [],
	buyers = [],
	sellers = [],
	locations = [],
}) => {
	const { t } = useTranslatedLabel();
	// const [showFilters, setShowFilters] = useState(false);
	const [filters, setFilters] = useState({ ...default_filters });
	const [date_field, setDateField] = useState("created_at");
	const [date_range, setDateRange] = useState("last_3_months");
	const [start_number, setStartNumber] = useState(0);
	const [end_number, setEndNumber] = useState(999999);
	const [number_field, setNumberField] = useState("");
	const [role_field, setRoleField] = useState("");
	const [type_field, setTypeField] = useState("");
	const [active_status, setActiveStatus] = useState("all");
	const {
		general_products,
		general_product_types,
		// general_request_status,
		general_order_status,
		general_services,
		general_service_types,
	} = useContext(GeneralContext);

	useEffect(() => {
		let whereObj = {};
		Object.keys(filters).map((key) => {
			if (key === "product_id") whereObj.product_type = { product_id: { _eq: filters[key] } };
			else if (key === "service_id") whereObj.service_type = { service_id: { _eq: filters[key] } };
			else if (key !== "from_date" && key !== "to_date" && key !== "role") whereObj[key] = { _eq: filters[key] };
			return null;
		});
		if (date_field && filters.from_date && filters.to_date) {
			if (date_field === "from_to_dates")
				whereObj._and = [{ from_date: { _gte: filters.from_date } }, { to_date: { _lte: filters.to_date } }];
			else whereObj[date_field] = { _gte: filters.from_date, _lte: filters.to_date };
		}
		if (number_field) whereObj[number_field] = { _gte: start_number, _lte: end_number };
		if (role_field) whereObj[`${role_field}_access`] = { _eq: true };
		if (type_field) whereObj.type = { _eq: type_field };

		onChange(whereObj);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filters, number_field, start_number, end_number, role_field, date_field, type_field, date_range]);

	const filterChange = (e) => {
		const { name, value } = e?.target || {};
		let new_filters = { ...filters };
		if (name) new_filters[name] = value;
		if (name === "product_id") delete new_filters.product_type_id;
		if (name === "service_id") delete new_filters.service_type_id;
		setFilters(new_filters);
	};

	useEffect(() => {
		if ((filters.from_date && !filters.to_date) || (filters.from_date && filters.to_date < filters.from_date)) {
			let new_filters = { ...filters };
			new_filters.to_date = dayjs().add(7, "day").format("YYYY-MM-DD");
			setFilters(new_filters);
		}
	}, [filters]);

	useEffect(() => {
		let new_filters = { ...filters };
		if (active_status === "active") new_filters.active = true;
		else if (active_status === "inactive") new_filters.active = false;
		else delete new_filters.active;
		setFilters(new_filters);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [active_status]);

	useEffect(() => {
		let new_filters = { ...filters };
		switch (date_range) {
			case "today":
				new_filters.from_date = dayjs().startOf("day").toISOString();
				new_filters.to_date = dayjs().endOf("day").toISOString();
				break;
			case "last_7_days":
				new_filters.from_date = dayjs().subtract(7, "day").format("YYYY-MM-DD");
				new_filters.to_date = dayjs().format("YYYY-MM-DD");
				break;
			case "last_30_days":
				new_filters.from_date = dayjs().subtract(30, "day").format("YYYY-MM-DD");
				new_filters.to_date = dayjs().format("YYYY-MM-DD");
				break;
			case "last_3_months":
				new_filters.from_date = dayjs().subtract(3, "month").format("YYYY-MM-DD");
				new_filters.to_date = dayjs().format("YYYY-MM-DD");
				break;
			case "next_7_days":
				new_filters.from_date = dayjs().format("YYYY-MM-DD");
				new_filters.to_date = dayjs().add(7, "day").format("YYYY-MM-DD");
				break;
			case "next_30_days":
				new_filters.from_date = dayjs().format("YYYY-MM-DD");
				new_filters.to_date = dayjs().add(30, "day").format("YYYY-MM-DD");
				break;
			case "next_3_months":
				new_filters.from_date = dayjs().format("YYYY-MM-DD");
				new_filters.to_date = dayjs().add(3, "month").format("YYYY-MM-DD");
				break;
			case "this_week":
				new_filters.from_date = dayjs().startOf("week").format("YYYY-MM-DD");
				new_filters.to_date = dayjs().endOf("week").format("YYYY-MM-DD");
				break;
			case "this_month":
				new_filters.from_date = dayjs().startOf("month").format("YYYY-MM-DD");
				new_filters.to_date = dayjs().endOf("month").format("YYYY-MM-DD");
				break;
			case "this_year":
				new_filters.from_date = dayjs().startOf("year").format("YYYY-MM-DD");
				new_filters.to_date = dayjs().endOf("year").format("YYYY-MM-DD");
				break;
			case "next_week":
				new_filters.from_date = dayjs().add(1, "week").startOf("week").format("YYYY-MM-DD");
				new_filters.to_date = dayjs().add(1, "week").endOf("week").format("YYYY-MM-DD");
				break;
			case "next_month":
				new_filters.from_date = dayjs().add(1, "month").startOf("month").format("YYYY-MM-DD");
				new_filters.to_date = dayjs().add(1, "month").endOf("month").format("YYYY-MM-DD");
				break;
			case "next_year":
				new_filters.from_date = dayjs().add(1, "year").startOf("year").format("YYYY-MM-DD");
				new_filters.to_date = dayjs().add(1, "year").endOf("year").format("YYYY-MM-DD");
				break;
			case "last_week":
				new_filters.from_date = dayjs().subtract(1, "week").startOf("week").format("YYYY-MM-DD");
				new_filters.to_date = dayjs().subtract(1, "week").endOf("week").format("YYYY-MM-DD");
				break;
			case "last_month":
				new_filters.from_date = dayjs().subtract(1, "month").startOf("month").format("YYYY-MM-DD");
				new_filters.to_date = dayjs().subtract(1, "month").endOf("month").format("YYYY-MM-DD");
				break;
			case "last_year":
				new_filters.from_date = dayjs().subtract(1, "year").startOf("year").format("YYYY-MM-DD");
				new_filters.to_date = dayjs().subtract(1, "year").endOf("year").format("YYYY-MM-DD");
				break;
			default:
				break;
		}
		setFilters(new_filters);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [date_range]);

	// const { objects: trade_buyers } = useDataQuery({
	// 	entity: "users",
	// 	where: { buyer_access: { _eq: true } },
	// 	order_by: { name: "asc" },
	// 	query_fields: `id name`,
	// });
	// const { objects: trade_sellers } = useDataQuery({
	// 	entity: "users",
	// 	where: { grower_access: { _eq: true } },
	// 	order_by: { name: "asc" },
	// 	query_fields: `id name`,
	// });
	// const { objects: service_buyers } = useDataQuery({
	// 	entity: "users",
	// 	where: { _or: [{ buyer_access: { _eq: true } }, { grower_access: { _eq: true } }] },
	// 	order_by: { name: "asc" },
	// 	query_fields: `id name`,
	// });
	// const { objects: service_sellers } = useDataQuery({
	// 	entity: "users",
	// 	where: { provider_access: { _eq: true } },
	// 	order_by: { name: "asc" },
	// 	query_fields: `id name`,
	// });

	return (
		<>
			<div className="row font-roboto-condensed ">
				{fields.includes("type") && (
					<div className="col">
						<span className="m-0 p-0">{t("type")}</span>
						<FilterDropdown
							data={[
								{ name: t("sell_offer"), value: "sell_offer" },
								{ name: t("buy_request"), value: "buy_request" },
							]}
							title={type_field ? t(type_field) : t("none")}
							nameField="name"
							name="type"
							value={type_field}
							valueField="value"
							onChange={(e) => setTypeField(e.target.value)}
						/>
					</div>
				)}
				{fields.includes("role") && (
					<div className="col">
						<span className="m-0 p-0">{t("role")}</span>
						<FilterDropdown
							data={[
								{ name: t("buyer"), value: "buyer" },
								{ name: t("grower"), value: "grower" },
								{ name: t("provider"), value: "provider" },
							]}
							title={role_field ? t(role_field) : t("none")}
							nameField="name"
							name="role"
							value={role_field}
							valueField="value"
							onChange={(e) => setRoleField(e.target.value)}
						/>
					</div>
				)}
				{(fields.includes("product_id") || fields.includes("product_type_id")) && (
					<div className="col">
						<span className="m-0 p-0">{t("product")}</span>
						<FilterDropdown
							data={general_products.map((e) => {
								let newObj = { ...e };
								newObj.name = `${t(e.id, e.name)}`;
								return newObj;
							})}
							title={filters.product_id ? t(filters.product_id) : t("none")}
							nameField="name"
							valueField="id"
							name="product_id"
							onChange={filterChange}
							value={filters.product_id}
						/>
					</div>
				)}
				{fields.includes("product_type_id") && filters.product_id ? (
					<div className="col">
						<span className="m-0 p-0">{t("variety")}</span>
						<FilterDropdown
							data={general_product_types
								.filter((e) => e.product_id === filters.product_id)
								.map((e) => {
									let newObj = { ...e };
									newObj.name = `${t(e.id, e.name)}`;
									return newObj;
								})}
							title={filters.product_type_id ? t(filters.product_type_id) : t("none")}
							nameField="name"
							valueField="id"
							name="product_type_id"
							value={filters.product_type_id}
							onChange={filterChange}
						/>
					</div>
				) : null}
				{(fields.includes("service_id") || fields.includes("service_type_id")) && (
					<div className="col">
						<span className="m-0 p-0">{t("service")}</span>
						<FilterDropdown
							data={general_services.map((e) => {
								let newObj = { ...e };
								newObj.name = `${t(e.id, e.name)}`;
								return newObj;
							})}
							title={filters.service_id ? t(filters.service_id) : t("none")}
							nameField="name"
							valueField="id"
							name="service_id"
							onChange={filterChange}
							value={filters.service_id}
						/>
					</div>
				)}
				{fields.includes("service_type_id") && filters.service_id ? (
					<div className="col">
						<span className="m-0 p-0">{t("category")}</span>
						<FilterDropdown
							data={general_service_types
								.filter((e) => e.service_id === filters.service_id)
								.map((e) => {
									let newObj = { ...e };
									newObj.name = `${t(e.id, e.name)}`;
									return newObj;
								})}
							title={filters.service_type_id ? t(filters.service_type_id) : t("none")}
							nameField="name"
							valueField="id"
							name="service_type_id"
							value={filters.service_type_id}
							onChange={filterChange}
							className="block"
						/>
					</div>
				) : null}
				{fields.includes("request_status") && (
					<div className="col">
						<span className="m-0 p-0">{t("status")}</span>
						<FilterDropdown
							// data={general_request_status.map((e) => {
							// 	let newObj = { ...e };
							// 	newObj.name = `${t(e.value, e.comment)}`;
							// 	return newObj;
							// })}
							data={[
								{
									value: "open",
									name: t("open"),
								},
								{
									value: "completed",
									name: t("completed"),
								},
							]}
							title={filters.status ? t(filters.status) : t("none")}
							nameField="name"
							name="status"
							value={filters.status}
							valueField="value"
							onChange={filterChange}
						/>
					</div>
				)}
				{fields.includes("order_status") && entity === "trade_orders" && (
					<div className="col">
						<span className="m-0 p-0">{t("status")}</span>
						<FilterDropdown
							data={general_order_status
								.map((e) => {
									let newObj = { ...e };
									newObj.name = `${t(e.value, e.comment)}`;
									return newObj;
								})
								.filter((item) => !["cancelled", "serviced", "inprogress"].includes(item.value))}
							title={filters.status ? t(filters.status) : t("none")}
							nameField="name"
							name="status"
							value={filters.status}
							valueField="value"
							onChange={filterChange}
						/>
					</div>
				)}
				{fields.includes("order_status") && entity === "service_orders" && (
					<div className="col">
						<span className="m-0 p-0">{t("status")}</span>
						<FilterDropdown
							data={general_order_status
								.map((e) => {
									let newObj = { ...e };
									newObj.name = `${t(e.value, e.comment)}`;
									return newObj;
								})
								.filter((item) => ["open", "inprogress", "fullypaid", "serviced", "completed"].includes(item.value))}
							title={filters.status ? t(filters.status) : t("none")}
							nameField="name"
							name="status"
							value={filters.status}
							valueField="value"
							onChange={filterChange}
						/>
					</div>
				)}
				{number_fields?.length > 1 && (
					<div className="col">
						<span className="m-0 p-0">{t("number_field")}</span>
						<FilterDropdown
							data={number_fields.map((e) => {
								return {
									name: t(e),
									value: e,
								};
							})}
							title={number_field ? t(number_field) : t("none")}
							nameField="name"
							name="number_field"
							value={number_field}
							valueField="value"
							onChange={(e) => setNumberField(e.target.value)}
						/>
					</div>
				)}
				{number_field && (
					<div className="col">
						<span className="m-0 p-0">{t("start_number")}</span>
						<input
							type="number"
							min={0}
							max={999999999}
							style={{ textAlign: "right" }}
							className="form-control sm-form-control"
							value={start_number}
							onChange={(e) => setStartNumber(e.target.value)}
							name="start_number"
						/>
					</div>
				)}
				{number_field && (
					<div className="col">
						<span className="m-0 p-0">{t("end_number")}</span>
						<input
							type="number"
							min={0}
							max={999999999}
							style={{ textAlign: "right" }}
							className="form-control sm-form-control"
							value={end_number}
							onChange={(e) => setEndNumber(e.target.value)}
							name="end_number"
						/>
					</div>
				)}
				<div className="col">
					<span className="m-0 p-0">{t("date_field")}</span>
					<FilterDropdown
						data={
							fields.includes("from_to_dates")
								? [
										{ name: t("created_at"), value: "created_at" },
										{ name: t("updated_at"), value: "updated_at" },
										{ name: t("from_date"), value: "from_date" },
										{ name: t("to_date"), value: "to_date" },
								  ]
								: fields.includes("dates")
								? [
										{ name: t("created_at"), value: "created_at" },
										{ name: t("updated_at"), value: "updated_at" },
										{ name: t("order_date"), value: "date" },
								  ]
								: [
										{ name: t("created_at"), value: "created_at" },
										{ name: t("updated_at"), value: "updated_at" },
								  ]
						}
						title={t(date_field)}
						nameField="name"
						name="date_field"
						value={date_field}
						valueField="value"
						onChange={(e) => setDateField(e.target.value)}
					/>
				</div>
				<div className="col">
					<span className="m-0 p-0">{t("date_range")}</span>
					<FilterDropdown
						data={[
							{ name: "Custom", value: "custom" },
							{ name: "Today", value: "today" },
							{ name: "This Week", value: "this_week" },
							{ name: "This Month", value: "this_month" },
							{ name: "This Year", value: "this_year" },
							{ name: "Next Week", value: "next_week" },
							{ name: "Next Month", value: "next_month" },
							{ name: "Next Year", value: "next_year" },
							{ name: "Last Week", value: "last_week" },
							{ name: "Last Month", value: "last_month" },
							{ name: "Last Year", value: "last_year" },
							{ name: "Next 7 Days", value: "next_7_days" },
							{ name: "Next 30 Days", value: "next_30_days" },
							{ name: "Next 3 Months", value: "next_3_months" },
							{ name: "Last 7 Days", value: "last_7_days" },
							{ name: "Last 30 Days", value: "last_30_days" },
							{ name: "Last 3 Months", value: "last_3_months" },
						].map((e) => {
							let newObj = { ...e };
							newObj.name = `${t(e.value, e.name)}`;
							return newObj;
						})}
						title={t(date_range)}
						nameField="name"
						name="date_range"
						value={date_range}
						valueField="value"
						onChange={(e) => setDateRange(e.target.value)}
					/>
				</div>
				{date_range === "custom" && (
					<div className="col">
						<span className="m-0 p-0">{t("start_date")}</span>
						<DatePicker
							selected={filters.from_date ? Date.parse(filters.from_date) : null}
							isClearable
							minDate={new Date()}
							onChange={(date) =>
								setFilters((state) => ({
									...state,
									from_date: date,
									to_date: null,
								}))
							}
							customInput={<DatePickerChild />}
							className="flex-basis-70"
							dateFormat="dd/MM/yyyy"
							placeholderText="dd/mm/yyyy"
						/>
					</div>
				)}
				{date_range === "custom" && (
					<div className="col">
						<span className="m-0 p-0">{t("end_date")}</span>
						<DatePicker
							selected={filters.to_date ? Date.parse(filters.to_date) : null}
							isClearable
							disabled={!filters.from_date}
							minDate={new Date(filters.from_date)}
							onChange={(date) => setFilters((state) => ({ ...state, to_date: date }))}
							customInput={<DatePickerChild />}
							className="flex-basis-70"
							dateFormat="dd/MM/yyyy"
							placeholderText="dd/mm/yyyy"
						/>
					</div>
				)}
				<div className="col">
					<span className="m-0 p-0">{t("active")}</span>
					<FilterDropdown
						data={[
							{ name: "Active", value: "active" },
							{ name: "InActive", value: "inactive" },
							{ name: "All", value: "all" },
						].map((e) => {
							let newObj = { ...e };
							newObj.name = `${t(e.value, e.name)}`;
							return newObj;
						})}
						title={t(active_status)}
						nameField="name"
						valueField="value"
						name="active_status"
						onChange={(e) => setActiveStatus(e.target.value)}
						value={active_status}
					/>
				</div>
				<div className="col">
					<Button
						className="float-right btn-sm "
						variant="primary"
						onClick={() => {
							setFilters(default_filters);
							setNumberField("");
							setTypeField("");
							setRoleField("");
							setTypeField("");
							setDateField("created_at");
							setDateRange("this_year");
							setStartNumber(0);
							setEndNumber(999999);
							setActiveStatus("all");
						}}
					>
						{t("clear_all_filters")}
					</Button>
				</div>
			</div>
		</>
	);
};
