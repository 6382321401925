import React, { createContext, useReducer } from "react";
import storage from "store2";

const initialState = {
	general_location: {},
	general_language: storage.get("language", "en_gb"),
	general_products: [],
	general_product_types: [],
	general_services: [],
	general_service_types: [],
	general_certifications: [],
	general_currencies: [],
	general_languages: [],
	general_order_status: [],
	general_product_units: [],
	general_request_status: [],
	general_request_types: [],
	general_countries: [],
	general_settings: [],
	general_currencies_map: {},
	general_languages_map: {},
	general_order_status_map: {},
	general_product_units_map: {},
	general_request_status_map: {},
	general_request_types_map: {},
	general_countries_map: {},
	general_settings_map: {},
	general_roles: [],
	general_roles_map: {},
	general_translations: [],
	general_translations_map: {},
	general_logistics: [],
	general_logistics_map: {},
};

const generalActionTypes = {
	SET_LOCATION: "SET_LOCATION",
	SET_LANGAUGE: "SET_LANGAUGE",
	SET_PRODUCTS: "SET_PRODUCTS",
	SET_PRODUCT_TYPES: "SET_PRODUCT_TYPES",
	SET_SERVICES: "SET_SERVICES",
	SET_SERVICE_TYPES: "SET_SERVICE_TYPES",
	SET_CERTIFICATIONS: "SET_CERTIFICATIONS",
	SET_CURRENCIES: "SET_CURRENCIES",
	SET_LANGUAGES: "SET_LANGUAGES",
	SET_ORDER_STATUS: "SET_ORDER_STATUS",
	SET_PRODUCT_UNITS: "SET_PRODUCT_UNITS",
	SET_REQUEST_STATUS: "SET_REQUEST_STATUS",
	SET_REQUEST_TYPES: "SET_REQUEST_TYPES",
	SET_COUNTRIES: "SET_COUNTRIES",
	SET_SETTINGS: "SET_SETTINGS",
	SET_ROLES: "SET_ROLES",
	SET_TRANSLATIONS: "SET_TRANSLATIONS",
	SET_LOGISTICS: "SET_LOGISTICS",
};

const generalReducer = (state, action) => {
	switch (action.type) {
		case generalActionTypes.SET_LOCATION:
			return { ...state, general_location: action.payload };

		case generalActionTypes.SET_LANGAUGE:
			storage.set("language", action.payload);
			return { ...state, general_language: action.payload };

		case generalActionTypes.SET_PRODUCTS:
			return { ...state, general_products: action.payload };
		case generalActionTypes.SET_PRODUCT_TYPES:
			return {
				...state,
				general_product_types: action.payload,
			};

		case generalActionTypes.SET_SERVICES:
			return { ...state, general_services: action.payload };
		case generalActionTypes.SET_SERVICE_TYPES:
			return {
				...state,
				general_service_types: action.payload,
			};

		case generalActionTypes.SET_CERTIFICATIONS:
			return { ...state, general_certifications: action.payload };

		case generalActionTypes.SET_CURRENCIES:
			let general_currencies_map = {};
			action.payload.map((s) => {
				general_currencies_map[s.value] = s;
				return s;
			});
			return {
				...state,
				general_currencies: action.payload,
				general_currencies_map,
			};
		case generalActionTypes.SET_LANGUAGES:
			let general_languages_map = {};
			action.payload.map((s) => {
				general_languages_map[s.value] = s;
				return s;
			});
			return {
				...state,
				general_languages: action.payload,
				general_languages_map,
			};
		case generalActionTypes.SET_ORDER_STATUS:
			let general_order_status_map = {};
			action.payload.map((s) => {
				general_order_status_map[s.value] = s;
				return s;
			});
			return {
				...state,
				general_order_status: action.payload,
				general_order_status_map,
			};
		case generalActionTypes.SET_PRODUCT_UNITS:
			let general_product_units_map = {};
			action.payload.map((s) => {
				general_product_units_map[s.value] = s;
				return s;
			});
			return {
				...state,
				general_product_units: action.payload,
				general_product_units_map,
			};
		case generalActionTypes.SET_REQUEST_STATUS:
			let general_request_status_map = {};
			action.payload.map((s) => {
				general_request_status_map[s.value] = s;
				return s;
			});
			return {
				...state,
				general_request_status: action.payload,
				general_request_status_map,
			};
		case generalActionTypes.SET_REQUEST_TYPES:
			let general_request_types_map = {};
			action.payload.map((s) => {
				general_request_types_map[s.value] = s;
				return s;
			});
			return {
				...state,
				general_request_types: action.payload,
				general_request_types_map,
			};
		case generalActionTypes.SET_COUNTRIES:
			let general_countries_map = {};
			action.payload.map((s) => {
				general_countries_map[s.value] = s;
				return s;
			});
			return {
				...state,
				general_countries: action.payload,
				general_countries_map,
			};

		case generalActionTypes.SET_SETTINGS:
			let general_settings_map = {};
			action.payload.map((s) => {
				general_settings_map[s.id] = s.is_numeric ? s.numeric : s.text;
				return s;
			});
			return {
				...state,
				general_settings: action.payload,
				general_settings_map,
			};

		case generalActionTypes.SET_ROLES:
			let general_roles_map = {};
			action.payload.map((s) => {
				general_roles_map[s.value] = s;
				return s;
			});
			return { ...state, general_roles: action.payload, general_roles_map };

		case generalActionTypes.SET_TRANSLATIONS:
			let labels_map = {};
			if (action.labels)
				action.labels.map((s) => {
					labels_map[s.id] = s.name;
					return s;
				});
			let general_translations_map = {};
			action.payload.map((s) => {
				general_translations_map[labels_map[s.map_id] ?? s.map_id] = s.text;
				return s;
			});
			return {
				...state,
				general_translations: action.payload,
				general_translations_map,
			};
		case generalActionTypes.SET_LOGISTICS:
			let general_logitics_map = {};
			action.payload.map((s) => {
				general_logitics_map[s.value] = s;
				return s;
			});
			return { ...state, general_logistics: action.payload, general_logitics_map };
		// can add all generic tables here to pull fom Database
		default:
			return state;
	}
};

const GeneralContext = createContext(initialState);
const GeneralProvider = ({ children }) => {
	const [state, dispatch] = useReducer(generalReducer, initialState);

	const dispatches = {
		setGeneralLocation(e) {
			dispatch({ type: generalActionTypes.SET_LOCATION, payload: e });
		},
		setGeneralLanguage(e) {
			dispatch({ type: generalActionTypes.SET_LANGAUGE, payload: e });
		},
		setGeneralProducts(e) {
			dispatch({ type: generalActionTypes.SET_PRODUCTS, payload: e });
		},
		setGeneralProductTypes(e) {
			dispatch({ type: generalActionTypes.SET_PRODUCT_TYPES, payload: e });
		},
		setGeneralServices(e) {
			dispatch({ type: generalActionTypes.SET_SERVICES, payload: e });
		},
		setGeneralServiceTypes(e) {
			dispatch({ type: generalActionTypes.SET_SERVICE_TYPES, payload: e });
		},
		setGeneralCertifications(e) {
			dispatch({ type: generalActionTypes.SET_CERTIFICATIONS, payload: e });
		},
		setGeneralCurrencies(e) {
			dispatch({ type: generalActionTypes.SET_CURRENCIES, payload: e });
		},
		setGeneralLanguages(e) {
			dispatch({ type: generalActionTypes.SET_LANGUAGES, payload: e });
		},
		setGeneralOrderStatus(e) {
			dispatch({ type: generalActionTypes.SET_ORDER_STATUS, payload: e });
		},
		setGeneralProductUnits(e) {
			dispatch({ type: generalActionTypes.SET_PRODUCT_UNITS, payload: e });
		},
		setGeneralRequestStatus(e) {
			dispatch({ type: generalActionTypes.SET_REQUEST_STATUS, payload: e });
		},
		setGeneralCountries(e) {
			dispatch({ type: generalActionTypes.SET_COUNTRIES, payload: e });
		},
		setGeneralSettings(e) {
			dispatch({ type: generalActionTypes.SET_SETTINGS, payload: e });
		},
		setGeneralRoles(e) {
			dispatch({ type: generalActionTypes.SET_ROLES, payload: e });
		},
		setGeneralTranslations(translations, labels, language) {
			dispatch({ type: generalActionTypes.SET_TRANSLATIONS, payload: translations, labels, language });
		},
		setGeneralRequestTypes(e) {
			dispatch({ type: generalActionTypes.SET_REQUEST_TYPES, payload: e });
		},
		setGeneralLogistics(e) {
			dispatch({ type: generalActionTypes.SET_LOGISTICS, payload: e });
		},
		// can add all generic tables here to pull fom Database
	};

	return <GeneralContext.Provider value={{ ...state, ...dispatches }}>{children}</GeneralContext.Provider>;
};

export { GeneralContext, GeneralProvider };
