import React from "react";
import { Spin } from 'antd'
import ReactApexChart from "react-apexcharts";
import { useTimeSeriesQuery } from "../../hooks/timeseriesQuery";

export const TinyLineChart = ({
	entity = "z_sell_offers",
	userEntity = "z_sellers",
	chartoptions = {},
	title = "Chart",
	userColumn,
	height = 200,
	showXaxisLabels = false,
	enableTooltip = true,
	filters = {},
}) => {
	const { data: objects = [], loading: dataLoading } = useTimeSeriesQuery({ entity, order_by: { date: "asc" }, where: filters });
	const { data: users = [], loading: userLoading } = useTimeSeriesQuery({ entity: userEntity, order_by: { date: "asc" }, where: filters });
	const series = userColumn
		? [
			{
				name: "Amount",
				type: "column",
				data: objects?.map((e) => parseInt(e.amount)),
			},
			{
				name: "Count",
				type: "line",
				data: objects?.map((e) => parseInt(e.count)),
			},
			{
				name: userColumn,
				type: "line",
				data: users?.map((e) => parseInt(e.count)),
			},
		]
		: [
			{
				name: "Amount",
				type: "column",
				data: objects?.map((e) => parseInt(e.amount)),
			},
			{
				name: "Count",
				type: "line",
				data: objects?.map((e) => parseInt(e.count)),
			},
		];
	const options = {
		chart: {
			type: "line",
			stacked: false,
			height: height,
		},
		dataLabels: {
			enabled: false,
		},
		stroke: {
			width: userColumn ? [1, 4, 4] : [1, 4],
		},
		yaxis: userColumn
			? [
				{
					seriesName: "Amount",
					axisTicks: {
						show: true,
					},
					labels: {
						style: {
							colors: "darkblue",
						},
					},
					title: {
						text: "Amount",
						style: {
							color: "darkblue",
						},
					},
					tooltip: {
						enabled: enableTooltip,
					},
				},
				{
					seriesName: "Count",
					opposite: true,
					axisTicks: {
						show: true,
					},
					labels: {
						style: {
							colors: "darkgreen",
						},
					},
					title: {
						text: "Count",
						style: {
							color: "darkgreen",
						},
					},
					tooltip: {
						enabled: enableTooltip,
					},
				},
				{
					seriesName: userColumn,
					opposite: true,
					axisTicks: {
						show: true,
					},
					labels: {
						style: {
							colors: "#feb019",
						},
					},
					title: {
						text: userColumn,
						style: {
							color: "#feb019",
						},
					},
					tooltip: {
						enabled: enableTooltip,
					},
				},
			]
			: [
				{
					seriesName: "Amount",
					axisTicks: {
						show: true,
					},
					labels: {
						style: {
							colors: "darkblue",
						},
					},
					title: {
						text: "Amount",
						style: {
							color: "darkblue",
						},
					},
					tooltip: {
						enabled: enableTooltip,
					},
				},
				{
					seriesName: "Count",
					opposite: true,
					axisTicks: {
						show: true,
					},
					labels: {
						style: {
							colors: "darkgreen",
						},
					},
					title: {
						text: "Count",
						style: {
							color: "darkgreen",
						},
					},
					tooltip: {
						enabled: enableTooltip,
					},
				},
			],
		xaxis: {
			labels: {
				show: showXaxisLabels,
			},
			categories: objects?.map((e) => e.date),
			tooltip: {
				enabled: true,
			},
		},
		tooltip: {
			fixed: {
				enabled: true,
				position: "topLeft", // topRight, topLeft, bottomRight, bottomLeft
				offsetY: 0,
				offsetX: 60,
			},
		},
		legend: {
			horizontalAlign: "left",
			offsetX: 40,
		},
		...chartoptions,
	};

	return (
		<Spin spinning={dataLoading || userLoading}>
			<div id="chart bg-light p-2  font-roboto-condensed">
				<ReactApexChart options={options} series={series} type="area" height={height} />
			</div>
		</Spin>
	);
};
