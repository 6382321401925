import { gql, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useCountSubscription } from "./countSubscription";

export const useDataQuery = (props) => {
	const {
		where = {},
		filters = {},
		search_on = {},
		order_by = {},
		distinct_on,
		entity = "products",
		query_fields = " id ",
		field,
		distinct,
	} = props;
	const total = useCountSubscription(entity, { ...where, ...filters }, field, distinct);
	const [objects, setObjects] = useState([]);

	const QUERY = gql`
		query get_${entity}($where: ${entity}_bool_exp, $limit: Int, $offset: Int, $order_by: [${entity}_order_by!], $distinct_on: [${entity}_select_column!])
		{ objects: ${entity}(where: $where, limit: $limit, offset: $offset, order_by: $order_by, distinct_on: $distinct_on) { ${query_fields} } }
	`;
	const { data, error, loading, refetch } = useQuery(QUERY, {
		variables: {
			where: { ...where, ...filters, ...search_on },
			order_by,
			distinct_on,
			limit: props.limit || null,
		},
	});
	useEffect(() => {
		if (!loading && !error && data && data.objects) setObjects(data?.objects);
	}, [data, error, loading]);

	useEffect(() => {
		refetch();
	}, [refetch, total]);

	return { data, objects, loading, refetch, total };
};

export const usePaginatedQuery = (props) => {
	const {
		where = {},
		filters = {},
		order_by = {},
		distinct_on,
		entity = "products",
		field,
		distinct,
		query_fields = " id ",
		search_on = {},
	} = props;
	const [objects, setObjects] = useState([]);
	const [limit, setLimit] = useState(props.limit || 10);
	const [page, setPage] = useState(1);
	const offset = (page - 1) * limit;
	const total = useCountSubscription(entity, { ...where, ...filters }, field, distinct);
	const pages = Math.ceil(total / limit);
	if (total < offset) setPage(1);

	const QUERY = gql`
		query get_${entity}($where: ${entity}_bool_exp, $limit: Int, $offset: Int, $order_by: [${entity}_order_by!], $distinct_on: [${entity}_select_column!])
		{ objects: ${entity}(where: $where, limit: $limit, offset: $offset, order_by: $order_by, distinct_on: $distinct_on) { ${query_fields} } }
	`;
	const { data, error, loading, refetch } = useQuery(QUERY, {
		variables: {
			where: { ...where, ...filters, ...search_on },
			limit,
			offset,
			order_by,
			distinct_on,
		},
	});
	useEffect(() => {
		if (!loading && !error && data && data.objects) setObjects(data?.objects);
		else if (error) console.error(error);
	}, [data, error, loading]);

	useEffect(() => {
		refetch();
	}, [refetch, total]);
	return {
		data,
		objects,
		loading,
		refetch,
		setLimit,
		setPage,
		limit,
		offset,
		pages,
		page,
		total,
	};
};
