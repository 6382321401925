import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { Q_DUMMY } from "../gql";
import { useCountSubscription } from "./countSubscription";

export const useAllObjectsQuery = ({ where = {}, filters = {}, order_by = {}, query = Q_DUMMY, entity, field, distinct }) => {
	const [objects, setObjects] = useState([]);
	const total = useCountSubscription(entity, { ...where, ...filters }, field, distinct);
	const { data, error, loading, refetch } = useQuery(query, { variables: { where: { ...where, ...filters }, order_by } });
	useEffect(() => {
		if (!loading && !error && data && data.objects) setObjects(data.objects);
	}, [data, error, loading]);

	useEffect(() => {
		refetch();
	}, [refetch, total]);

	return {
		data,
		objects,
		loading,
		total,
		refetch,
	};
};
