import React, { useContext } from "react";
import { AntdTable } from "../../../../shared/components";
import { useDocTitle } from "../../../../shared/helpers";
import { useTranslatedLabel } from "../../../../shared/hooks";
import { GeneralContext } from "../../store";
import { TranslationModal } from "./modal-labels";
const data_fields = `name id created_at updated_at desc translations { id map_id language text } `;

export const Labels = ({ component_filters = {}, hideColumns = [], ...props }) => {
	useDocTitle("labels");
	const { general_languages } = useContext(GeneralContext);
	const { t } = useTranslatedLabel();
	const LANGUAGES = general_languages.map((l) => ({
		title: l.comment,
		key: l.value,
		render: (data) => data[l.value] ?? "",
		sorter: (a, b) => a && b && (a[l.value] ?? "").localeCompare(b[l.value] ?? ""),
	}));
	const COLUMNS = [
		{
			title: t("name"),
			key: "name",
			render: AntdTable.TextRenderer("name"),
			sorter: AntdTable.TextSorter("name"),
		},
		{
			title: t("desc"),
			key: "desc",
			render: AntdTable.TextRenderer("desc"),
			sorter: AntdTable.TextSorter("desc"),
		},
		...LANGUAGES,

		// {
		// 	title: t("created_at"),
		// 	key: "created_at",
		// 	render: AntdTable.DateRenderer("created_at"),
		// 	sorter: AntdTable.TextSorter("created_at"),
		// },
		// {
		// 	title: t("updated_at"),
		// 	key: "updated_at",
		// 	render: AntdTable.DateRenderer("updated_at"),
		// 	sorter: AntdTable.TextSorter("updated_at"),
		// },
		{
			title: <TranslationModal entity="labels" />,
			key: "id",
			render: (text, row) => <TranslationModal key={`${row.id}_x`} entity="labels" current={row} refetch={row.refetch} />,
		},
	];

	return (
		<>
			<AntdTable
				entity="labels"
				columns={COLUMNS}
				order_by={{ name: "asc" }}
				filters={{ ...component_filters }}
				query_fields={data_fields}
				search_fields={["name", "desc"]}
				{...props}
			/>
		</>
	);
};
