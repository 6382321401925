import { useMutation } from "@apollo/client";
import { Steps } from "antd";
import dayjs from "dayjs";
import React, { useContext, useState } from "react";
import { Badge } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Skelton from "react-loading-skeleton";
import { useParams } from "react-router-dom";
import { AntdTable } from "../../../../shared/components";
import { useTranslatedLabel } from "../../../../shared/hooks";
import ViewServiceOrder from "../../../client/pages/user/service/orders/view-order";
import { CustomLink, Layout } from "../../components";
import { INSERT_ONE, Q_SERVICE_ORDERS } from "../../gql";
import { useAllObjectsQuery } from "../../hooks/allObjectsQuery";
import { AuthContext, GeneralContext } from "../../store";
import { LogisticModal } from "./logistics-modal";

const { Step } = Steps;

export const ServiceOrderDetails = () => {
	const { id } = useParams();
	return (
		<Layout title={`Service Order Details`}>
			<div className="bg-light pb-5 order-detailsr">
				<div className="container">
					<ViewServiceOrder component={true} inAdmin={true} order_id={id} />
				</div>
			</div>
		</Layout>
	);
};

export const ServiceOrderDetailsOLD = () => {
	const { t, dt } = useTranslatedLabel();
	const { id } = useParams();
	const { general_order_status } = useContext(GeneralContext);

	const { user } = useContext(AuthContext);
	const { register, handleSubmit } = useForm({
		mode: "onChange",
	});
	const [showLog, setShowLog] = useState(false);
	const { objects, loading, error, refetch } = useAllObjectsQuery({
		query: Q_SERVICE_ORDERS,
		where: {
			id: { _eq: id },
		},
	});

	const [addNote] = useMutation(INSERT_ONE("notes"), {
		onCompleted: () => {
			refetch();
		},
		onError: (error) => {
			console.error(error.message);
		},
	});

	const onLogAdd = () => setShowLog(true);
	const onLogClose = (data) => {
		setShowLog(false);
		if (data) {
			refetch();
		}
	};

	const onAddNote = (e) => {
		if (e.note) {
			addNote({
				variables: {
					object: {
						service_order_id: id,
						text: e.note,
						user_id: user.id,
					},
				},
			});
		}
	};

	const LOGISTICS_FIELDS = [
		{
			title: t("tracking_number"),
			key: "tracking_id",
			dataIndex: "tracking_id",
		},
		{
			title: t("driver_name"),
			key: "contact_name",
			dataIndex: "contact_name",
		},
		{
			title: t("phone"),
			key: "contact_phone",
			dataIndex: "contact_phone",
		},
		{
			title: t("status"),
			key: "status",
			dataIndex: "status",
		},
		{
			title: t("date"),
			key: "delivery_date",
			render: AntdTable.DateRenderer("delivery_date"),
		},
	];

	const COMMON_FIELDS = [
		{
			title: t("date"),
			key: "created_at",
			render: ({ value }) => dayjs(value).format("DD/MM/YYYY"),
		},
		{
			title: t("type"),
			key: "type_of_payment",
			dataIndex: "type_of_payment",
		},
		{
			title: t("payment_mode"),
			key: "payment_mode",
			dataIndex: "payment_mode",
		},
		{
			title: t("amount"),
			key: "amount",
			dataIndex: "amount",
		},
		{
			title: t("status"),
			key: "status",
			dataIndex: "status",
		},
		{
			title: t("id"),
			key: "id",
			dataIndex: "id",
		},
	];

	if (loading) {
		return <Skelton height="80vh" />;
	} else if (!error && objects) {
		const [current] = objects;
		return (
			<Layout>
				<LogisticModal show={showLog} onClose={onLogClose} order={current} />
				<div className="bg-light pb-5 order-details">
					<div className="container">
						<div className="d-flex pt-5">
							<div className="order-details-main">
								<div className="mb-3">
									<div className="order-details-summary">
										<div className="d-flex justify-content-between  bg-white p-4">
											<div>
												<h3 className="display-4 font-weight-bold">
													<span className="mr-1">Service Order #{current?.uid}</span>
													<i className="las la-clipboard"></i>
												</h3>
												<div className="d-flex sm-text">
													<p className="ml-1">
														<span className="font-weight-bold">Date:</span>{" "}
														<span>{dayjs(current?.created_at).format("MMMM DD, YYYY")}</span>
													</p>
												</div>
											</div>
											<div className="text-right">
												<h3 className="order-amount font-weight-bold">{`$ ${current?.provider_service?.amount}`}</h3>
												<Badge variant="success" className="text-capitalize">
													{current?.enum_order_status?.comment} <i className="las la-check-circle"></i>
												</Badge>
											</div>
										</div>
									</div>
									<div className="pt-2 pb-2 bg-white mb-1">
										<h5 className="font-weight-bold ml-3">Order Status</h5>
										<div className="row my-4">
											<div className="col-12">
												<Steps
													size="small"
													current={general_order_status.findIndex((item) => item.value === current?.status)}
												>
													{general_order_status.map((item) => {
														if (current?.status !== "cancelled") {
															if (item.value !== "cancelled") {
																return <Step key={item.value} title={item.comment} />;
															} else {
																return null;
															}
														} else {
															if (item.value !== "delivered") {
																return (
																	<Step
																		key={item.value}
																		title={item.comment}
																		status={item.value === "cancelled" && "error"}
																	/>
																);
															} else {
																return null;
															}
														}
													})}
												</Steps>
											</div>
										</div>
									</div>
								</div>
								{/*
                <div className="d-flex justify-content-between bg-white p-2 mb-3">
                  <div className="text-left">
                    <h5 className="font-weight-bold">
                      <Icon
                        type="farm"
                        style={{
                          width: "1rem",
                          height: "1rem",
                          fill: "currentColor",
                        }}
                        className="mr-1"
                      />{" "}
                      Farm Location
                    </h5>
                    <p className="mb-0">{`Bangalore, Karnataka`}</p>
                    <p>India</p>
                  </div>
                  <div className="text-right">
                    <h5 className="font-weight-bold">
                      Delivery Location
                      <Icon
                        type="box"
                        style={{
                          width: "1rem",
                          height: "1rem",
                          fill: "currentColor",
                        }}
                        className="ml-1"
                      />
                    </h5>
                    <p className="mb-0">{`Chennai, Tamilnadu`}</p>
                    <p>India</p>
                  </div>
                </div> */}

								<div className="pt-2 pb-2 bg-white mb-3">
									<h5 className="font-weight-bold ml-3">Payment Details</h5>
									<AntdTable
										columns={COMMON_FIELDS}
										entity={"payments"}
										where={{
											trade_order_id: {
												_eq: id,
											},
										}}
										query_fields={`created_at type_of_payment payment_mode amount status id`}
									/>
								</div>
								<div className="pt-2 pb-2 bg-white mb-3">
									<h5 className="font-weight-bold ml-3 d-flex align-items-center">
										Logistics Details
										<div className="btn btn-outline-primary  ml-auto" onClick={onLogAdd}>
											<span>Add Logistics</span>
										</div>
									</h5>

									<AntdTable
										columns={LOGISTICS_FIELDS}
										entity={"logistics"}
										where={{
											service_order_id: {
												_eq: id,
											},
										}}
										query_fields="tracking_id contact_name contact_phone status delivery_date"
									/>
								</div>
							</div>
							<div
								className="new-offer-preview bg-white p-3 d-flex flex-column justify-content-end align-self-start"
								style={{ minHeight: "40rem" }}
							>
								<div className="flex-grow-1 mb-3">
									<h4 className="font-weight-bold text-uppercase">{t("details")}</h4>
									<hr />
									<div className="mb-3">
										<span className="text-muted mb-0">Provider</span>
										<CustomLink page="users" id={current?.provider_service?.user?.id}>
											<span className="font-weight-bold mb-0 float-right">{current?.provider_service?.user?.name}</span>
										</CustomLink>
									</div>
									<div className="mb-3">
										<span className="text-muted mb-0">{t("buyer")}</span>
										<CustomLink page="users" id={current?.buyer?.id}>
											<span className="font-weight-bold mb-0 float-right">{current?.buyer?.name}</span>
										</CustomLink>
									</div>
									<hr />
									<div className="mb-3">
										<span className="text-muted mb-0">{t("service")}</span>
										<CustomLink page="services" id={current?.service_type?.service?.id}>
											<span className="font-weight-bold mb-0 float-right">{current?.service_type?.service?.name}</span>
										</CustomLink>
									</div>
									<div className="mb-3">
										<span className="text-muted mb-0">Category</span>
										<CustomLink page="serviceTypes" id={current?.service_type?.id}>
											<span className="font-weight-bold mb-0 float-right">{current?.service_type?.name}</span>
										</CustomLink>
									</div>
									<hr />
									<div className="mb-3">
										<span className="text-muted mb-0">Service Fee</span>
										<span className="font-weight-bold mb-0 float-right">{current?.provider_service?.amount}</span>
									</div>

									<hr />

									<div className="mb-3">
										<span className="text-muted mb-0">Date</span>
										<span className="font-weight-bold mb-0  float-right">{dt(current?.date)}</span>
									</div>
									{/* <hr />
									<div className="mb-3">
										<span className="text-muted mb-0">Range in Kms</span>
										<span className="font-weight-bold mb-0  float-right">{current?.provider_service?.range_in_kms}</span>
									</div> */}
									<hr />
									<div className="mb-3">
										<p className="text-muted mb-0">{t("origin")}</p>
										<CustomLink pages="locations" id={current?.provider_service.location?.id}>
											<span className="font-weight-bold mb-0">
												{current?.provider_service.location?.address_line_1},
												{current?.provider_service.location?.address_line_2}
												{current?.provider_service.location?.address_line_3}
											</span>
											<span className="font-weight-bold mb-0">{current?.provider_service.location?.city}</span>
											<span className="font-weight-bold mb-0">{current?.provider_service.location?.state},</span>
											<span className="font-weight-bold mb-0">{current?.provider_service.location?.country},</span>
											<span className="font-weight-bold mb-0">{current?.provider_service.location?.postcode}</span>
										</CustomLink>
									</div>
									<hr />
									{current?.location && (
										<div className="mb-3">
											<p className="text-muted mb-0">{t("destination")}</p>
											<CustomLink pages="locations" id={current?.location?.id}>
												<span className="font-weight-bold mb-0">
													{current?.location?.address_line_1}
													<br />
													{current?.location?.address_line_2}
													{current?.location?.address_line_3}
												</span>
												<span className="font-weight-bold mb-0">{current?.location?.city}</span>
												<span className="font-weight-bold mb-0">{current?.location?.state}</span>
												<span className="font-weight-bold mb-0">{current?.location?.country}</span>
												<span className="font-weight-bold mb-0">{current?.location?.postcode}</span>
											</CustomLink>
										</div>
									)}

									<hr />
									<div className=" mb-3 bg-white">
										<p className="text-muted mb-0">{t("notes")}</p>
										<form onSubmit={handleSubmit(onAddNote)}>
											<textarea
												name="note"
												ref={register({ required: "Required" })}
												placeholder="Add a Note"
												rows="5"
												class="ant-input"
											/>

											<div className="d-flex ">
												<input type="submit" className="btn btn-primary  mt-2 ml-auto" value="Add Note" />
											</div>
										</form>
									</div>
									<div className="mb-3">
										<div className=" m-2 p-4">
											{true ? (
												<Steps direction="vertical" progressDot={true}>
													{current?.notes?.map((item, index) => (
														<Step
															className="custom-step"
															key={item.id}
															title={item.text}
															description={item.user.email}
															status={current?.notes.length - 1 === index ? "process" : "finish"}
														/>
													))}
												</Steps>
											) : (
												<Steps direction="vertical" progressDot={true}>
													<Step className="custom-step" title={"No notes are added"} />
												</Steps>
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Layout>
		);
	} else {
		return null;
	}
};

/*


  if (loading) {
    return (
      <Layout>
        <Loader />
      </Layout>
    );
  } else {
    const [current] = objects;
    return (
      <Layout>
        <div
          className="admin-container"
          style={{ backgroundColor: "rgba(255,255,255, 0.3)" }}
        >
          <Row className="page-title">
            <Col>
              <h1>Service Order Details</h1>
              <h3 className="primary-text">{`#${current?.uid}`}</h3>
            </Col>
            <Col>
              <Row>
                <Col>
                  <Button size="lg" block>
                    Download Pdf
                  </Button>
                </Col>
                <Col>
                  <Button size="lg" block>
                    Email Invoice
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row style={{ marginLeft: "2rem" }}>
            <Stepper steps={statuses} current={current?.status} />
          </Row>
          <Row>
            <Col xs={8}>
              <Row>
                <Col>
                  <Card>
                    <Card.Body>
                      <Row>
                        <Col>
                          <h3>{`${current?.provider_service?.location?.city}, ${current?.provider_service?.location?.enum_country.comment}`}</h3>
                          <p>Service Location</p>
                        </Col>
                        <Col>
                          <h3>$ {current?.provider_service?.amount}</h3>
                          <p>Order Value</p>
                        </Col>
                        <Col></Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Group>
                            <Form.Control as="textarea" rows="3" />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={{ span: 4, offset: 8 }}>
                          <Button block size="lg">
                            Add a Note
                          </Button>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Card>
                    <Card.Body>
                      <Row>
                        <Col>
                          <h3>Payment Details</h3>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <AntdTable
                            columns={COMMON_FIELDS}
                            entity={"payments"}
                            where={{
                              service_order_id: { _eq: id },
                            }}
                          />
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Col>

             Right Column
            <Col xs={4}>
              <Row>
                <Card>
                  <Card.Body>
                    <Row>
                      <Col>
                        <h3>Service Order Details</h3>
                      </Col>
                       <Col>
                        <Button
                          variant="light"
                          className="float-right"
                          onClick={onEdit}
                        >
                          Edit
                        </Button>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <table rowKey="id" className="custom-table">
                          <tbody>
                            <tr>
                              <td>{t("id")}</td>
                              <td>{`${current?.uid}`}</td>
                            </tr>
                            <tr>
                              <td>{t("service")}</td>
                              <td>
                                <CustomLink
                                  page="services"
                                  id={current?.service_type?.service?.id}
                                >{`${current?.service_type?.service?.name}`}</CustomLink>
                              </td>
                            </tr>
                            <tr>
                              <td>{t("service_category")}</td>
                              <td>
                                <CustomLink
                                  page="serviceTypes"
                                  id={current?.service_type?.id}
                                >{`${current?.service_type?.name}`}</CustomLink>
                              </td>
                            </tr>
                            <tr>
                              <td>{t("status")}</td>
                              <td>{current?.enum_order_status?.comment}</td>
                            </tr>
                            <tr>
                              <td>Provider</td>
                              <td>
                                <CustomLink
                                  page="users"
                                  id={current?.provider_service?.user?.id}
                                >
                                  {current?.provider_service?.user?.email}
                                </CustomLink>
                              </td>
                            </tr>
                            <tr>
                              <td>Customer</td>
                              <td>
                                <CustomLink
                                  page="users"
                                  id={current?.buyer?.id}
                                >
                                  {current?.buyer?.email}
                                </CustomLink>
                              </td>
                            </tr>
                            <tr>
                              <td>Customer Contact</td>
                              <td>{current?.buyer?.phone_number}</td>
                            </tr>
                            <tr>
                              <td>{t("price_per_unit")}</td>
                              <td>{current?.provider_service?.amount}</td>
                            </tr>

                            <tr>
                              <td>{t("logistics")}</td>
                              <td>No</td>
                            </tr>
                            <tr>
                              <td>Location</td>
                              <td>
                                <CustomLink
                                  page="locations"
                                  id={current?.provider_service?.location?.id}
                                >
                                  {`${current?.provider_service?.location?.city}, ${current?.provider_service?.location?.enum_country.comment}`}
                                </CustomLink>
                              </td>
                              <td></td>
                            </tr>
                            <tr>
                              <td>Comments</td>
                              <td>Packed in 20kg Cartons</td>
                            </tr>
                          </tbody>
                        </table>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Row>
            </Col>
          </Row>
        </div>
      </Layout>
    );
  }
*/
