import { useMutation, useSubscription } from "@apollo/client";
import dayjs from "dayjs";
import React, { Fragment, useContext, useEffect, useState } from "react";
import ImageGallery from "react-image-gallery";
import Skeleton from "react-loading-skeleton";
import NumberFormat from "react-number-format";
import { Link, Redirect, useParams } from "react-router-dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import defaultImage from "../../../../images/offer-preview-default.png";
import { APP_CONFIG } from "../../../../shared/app_config";
import { getThumbNail, PreviewMap } from "../../../../shared/helpers/utils";
import { useTranslatedLabel } from "../../../../shared/hooks";
import { AuthLayout } from "../../components";
import { PATHS } from "../../config";
import { INSERT_TRADE_VIEWS, UPDATE_REQUEST, UPDATE_REQUESTS } from "../../gql";
import { GET_BIDDERS, GET_TRADE_OFFER_BY_ID } from "../../gql/queries";
import { AuthContext } from "../../store";
import { ChatBox } from "../dealroom/chat";
import { TradeOfferList } from "./offer-list";

const ViewTradeOffer = ({
	history,
	component = false,
	inAdmin = false,
	offer_id = "",
	pagination,
	noFilter = false,
	limit = null,
	component_filters = {},
}) => {
	const { t, dt } = useTranslatedLabel();
	const [newpath, setNewPath] = useState("");
	const { user_role, user } = useContext(AuthContext);

	const [showMap, setShowMap] = useState(false);
	const [offer, setOffer] = useState(null);
	const { offerId } = useParams();

	const current_id = component ? offer_id : offerId;
	const user_id = inAdmin ? offer?.user?.id : user?.id;

	const { loading, data, error } = useSubscription(GET_TRADE_OFFER_BY_ID, {
		variables: { id: current_id },
	});

	const [updateRequest] = useMutation(UPDATE_REQUEST);
	const [updateRequests] = useMutation(UPDATE_REQUESTS);

	// Populate location
	const [location_data, setLocationData] = useState();
	useEffect(() => {
		if (offer?.location) {
			setLocationData({
				...offer?.location,
				name: offer?.location?.name,
				fullAddress: `${offer?.location?.name},${offer?.location?.city ?? " "},${offer?.location?.state ?? " "},${
					offer?.location?.country ?? " "
				}`,
				mapCenter: {
					lat: offer?.location?.lat || 0,
					lng: offer?.location?.lng || 0,
				},
				markerPosition: {
					lat: offer?.location?.lat || 0,
					lng: offer?.location?.lng || 0,
				},
			});
		}
	}, [offer]);

	useEffect(() => {
		if (!loading && data?.object && !error) setOffer(data?.object);
	}, [data, loading, error]);

	const [bidders, setBidders] = useState([]);
	const [bidder_id, setBidderId] = useState("");

	const { data: biddersData, loading: biddersLoading, error: biddersError } = useSubscription(GET_BIDDERS, {
		variables: { user_id: user_id, offer_id: current_id },
	});

	useEffect(() => {
		if (!biddersLoading && biddersData && biddersData.objects && !biddersError) {
			let userMap = {};
			biddersData.objects.map((o) => {
				userMap[o.user?.id] = { ...o.user, timestamp: o.timestamp };
				return 0;
			});
			setBidders(Object.values(userMap).sort((a, b) => b?.timestamp?.localeCompare(a?.timestamp)));
		}
	}, [biddersData, biddersError, biddersLoading]);

	useEffect(() => {
		if (!bidder_id && bidders.length > 0) setBidderId(bidders[0].id);
	}, [bidder_id, bidders]);

	const [insertViews] = useMutation(INSERT_TRADE_VIEWS);
	useEffect(() => {
		if (!inAdmin) {
			if (offer && offer?.user && offer?.user?.id !== user?.id)
				insertViews({
					variables: {
						request_id: current_id,
						user_id: user?.id,
						date: dayjs().format("YYYY-MM-DD"),
					},
				}).catch((e) => e);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [offer, inAdmin]);

	const valid_user =
		(user_role === "buyer" && offer?.type === "sell_offer") ||
		(user_role === "grower" && offer?.type === "buy_request") ||
		offer?.user?.id === user?.id;

	const images = offer?.pictures || [];

	// if error (e.g offer no longer available, or invalid request id)
	if (!loading && error && !inAdmin) return <Redirect to={PATHS.marketplace} />;

	if (user_role && user_role === "provider" && !inAdmin) return <Redirect to={PATHS.services} />;

	const Wrapper = component ? Fragment : AuthLayout;

	return (
		<>
			<div id="main-wrapper" style={component ? { marginTop: "0px", paddingTop: "0px" } : {}}>
				<Wrapper showBottom={true}>
					{newpath && <Redirect to={newpath} />}
					<div className="offer-details-page bg-light">
						<div className="container pb-4 pt-5">
							<div className="row">
								<div>
									<h3 className="page-title display-4" style={{ padding: "0px", marginLeft: component ? 16 : 0 }}>
										{t(offer?.enum_request_type.value)} # {offer?.uid}
									</h3>
								</div>
								<div className="ml-auto" style={component ? { marginRight: 16 } : {}}>
									{!inAdmin && offer?.user?.id === user?.id && offer?.active && (
										<div className="">
											<div
												onClick={() => setNewPath(`${PATHS.trade_edit_offer}/${offer?.id}`)}
												className="btn btn-sm btn-outline-primary ml-3"
											>
												{t("edit")}
											</div>
											<div
												onClick={() =>
													Swal.fire({
														title: t("are_you_sure?"),
														text: t("you_wont_be_able_to_revert!"),
														icon: "warning",
														showCancelButton: true,
														confirmButtonText: t("yes"),
													}).then((x) => {
														if (x.value) {
															updateRequest({
																variables: {
																	id: offer?.id,
																	object: {
																		active: false,
																		status: "completed",
																	},
																},
															});
															updateRequests({
																variables: {
																	where: {
																		request_id: { _eq: offer?.id },
																		active: { _eq: true },
																	},
																	object: {
																		active: false,
																		status: "completed",
																	},
																},
															});
															setNewPath(`${PATHS.trade_offer_history}`);
														}
													})
												}
												className="btn btn-sm btn-outline-primary ml-3"
											>
												{t("close")}
											</div>
										</div>
									)}
								</div>
							</div>

							<div className="offer-details pb-3">
								<div className="offer-main bg-white p-4">
									{loading && (
										<div className="mb-2">
											<Skeleton height={30} />
										</div>
									)}

									{loading && (
										<div className="d-flex align-items-center mb-3 w-50">
											<div className="mr-2">
												<Skeleton circle={true} height={25} width={25} />
											</div>
											<div className="flex-grow-1">
												<Skeleton height={25} />
											</div>
										</div>
									)}
									{!loading && offer?.id && (
										<div className="d-flex justify-content-between bg-white p-2 mb-3">
											<div className="text-left font-success">
												<h3 className="d-inline-flex align-items-center">
													<span className="">{`${t(
														offer?.product_type?.product?.id,
														offer?.product_type?.product?.name
													)}, ${t(offer?.product_type?.id, offer?.product_type?.name)} `}</span>
												</h3>
												<br />
												<p className="d-inline-flex align-items-center">
													<img
														src={offer?.user?.photo_url || APP_CONFIG.REACT_APP_AVATAR_URL}
														style={{ height: "30px" }}
														alt=""
													/>
													<span className="ml-3 mr-3">{offer?.user?.name}</span>
												</p>
											</div>
											<div className="text-right font-danger">
												<h3 className="d-inline-flex align-items-center">
													<span className="ml-3">
														<NumberFormat
															value={parseFloat(offer?.price_per_unit).toFixed(2)}
															displayType={"text"}
															thousandSeparator={true}
															prefix={"$ "}
															suffix={`/ ${t(offer?.enum_product_unit.comment)}`}
														/>
													</span>
												</h3>
												<br />
												{offer?.user?.id === user?.id && (
													<p className="d-inline-flex align-items-center">
														{`${t("viewed")} : ${offer?.views?.aggregate?.sum?.views || 0} | ${t("searched")}: ${
															offer?.views?.aggregate?.sum?.searches || 0
														}`}
													</p>
												)}
											</div>
										</div>
									)}
									{loading && (
										<div className="mb-3">
											<Skeleton height={300} />
										</div>
									)}

									{!loading && offer?.id && (
										<div className="grower-description">
											{user_role === "grower" && offer?.type === "buy_request" && (
												<>
													<hr />
													<div className="h4 font-weight-bold mb-3">{t("activity")}</div>
												</>
											)}
											{user_role === "buyer" && offer?.type === "sell_offer" && (
												<>
													<hr />
													<div className="h4 font-weight-bold mb-3">{t("activity")}</div>
												</>
											)}
											{offer?.user?.id === user?.id && (
												<>
													<hr />
													<div className="h4 font-weight-bold mb-3">{t("activity")}</div>
												</>
											)}
											<div>
												{offer?.id && valid_user && offer?.user?.id !== user?.id && (
													<ChatBox offer_id={offer?.id} bidder_id={offer?.user?.id} connection_id={offer?.id} />
												)}
												{offer?.id && valid_user && offer?.user?.id === user?.id && (
													<>
														{bidders.length > 0 ? (
															bidders.map((b, i) => (
																<ChatBox offer_id={offer?.id} bidder_id={b.id} connection_id={offer?.id} />
															))
														) : (
															<div>{t("no_activity")}</div>
														)}
													</>
												)}
												{offer?.id && inAdmin && (
													<>
														{bidders.length > 0 ? (
															bidders.map((b, i) => (
																<ChatBox
																	offer_id={offer?.id}
																	bidder_id={b.id}
																	connection_id={offer?.id}
																	admin={offer?.user}
																/>
															))
														) : (
															<div>{t("no_activity")}</div>
														)}
													</>
												)}
											</div>
										</div>
									)}

									{loading && !component && (
										<div className="mb-3">
											<Skeleton height={300} />
										</div>
									)}
									{!loading && !component && offer?.id && images && images.length > 0 && (
										<div className="mb-3">
											<hr />
											<div className="h4 font-weight-bold mb-3">{t("images")}</div>
											{!loading && offer && !offer?.pictures && (
												<div
													className="offer-image"
													style={{
														backgroundImage: `url(${defaultImage})`,
														height: "20rem",
													}}
												/>
											)}
											{!loading && offer && (
												<ImageGallery
													items={images.map((picture) => ({
														original: picture,
														thumbnail: getThumbNail(picture),
													}))}
													thumbnailPosition="bottom"
													showBullets={false}
													showNav={false}
													showPlayButton={false}
													showFullscreenButton={!false}
												/>
											)}
										</div>
									)}
									{false && !loading && offer?.id && offer?.user && offer?.user?.id !== user?.id && (
										<div className="grower-description">
											<hr />
											<h4 className="font-weight-bold mb-3">
												{offer?.type === "sell_offer" ? t("about_the_grower") : t("about_the_buyer")}
											</h4>
											<div className="d-flex align-items-center">
												<img src={offer?.user?.photo_url || APP_CONFIG.REACT_APP_AVATAR_URL} alt="" className="mr-4" />
												<div>
													<p className="mb-1">
														<span className="font-weight-bold text-muted">{offer?.user?.name}</span>
													</p>
													{user_role &&
														((user_role === "buyer" && offer?.type === "sell_offer") ||
															(user_role === "grower" && offer?.type === "buy_request")) && (
															<p>
																<Link to={"/messages"} className="btn btn-outline-primary">
																	{t("contact_me")}
																</Link>
															</p>
														)}
												</div>
											</div>
										</div>
									)}
								</div>

								<aside className="offer-sidebar">
									{loading && (
										<div className="mb-2 p-4 bg-white">
											<Skeleton height={500} />
										</div>
									)}
									{!loading && offer?.id && (
										<div className="bg-white mb-2 px-4 pb-4 pt-3 border">
											<div className="mb-2">
												<span className="text-muted mb-0">{t("product")}</span>
												<span className="font-weight-bold mb-0 float-right">
													{t(offer?.product_type?.product?.id, offer?.product_type?.product?.name)}
												</span>
											</div>
											<div className="mb-2">
												<span className="text-muted mb-0">{t("variety")}</span>
												<span className="font-weight-bold mb-0 float-right">
													{t(offer?.product_type?.id, offer?.product_type?.name)}
												</span>
											</div>
											<hr />
											<div className="mb-2">
												<span className="text-muted mb-0">{t("price_per_unit")}</span>
												<span className="font-weight-bold mb-0 float-right">
													<NumberFormat
														value={parseFloat(offer?.price_per_unit).toFixed(2)}
														displayType={"text"}
														thousandSeparator={true}
														prefix={"$ "}
													/>
												</span>
											</div>
											<div className="mb-2">
												<span className="text-muted mb-0">{`${t("quantity")} (${t("tonne")})`}</span>
												<span className="font-weight-bold mb-0 float-right">
													<NumberFormat
														value={parseInt(offer?.number_of_units)}
														displayType={"text"}
														thousandSeparator={true}
													/>
												</span>
											</div>
											<div className="mb-2">
												<span className="text-muted mb-0">{`${t("advance")} %`}</span>
												<span className="font-weight-bold mb-0 float-right">
													<NumberFormat
														value={parseInt(offer?.advance_payment_percentage)}
														displayType={"text"}
														thousandSeparator={true}
														suffix={" %"}
													/>
												</span>
											</div>
											<hr />
											<div className="mb-2">
												<span className="text-muted mb-0">{t("total_amount")}</span>
												<span className="font-weight-bold mb-0 float-right">
													<NumberFormat
														value={parseFloat(offer?.number_of_units * offer?.price_per_unit)?.toFixed(2)}
														displayType={"text"}
														thousandSeparator={true}
														prefix={"$ "}
													/>
												</span>
											</div>
											<div className="mb-2">
												<span className="text-muted mb-0">{t("advance_amount")}</span>
												<span className="font-weight-bold mb-0 float-right">
													<NumberFormat
														value={parseFloat(
															(offer?.advance_payment_percentage *
																offer?.number_of_units *
																offer?.price_per_unit) /
																100
														)?.toFixed(2)}
														displayType={"text"}
														thousandSeparator={true}
														prefix={"$ "}
													/>
												</span>
											</div>
											<div className="mb-2">
												<span className="text-muted mb-0">{t("remaining_amount")}</span>
												<span className="font-weight-bold mb-0 float-right">
													<NumberFormat
														value={parseFloat(
															offer?.number_of_units * offer?.price_per_unit -
																(offer?.advance_payment_percentage *
																	offer?.number_of_units *
																	offer?.price_per_unit) /
																	100
														)?.toFixed(2)}
														displayType={"text"}
														thousandSeparator={true}
														prefix={"$ "}
													/>
												</span>
											</div>
											<hr />
											<div className="mb-3">
												<span className="text-muted mb-0"> {t("dates")}</span>
												<span className="font-weight-bold mb-0 float-right">
													{dt(`${offer?.from_date}`)}
													{" - "}
													{dt(`${offer?.to_date}`)}
												</span>
											</div>
											<hr />
											<div className="mb-3">
												<span className="text-muted mb-0"> {t("logistics")}</span>
												<span className="font-weight-bold mb-0 float-right">{t(offer?.logistics)}</span>
											</div>
											<hr />
											<div className="mb-3">
												<p className="text-muted mb-0">
													{offer?.type === "sell_offer"
														? `${t("farm")} ${t("location")}`
														: `${t("delivery")} ${t("location")}`}{" "}
													<span
														onClick={() => setShowMap(!showMap)}
														className="btn link text-primary float-right ml-3"
													>
														{showMap ? t("hide_map") : t("show_map")}
													</span>
												</p>
												{user?.id === offer?.user?.id && (
													<p className="font-weight-bold mb-0">{offer?.location?.name}</p>
												)}
												<p className="font-weight-bold mb-0">
													{offer?.location?.address_line_1}, {offer?.location?.city}, {offer?.location?.state},{" "}
													{offer?.location?.country} {offer?.location?.postcode}
												</p>
											</div>
											{showMap && (
												<PreviewMap
													data={location_data}
													isMarkerShown
													loadingElement={<div style={{ height: `20rem` }} />}
													containerElement={<div style={{ height: `20rem` }} />}
													mapElement={<div style={{ height: `100%` }} />}
												/>
											)}
											{!loading && component && offer?.id && images && images.length > 0 && (
												<div className="mb-3">
													<hr />
													<div className="h4 font-weight-bold mb-3">{t("images")}</div>
													{!loading && offer && !offer?.pictures && (
														<div
															className="offer-image"
															style={{
																backgroundImage: `url(${defaultImage})`,
																height: "20rem",
															}}
														/>
													)}
													{!loading && offer && (
														<ImageGallery
															items={images.map((picture) => ({
																original: picture,
																thumbnail: getThumbNail(picture),
															}))}
															thumbnailPosition="bottom"
															showBullets={false}
															showNav={false}
															showPlayButton={false}
															showFullscreenButton={!false}
														/>
													)}
												</div>
											)}
										</div>
									)}
								</aside>
							</div>
						</div>

						{loading && (
							<div className="mb-3">
								<Skeleton height={100} />
							</div>
						)}
						{!component && (
							<>
								{offer?.user?.id !== user?.id && (
									<TradeOfferList
										offers={offer?.similar_offers_by_type_product_type}
										title={`${t("similar_offers_for")} ${t(
											offer?.product_type?.product?.id,
											offer?.product_type?.product?.name
										)}, ${t(offer?.product_type?.id, offer?.product_type?.name)}`}
									/>
								)}
								{loading && (
									<div className="mb-3">
										<Skeleton height={100} />
									</div>
								)}
								{offer?.user?.id !== user?.id && (
									<TradeOfferList
										offers={offer?.similar_offers_by_type_user}
										title={`${
											offer?.type === "sell_offer" ? t("more_offers_by_same_grower") : t("more_offers_by_same_buyer")
										}`}
									/>
								)}
								{loading && (
									<div className="mb-3">
										<Skeleton height={100} />
									</div>
								)}

								{offer?.user?.id === user?.id && (
									<TradeOfferList offers={offer?.matching_offers} title={`${t("matching_connections")}`} />
								)}
							</>
						)}
					</div>
				</Wrapper>
			</div>
		</>
	);
};

export default ViewTradeOffer;
