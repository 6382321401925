import { StyleSheet, Text, View } from "@react-pdf/renderer";
import React from "react";

const styles = StyleSheet.create({
	headerContainer: {
		marginTop: 36,
		flexDirection: "row",
	},
	section: {
		width: "50%",
		marginRight: "4rem",
	},
	section2: {
		width: "50%",
		textAlign: "right",
		marginLeft: "4rem",
	},
	billTo: {
		marginTop: 20,
		paddingBottom: 3,
		fontFamily: "Helvetica-Oblique",
	},
	address: {
		width: "60%",
		flexWrap: "wrap",
	},
	address2: {
		alignSelf: "flex-end",
		flexWrap: "wrap",
		width: "60%",
		textAlign: "right",
	},
});

const BillTo = ({ invoice }) => (
	<View style={styles.headerContainer}>
		<View style={styles.section}>
			<Text style={styles.billTo}>From:</Text>
			<Text>{invoice.seller_company}</Text>
			<Text style={styles.address}>{invoice.seller_address}</Text>
			<Text>{invoice.seller_phone}</Text>
			{/* <Text>{invoice.seller_email}</Text> */}
		</View>
		<View style={styles.section2}>
			<Text style={styles.billTo}>To:</Text>
			<Text>{invoice.buyer_company}</Text>
			<Text style={styles.address2}>{invoice.buyer_address}</Text>
			<Text>{invoice.buyer_phone}</Text>
			{/* <Text>{invoice.buyer_email}</Text> */}
		</View>
	</View>
);

export default BillTo;
