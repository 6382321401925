import { useLazyQuery, useMutation } from "@apollo/client";
import { ErrorMessage } from "@hookform/error-message";
import React, { useContext, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toTitleCase, useDocTitle } from "../../../../shared/helpers";
import { useTranslatedLabel } from "../../../../shared/hooks";
import { DELETE_MANY, DELETE_ONE, SEARCH_COUNT, UPSERT_MANY, UPSERT_ONE } from "../../gql";
import { GeneralContext } from "../../store";

export const TranslationModal = ({ entity, current = { id: null, name: "", desc: "" }, refetch = () => {}, title }) => {
	useDocTitle("labels");
	const [show, setShow] = useState(false);
	const { general_languages } = useContext(GeneralContext);
	const { t } = useTranslatedLabel();

	const { register, handleSubmit, errors, reset, formState, setError, clearErrors } = useForm({
		mode: "onChange",
		reValidateMode: "onChange",
		defaultValues: current,
	});
	const { dirty, isValid } = formState;

	//Generate Query
	const [upsertOne] = useMutation(UPSERT_ONE("labels", "labels_name_key", ["desc", "icon"]));
	const [upsertTranslations] = useMutation(UPSERT_MANY("translations", "translations_map_id_language_key", ["text"]));

	const [deleteOne] = useMutation(DELETE_ONE(entity));
	const [deleteTranslations] = useMutation(DELETE_MANY("translations"));

	const [uniqueNameQuery, { data, loading, error }] = useLazyQuery(SEARCH_COUNT(entity));

	useEffect(() => {
		if(show && current?.id) {
			reset(current)
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [show, current])

	useEffect(() => {
		if (!loading && !error && data && data.counts && data.counts.aggregate && data.counts.aggregate.count > 0)
			setError("name", { type: "manual", message: "Already Exists !" });
		else clearErrors("name");
	}, [clearErrors, data, error, loading, setError]);

	const handleClose = () => {
		refetch();
		reset(current);
		setShow(false);
	};

	const onSubmit = (e) => {
		upsertOne({ variables: { object: { name: e.name, desc: e.desc } } })
			.then(async ({ data }) => {
				if (data && data.object && data.object.id) {
					let object = { ...e };
					delete object.name;
					delete object.desc;
					let objects = [];
					Object.keys(object).map((lang) => {
						objects.push({
							map_id: data.object.id,
							language: lang,
							text: object[lang].trim(),
						});
						return null;
					});
					await upsertTranslations({ variables: { objects } }).catch((e) => console.warn(e));
				}
			})
			.catch((e) => console.warn(e))
			.finally(() => handleClose());
	};
	const onDelete = (id) => {
		deleteTranslations({ variables: { where: { id: { _eq: id } } } })
			.then(({ error }) => {
				if (!error) deleteOne({ variables: { id } }).catch((e) => console.warn(e));
			})
			.catch((e) => console.warn(e))
			.finally(() => handleClose());
	};

	//   const receiveImageData = (data) => {
	//     setPictures(data);
	//   };

	if (show)
		return (
			<Modal key={current?.id || "new"} centered show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>{current?.id ? `Edit ${current?.name}` : `Add ${toTitleCase(entity)}`}</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="row">
							<div className="col-4">
								<label htmlFor="name">
									<div className="">
										Name <span className="text-danger">*</span>
									</div>
									<small className="text-danger">
										<ErrorMessage errors={errors} name="name">
											{({ message }) => <p>{message}</p>}
										</ErrorMessage>
									</small>
								</label>
							</div>
							<div className="col-8">
								<input
									className="form-control text-dark"
									name="name"
									disabled={current?.id}
									ref={register({
										required: "Required !",
										validate: {
											name: (value) => {
												if (value && value !== current?.name) uniqueNameQuery({ variables: { text: value } });
												return true;
											},
										},
									})}
								/>
							</div>
						</div>
						<div className="row">
							<div className="col-4">
								<label htmlFor="desc">
									<div className="">
										Desc <span className="text-danger">*</span>
									</div>
									<small className="text-danger">
										<ErrorMessage errors={errors} name="desc" />
									</small>
								</label>
							</div>
							<div className="col-8">
								<input
									className="form-control text-dark"
									disabled={current?.id}
									name="desc"
									ref={register({
										required: "Required !",
									})}
								/>
							</div>
						</div>
						{general_languages.map((field, index) => {
							return (
								<div className="row" key={index}>
									<div className="col-4">
										<label htmlFor={field.value}>
											<div className="">
												{field.comment} <span className="text-danger">*</span>
											</div>
											<small className="text-danger">
												<ErrorMessage errors={errors} name={field.value} />
											</small>
										</label>
									</div>
									<div className="col-8">
										<input
											className="form-control text-dark"
											name={field.value}
											lang={field.value.substring(0, 3)}
											ref={register({ required: "Required" })}
										/>
									</div>
								</div>
							);
						})}
						{/* <div className="row">
              <div className="col">
                <ImageUploader
                  sendImageData={receiveImageData}
                  pictures={pictures}
                  limit={1}
                />
              </div>
            </div> */}
						<div className="row">
							<div className="col">
								<hr />
							</div>
						</div>
						<div className="row">
							<Button variant="primary" onClick={handleClose} className="mr-3 pull-left">
								{t("cancel")}
							</Button>
							{dirty && (
								<Button variant="warning" onClick={() => reset(current)} className="mr-3">
									{current?.id ? t("reset") : t("clear")}
								</Button>
							)}
							{current?.id && (
								<Button variant="danger" onClick={() => onDelete(current?.id)} className="mr-3">
									{t("delete")}
								</Button>
							)}
							{isValid && <input className="btn btn-success mr-3 float-right" type="submit" />}
						</div>
					</form>
				</Modal.Body>
			</Modal>
		);
	else
		return (
			<Button size="sm" key={current?.id || "new"} onClick={() => setShow(true)}>
				{title ? title : current?.id ? `Edit` : `Add`}
			</Button>
		);
};
