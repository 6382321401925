import React from "react";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";

import { UPDATE_ONE } from "../../gql";
import defaultNotificationImage from "../../../../images/default-notification-image.png";

export const NotificationListItem = ({ notification, is_default = false }) => {
  const history = useHistory();
  const [updateNotification] = useMutation(UPDATE_ONE("notifications"));

  const visitNotificationLink = async ({ id: notificationId, cta_link }) => {
    try {
      history.push(cta_link);
      await updateNotification({
        variables: { id: notificationId, object: {} },
      });
    } catch (err) {
      console.warn(err);
    }
  };

  return (
    <>
      {!is_default && (
        <div
          className={
            notification.active
              ? `notification-list-item p-2 d-flex align-items-center notification-list-item-active`
              : `notification-list-item p-2 d-flex align-items-center`
          }
          onClick={() => visitNotificationLink(notification)}
        >
          <div
            className="notification-item-avatar"
            style={{ backgroundImage: `url(${defaultNotificationImage})` }}
          />
          <div className="flex-grow-1 text-left">
            <p className="mb-0 sm-text font-weight-bold">
              {notification.header}
            </p>
            <p className="mb-2 sm-text">{notification.cta_title}</p>
            <p className="mb-0 text-gray sm-text-2">
              {dayjs(notification.created_at).format("h:mm A MMMM D, YYYY")}
            </p>
          </div>
        </div>
      )}
      {is_default && (
        <div className="notification-default-item text-center">
          <p className="mb-2">
            <i className="las la-check"></i>
          </p>
          <p className="text-gray mb-0 sm-text">
            All caught up! You don't have any notifications.
          </p>
        </div>
      )}
    </>
  );
};
