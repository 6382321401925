import React, { useContext } from "react";
import { useTranslatedLabel } from "../../../../shared/hooks";
import { AuthContext } from "../../store";

export const BidMessage = ({ offer, bid, refetch, admin }) => {
	const { t, dt_tm } = useTranslatedLabel();
	const { user } = useContext(AuthContext);
	const user_id = admin?.id || user?.id;

	return (
		<div className="bubble-container">
			<div className={`bubble bg-light ${bid?.user?.id === user_id ? "align-content-end" : ""}`}>
				<div className="text-muted">
					<small className={`${bid?.user?.id === user_id ? "float-right" : ""}`}>{dt_tm(bid?.updated_at)}</small>
				</div>
				<div>
					{bid?.status === "accepted" ? (
						<span className={`font-weight-bold text-success ${bid?.user?.id === user_id ? "float-right" : ""}`}>
							{`${t("i_accept_your_deal")} : ${t("price_per_unit")} $${bid?.price_per_unit}, ${t("quantity")} ${
								bid?.number_of_units
							} ${t(bid?.enum_product_unit?.comment)}, ${t("advance")} @ ${bid?.advance_payment_percentage} %.`}
						</span>
					) : bid?.id === offer?.id ? (
						<span className={`text-success ${bid?.user?.id === user_id ? "float-right" : ""}`}>
							{`${t("initial_offer_price")} : ${t("price_per_unit")} $${bid?.price_per_unit}, ${t("quantity")} ${
								bid?.number_of_units
							} ${t(bid?.enum_product_unit?.comment)}, ${t("advance")} @ ${bid?.advance_payment_percentage} %.`}
						</span>
					) : (
						<span className={`text-primary ${bid?.user?.id === user_id ? "float-right" : ""}`}>
							{`${t("can_you_agree_on")} : ${t("price_per_unit")} $${bid?.price_per_unit}, ${t("quantity")} ${
								bid?.number_of_units
							} ${t(bid?.enum_product_unit?.comment)}, ${t("advance")} @ ${bid?.advance_payment_percentage} %.`}
						</span>
					)}
				</div>
				{/* <div className="text-black">Location: {bid?.location?.name}</div> */}
			</div>
		</div>
	);
};
