import { StyleSheet, Text, View } from "@react-pdf/renderer";
import React from "react";

const styles = StyleSheet.create({
	titleContainer: {
		flexDirection: "row",
		marginTop: 24,
	},
	reportTitle: {
		color: "#61dafb",
		letterSpacing: 4,
		fontSize: 25,
		textAlign: "center",
		textTransform: "uppercase",
	},
});

const InvoiceTitle = ({ title, service }) => (
	<View style={styles.titleContainer}>
		<Text style={styles.reportTitle}>{title}</Text>
	</View>
);

export default InvoiceTitle;
