import React, { useState } from "react";
import { Tab, Tabs, Button } from "react-bootstrap";
import { AntdTable, MapView } from "../../../../shared/components";
import { getParams } from "../../../../shared/helpers";
import { useTranslatedLabel } from "../../../../shared/hooks";
import { Link } from 'react-router-dom'
import { Layout } from "../../components";
import { useMutation } from "@apollo/client";
import { UPDATE_ONE_BY_ID } from "../../gql";
import { Spin } from "antd";
// import { Filters } from "../transactions/filters";
import { PATHS as ADMINPATHS } from "../../../admin/config";
// import { PATHS } from "../../../client/config";

const data_fields = `id created_at updated_at  agent_access buyer_access grower_access provider_access moderator_access active email photo_url phone_number created_at name account_verified
	sell_trade_offers: requests_aggregate(where: { type: {_eq: sell_offer }}) { aggregate { count sum {  total_amount } } }
	buy_trade_offers: requests_aggregate(where: { type: {_eq: buy_request }}) { aggregate { count sum {  total_amount } } }
	buy_trade_orders: tradeOrdersByBuyerId_aggregate {  aggregate { count sum { total_amount } } }
	sell_trade_orders: tradeOrdersBySellerId_aggregate { aggregate { count sum { total_amount } } }
	buy_service_orders: serviceOrdersByBuyerId_aggregate { aggregate { count sum {  total_amount } } }
	sell_service_orders: serviceOrdersBySellerId_aggregate { aggregate { count sum {  total_amount } } }
	buy_service_offers: provider_services_aggregate { aggregate { count sum {  price_per_unit } } }
	sell_service_offers: provider_services_aggregate { aggregate { count sum {  price_per_unit } } }
	location { id city name  lat lng  is_farm user { id buyer_access grower_access provider_access }  enum_country { value comment } }`;

const map_fields = `id location { id city lat lng  is_farm user { id buyer_access grower_access provider_access }  name enum_country { value comment } }`;

export const Users = ({
  history,
  component = false,
  component_filters = {},
}) => {
  const { t } = useTranslatedLabel();
  const { arg_1: role } = getParams();
  if (role) component_filters[`${role}_access`] = { _eq: true };

  const [update, { loading }] = useMutation(UPDATE_ONE_BY_ID("users"));
  // const [filters, setFilters] = useState({});
  const [tab_id, setTabId] = useState("table");
  const COLUMNS = [];
  COLUMNS.push(
    {
      title: "Name",
      key: "name",
      render: (row) => <Link to={`${ADMINPATHS.users}/${row.id}`}>
        {row?.name}
      </Link>,
      sorter: AntdTable.TextSorter("name"),
    },
    {
      title: "Email",
      key: "email",
      dataIndex: "email",
      sorter: AntdTable.TextSorter("email"),
    },
    {
      title: "Phone",
      key: "phone_number",
      dataIndex: "phone_number",
      sorter: AntdTable.TextSorter("phone_number"),
    },
    {
      title: "Location",
      key: "location",
      render: AntdTable.LocationRenderer("location"),
      sorter: AntdTable.LocationSorter("location"),
    }
  );
  if (!role)
    COLUMNS.push({
      title: "Role",
      key: "role",
      render: AntdTable.RoleRenderer,
    });
  if (role === "grower")
    COLUMNS.push({
      title: t("trade_offers"),
      key: "sell_trade_offers",
      render: AntdTable.AggregateCountRenderer(
        "sell_trade_offers",
        (row) => `${ADMINPATHS.offers}?seller_id=${row?.id}`
      ),
      sorter: AntdTable.AggregateCountSorter("sell_trade_offers"),
    });
  if (role === "buyer")
    COLUMNS.push({
      title: t("trade_offers"),
      key: "buy_trade_offers",
      render: AntdTable.AggregateCountRenderer(
        "buy_trade_offers",
        (row) => `${ADMINPATHS.offers}?buyer_id=${row?.id}`
      ),
      sorter: AntdTable.AggregateCountSorter("buy_trade_offers"),
    });
  if (role === "grower")
    COLUMNS.push({
      title: t("trade_orders"),
      key: "sell_trade_orders",
      render: AntdTable.AggregateCountRenderer(
        "sell_trade_orders",
        (row) => `${ADMINPATHS.orders}?seller_id=${row?.id}`
      ),
      sorter: AntdTable.AggregateCountSorter("sell_trade_orders"),
    });
  if (role === "buyer")
    COLUMNS.push({
      title: t("trade_orders"),
      key: "buy_trade_orders",
      render: AntdTable.AggregateCountRenderer(
        "buy_trade_orders",
        (row) => `${ADMINPATHS.orders}?buyer_id=${row?.id}`
      ),
      sorter: AntdTable.AggregateCountSorter("buy_trade_orders"),
    });
  if (role === "grower" || role === "buyer")
    COLUMNS.push({
      title: t("service_offers"),
      key: "buy_service_offers",
      render: AntdTable.AggregateCountRenderer("buy_service_offers"),
      sorter: AntdTable.AggregateCountSorter("buy_service_offers"),
    });
  if (role === "provider")
    COLUMNS.push({
      title: t("service_offers"),
      key: "sell_service_offers",
      render: AntdTable.AggregateCountRenderer("sell_service_offers"),
      sorter: AntdTable.AggregateCountSorter("sell_service_offers"),
    });
  if (role === "grower" || role === "buyer")
    COLUMNS.push({
      title: t("service_orders"),
      key: "buy_service_orders",
      render: AntdTable.AggregateCountRenderer("buy_service_orders"),
      sorter: AntdTable.AggregateCountSorter("buy_service_orders"),
    });
  if (role === "provider")
    COLUMNS.push({
      title: t("service_orders"),
      key: "sell_service_orders",
      render: AntdTable.AggregateCountRenderer("sell_service_orders"),
      sorter: AntdTable.AggregateCountSorter("sell_service_orders"),
    });

  COLUMNS.push({
    title: t("action"),
    key: "account_verified",
    render: (_, row) => {
      return (
        <Button
          size="sm"
          onClick={() =>
            update({
              variables: {
                id: row.id,
                object: { account_verified: !row.account_verified },
              },
            })
              .then(() => row.refetch())
              .catch((e) => console.warn(e))
          }
          className="mr-2 ml-2 btn-primary"
        >
          {row.account_verified ? "De-Activate" : "Activate"}
        </Button>
      );
    },
  });

  const content = (
    <>
      <Tabs
        activeKey={tab_id}
        onSelect={(e) => setTabId(e)}
        className="m-0 p-0"
      >
        <Tab eventKey="table" title={t("table_view")}>
          <Spin spinning={loading}>
            <AntdTable
              entity="users"
              columns={COLUMNS}
              where={{ active: { _eq: true }, ...component_filters }}
              order_by={{ uid: "desc" }}
              query_fields={data_fields}
            // filters={filters}
            />
          </Spin>
        </Tab>
        <Tab eventKey="map" title={t("map_view")}>
          <MapView
            zoom={2}
            entity="users"
            where={{ active: { _eq: true }, ...component_filters }}
            order_by={{ uid: "desc" }}
            query_fields={map_fields}
            map_field="location"
          // filters={filters}
          />
        </Tab>
      </Tabs>
    </>
  );

  return (
    <>
      {component ? (
        content
      ) : (
          <Layout
            title={
              role === "grower"
                ? "Growers"
                : role === "buyer"
                  ? "Buyers"
                  : role === "provider"
                    ? "Providers"
                    : "Users"
            }
          >
            <div>{content}</div>
          </Layout>
        )}
    </>
  );
};
