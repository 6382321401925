import { useQuery } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import OtpInput from "react-otp-input";
import "react-phone-input-2/lib/bootstrap.css";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { APP_CONFIG } from "../../../../shared/app_config";
import { useDocTitle, useLocalStorage } from "../../../../shared/helpers";
import { useTranslatedLabel } from "../../../../shared/hooks";
import { webAuth } from "../../../admin/config";
import { PATHS } from "../../config";
import { SEARCH_COUNT } from "../../gql/queries";
import { sendOTP } from "../../services/auth";
import { AuthContext } from "../../store/auth";
// import { GeneralContext } from "../../store";

export const Signin = () => {
  useDocTitle("sign_in");
  // const { general_language, setGeneralLanguage, general_languages, general_languages_map } = useContext(GeneralContext);
  const { logoutUser } = useContext(AuthContext);

  useEffect(() => {
    logoutUser();
    // logout();
    localStorage.clear();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { addToast } = useToasts();
  const { t } = useTranslatedLabel();
  const [loading, setLoading] = useState(false);
  const [otpSent, setOTPSent] = useState(false);
  const [otp, setOTP] = useState("");
  const [email, setEmail] = useState("");

  const [, setLocalEmail] = useLocalStorage("email", email);

  const [uniqueEmail, setUniqueEmail] = useState(true);
  const {
    data: uniqueEmailData,
    loading: uniqueEmailLoading,
    error: uniqueEmailError,
  } = useQuery(SEARCH_COUNT("admins", "email"), {
    variables: { text: email },
    skip: !email || email.trim().length === 0
  });

  useEffect(() => {
    if (
      !uniqueEmailLoading &&
      !uniqueEmailError &&
      uniqueEmailData &&
      uniqueEmailData.counts &&
      uniqueEmailData.counts.aggregate &&
      uniqueEmailData.counts.aggregate.count > 0
    )
      setUniqueEmail(false);
    else setUniqueEmail(true);
  }, [uniqueEmailData, uniqueEmailLoading, uniqueEmailError]);

  const getOTP = async () => {
    if (uniqueEmail) {
      addToast(`${email} - ${t("not_registered")}`, { appearance: "error" });
    } else {
      setLoading(true);
      try {
        setLocalEmail(email);
        await sendOTP({ connection: "email", email });
        addToast(`OTP code sent to ${email}`, { appearance: "success" });
        setOTPSent(true);
        setLoading(false);
      } catch (err) {
        console.warn(err);
        setLoading(false);
      }
    }
  };
  const putOTP = () => {
    setLoading(true);
    try {
      let variables = {
        connection: "email",
        send: "code",
        verificationCode: otp,
      };
      variables.email = email;
      webAuth.passwordlessLogin(variables, (err, res) => {
        if (err) {
          console.warn(err);
          addToast(`${t("invalid_verfication_code")}`, { appearance: "error" });
          setOTP("");
        }
        setLoading(false);
      });
    } catch (err) {
      console.warn(err);
      addToast(`${t("invalid_verfication_code")}`, { appearance: "error" });
      setLoading(false);
      setOTP("");
    }
  };

  // useEffect(() => {
  // 	if (otp > 99999) putOTP();
  // 	// eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [otp]);

  return (
    <>
      {/* <div className="row col bg-secondary m-0 p-0">
				<div className="container">
					<Navbar className="m-0 p-0">
						<Nav className="mx-auto"></Nav>
						<Nav className="ml-2">
							<Nav.Item className="m-0 p-0 ml-2">
								<Link className="text-primary" to={"/"} style={{ fontSize: 12 }}>
									{t("home")}
								</Link>
							</Nav.Item>
						</Nav>
						<Nav className="ml-2">
							{general_languages_map[general_language]?.comment && (
								<Nav className="ml-2">
									<Nav.Item className="m-0 p-0 ml-2 pull-right">
										<small className="transaction-dropdown">
											<p className="m-0 p-0" style={{ fontSize: 12 }}>
												<i className="las la-language la-lg"></i>
												{"  "}
												{general_languages_map[general_language]?.comment || "English"}
											</p>
											<div className="transaction-dropdown-menu">
												<>
													{general_languages
														//.filter((l) => l.value !== general_language)
														.map((l, index) => (
															<Link
																to="#"
																className="dropdown-item"
																key={index}
																onClick={(e) => {
																	e.preventDefault();
																	setGeneralLanguage(l.value);
																}}
																style={{ fontSize: 12 }}
															>
																{l.comment}
															</Link>
														))}
												</>
											</div>
										</small>
									</Nav.Item>
								</Nav>
							)}
						</Nav>
					</Navbar>
				</div>
			</div> */}
      <div className="sign-in">
        <div className="container">
          <form
            className="row justify-content-center align-items-center"
            onSubmit={(e) => {
              e.preventDefault();
              if (!otpSent) {
                getOTP();
              } else {
                putOTP();
              }
            }}
          >
            <div className="col-xl-5 col-md-6">
              <div className="auth-logo text-center my-5">
                <Link to={PATHS.base}>
                  <img
                    src={APP_CONFIG.REACT_APP_LOGO_URL}
                    alt="Mahaseel logo"
                  />
                </Link>
              </div>
              <div className="sign-in-card card">
                <div className="card-body ">
                  {!otpSent && (
                    <div className=" form-group auth-form-group">
                      <input
                        name="email"
                        type="email"
                        className="form-control form-control-sm"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        // onBlur={(e) => uniqueEmailQuery({ variables: { text: e } })}
                      />
                    </div>
                  )}
                  {otpSent && email && (
                    <div className=" form-group auth-form-group">
                      <input
                        name="email"
                        type="email"
                        className="form-control form-control-sm"
                        placeholder="Email"
                        value={email}
                        disabled={true}
                      />
                    </div>
                  )}
                  {otpSent && (
                    <div className=" form-group auth-form-group">
                      <OtpInput
                        onChange={(otp) => setOTP(otp)}
                        value={otp}
                        numInputs={6}
                        isInputNum={true}
                        shouldAutoFocus={true}
                        separator={<span> - </span>}
                        inputStyle={{ fontSize: 16, width: "100%" }}
                      />
                    </div>
                  )}
                  <br />

                  <div className="text-center">
                    <div className="text-center mt-2">
                      <button
                        type="submit"
                        className="btn btn-block btn-outline-primary"
                        disabled={loading || !email}
                      >
                        {loading ? (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        ) : !otpSent ? (
                          <span>{t("proceed")}</span>
                        ) : (
                          <span>{t("signin")}</span>
                        )}
                      </button>
                    </div>
                  </div>
                  {/* ) : (
											<div className="text-center">
												<div className="text-center mt-2">
													<button
														type="button"
														className="btn btn-block btn-outline-primary"
														disabled={loading || !email}
													>
														{loading ? (
															<Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
														) : (
																<span>{t("signin")}</span>
															)}
													</button>
												</div>
											</div>
										)} */}

                  {otpSent && email && !loading && (
                    <div className="text-center mt-2">
                      <button
                        type="button"
                        className="btn btn-block btn-outline-primary"
                        onClick={getOTP}
                      >
                        {loading ? (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        ) : (
                          <span>{t("resend")}</span>
                        )}
                      </button>
                    </div>
                  )}

                  {otpSent && email && !loading && (
                    <div className="text-center mt-2">
                      <button
                        type="button"
                        className="btn btn-block btn-outline-primary"
                        onClick={() => {
                          setOTPSent(false);
                          setOTP("");
                        }}
                      >
                        <span>{t("change_email")}</span>
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
