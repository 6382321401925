import React from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { useTranslatedLabel } from "../../../../shared/hooks/useTranslatedLabel";
import { PATHS } from "../../config";

const Bottom = () => {
	const { t } = useTranslatedLabel();
	return (
		<>
			<div className="bottom py-5">
				<div className="container">
					<div className="row sm-px-3 md-px-5">
						<div className="col-xl-4 col-lg-4 col-12 sm-mb-5 md-mb-5">
							<div className="bottom-logo sm-text-center md-text-center">
								<img
									className="pb-3"
									src="https://res.cloudinary.com/ampcome/image/upload/v1593564863/mahaseel/mahaseel_trans_logo.png"
									alt="Mahaseel logo"
								/>
								<br />
								<span
									style={{
										fontSize: "11px",
										fontWeight: 300,
										marginLeft: "5px",
									}}
								>
									Powered by Mahaseel Labs
								</span>
							</div>
						</div>
						<div className="col-xl-2 col-lg-2 col-6">
							<div className="bottom-widget">
								<h4 className="widget-title font-weight-bold">{t("company")}</h4>
								<ul>
									<li>
										<Link to={PATHS.about}>{t("about")}</Link>
									</li>
									<li>
										<Link to={PATHS.careers}>{t("career")}</Link>
									</li>

									<li>
										<HashLink to={PATHS.partners}>{t("our_partners")}</HashLink>
									</li>
								</ul>
							</div>
						</div>
						<div className="col-xl-2 col-lg-2 col-6">
							<div className="bottom-widget">
								<h4 className="widget-title font-weight-bold">{t("support")}</h4>
								<ul>
									<li>
										<Link to={PATHS.faq}>{t("faq")}</Link>
									</li>
									<li>
										<Link to={PATHS.posts}>{t("blog")}</Link>
									</li>
									<li>
										<Link to={PATHS.helpdesk}>{t("help_desk")}</Link>
									</li>
								</ul>
							</div>
						</div>
						<div className="col-xl-2 col-lg-2 col-6">
							<div className="bottom-widget">
								<h4 className="widget-title font-weight-bold">{t("legal")}</h4>
								<ul>
									<li>
										<Link to={PATHS.disclaimer}>{t("disclaimer")}</Link>
									</li>
									<li>
										<Link to={PATHS.privacy}>{t("privacy_policy")}</Link>
									</li>
									<li>
										<Link to={PATHS.terms}>{t("terms_of_service")}</Link>
									</li>
								</ul>
							</div>
						</div>
						<div className="col-xl-2 col-lg-2 col-6">
							<div className="bottom-widget">
								<h4 className="widget-title font-weight-bold">{t("apps")}</h4>
								<ul className="appss-content my-2">
									<li>
										<Link to={"#"} className="btn btn-primary mb-2 mr-0 waves-effect">
											<img src={require("../../../../images/android.svg")} alt="" />
										</Link>
									</li>
									<li>
										<Link to={"#"} className="btn btn-primary mr-0 waves-effect">
											<img src={require("../../../../images/apple.svg")} alt="" />
										</Link>
									</li>
								</ul>
								<div className="footer-social">
									<ul className="sm-text-center md-text-center">
										<li>
											<Link to={"#"}>
												<i className="fa fa-facebook"></i>
											</Link>
										</li>
										<li>
											<Link to={"#"}>
												<i className="fa fa-twitter"></i>
											</Link>
										</li>
										<li>
											<Link to={"#"}>
												<i className="fa fa-linkedin"></i>
											</Link>
										</li>
										<li>
											<Link to={"#"}>
												<i className="fa fa-youtube"></i>
											</Link>
										</li>
										<li>
											<Link to={"#"}>
												<i className="fa fa-whatsapp"></i>
											</Link>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Bottom;
