import React from "react";
import { GoogleMap, Marker, withGoogleMap } from "react-google-maps";
import { Loader } from "./loader";

const MapView = withGoogleMap(({ data, zoom = 2 }) => {
	return (
		<GoogleMap defaultZoom={zoom} defaultCenter={data[0]}>
			{data.map((item) => (
				<Marker position={item} key={item.id} icon={item.icon || ""} />
			))}
		</GoogleMap>
	);
});

export const MapComponent = ({ data }) => (
	<MapView
		zoom={2}
		data={data}
		loadingElement={<Loader />}
		containerElement={<div style={{ height: "80vh" }} />}
		mapElement={<div style={{ height: "100%" }} />}
	/>
);
