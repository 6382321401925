import React from "react";
import { useParams } from "react-router-dom";
import ViewTradeOrder from "../../../client/pages/user/trade/orders/view-order";
import { Layout } from "../../components";

export const OrderDetails = () => {
	const { id } = useParams();
	return (
		<Layout title={`Trade Order Details`}>
			<div className="bg-light pb-5 order-details">
				<div className="container">
					<ViewTradeOrder component={true} inAdmin={true} order_id={id} />
				</div>
			</div>
		</Layout>
	);
};
