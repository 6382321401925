import React from "react";

export const Timeline = ({ data = [{ title: "", content: "" }] }) => {
	return (
		<ul className="timeline">
			{data.map((item, i) => (
				<li key={i}>
					<div className="float-right small-time">10:35 am</div>
					<h3>{item.title}</h3>
					<p>{item.content}</p>
				</li>
			))}
		</ul>
	);
};
