import React, { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { Redirect, useParams } from "react-router-dom";
import { useDocTitle } from "../../../../shared/helpers";
import { useTranslatedLabel } from "../../../../shared/hooks";
import { Layout } from "../../components";
import { Certifications } from "./certifications";
import { Labels } from "./labels";
import { ProductTypes } from "./product-types";
import { Products } from "./products";
import { ServiceTypes } from "./service-types";
import { Services } from "./services";

export const MasterData = (props) => {
  useDocTitle("master_data");
  const { t } = useTranslatedLabel();
  const [newPath, setNewPath] = useState("");
  const { tabId } = useParams();
  return (
    <Layout title={t("master_data")}>
      <div className="">
        {newPath && <Redirect push to={newPath} />}
        <Tabs
          className="m-0 p-0"
          activeKey={tabId}
          onSelect={(e) => setNewPath(`/master/${e}`)}
        >
          <Tab eventKey="products" title={t("product")}>
            <Products />
          </Tab>
          <Tab eventKey="product-types" title={t("product_variety")}>
            <ProductTypes />
          </Tab>
          <Tab eventKey="certifications" title={t("certifications")}>
            <Certifications />
          </Tab>
          <Tab eventKey="services" title={t("services")}>
            <Services />
          </Tab>
          <Tab eventKey="service-types" title={t("service_category")}>
            <ServiceTypes />
          </Tab>
          <Tab eventKey="labels" title={t("labels")}>
            <Labels />
          </Tab>
        </Tabs>
      </div>
    </Layout>
  );
};
