import { useSubscription } from "@apollo/client";
import { useEffect, useRef } from "react";
import { GET_TIME_SERIES } from "../gql";

export const useTimeSeriesQuery = ({ where = {}, order_by = {}, entity }) => {
	const objects = useRef([]);
	const { data, error, loading } = useSubscription(GET_TIME_SERIES(entity), {
		variables: { where, order_by }
	});
	useEffect(() => {
		if (!loading && !error && data && data.objects) objects.current = data.objects;
	}, [data, error, loading]);
	return { data: objects.current, loading };
};
