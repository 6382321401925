import { gql } from "@apollo/client";

export const SEARCH_COUNT_USERID = (entity, field = "name") =>
  gql`query ${entity}($text:String!, $user_id: String!) {counts: ${entity}_aggregate(where:{user_id:{_eq:$user_id}, ${field}:{_ilike:$text}}) { aggregate {count} } }`;

export const SEARCH_COUNT = (entity, field = "name") =>
  gql`query ${entity}($text:String!) {counts: ${entity}_aggregate(where:{${field}:{_ilike:$text}}) { aggregate {count} } }`;

export const GET_USER_BY_ID = gql`
  subscription GET_USER_BY_ID($id: String!) {
    object: users_by_pk(id: $id) {
      active
      id
      name
      email
      created_at
      photo_url
      currency
      language
      photo_url
      phone_number
      otp_verified
      email_verified
      buyer_access
      grower_access
      provider_access
      language
      location_id
      account_verified
      location {
        lat
        lng
        active
        id
        name
        address_line_1
        address_line_2
        address_line_3
        city
        color
        uid
        created_at
        updated_at
        state
        country
        postcode
        lat
        lng
        is_farm
        is_verified
        area
        crops
        certifications
        enum_country {
          id: value
          value
          comment
        }
        user_id
        user {
          active
          id
          email
          photo_url
        }
      }
      locations {
        active
        id
        name
        address_line_1
        address_line_2
        address_line_3
        city
        color
        uid
        created_at
        updated_at
        state
        country
        postcode
        lat
        lng
        is_farm
        is_verified
        area
        crops
        certifications
        enum_country {
          id: value
          value
          comment
        }
        user_id
        user {
          active
          id
          email
          photo_url
        }
      }
    }
  }
`;

export const GET_OTP_DETAILS = gql`
  query GetOTPDetails($token_id: uuid!) {
    otps(where: { id: { _eq: $token_id } }) {
      code
      phone_number
      issued_at
      expires_at
    }
  }
`;

export const GET_STARTUP_DATA = gql`
  query GetStartupData {
    translations(order_by: { map_id: asc, language: asc }) {
      id
      map_id
      language
      text
    }
    labels(order_by: { name: asc }) {
      id
      name
      desc
      icon
    }
    products(where: { active: { _eq: true } }, order_by: { name: asc }) {
      name
      active
      id
      pictures
    }
    services(where: { active: { _eq: true } }, order_by: { name: asc }) {
      name
      id
      pictures
    }
    product_types(where: { active: { _eq: true } }, order_by: { name: asc }) {
      name
      id
      product_id
      pictures
      product {
        pictures
        id
        name
      }
    }
    service_types(where: { active: { _eq: true } }, order_by: { name: asc }) {
      name
      id
      service_id
      pictures
      service {
        pictures
        id
        name
      }
    }
    certifications(where: { active: { _eq: true } }, order_by: { name: asc }) {
      name
      id
    }
    enum_countries(order_by: { value: asc }) {
      id: value
      value
      comment
    }
    enum_logistics(order_by: { value: asc }) {
      id: value
      value
      comment
    }
    enum_currencies(order_by: { value: asc }) {
      id: value
      value
      comment
    }
    enum_languages(order_by: { value: asc }) {
      id: value
      value
      comment
    }
    enum_order_status(order_by: { value: asc }) {
      id: value
      value
      comment
    }
    enum_product_units(order_by: { value: asc }) {
      id: value
      value
      comment
    }
    enum_request_status(order_by: { value: asc }) {
      id: value
      value
      comment
    }
    enum_request_types(order_by: { value: asc }) {
      id: value
      value
      comment
    }
    enum_user_roles(order_by: { value: asc }) {
      id: value
      value
      comment
    }
    settings(order_by: { label: asc }) {
      id
      label
      is_numeric
      text
      numeric
    }
  }
`;

export const GET_GENERAL_TRANSLATIONS = gql`
  subscription translations($language: enum_languages_enum!) {
    translations(where: { language: { _eq: $language } }) {
      id
      map_id
      text
    }
  }
`;
export const GET_GENERAL_LABELS = gql`
  subscription labels {
    labels(order_by: { name: asc }) {
      id
      name
      desc
      icon
    }
  }
`;
export const GET_GENERAL_PRODUCTS = gql`
  subscription products {
    products(where: { active: { _eq: true } }, order_by: { name: asc }) {
      name
      active
      id
      pictures
    }
  }
`;
export const GET_GENERAL_SERVICES = gql`
  subscription services {
    services(where: { active: { _eq: true } }, order_by: { name: asc }) {
      name
      id
      pictures
    }
  }
`;
export const GET_GENERAL_PRODUCT_TYPES = gql`
  subscription product_types {
    product_types(where: { active: { _eq: true } }, order_by: { name: asc }) {
      name
      id
      product_id
      pictures
      product {
        pictures
        id
        name
      }
    }
  }
`;
export const GET_GENERAL_SERVICE_TYPES = gql`
  subscription service_types {
    service_types(where: { active: { _eq: true } }, order_by: { name: asc }) {
      name
      id
      service_id
      pictures
      service {
        pictures
        id
        name
      }
    }
  }
`;
export const GET_GENERAL_CERTIFICATIONS = gql`
  subscription certifications {
    certifications(where: { active: { _eq: true } }, order_by: { name: asc }) {
      name
      id
    }
  }
`;
export const GET_GENERAL_COUNTRIES = gql`
  subscription enum_countries {
    enum_countries(order_by: { value: asc }) {
      id: value
      value
      comment
    }
  }
`;
export const GET_GENERAL_LOGISTICS = gql`
  subscription enum_logistics {
    enum_logistics(order_by: { value: asc }) {
      id: value
      value
      comment
    }
  }
`;
export const GET_GENERAL_CURRENCIES = gql`
  subscription enum_currencies {
    enum_currencies(order_by: { value: asc }) {
      id: value
      value
      comment
    }
  }
`;
export const GET_GENERAL_LANGUAGES = gql`
  subscription enum_languages {
    enum_languages(order_by: { value: asc }) {
      id: value
      value
      comment
    }
  }
`;
export const GET_GENERAL_ORDER_STATUS = gql`
  subscription enum_order_status {
    enum_order_status(order_by: { value: asc }) {
      id: value
      value
      comment
    }
  }
`;
export const GET_GENERAL_PRODUCT_UNTIS = gql`
  subscription enum_product_units {
    enum_product_units(order_by: { value: asc }) {
      id: value
      value
      comment
    }
  }
`;
export const GET_GENERAL_REQUEST_STATUS = gql`
  subscription enum_request_status {
    enum_request_status(order_by: { value: asc }) {
      id: value
      value
      comment
    }
  }
`;
export const GET_GENERAL_REQUEST_TYPES = gql`
  subscription enum_request_types {
    enum_request_types(order_by: { value: asc }) {
      id: value
      value
      comment
    }
  }
`;
export const GET_GENERAL_USER_ROLES = gql`
  subscription enum_user_roles {
    enum_user_roles(order_by: { value: asc }) {
      id: value
      value
      comment
    }
  }
`;
export const GET_GENERAL_SETTINGS = gql`
  subscription settings {
    settings(order_by: { label: asc }) {
      id
      label
      is_numeric
      text
      numeric
    }
  }
`;
export const GET_STARTUP_USER_DATA = gql`
  query GetStartupUserData($user_id: String!) {
    locations(where: { active: { _eq: true }, user_id: { _eq: $user_id } }) {
      active
      id
      name
      address_line_1
      address_line_2
      address_line_3
      city
      lat
      lng
      color
      uid
      created_at
      updated_at
      state
      country
      postcode
      lat
      lng
      is_farm
      is_verified
      area
      crops
      certifications
      enum_country {
        id: value
        value
        comment
      }
      user_id
      user_id
      user {
        active
        id
        email
        photo_url
      }
    }
  }
`;
export const Q_DUMMY = gql`
  query DUMMY(
    $where: locations_bool_exp
    $filter: locations_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [locations_order_by!]
    $distinct_on: [locations_select_column!]
  ) {
    objects: locations(
      where: $where
      limit: $limit
      offset: $offset
      order_by: $order_by
      distinct_on: $distinct_on
    ) {
      active
      id
    }
    totals: locations_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;
export const Q_REQUESTS = gql`
  query GetOffers(
    $where: requests_bool_exp
    $filter: requests_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [requests_order_by!]
    $distinct_on: [requests_select_column!]
  ) {
    objects: requests(
      where: $where
      limit: $limit
      offset: $offset
      order_by: $order_by
      distinct_on: $distinct_on
    ) {
      active
      id
      uid
      created_at
      updated_at
      type
      days_to_maturity
      views: views_aggregate {
        aggregate {
          sum {
            searches
            views
          }
        }
      }
      user_id
      user_id
      user {
        name
        active
        id
        photo_url
      }
      location_id
      location {
        lat
        lng
        active
        id
        name
        address_line_1
        address_line_2
        address_line_3
        city
        color
        uid
        created_at
        updated_at
        state
        country
        postcode
        lat
        lng
        is_farm
        is_verified
        area
        crops
        certifications
        enum_country {
          id: value
          value
          comment
        }
        user_id
        user {
          active
          id
          email
          photo_url
        }
      }
      product_type {
        active
        id
        name
        product_id
        pictures
        product {
          pictures
          active
          id
          name
        }
      }
      status
      enum_request_status {
        id: value
        value
        comment
      }
      type
      days_to_maturity
      views: views_aggregate {
        aggregate {
          sum {
            searches
            views
          }
        }
      }
      enum_request_type {
        id: value
        value
        comment
      }
      unit
      unit
      enum_product_unit {
        id: value
        value
        comment
      }
      price_per_unit
      uid
      created_at
      updated_at
      number_of_units
      total_amount
      advance_payment_percentage
      advance_amount
      from_date
      to_date
      logistics
      pictures
      request_id
      offer_id
      offer {
        active
        id
        uid
        created_at
        updated_at
      }
      offer_user_id
      offer_user {
        active
        id
        name
      }
      request {
        uid
        created_at
        updated_at
        active
        id
        user_id
        user {
          active
          id
          name
          photo_url
        }
      }
    }
    product_types: requests(where: $filter, distinct_on: product_type_id) {
      id: product_type_id
      product_type {
        active
        id
        name
        product_id
        pictures
        product {
          pictures
          active
          id
          name
        }
      }
    }
    countries: requests(where: $filter, distinct_on: location_id) {
      id: location_id
      location {
        lat
        lng
        id
        uid
        created_at
        updated_at
        color
        active
        id
        country
        enum_country {
          id: value
          value
          comment
        }
      }
    }
    users: requests(where: $filter, distinct_on: user_id) {
      id: user_id
      user_id
      user_id
      user {
        name
        active
        id
        photo_url
      }
    }
    totals: requests_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;
export const Q_PROVIDER_SERVICES = gql`
  query GetProviderOffers(
    $where: provider_services_bool_exp
    $filter: provider_services_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [provider_services_order_by!]
    $distinct_on: [provider_services_select_column!]
  ) {
    objects: provider_services(
      where: $where
      limit: $limit
      offset: $offset
      order_by: $order_by
      distinct_on: $distinct_on
    ) {
      active
      id
      uid
      status
      enum_request_status {
        id: value
        value
        comment
      }
      user_id
      user_id
      user {
        name
        active
        id
        photo_url
      }
      location {
        lat
        lng
        active
        id
        name
        address_line_1
        address_line_2
        address_line_3
        city
        color
        uid
        created_at
        updated_at
        state
        country
        postcode
        lat
        lng
        is_farm
        is_verified
        area
        crops
        certifications
        enum_country {
          id: value
          value
          comment
        }
        user_id
        user {
          active
          id
          email
          photo_url
        }
      }
      service_type {
        pictures
        pictures
        active
        id
        name
        service_id
        pictures
        service {
          pictures
          active
          id
          name
        }
      }
      price_per_unit
      range_in_kms
      pictures
    }
    service_types: provider_services(
      where: $filter
      distinct_on: service_type_id
    ) {
      id: service_type_id
      service_type {
        pictures
        pictures
        active
        id
        name
        service_id
        pictures
        service {
          pictures
          active
          id
          name
        }
      }
    }
    countries: provider_services(where: $filter, distinct_on: location_id) {
      id: location_id
      location {
        lat
        lng
        active
        id
        name
        address_line_1
        address_line_2
        address_line_3
        city
        color
        uid
        created_at
        updated_at
        state
        country
        postcode
        lat
        lng
        is_farm
        is_verified
        area
        crops
        certifications
        enum_country {
          id: value
          value
          comment
        }
        user_id
        user {
          active
          id
          email
          photo_url
        }
      }
    }
    totals: provider_services_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_OFFERS = gql`
  query GetOffers {
    requests(
      where: {
        _or: [
          { type: { _eq: buy_request }, active: { _eq: true } }
          { type: { _eq: sell_offer }, active: { _eq: true } }
        ]
      }
    ) {
      active
      id
      type
      days_to_maturity
      views: views_aggregate {
        aggregate {
          sum {
            searches
            views
          }
        }
      }
      user_id
      user_id
      user {
        name
        active
        id
        photo_url
      }
      location {
        lat
        lng
        active
        id
        name
        address_line_1
        address_line_2
        address_line_3
        city
        color
        uid
        created_at
        updated_at
        state
        country
        postcode
        lat
        lng
        is_farm
        is_verified
        area
        crops
        certifications
        enum_country {
          id: value
          value
          comment
        }
        user_id
        user {
          active
          id
          email
          photo_url
        }
      }
      product_type {
        active
        id
        name
        product_id
        pictures
        product {
          pictures
          active
          id
          name
        }
      }
      unit
      enum_product_unit {
        id: value
        value
        comment
      }
      price_per_unit
      uid
      created_at
      updated_at
      number_of_units
      from_date
      to_date
      pictures
      total_amount
      advance_amount
      request_id
      offer_id
      offer {
        active
        id
        uid
        created_at
        updated_at
      }
      offer_user_id
      offer_user {
        active
        id
        name
      }
      enum_request_status {
        id: value
        value
        comment
      }
      request {
        uid
        created_at
        updated_at
        active
        id
        user_id
        user {
          active
          id
          name
          photo_url
        }
      }
    }
  }
`;

export const GET_OFFERS_BY_CATEGORY = gql`
  query GetOffersByCategory($product_id: uuid!) {
    requests(
      where: {
        active: { _eq: true }
        product_id: { _eq: $product_id }
        _or: [{ type: { _eq: buy_request } }, { type: { _eq: sell_offer } }]
      }
    ) {
      active
      id
      type
      days_to_maturity
      views: views_aggregate {
        aggregate {
          sum {
            searches
            views
          }
        }
      }
      user_id
      user_id
      user {
        name
        active
        id
        photo_url
      }
      location {
        lat
        lng
        active
        id
        name
        address_line_1
        address_line_2
        address_line_3
        city
        color
        uid
        created_at
        updated_at
        state
        country
        postcode
        lat
        lng
        is_farm
        is_verified
        area
        crops
        certifications
        enum_country {
          id: value
          value
          comment
        }
        user_id
        user {
          active
          id
          email
          photo_url
        }
      }
      product_type {
        active
        id
        name
        product_id
        pictures
        product {
          pictures
          active
          id
          name
        }
      }
      unit
      enum_product_unit {
        id: value
        value
        comment
      }
      price_per_unit
      uid
      created_at
      updated_at
      number_of_units
      from_date
      to_date
      pictures
      request_id
      offer_id
      offer {
        active
        id
        uid
        created_at
        updated_at
      }
      offer_user_id
      offer_user {
        active
        id
        name
      }
      enum_request_status {
        id: value
        value
        comment
      }
      request {
        uid
        created_at
        updated_at
        active
        id
        user_id
        user {
          active
          id
          name
          photo_url
        }
      }
    }
  }
`;
export const GET_BIDDERS = gql`
  subscription GET_BIDDERS($user_id: String!, $offer_id: uuid) {
    objects: requests(
      where: {
        request_id: { _eq: $offer_id }
        request: { user_id: { _eq: $user_id } }
        user_id: { _neq: $user_id }
      }
      distinct_on: user_id
    ) {
      id
      uid
      created_at
      updated_at
      active
      updated_at
      user_id
      user_id
      user {
        id
        email
        photo_url
        name
        photo_url
      }
    }
  }
`;

export const GET_PROVIDER_MATCHES = gql`
  subscription GET_PROVIDER_MATCHES($offer_id: uuid!, $user_id: String) {
    objects: provider_matches(
      where: { id: { _eq: $offer_id }, user_id: { _eq: $user_id } }
    ) {
      id
      range_in_kms
      distance
      user: matching_user {
        name
        active
        id
        photo_url
      }
      location: matching_location {
        lat
        lng
        active
        id
        name
        address_line_1
        address_line_2
        address_line_3
        city
        color
        uid
        created_at
        updated_at
        state
        country
        postcode
        lat
        lng
        is_farm
        is_verified
        area
        crops
        certifications
        enum_country {
          id: value
          value
          comment
        }
        user_id
        user {
          active
          id
          email
        }
      }
    }
  }
`;
export const GET_TRADE_OFFER_BY_ID = gql`
  subscription GET_TRADE_OFFER_BY_ID($id: uuid!) {
    object: requests_by_pk(id: $id) {
      active
      id
      uid
      created_at
      updated_at
      type
      days_to_maturity
      user_id
      user_id
      user {
        name
        active
        id
        photo_url
      }
      location_id
      location {
        lat
        lng
        active
        id
        name
        address_line_1
        address_line_2
        address_line_3
        city
        color
        uid
        created_at
        updated_at
        state
        country
        postcode
        lat
        lng
        is_farm
        is_verified
        area
        crops
        certifications
        enum_country {
          id: value
          value
          comment
        }
        user_id
        user {
          active
          id
          email
          photo_url
        }
      }
      product_type {
        active
        id
        name
        product_id
        pictures
        product {
          pictures
          active
          id
          name
        }
      }
      status
      enum_request_status {
        id: value
        value
        comment
      }
      type
      days_to_maturity
      enum_request_type {
        id: value
        value
        comment
      }
      unit
      enum_product_unit {
        id: value
        value
        comment
      }
      price_per_unit
      uid
      created_at
      updated_at
      number_of_units
      total_amount
      advance_payment_percentage
      advance_amount
      from_date
      to_date
      logistics
      pictures
      request_id
      offer_id
      offer {
        active
        id
        uid
        created_at
        updated_at
      }
      offer_user_id
      offer_user {
        active
        id
        name
      }
      request {
        uid
        created_at
        updated_at
        active
        id
        user_id
        user {
          active
          id
          name
          photo_url
        }
      }
      matching_offers(order_by: { uid: desc }) {
        active
        id
        updated_at
        timestamp
        product_type {
          active
          id
          name
          product_id
          pictures
          product {
            pictures
            active
            id
            name
          }
        }
        type
        days_to_maturity
        views {
          searches
          id: timestamp
          user_id
          views
        }
        enum_request_type {
          comment
          id: value
          value
        }
        status
        enum_request_status {
          comment
          id: value
          value
        }
        advance_amount
        total_amount
        price_per_unit
        uid
        created_at
        updated_at
        number_of_units
        unit
        enum_product_unit {
          comment
          id: value
          value
        }
        total_amount
        from_date
        to_date
        location {
          lat
          lng
          active
          id
          name
          address_line_1
          address_line_2
          address_line_3
          city
          color
          uid
          created_at
          updated_at
          state
          country
          postcode
          lat
          lng
          is_farm
          is_verified
          area
          crops
          certifications
          enum_country {
            id: value
            value
            comment
          }
          user_id
          user_id
          user {
            active
            id
            email
            photo_url
          }
        }
        logistics
        advance_payment_percentage
        active
        id
        uid
        created_at
        updated_at
        notes {
          created_at
          updated_at
          active
          id
          text
        }
        user_id
        user {
          active
          id
          email
          photo_url
          name
          photo_url
        }
        request_id
        offer_id
        offer {
          active
          id
          uid
          created_at
          updated_at
        }
        offer_user_id
        offer_user {
          active
          id
          name
        }
        request {
          uid
          created_at
          updated_at
          active
          id
          user_id
          user_id
          user {
            active
            id
            name
            photo_url
          }
        }
      }
      similar_offers_by_type_product_type(limit: 3, offset: 0) {
        uid
        created_at
        updated_at
        active
        id
        user_id
        user {
          name
          active
          id
          photo_url
        }
        location {
          lat
          lng
          active
          id
          name
          address_line_1
          address_line_2
          address_line_3
          city
          color
          uid
          created_at
          updated_at
          state
          country
          postcode
          lat
          lng
          is_farm
          is_verified
          area
          crops
          certifications
          enum_country {
            id: value
            value
            comment
          }
          user_id
          user_id
          user {
            active
            id
            email
            photo_url
          }
        }
        product_type {
          active
          id
          name
          product_id
          pictures
          product {
            pictures
            active
            id
            name
          }
        }
        enum_request_status {
          id: value
          value
          comment
        }
        unit
        enum_product_unit {
          id: value
          value
          comment
        }
        price_per_unit
        uid
        created_at
        updated_at
        number_of_units
        from_date
        to_date
        pictures
        request_id
        offer_id
        offer {
          active
          id
          uid
          created_at
          updated_at
        }
        offer_user_id
        offer_user {
          active
          id
          name
        }
        request {
          uid
          created_at
          updated_at
          active
          id
          user_id
          user {
            active
            id
            name
            photo_url
          }
        }
      }
      similar_offers_by_type_user(limit: 3, offset: 0) {
        uid
        created_at
        updated_at
        active
        id
        user_id
        user {
          name
          active
          id
          photo_url
        }
        location {
          lat
          lng
          active
          id
          name
          address_line_1
          address_line_2
          address_line_3
          city
          color
          uid
          created_at
          updated_at
          state
          country
          postcode
          lat
          lng
          is_farm
          is_verified
          area
          crops
          certifications
          enum_country {
            id: value
            value
            comment
          }
          user_id
          user {
            active
            id
            email
            photo_url
          }
        }
        product_type {
          active
          id
          name
          product_id
          pictures
          product {
            pictures
            active
            id
            name
          }
        }
        enum_request_status {
          id: value
          value
          comment
        }
        unit
        enum_product_unit {
          id: value
          value
          comment
        }
        price_per_unit
        uid
        created_at
        updated_at
        number_of_units
        from_date
        to_date
        pictures
        request_id
        offer_id
        offer {
          active
          id
          uid
          created_at
          updated_at
        }
        offer_user_id
        offer_user {
          active
          id
          name
        }
        request {
          uid
          created_at
          updated_at
          active
          id
          user_id
          user {
            active
            id
            name
            photo_url
          }
        }
      }
    }
  }
`;

export const GET_TRADE_ORDER_BY_ID = gql`
  subscription GET_TRADE_ORDER_BY_ID($id: uuid!) {
    object: trade_orders_by_pk(id: $id) {
      id
      uid
      created_at
      updated_at
      open_at
      completed_at
      cancelled_at
      advancepaid_at
      delivered_at
      fullypaid_at
      readyfordelivery_at
      received_at
      product_type_id
      product_type {
        id
        uid
        name
        product_id
        pictures
        product {
          id
          uid
          pictures
          name
        }
      }
      enum_order_status {
        id: value
        comment
        value
      }
      price_per_unit
      number_of_units
      total_amount
      from_date
      active
      to_date
      advance_amount
      advance_payment_percentage
      status
      from_location {
        lat
        lng
        active
        id
        name
        address_line_1
        address_line_2
        address_line_3
        city
        color
        uid
        created_at
        updated_at
        state
        country
        postcode
        lat
        lng
        is_farm
        is_verified
        area
        crops
        certifications
        enum_country {
          id: value
          value
          comment
        }
        user_id
        user {
          active
          id
          email
          photo_url
        }
      }
      to_location {
        lat
        lng
        active
        id
        name
        address_line_1
        address_line_2
        address_line_3
        city
        color
        uid
        created_at
        updated_at
        state
        country
        postcode
        lat
        lng
        is_farm
        is_verified
        area
        crops
        certifications
        enum_country {
          id: value
          value
          comment
        }
        user_id
        user {
          active
          id
          email
          photo_url
        }
      }
      logistics_info
      logistics(order_by: { created_at: desc }) {
        id
        uid
        active
        created_at
        updated_at
        contact_name
        contact_phone
        delivery_date
        pickup_date
        tracking_id
        status
        user_id
        user {
          id
          name
        }
      }
      logistics_aggregate {
        aggregate {
          count
        }
      }
      notes {
        created_at
        updated_at
        id
        text
        created_at
        user_id
        user {
          email
          photo_url
          name
          id
        }
      }
      seller {
        email
        photo_url
        name
        id
        phone_number
      }
      buyer {
        email
        photo_url
        name
        id
        phone_number
      }
      payments(order_by: { created_at: desc }) {
        created_at
        updated_at
        advance
        amount
        status
        enum_order_status {
          id: value
          comment
          value
        }
        updated_at
        timestamp
        id
        uid
        created_at
        stripe_session_id
      }
      unit
      enum_product_unit {
        id: value
        value
        comment
      }
      offer: request {
        active
        id
        updated_at
        timestamp
        pictures
        product_type {
          active
          id
          name
          product_id
          pictures
          product {
            pictures
            active
            id
            name
          }
        }
        type
        days_to_maturity
        views: views_aggregate {
          aggregate {
            sum {
              searches
              views
            }
          }
        }
        enum_request_type {
          comment
          id: value
          value
        }
        status
        enum_request_status {
          comment
          id: value
          value
        }
        advance_amount
        total_amount
        price_per_unit
        uid
        created_at
        updated_at
        number_of_units
        unit
        enum_product_unit {
          comment
          id: value
          value
        }
        total_amount
        from_date
        to_date
        location {
          lat
          lng
          active
          id
          name
          address_line_1
          address_line_2
          address_line_3
          city
          color
          uid
          created_at
          updated_at
          state
          country
          postcode
          lat
          lng
          is_farm
          is_verified
          area
          crops
          certifications
          enum_country {
            id: value
            value
            comment
          }
          user_id
          user {
            active
            id
            email
            photo_url
          }
        }
        logistics
        advance_payment_percentage
        active
        id
        uid
        created_at
        updated_at
        notes {
          created_at
          updated_at
          active
          id
          text
        }
        user_id
        user {
          active
          id
          email
          photo_url
          name
          photo_url
        }
        request_id
        request {
          active
          id
          uid
          created_at
          updated_at
        }
        offer_id
        offer {
          active
          id
          uid
          created_at
          updated_at
        }
        offer_user_id
        offer_user {
          active
          id
          name
        }
      }
    }
  }
`;

export const GET_SERVICE_ORDER_BY_ID = gql`
  subscription GET_SERVICE_ORDER_BY_ID($id: uuid!) {
    object: service_orders_by_pk(id: $id) {
      id
      uid
      created_at
      updated_at
      open_at
      inprogress_at
      completed_at
      cancelled_at
      fullypaid_at
      serviced_at
      days_to_maturity
      total_amount
      price_per_unit
      number_of_units
      service_type_id
      service_type {
        pictures
        pictures
        id
        uid
        name
        service_id
        pictures
        service {
          id
          uid
          pictures
          name
        }
      }
      offer: provider_service {
        active
        id
        uid
        status
        enum_request_status {
          id: value
          value
          comment
        }
        user_id
        user {
          name
          active
          id
          photo_url
        }
        location_id
        location {
          lat
          lng
          active
          id
          name
          address_line_1
          address_line_2
          address_line_3
          city
          color
          uid
          created_at
          updated_at
          state
          country
          postcode
          lat
          lng
          is_farm
          is_verified
          area
          crops
          certifications
          enum_country {
            id: value
            value
            comment
          }
          user_id
          user {
            active
            id
            email
            photo_url
          }
        }
        service_type {
          pictures
          active
          id
          name
          service_id
          service {
            pictures
            active
            id
            name
          }
        }
        range_in_kms
        price_per_unit
        pictures
      }
      enum_order_status {
        id: value
        comment
        value
      }
      active
      date
      status
      from_location {
        lat
        lng
        active
        id
        name
        address_line_1
        address_line_2
        address_line_3
        city
        color
        uid
        created_at
        updated_at
        state
        country
        postcode
        lat
        lng
        is_farm
        is_verified
        area
        crops
        certifications
        enum_country {
          id: value
          value
          comment
        }
        user_id
        user {
          active
          id
          email
          photo_url
        }
      }
      to_location {
        lat
        lng
        active
        id
        name
        address_line_1
        address_line_2
        address_line_3
        city
        color
        uid
        created_at
        updated_at
        state
        country
        postcode
        lat
        lng
        is_farm
        is_verified
        area
        crops
        certifications
        enum_country {
          id: value
          value
          comment
        }
        user_id
        user {
          active
          id
          email
          photo_url
        }
      }
      notes {
        created_at
        updated_at
        id
        text
        user_id
        user {
          email
          photo_url
          name
          id
        }
      }
      seller {
        email
        photo_url
        name
        id
        phone_number
      }
      buyer {
        email
        photo_url
        name
        id
        phone_number
      }
      payments(order_by: { created_at: desc }) {
        created_at
        updated_at
        advance
        amount
        status
        enum_order_status {
          id: value
          comment
          value
        }
        updated_at
        timestamp
        id
        uid
        created_at
        stripe_session_id
      }
    }
  }
`;

export const GET_FARM_BY_ID = gql`
  subscription GET_FARM_BY_ID($id: uuid!) {
    farm: locations_by_pk(id: $id) {
      active
      id
      name
      address_line_1
      address_line_2
      address_line_3
      city
      color
      uid
      created_at
      updated_at
      state
      country
      postcode
      lat
      lng
      is_farm
      is_verified
      area
      crops
      certifications
      enum_country {
        id: value
        value
        comment
      }
    }
  }
`;

export const GET_LOCATION_BY_ID = gql`
  subscription GET_LOCATION_BY_ID($id: uuid!) {
    object: locations_by_pk(id: $id) {
      active
      id
      name
      address_line_1
      address_line_2
      address_line_3
      city
      color
      uid
      created_at
      updated_at
      state
      country
      postcode
      lat
      lng
      is_farm
      is_verified
      area
      crops
      certifications
      enum_country {
        id: value
        value
        comment
      }
    }
  }
`;

export const GET_SERVICE_OFFER_BY_ID = gql`
  subscription GET_SERVICE_OFFER_BY_ID($id: uuid!) {
    object: provider_services_by_pk(id: $id) {
      active
      id
      uid
      status
      enum_request_status {
        id: value
        value
        comment
      }
      user_id
      user {
        name
        active
        id
        photo_url
      }
      location_id
      location {
        lat
        lng
        active
        id
        name
        address_line_1
        address_line_2
        address_line_3
        city
        color
        uid
        created_at
        updated_at
        state
        country
        postcode
        lat
        lng
        is_farm
        is_verified
        area
        crops
        certifications
        enum_country {
          id: value
          value
          comment
        }
        user_id
        user {
          active
          id
          email
          photo_url
        }
      }
      service_type {
        pictures
        active
        id
        name
        service_id
        service {
          pictures
          active
          id
          name
        }
      }
      range_in_kms
      price_per_unit
      pictures
      similar_services_by_service(limit: 3, offset: 0) {
        uid
        created_at
        updated_at
        active
        id
        user_id
        user {
          name
          active
          id
          photo_url
        }
        location_id
        location {
          lat
          lng
          active
          id
          name
          address_line_1
          address_line_2
          address_line_3
          city
          color
          uid
          created_at
          updated_at
          state
          country
          postcode
          lat
          lng
          is_farm
          is_verified
          area
          crops
          certifications
          enum_country {
            id: value
            value
            comment
          }
          user_id
          user {
            active
            id
            email
            photo_url
          }
        }
        service_type {
          pictures
          active
          id
          name
          service_id
          service {
            pictures
            active
            id
            name
          }
        }
        range_in_kms
        price_per_unit
        pictures
      }
      similar_services_by_user(limit: 3, offset: 0) {
        uid
        created_at
        updated_at
        active
        id
        user_id
        user {
          name
          active
          id
          photo_url
        }
        location_id
        location {
          lat
          lng
          active
          id
          name
          address_line_1
          address_line_2
          address_line_3
          city
          color
          uid
          created_at
          updated_at
          state
          country
          postcode
          lat
          lng
          is_farm
          is_verified
          area
          crops
          certifications
          enum_country {
            id: value
            value
            comment
          }
          user_id
          user {
            active
            id
            email
            photo_url
          }
        }
        service_type {
          pictures
          active
          id
          name
          service_id
          service {
            pictures
            active
            id
            name
          }
        }
        range_in_kms
        price_per_unit
        pictures
      }
    }
  }
`;

export const GET_LOCATIONS_BY_USER_ID = gql`
  query GetLocationsByUserId($user_id: String!) {
    locations(where: { user_id: { _eq: $user_id } }) {
      active
      id
      name
      address_line_1
      address_line_2
      address_line_3
      city
      color
      uid
      lat
      lng
      created_at
      updated_at
      state
      country
      postcode
      lat
      lng
      is_farm
      is_verified
      area
      crops
      certifications
      enum_country {
        id: value
        value
        comment
      }
      user_id
      user {
        active
        id
        email
        photo_url
      }
    }
  }
`;

export const GET_CERTIFICATIONS = gql`
  query get_certifications {
    certifications(order_by: { uid: asc }) {
      active
      name
      active
      id
      uid
      created_at
      updated_at
    }
  }
`;

export const GET_PRODUCTS = gql`
  query get_products {
    products(where: { active: { _eq: true } }, order_by: { name: asc }) {
      active
      pictures
      uid
      created_at
      updated_at
      name
      active
      id
      product_types_aggregate(
        where: { active: { _eq: true } }
        order_by: { name: asc }
      ) {
        aggregate {
          count
        }
      }
    }
  }
`;

export const GET_PRODUCT_TYPES = gql`
  query get_product_types($product_id: uuid!) {
    product_types(
      where: { product_id: { _eq: $product_id } }
      order_by: { name: asc }
    ) {
      active
      active
      id
      name
      uid
      created_at
      updated_at
      product_id
      pictures
    }
  }
`;

export const GET_SERVICE_CATEGORIES = gql`
  query get_services {
    services(where: { active: { _eq: true } }, order_by: { name: asc }) {
      active
      active
      id
      name
      uid
      created_at
      updated_at
      pictures
      service_types_aggregate(
        where: { active: { _eq: true } }
        order_by: { name: asc }
      ) {
        aggregate {
          count
        }
      }
    }
  }
`;

export const GET_SERVICE_SUBCATEGORIES = gql`
  query get_service_types($service_id: uuid!) {
    service_types(
      where: { service_id: { _eq: $service_id } }
      order_by: { name: asc }
    ) {
      active
      active
      id
      name
      uid
      created_at
      updated_at
      service_id
    }
  }
`;

export const GET_USERS = gql`
  query get_users {
    users {
      name
      active
      id
      email
      photo_url
      phone_number
      active
      locations {
        active
        id
        name
        address_line_1
        address_line_2
        address_line_3
        city
        color
        uid
        created_at
        updated_at
        state
        country
        postcode
        lat
        lng
        is_farm
        is_verified
        area
        crops
        certifications
        enum_country {
          id: value
          value
          comment
        }
        user_id
        user {
          active
          id
          email
          photo_url
        }
      }
    }
  }
`;
export const GET_OFFER_USERS = gql`
  query GET_OFFER_USERS($where: users_bool_exp, $order_by: [users_order_by!]) {
    objects: users(where: $where, order_by: $order_by) {
      id
      name
      email
      photo_url
      phone_number
    }
  }
`;
export const GET_FARMS = gql`
  query get_farms {
    locations(where: { is_farm: { _eq: true } }) {
      active
      id
      name
      address_line_1
      address_line_2
      address_line_3
      city
      color
      uid
      created_at
      updated_at
      state
      country
      postcode
      lat
      lng
      is_farm
      is_verified
      area
      crops
      certifications
      enum_country {
        id: value
        value
        comment
      }
      user_id
      user {
        active
        id
        email
        photo_url
      }
    }
  }
`;

export const GET_LOCATIONS = gql`
  query GET_LOCATIONS(
    $where: locations_bool_exp
    $order_by: [locations_order_by!]
  ) {
    objects: locations(where: $where, order_by: $order_by) {
      active
      id
      name
      address_line_1
      address_line_2
      address_line_3
      city
      color
      uid
      created_at
      updated_at
      state
      country
      postcode
      lat
      lng
      is_farm
      is_verified
      area
      crops
      certifications
      enum_country {
        id: value
        value
        comment
      }
      user_id
      user {
        active
        id
        email
        photo_url
      }
    }
  }
`;

export const GET_ALL_OFFERS = gql`
  query GET_ALL_OFFERS(
    $where: requests_bool_exp
    $order_by: [requests_order_by!]
  ) {
    objects: requests(where: $where, order_by: $order_by) {
      active
      id
      updated_at
      timestamp
      pictures
      product_type {
        active
        id
        name
        product_id
        pictures
        product {
          pictures
          active
          id
          name
        }
      }
      type
      days_to_maturity
      views: views_aggregate {
        aggregate {
          sum {
            searches
            views
          }
        }
      }
      enum_request_type {
        comment
        id: value
        value
      }
      status
      enum_request_status {
        comment
        id: value
        value
      }
      advance_amount
      total_amount
      price_per_unit
      uid
      created_at
      updated_at
      number_of_units
      unit
      enum_product_unit {
        comment
        id: value
        value
      }
      total_amount
      from_date
      to_date
      location {
        lat
        lng
        active
        id
        name
        address_line_1
        address_line_2
        address_line_3
        city
        color
        uid
        created_at
        updated_at
        state
        country
        postcode
        lat
        lng
        is_farm
        is_verified
        area
        crops
        certifications
        enum_country {
          id: value
          value
          comment
        }
        user_id
        user {
          active
          id
          email
          photo_url
        }
      }
      logistics
      advance_payment_percentage
      active
      id
      uid
      created_at
      updated_at
      notes {
        created_at
        updated_at
        active
        id
        text
      }
      user_id
      user {
        active
        id
        email
        photo_url
        name
        photo_url
      }
      request_id
      offer_id
      offer {
        active
        id
        uid
        created_at
        updated_at
      }
      offer_user_id
      offer_user {
        active
        id
        name
      }
      trade_orders_aggregate {
        aggregate {
          count
        }
      }
      request {
        uid
        created_at
        updated_at
        active
        id
        user_id
        user {
          active
          id
          name
          photo_url
        }
      }
    }
  }
`;

export const GET_MATCHING_OFFERS = gql`
  query GET_MATCHING_OFFERS(
    $where: requests_bool_exp
    $order_by: [requests_order_by!]
  ) {
    objects: requests(where: $where, order_by: $order_by) {
      active
      id
      updated_at
      timestamp
      product_type {
        active
        id
        name
        product_id
        pictures
        product {
          pictures
          active
          id
          name
        }
      }
      type
      days_to_maturity
      views: views_aggregate {
        aggregate {
          sum {
            searches
            views
          }
        }
      }
      enum_request_type {
        comment
        id: value
        value
      }
      status
      enum_request_status {
        comment
        id: value
        value
      }
      advance_amount
      total_amount
      price_per_unit
      uid
      created_at
      updated_at
      number_of_units
      unit
      enum_product_unit {
        comment
        id: value
        value
      }
      from_date
      to_date
      location {
        lat
        lng
        active
        id
        name
        address_line_1
        address_line_2
        address_line_3
        city
        color
        uid
        created_at
        updated_at
        state
        country
        postcode
        lat
        lng
        is_farm
        is_verified
        area
        crops
        certifications
        enum_country {
          id: value
          value
          comment
        }
        user_id
        user {
          active
          id
          email
          photo_url
        }
      }
      logistics
      advance_payment_percentage
      active
      id
      uid
      created_at
      updated_at
      notes {
        created_at
        updated_at
        active
        id
        text
      }
      user_id
      user {
        active
        id
        email
        photo_url
        name
        photo_url
      }
      request_id
      offer_id
      offer {
        active
        id
        uid
        created_at
        updated_at
      }
      offer_user_id
      offer_user {
        active
        id
        name
      }
      request {
        uid
        created_at
        updated_at
        active
        id
        user_id
        user {
          active
          id
          name
          photo_url
        }
      }
      children: matching_offers(order_by: $order_by) {
        active
        id
        updated_at
        timestamp
        product_type {
          active
          id
          name
          product_id
          pictures
          product {
            pictures
            active
            id
            name
          }
        }
        type
        days_to_maturity
        views {
          searches
          id: timestamp
          user_id
          views
        }
        enum_request_type {
          comment
          id: value
          value
        }
        status
        enum_request_status {
          comment
          id: value
          value
        }
        advance_amount
        total_amount
        price_per_unit
        uid
        created_at
        updated_at
        number_of_units
        unit
        enum_product_unit {
          comment
          id: value
          value
        }
        total_amount
        from_date
        to_date
        location {
          lat
          lng
          active
          id
          name
          address_line_1
          address_line_2
          address_line_3
          city
          color
          uid
          created_at
          updated_at
          state
          country
          postcode
          lat
          lng
          is_farm
          is_verified
          area
          crops
          certifications
          enum_country {
            id: value
            value
            comment
          }
          user_id
          user {
            active
            id
            email
            photo_url
          }
        }
        logistics
        advance_payment_percentage
        active
        id
        uid
        created_at
        updated_at
        notes {
          created_at
          updated_at
          active
          id
          text
        }
        user_id
        user {
          active
          id
          email
          photo_url
          name
          photo_url
        }
        request_id
        offer_id
        offer {
          active
          id
          uid
          created_at
          updated_at
        }
        offer_user_id
        offer_user {
          active
          id
          name
        }
        request {
          uid
          created_at
          updated_at
          active
          id
          user_id
          user {
            active
            id
            name
            photo_url
          }
        }
      }
    }
  }
`;

export const GET_REQUESTS = gql`
  query get_requests {
    requests {
      active
      id
      updated_at
      timestamp
      product_type {
        active
        id
        name
        product_id
        pictures
        product {
          pictures
          active
          id
          name
        }
      }
      type
      days_to_maturity
      views: views_aggregate {
        aggregate {
          sum {
            searches
            views
          }
        }
      }
      enum_request_type {
        comment
        id: value
        value
      }
      status
      enum_request_status {
        comment
        id: value
        value
      }
      price_per_unit
      uid
      created_at
      updated_at
      number_of_units
      unit
      enum_product_unit {
        comment
        id: value
        value
      }
      total_amount
      from_date
      to_date
      location {
        lat
        lng
        active
        id
        name
        address_line_1
        address_line_2
        address_line_3
        city
        color
        uid
        created_at
        updated_at
        state
        country
        postcode
        lat
        lng
        is_farm
        is_verified
        area
        crops
        certifications
        enum_country {
          id: value
          value
          comment
        }
        user_id
        user {
          active
          id
          email
          photo_url
        }
      }
      logistics
      advance_payment_percentage
      active
      id
      uid
      created_at
      updated_at
      notes {
        created_at
        updated_at
        active
        id
        text
      }
      user_id
      user {
        active
        id
        email
        photo_url
      }
      request_id
      offer_id
      offer {
        active
        id
        uid
        created_at
        updated_at
      }
      offer_user_id
      offer_user {
        active
        id
        name
      }
      request {
        uid
        created_at
        updated_at
        active
        id
        user_id
        user {
          active
          id
          name
          photo_url
        }
      }
    }
  }
`;

export const GET_SIMILAR_REQUEST = gql`
  query get_similar_requests($id: uuid!) {
    requests(where: { id: { _eq: $id } }) {
      active
      id
      similar_offers_by_type_user {
        uid
        created_at
        updated_at
        active
        id
        from_date
        user_id
        user {
          active
          id
          email
          photo_url
        }
        uid
        created_at
        updated_at
        location {
          lat
          lng
          active
          id
          name
          address_line_1
          address_line_2
          address_line_3
          city
          color
          uid
          created_at
          updated_at
          state
          country
          postcode
          lat
          lng
          is_farm
          is_verified
          area
          crops
          certifications
          enum_country {
            id: value
            value
            comment
          }
          user_id
          user {
            active
            id
            email
            photo_url
          }
        }
        request_id
        offer_id
        offer {
          active
          id
          uid
          created_at
          updated_at
        }
        offer_user_id
        offer_user {
          active
          id
          name
        }
        enum_request_status {
          comment
          id: value
          value
        }
        request {
          uid
          created_at
          updated_at
          active
          id
          user_id
          user {
            active
            id
            name
            photo_url
          }
        }
      }
    }
  }
`;

export const GET_USER_OFFERS = gql`
  query GET_USER_OFFERS(
    $where: requests_bool_exp
    $filter: requests_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [requests_order_by!]
    $distinct_on: [requests_select_column!]
    $user_id: String
  ) {
    objects: requests(
      where: $where
      limit: $limit
      offset: $offset
      order_by: $order_by
      distinct_on: $distinct_on
    ) {
      active
      enum_request_type {
        id: value
        value
        comment
      }
      created_at
      id
      uid
      created_at
      updated_at
      type
      days_to_maturity
      views: views_aggregate {
        aggregate {
          sum {
            searches
            views
          }
        }
      }
      user_id
      user {
        name
        active
        id
        photo_url
      }
      location {
        lat
        lng
        active
        id
        name
        address_line_1
        address_line_2
        address_line_3
        city
        color
        uid
        created_at
        updated_at
        state
        country
        postcode
        lat
        lng
        is_farm
        is_verified
        area
        crops
        certifications
        enum_country {
          id: value
          value
          comment
        }
        user_id
        user {
          active
          id
          email
          photo_url
        }
      }
      product_type {
        active
        id
        name
        product_id
        pictures
        product {
          pictures
          active
          id
          name
        }
      }
      unit
      enum_product_unit {
        id: value
        value
        comment
      }
      request_id
      price_per_unit
      uid
      created_at
      updated_at
      number_of_units
      from_date
      to_date
      pictures
      total_amount
      advance_amount
      request_id
      offer_id
      offer {
        active
        id
        uid
        created_at
        updated_at
      }
      offer_user_id
      offer_user {
        active
        id
        name
      }
      enum_request_status {
        comment
        id: value
        value
      }
      active_bids: requests_aggregate(
        where: { user_id: { _neq: $user_id }, active: { _eq: true } }
      ) {
        aggregate {
          count
        }
      }
      bids: requests_aggregate(where: { user_id: { _neq: $user_id } }) {
        aggregate {
          count
        }
      }
      request {
        uid
        created_at
        updated_at
        uid
        created_at
        updated_at
        active
        id
        user_id
        user {
          active
          id
          name
          photo_url
        }
      }
    }
  }
`;

export const GET_TRADE_ORDERS = gql`
  query GET_TRADE_ORDERS(
    $where: trade_orders_bool_exp
    $filter: trade_orders_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [trade_orders_order_by!]
    $distinct_on: [trade_orders_select_column!]
  ) {
    objects: trade_orders(
      where: $where
      limit: $limit
      offset: $offset
      order_by: $order_by
      distinct_on: $distinct_on
    ) {
      active
      id
      uid
      created_at
      updated_at
      product_type {
        active
        id
        name
        product_id
        pictures
        product {
          pictures
          active
          id
          name
        }
      }
      enum_order_status {
        comment
        id: value
        value
      }
      price_per_unit
      number_of_units
      total_amount
      from_date
      active
      to_date
      advance_amount
      advance_payment_percentage
      status
      days_to_maturity
      from_location {
        lat
        lng
        active
        id
        name
        address_line_1
        address_line_2
        address_line_3
        city
        color
        uid
        created_at
        updated_at
        state
        country
        postcode
        lat
        lng
        is_farm
        is_verified
        area
        crops
        certifications
        enum_country {
          id: value
          value
          comment
        }
        user_id
        user {
          active
          id
          email
          photo_url
        }
      }
      to_location {
        lat
        lng
        active
        id
        name
        address_line_1
        address_line_2
        address_line_3
        city
        color
        uid
        created_at
        updated_at
        state
        country
        postcode
        lat
        lng
        is_farm
        is_verified
        area
        crops
        certifications
        enum_country {
          id: value
          value
          comment
        }
        user_id
        user {
          active
          id
          email
          photo_url
        }
      }
      logistics_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;

export const GET_SERVICE_ORDERS = gql`
  query GET_SERVICE_ORDERS(
    $where: service_orders_bool_exp
    $filter: service_orders_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [service_orders_order_by!]
    $distinct_on: [service_orders_select_column!]
  ) {
    objects: service_orders(
      where: $where
      limit: $limit
      offset: $offset
      order_by: $order_by
      distinct_on: $distinct_on
    ) {
      active
      id
      days_to_maturity
      created_at
      active
      uid
      created_at
      updated_at
      total_amount
      price_per_unit
      number_of_units
      service_type {
        pictures
        pictures
        active
        id
        name
        service_id
        service {
          pictures
          active
          id
          name
        }
      }
      date
      provider_service_id
      buyer {
        active
        id
        email
        photo_url
      }
      seller {
        active
        id
        email
        photo_url
      }
      from_location {
        lat
        lng
        active
        id
        name
        address_line_1
        address_line_2
        address_line_3
        city
        color
        uid
        created_at
        updated_at
        state
        country
        postcode
        lat
        lng
        is_farm
        is_verified
        area
        crops
        certifications
        enum_country {
          id: value
          value
          comment
        }
        user_id
        user {
          active
          id
          email
          photo_url
        }
      }
      to_location {
        lat
        lng
        active
        id
        name
        address_line_1
        address_line_2
        address_line_3
        city
        color
        uid
        created_at
        updated_at
        state
        country
        postcode
        lat
        lng
        is_farm
        is_verified
        area
        crops
        certifications
        enum_country {
          id: value
          value
          comment
        }
        user_id
        user {
          active
          id
          email
          photo_url
        }
      }

      enum_order_status {
        comment
        id: value
        value
      }

      status
    }
  }
`;

export const SEARCH_MAIN = gql`
  query search_main($text: String!, $type: String!) {
    matched: search_main(
      where: { name: { _ilike: $text }, type: { _ilike: $type } }
      limit: 8
      order_by: { name: asc }
    ) {
      type
      id
      name
      parent_id
      parent_name
      pictures
    }
  }
`;

export const MATCHING_CONNECTIONS_COUNT = gql`
  subscription MATCHING_CONNECTIONS_COUNT($user_id: String!) {
    matching: users_by_pk(id: $user_id) {
      id
      count: matching_offer_counts_by_product_type_date
    }
  }
`;

export const MATCHING_CONNECTIONS = gql`
  query MATCHING_CONNECTIONS($user_id: String!, $limit: Int) {
    matching: users_by_pk(id: $user_id) {
      id
      objects: matching_offers_by_product_type_date(limit: $limit) {
        uid
        created_at
        updated_at
        active
        id
        price_per_unit
        uid
        created_at
        updated_at
        number_of_units
        product_type {
          product {
            pictures
            name
          }
          name
        }
        statuses: enum_request_status {
          comment
        }
        total_amount
        from_date
        to_date
        request_id
      }
    }
  }
`;
