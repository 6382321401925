import React, { useContext } from "react";
import Bottom from "./bottom";
import Footer from "./footer";
import AuthHeader from "./header-auth";
import { AuthContext } from "../../store";

export const AuthLayout = ({ children, title, showBottom }) => {
	const { user } = useContext(AuthContext)
	return (
		<>
			<div id="main-wrapper">
				<AuthHeader />
				<div className="bg-light" style={{ minHeight: "95vh", paddingTop: user?.account_verified === false ? '1rem' : undefined }}>
					{children}
				</div>
				{showBottom && <Bottom />}
				<Footer />
			</div>
		</>
	);
};
