import React, { useEffect, useRef } from "react";
import { Spinner } from "react-bootstrap";
import { getThumbNail } from "../../../shared/helpers";
import { useTranslatedLabel } from "../../../shared/hooks";
import { SelectedImage } from "./selected-image";

export const ImageUploader = ({ setImages, images = [], limit = 3, uploading = false, pictures = [] }) => {
	const { t } = useTranslatedLabel();
	const inputElement = useRef(null);


	useEffect(() => {
		if (pictures.length > 0) {
			setImages(pictures.map((p) => ({ file: p, preview: p })));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pictures]);

	const selectImages = () => {
		inputElement.current.click();
	};

	const setImagesFromFiles = async (files = []) => {
		if (files.length > limit) files = files.slice(0, limit);

		const filesArr = [];
		for (const file of files) filesArr.push({ file, preview: URL.createObjectURL(file) });

		setImages(state => {
			let newState = [...state, ...filesArr]
			if (newState.length > limit) newState = newState.slice(0, limit);
			return [...newState]
		})

	};

	const onChange = (e) => {
		if (e.target.files.length > 0) setImagesFromFiles(Array.from(e.target.files));
	};

	const handleDragEnter = (e) => {
		e.preventDefault();
		e.stopPropagation();
	};

	const handleDragLeave = (e) => {
		e.preventDefault();
		e.stopPropagation();
	};

	const handleDragOver = (e) => {
		e.preventDefault();
		e.stopPropagation();
	};

	const handleDrop = (e) => {
		e.preventDefault();
		e.stopPropagation();
		setImagesFromFiles(Array.from(e.dataTransfer.files).filter((file) => ["image/png", "image/jpeg"].includes(file.type)));
	};

	const removeImage = (preview) => {
		const updatedImagesList = images.filter((image) => image.preview !== preview);
		setImages(updatedImagesList);
	};

	return (
		<>
			{(uploading || images.length > 0) && (
				<div className="images-preview">
					<div className="images-preview-container">
						{uploading ? (
							<div className="text-center mb-1 p-3 m-3">
								<Spinner as="span" animation="border" user_role="status" aria-hidden="true" />
							</div>
						) : (
								<div className="bg-light p-3 flex-grow-1 d-flex">
									{images.map((image, index) => (
										<SelectedImage
											key={index}
											imageUrl={getThumbNail(image.preview ?? "")}
											removeImage={() => removeImage(image.preview ?? "")}
										/>
									))}
								</div>
							)}
						<div className="d-flex align-items-center justify-content-between py-2 px-3">
							<div className="mb-0">
								{images.length} {images.length > 1 ? t("images") : t("image")}{" "}
								{uploading ? t("uploading") : null || images.length > 0 ? t("uploaded") : t("selected")}
							</div>
						</div>
					</div>
				</div>
			)}
			{images.length < 3 && (
				<div
					className="image-uploader p-3"
					onDrop={handleDrop}
					onDragOver={handleDragOver}
					onDragEnter={handleDragEnter}
					onDragLeave={handleDragLeave}
				>
					{uploading ? (
						<i className="las la-spinners la-3x"></i>
					) : (
							<div className="image-uploader-container">
								<div className="text-center mb-1">
									<i className="las la-cloud-upload-alt"></i>
									<p className="mb-0">{t("drag_and_drop_images")}</p>
								</div>
								<div className="image-selector mb-3">
									<button type="button" className="btn btn-light" onClick={selectImages}>
										{t("choose_images")}
									</button>
									<input
										type="file"
										name="file"
										id="file"
										accept=".jpg,.jpeg,.png,.svg"
										ref={inputElement}
										onChange={onChange}
										multiple
									/>
								</div>
								<p className="font-italic mb-0">{`${t("minimum")}: 1 | ${t("maximum")}: ${limit}`}</p>
							</div>
						)}
				</div>
			)}
		</>
	);
};

// export const ImageUploader = ({ sendImageData, pictures = [], limit = 3 }) => {
// 	const { t } = useTranslatedLabel();
// 	const inputElement = useRef(null);
// 	const [images, setImages] = useState([]);

// 	const [uploading, setUploading] = useState(false);
// 	const [uploaded, setUploaded] = useState(false);

// 	useEffect(() => {
// 		if (pictures.length > 0) setImages(pictures.map((p) => ({ file: p, preview: p })));
// 	}, [pictures]);

// 	const selectImages = () => {
// 		inputElement.current.click();
// 	};

// 	const setImagesFromFiles = async (files = []) => {
// 		if (files.length > limit) files = files.slice(0, limit);

// 		const filesArr = [];
// 		for (const file of files) filesArr.push({ file, preview: URL.createObjectURL(file) });

// 		setUploading(true);
// 		setImages(filesArr);
// 		try {
// 			const response = await uploadImagesToCloudinary(files);
// 			const data = await response.json();
// 			const uploads = data.map((upload) => upload.secure_url);
// 			sendImageData(uploads);
// 			setUploading(false);
// 			setUploaded(true);
// 		} catch (err) {
// 			console.error(err);
// 			setUploading(false);
// 		}
// 	};

// 	const onChange = (e) => {
// 		if (e.target.files.length > 0) setImagesFromFiles(Array.from(e.target.files));
// 	};

// 	const handleDragEnter = (e) => {
// 		e.preventDefault();
// 		e.stopPropagation();
// 	};

// 	const handleDragLeave = (e) => {
// 		e.preventDefault();
// 		e.stopPropagation();
// 	};

// 	const handleDragOver = (e) => {
// 		e.preventDefault();
// 		e.stopPropagation();
// 	};

// 	const handleDrop = (e) => {
// 		e.preventDefault();
// 		e.stopPropagation();
// 		setImagesFromFiles(Array.from(e.dataTransfer.files).filter((file) => ["image/png", "image/jpeg"].includes(file.type)));
// 	};

// 	const removeImage = (preview) => {
// 		const updatedImagesList = images.filter((image) => image.preview !== preview);
// 		setImages(updatedImagesList);
// 	};

// 	return (
// 		<>
// 			{(uploading || images.length > 0) && (
// 				<div className="images-preview">
// 					<div className="images-preview-container">
// 						{uploading ? (
// 							<div className="text-center mb-1 p-3 m-3">
// 								<Spinner as="span" animation="border" user_role="status" aria-hidden="true" />
// 							</div>
// 						) : (
// 							<div className="bg-light p-3 flex-grow-1 d-flex">
// 								{images.map((image, index) => (
// 									<SelectedImage
// 										key={index}
// 										imageUrl={getThumbNail(image.preview)}
// 										removeImage={() => removeImage(image.preview)}
// 									/>
// 								))}
// 							</div>
// 						)}
// 						<div className="d-flex align-items-center justify-content-between py-2 px-3">
// 							<div className="mb-0">
// 								{images.length} {images.length > 1 ? t("images") : t("image")}{" "}
// 								{uploading ? t("uploading") : uploaded || pictures.length > 0 ? t("uploaded") : t("selected")}
// 							</div>
// 						</div>
// 					</div>
// 				</div>
// 			)}
// 			{!uploading && images.length === 0 && (
// 				<div
// 					className="image-uploader p-3"
// 					onDrop={handleDrop}
// 					onDragOver={handleDragOver}
// 					onDragEnter={handleDragEnter}
// 					onDragLeave={handleDragLeave}
// 				>
// 					{uploading ? (
// 						<i className="las la-spinners la-3x"></i>
// 					) : (
// 						<div className="image-uploader-container">
// 							<div className="text-center mb-1">
// 								<i className="las la-cloud-upload-alt"></i>
// 								<p className="mb-0">{t("drag_and_drop_images")}</p>
// 							</div>
// 							<div className="image-selector mb-3">
// 								<button type="button" className="btn btn-light" onClick={selectImages}>
// 									{t("choose_images")}
// 								</button>
// 								<input
// 									type="file"
// 									name="file"
// 									id="file"
// 									accept=".jpg,.jpeg,.png,.svg"
// 									ref={inputElement}
// 									onChange={onChange}
// 									multiple
// 								/>
// 							</div>
// 							<p className="font-italic mb-0">{`${t("minimum")}: 1 | ${t("maximum")}: ${limit}`}</p>
// 						</div>
// 					)}
// 				</div>
// 			)}
// 		</>
// 	);
// };
