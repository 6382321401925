import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useDocTitle } from "../../../../shared/helpers";
import { useTranslatedLabel } from "../../../../shared/hooks";
import { Layout } from "../../components";
import { Farms } from "../locations/farms";
import { Offers } from "../transactions/offers";
import { Orders } from "../transactions/orders";
import { ServiceOffers } from "../transactions/service-offers";
import { ServiceOrders } from "../transactions/service-orders";

export const MasterDataDetails = (props) => {
  const { t } = useTranslatedLabel();
  const { id } = useParams();

  const currentPage = props.location?.pathname?.split("/")[2];
  useDocTitle(currentPage);
  const name = props.location?.state?.data?.name;

  const filter = {};

  switch (currentPage) {
    case "products":
      filter.product_id = { _eq: id };

      break;
    case "services":
      filter.service_id = { _eq: id };

      break;
    case "product-types":
      filter.product_type_id = { _eq: id };

      break;
    case "service-types":
      filter.service_type_id = { _eq: id };

      break;
    case "certifications":
      filter.certifications_id = { _eq: id };
      break;

    default:
      break;
  }

  return (
    <Layout title={name} key={currentPage}>
      <div>
        <Tabs
          defaultKey="profile"
          style={{ paddingTop: "0px", marginTop: "0px" }}
        >
          <Tab eventKey="dashboard" title={t("dashboard")}></Tab>
          {(currentPage === "products" || currentPage === "product-types") && (
            <Tab eventKey="offers" title={t("trade_offers")}>
              <Offers
                component={true}
                component_filters={{
                  ...filter,
                }}
              />
            </Tab>
          )}
          {(currentPage === "products" || currentPage === "product-types") && (
            <Tab eventKey="orders" title={t("trade_orders")}>
              <Orders
                component={true}
                component_filters={{
                  ...filter,
                }}
              />
            </Tab>
          )}
          {(currentPage === "services" || currentPage === "service-types") && (
            <Tab eventKey="service_offers" title={t("service_offers")}>
              <ServiceOffers
                component={true}
                component_filters={{
                  ...filter,
                }}
              />
            </Tab>
          )}
          {(currentPage === "services" || currentPage === "service-types") && (
            <Tab eventKey="service_orders" title={t("service_orders")}>
              <ServiceOrders
                component={true}
                component_filters={{
                  ...filter,
                }}
              />
            </Tab>
          )}
          {currentPage === "certifications" && (
            <Tab eventKey="certifications" title={t("farms")}>
              <Farms
                component={true}
                // component_filters={{
                //   ...filter,
                // }}
              />
            </Tab>
          )}
        </Tabs>
      </div>
    </Layout>
  );
};
