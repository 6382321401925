import { useQuery } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { Sugar } from "react-preloaders";
import { Link, Redirect } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { PATHS } from "../../config";
import { CHECK_SUPERADMIN_EXIST } from "../../gql";
import { register } from "../../services/auth";
import { AuthContext } from "../../store";

export const CreateSuperAdmin = ({ history }) => {
	const { setUserRole, is_authenticated } = useContext(AuthContext);
	const { addToast } = useToasts();
	const [{ full_name, email, phone_number, password, confirm_password }, setFields] = useState({
		full_name: "",
		email: "",
		password: "",
		phone_number: "",
		confirm_password: "",
	});

	const [loading, setLoading] = useState(false);
	const [superAdminExist, setSuperAdminExist] = useState(undefined);
	const { data: superAdminList, loading: superAdminListLoading } = useQuery(CHECK_SUPERADMIN_EXIST);

	// Check if Super Admin Exist
	useEffect(() => {
		if (superAdminList && !superAdminListLoading) {
			const exists = superAdminList.users.length > 0 ? true : false;
			setSuperAdminExist(exists);
		}
	}, [superAdminList, superAdminListLoading]);

	const onChange = (e) => {
		e.preventDefault();

		setFields({
			...{ full_name, email, phone_number, password, confirm_password },
			[e.target.name]: e.target.value,
		});
	};

	const registerSuperAdmin = async (e) => {
		e.preventDefault();
		if (full_name && email && password && confirm_password && password === confirm_password) {
			setLoading(true);
			try {
				setUserRole("moderator");
				await register({
					email,
					password,
					phoneNumber: phone_number,
					fullName: full_name,
				});
			} catch (err) {
				addToast(err.description, { appearance: "error" });
				setLoading(false);
			}
		}
	};

	if (superAdminExist && !is_authenticated) return <Redirect to={PATHS.signin} />;

	if (superAdminExist && is_authenticated) return <Redirect to={PATHS.dashboard} />;

	if (superAdminListLoading) return <Sugar color={"#ccd92b"} time={0} customLoading={true} />;

	return (
		<>
			<div className="authincation bg-white admin-auth">
				<div className="container">
					<div className="row justify-content-center align-items-center">
						<div className="col-xl-5 col-md-6">
							<div className="auth-logo text-center my-3">
								<Link to={PATHS.base}>
									<img
										src="https://res.cloudinary.com/ampcome/image/upload/c_scale,w_100/v1592541595/mahaseel/mahaseel_new_logo.jpg"
										alt="Mahaseel logo"
									/>
								</Link>
							</div>
							<div className="auth-form card">
								<div className="card-body">
									<h3 className="text-center display-4 mb-3">Add Adminstrator</h3>
									<form name="create-super-admin-form">
										<div className="form-group">
											<label htmlFor=""></label>
											<input
												type="text"
												className="form-control"
												placeholder="Full Name"
												name="full_name"
												onChange={onChange}
												value={full_name}
											/>
										</div>
										<div className="form-group">
											<label htmlFor=""></label>
											<input
												type="email"
												className="form-control"
												placeholder="Email"
												name="email"
												onChange={onChange}
												value={email}
											/>
										</div>
										<div className="form-group">
											<label htmlFor=""></label>
											<input
												type="text"
												className="form-control"
												placeholder="Phone Number"
												name="phone_number"
												onChange={onChange}
												value={phone_number}
											/>
										</div>
										<div className="form-group">
											<label htmlFor=""></label>
											<input
												type="password"
												className="form-control"
												placeholder="Password"
												name="password"
												onChange={onChange}
												value={password}
											/>
										</div>
										<div className="form-group">
											<label htmlFor=""></label>
											<input
												type="password"
												className="form-control"
												placeholder="Confirm Password"
												name="confirm_password"
												onChange={onChange}
												value={confirm_password}
											/>
										</div>
										<div className="text-center mt-5">
											<button className="btn btn-success btn-block" onClick={registerSuperAdmin} disabled={loading}>
												{loading ? (
													<Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
												) : (
													<span>Create Adminstrator Account</span>
												)}
											</button>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
