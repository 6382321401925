import { useMutation, useSubscription } from "@apollo/client";
import { loadStripe } from "@stripe/stripe-js";
import { Input, Steps } from "antd";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { default as Skeleton, default as Skelton } from "react-loading-skeleton";
import NumberFormat from "react-number-format";
import { Link, useParams } from "react-router-dom";
import Stepper from "react-stepper-horizontal";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import GreenMarker from "../../../../../../images/marker-green.png";
import RedMarker from "../../../../../../images/marker-red.png";
import { APP_CONFIG } from "../../../../../../shared/app_config";
import { getMapPoint, PreviewGoogleMap, useDocTitle } from "../../../../../../shared/helpers";
import { useTranslatedLabel } from "../../../../../../shared/hooks";
import { AuthLayout } from "../../../../components";
import { PATHS } from "../../../../config";
import { CREATE_NOTES, GET_TRADE_ORDER_BY_ID, UPDATE_ONE } from "../../../../gql";
import { createCheckoutSession } from "../../../../services";
import { AuthContext, GeneralContext } from "../../../../store";
import { LogisticModal } from "../../add-logistics";
import { Invoice } from "../../invoice";

const { Step } = Steps;
const { TextArea } = Input;

const ViewTradeOrder = ({ component = false, order_id = "", inAdmin = "", adminId = null }) => {
	useDocTitle("order_details");
	const { t, dt, dt_tm } = useTranslatedLabel();
	const { orderId } = useParams();
	const { user } = useContext(AuthContext);
	const userId = inAdmin ? adminId : user?.id;
	const [note, setNote] = useState("");
	const [showLog, setShowLog] = useState(false);
	const [currentLogistic, setCurrentLogistic] = useState(null);
	const { general_order_status, general_language } = useContext(GeneralContext);
	const [order, setOrder] = useState(null);

	const [updateOrder] = useMutation(UPDATE_ONE("trade_orders"));
	const [updateLogistics] = useMutation(UPDATE_ONE("logistics"));
	const [addNote] = useMutation(CREATE_NOTES);

	const { data, loading, error } = useSubscription(GET_TRADE_ORDER_BY_ID, {
		variables: { id: component ? order_id : orderId },
	});
	useEffect(() => {
		if (!loading && data?.object && !error) setOrder(data?.object);
	}, [data, loading, error]);

	const onAddNote = () => {
		if (note) {
			addNote({
				variables: {
					input: {
						user_id: user.id,
						trade_order_id: order?.id,
						text: note,
					},
				},
			}).then(() => setNote(""));
		}
	};
	const steps = ["open", "advancepaid", "readyfordelivery", "delivered", "received", "fullypaid", "completed"];

	const [payment_in_progress, setPaymentInProgress] = useState(false);
	const stripePromise = (publishableKey) => loadStripe(publishableKey);

	const makePayment = async (advance) => {
		setPaymentInProgress(true);

		try {
			const response = await createCheckoutSession({
				offer_id: order?.offer?.id,
				from_location_id: order?.from_location?.id,
				to_location_id: order?.to_location?.id,
				seller_id: order?.seller?.id,
				buyer_id: order?.buyer?.id,
				trade: true,
				advance: advance ? true : false,
				offer: { ...order.offer },
			});
			const { sessionId, publishableKey } = response.data;
			const stripe = await stripePromise(publishableKey);
			const { error } = await stripe.redirectToCheckout({ sessionId });
			setPaymentInProgress(false);
			if (error) console.error(error);
		} catch (err) {
			console.error(err);
			setPaymentInProgress(false);
		}
	};

	let logistics_completed = true;
	if (order?.logistics && order?.logistics.length > 0) {
		order.logistics.map((l) => {
			if (l.status !== "completed") logistics_completed = false;
			return null;
		});
	} else {
		logistics_completed = false;
	}

	const Wrapper = component ? Fragment : AuthLayout;

	return (
		<>
			<div id="main-wrapper" style={component ? { marginTop: "0px" } : {}}>
				<Wrapper>
					<LogisticModal
						show={showLog}
						current={currentLogistic}
						onClose={() => {
							setShowLog(false);
							setCurrentLogistic(null);
						}}
						order={order}
						statuses={general_order_status}
					/>

					{order ? (
						<div className="offer-details-page bg-light">
							<div className="container pb-4 pt-5">
								<div className="row ml-1">
									<div>
										<h3 className="page-title display-4 ml-2 mr-2" style={{ padding: "0px" }}>
											{`${t("trade_order")} # ${order?.uid} - ${t(order?.status)}`}
										</h3>
									</div>
									<div className="ml-auto mr-3">
										{!inAdmin && order && order?.active && order?.status === "open" && user?.id === order?.buyer?.id && (
											<button
												type="button"
												className="btn btn-sm btn-block btn-outline-primary ml-3"
												onClick={() => makePayment(true)}
											>
												{payment_in_progress ? (
													<Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
												) : (
													<span>
														{t("proceed_to_pay")}{" "}
														<b>
															<NumberFormat
																value={parseFloat(order?.advance_amount).toFixed(2)}
																displayType={"text"}
																thousandSeparator={true}
																prefix={"$ "}
															/>
														</b>
													</span>
												)}
											</button>
										)}
										{!inAdmin &&
											order &&
											order?.active &&
											order?.status === "advancepaid" &&
											user?.id === order?.seller?.id && (
												<button
													type="button"
													className="btn btn-sm btn-block btn-outline-primary ml-3"
													onClick={() =>
														Swal.fire({
															title: t("are_you_sure?"),
															text: t("you_wont_be_able_to_revert!"),
															icon: "warning",
															showCancelButton: true,
															confirmButtonText: t("yes"),
															cancelButtonText: t("cancel"),
														}).then((x) => {
															if (x.value) {
																updateOrder({
																	variables: {
																		id: order?.id,
																		object: {
																			status: "readyfordelivery",
																		},
																	},
																});
															}
														})
													}
												>
													{`${t("mark_as")} " ${t("readyfordelivery")} "`}
												</button>
											)}
										{order &&
											order?.active &&
											order?.status === "readyfordelivery" &&
											((order?.logistics_info === "seller_will_handle_logistics" && order?.seller?.id === user?.id) ||
												(order?.logistics_info === "buyer_will_handle_logistics" && order?.buyer?.id === user?.id) ||
												(order?.logistics_info === "third_party_will_handle_logistics" && inAdmin)) &&
											!logistics_completed &&
											order?.logistics?.length === 0 && (
												<div className="btn btn-sm btn-outline-primary ml-3" onClick={() => setShowLog(true)}>
													<span>{t("add_logistics")}</span>
												</div>
											)}

										{order &&
											order?.active &&
											order?.status === "readyfordelivery" &&
											((order?.logistics_info === "seller_will_handle_logistics" && order?.seller?.id === user?.id) ||
												(order?.logistics_info === "buyer_will_handle_logistics" && order?.buyer?.id === user?.id) ||
												(order?.logistics_info === "third_party_will_handle_logistics" && inAdmin)) &&
											logistics_completed && (
												<button
													type="button"
													className="btn btn-sm btn-block btn-outline-primary ml-3"
													onClick={() =>
														Swal.fire({
															title: t("are_you_sure?"),
															text: t("you_wont_be_able_to_revert!"),
															icon: "warning",
															showCancelButton: true,
															confirmButtonText: t("yes"),
															cancelButtonText: t("cancel"),
														}).then((x) => {
															if (x.value) {
																updateOrder({
																	variables: {
																		id: order?.id,
																		object: {
																			status: "delivered",
																		},
																	},
																});
															}
														})
													}
												>
													{`${t("mark_as")} " ${t("delivered")} "`}
												</button>
											)}
										{!inAdmin && order && order?.active && order?.status === "delivered" && user?.id === order?.buyer?.id && (
											<button
												type="button"
												className="btn btn-sm btn-block btn-outline-primary ml-3"
												onClick={() =>
													Swal.fire({
														title: t("are_you_sure?"),
														text: t("you_wont_be_able_to_revert!"),
														icon: "warning",
														showCancelButton: true,
														confirmButtonText: t("yes"),
														cancelButtonText: t("cancel"),
													}).then((x) => {
														if (x.value) {
															updateOrder({
																variables: {
																	id: order?.id,
																	object: {
																		status: "received",
																	},
																},
															});
														}
													})
												}
											>
												{`${t("mark_as")} " ${t("received")} "`}
											</button>
										)}
										{!inAdmin && order && order?.active && order?.status === "received" && user?.id === order?.buyer?.id && (
											<button
												type="button"
												className="btn btn-sm btn-block btn-outline-primary  ml-3"
												onClick={() => makePayment()}
											>
												{payment_in_progress ? (
													<Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
												) : (
													<span>
														{t("proceed_to_pay")}
														<b>
															<NumberFormat
																value={parseFloat(order.total_amount - order.advance_amount).toFixed(2)}
																displayType={"text"}
																thousandSeparator={true}
																prefix={"$ "}
															/>
														</b>
													</span>
												)}
											</button>
										)}
										{!inAdmin && order && order?.active && order?.status === "fullypaid" && user?.id === order?.seller?.id && (
											<button
												type="button"
												className="btn btn-sm btn-block btn-outline-primary  ml-3"
												onClick={() =>
													Swal.fire({
														title: t("are_you_sure?"),
														text: t("you_wont_be_able_to_revert!"),
														icon: "warning",
														showCancelButton: true,
														confirmButtonText: t("yes"),
														cancelButtonText: t("cancel"),
													}).then((x) => {
														if (x.value) {
															updateOrder({
																variables: {
																	id: order?.id,
																	object: {
																		status: "completed",
																		active: false,
																	},
																},
															});
														}
													})
												}
											>
												{`${t("mark_as")} " ${t("completed")} "`}
											</button>
										)}
									</div>
								</div>
								{order && order?.status !== "cancelled" && (
									<div className="row ml-1 mb-3 bg-white">
										<Stepper
											activeStep={steps.indexOf(order?.status)}
											steps={[
												{
													title: (
														<>
															<div>{t("open")}</div>
															{order?.open_at && <small>{dt_tm(`${order?.open_at}`)}</small>}
														</>
													),
												},
												{
													title: (
														<>
															<div>{t("advancepaid")}</div>
															{order?.advancepaid_at && <small>{dt_tm(`${order?.advancepaid_at}`)}</small>}
														</>
													),
												},
												{
													title: (
														<>
															<div>{t("readyfordelivery")}</div>
															{order?.readyfordelivery_at && (
																<small>{dt_tm(`${order?.readyfordelivery_at}`)}</small>
															)}
														</>
													),
												},
												{
													title: (
														<>
															<div>{t("delivered")}</div>
															{order?.delivered_at && <small>{dt_tm(`${order?.delivered_at}`)}</small>}
														</>
													),
												},
												{
													title: (
														<>
															<div>{t("received")}</div>
															{order?.received_at && <small>{dt_tm(`${order?.received_at}`)}</small>}
														</>
													),
												},
												{
													title: (
														<>
															<div>{t("fullypaid")}</div>
															{order?.fullypaid_at && <small>{dt_tm(`${order?.fullypaid_at}`)}</small>}
														</>
													),
												},
												{
													title: (
														<>
															<div>{t("completed")}</div>
															{order?.completed_at && <small>{dt_tm(`${order?.completed_at}`)}</small>}
														</>
													),
												},
											]}
										/>
									</div>
								)}
								<div className="offer-details pb-5">
									<div className="offer-main bg-white p-4">
										{loading && (
											<div className="mb-2">
												<Skeleton height={30} />
											</div>
										)}
										{!loading && order && (
											<div className="row bg-white p-2 mb-3">
												<div className="col-sm-12 col-md-12 col-lg-6 text-left font-success">
													<p className="text-muted">{t("seller")}</p>
													<h5 className="d-inline-flex align-items-center">
														<img
															src={order?.seller?.photo_url || APP_CONFIG.REACT_APP_AVATAR_URL}
															style={{ height: "30px" }}
															alt=""
														/>
														<span className="ml-3 mr-3">{order?.seller?.name}</span>
													</h5>
													<p className="d-inline-flex align-items-center">
														<img src={GreenMarker} alt="" />
														<span className="ml-3 mr-3">
															{order?.from_location?.address_line_1}, {order?.from_location?.city},{" "}
															{order?.from_location?.state}, {order?.from_location?.country}{" "}
															{order?.from_location?.postcode}
														</span>
													</p>
												</div>
												<div className="col-sm-12 col-md-12 col-lg-6 text-right sm-text-left md-text-left font-danger">
													<p className="text-muted">{t("buyer")}</p>
													<h5 className="d-inline-flex align-items-center sm-flex-row-reverse md-flex-row-reverse md-d-flex md-justify-end">
														<span className="ml-3 mr-3">{order?.buyer?.name}</span>
														<img
															src={order?.buyer?.photo_url || APP_CONFIG.REACT_APP_AVATAR_URL}
															style={{ height: "30px" }}
															alt=""
														/>
													</h5>
													<p className="d-inline-flex align-items-center sm-flex-row-reverse md-flex-row-reverse">
														<span className="ml-3 mr-3">
															{order?.to_location?.address_line_1}, {order?.to_location?.city},{" "}
															{order?.to_location?.state}, {order?.to_location?.country}{" "}
															{order?.to_location?.postcode}
														</span>
														<img src={RedMarker} alt="" />
													</p>
												</div>
											</div>
										)}

										{loading && (
											<div className="mb-4">
												<Skeleton height={300} />
											</div>
										)}

										{loading && (
											<div className="mb-4">
												<div className="mb-2 w-50">
													<Skeleton height={25} />
												</div>
												<div className="mb-1">
													<Skeleton height={50} />
												</div>
											</div>
										)}

										<PreviewGoogleMap
											isMarkerShown
											zoom={3}
											positions={[
												getMapPoint({ ...order?.from_location, icon: GreenMarker }),
												getMapPoint({ ...order?.to_location, icon: RedMarker }),
											]}
											loadingElement={<div style={{ height: `20rem` }} />}
											containerElement={<div style={{ height: `20rem` }} />}
											mapElement={<div style={{ height: `100%` }} />}
										/>
										<hr />
										<div className="pt-2 pb-2 bg-white mb-3">
											<h5 className="font-weight-bold ml-3 d-flex align-items-center justify-content-between">
												<span>{t("payments")}</span>
												{(order?.status === "fullypaid" || order?.status === "completed") && <Invoice order={order} />}
											</h5>
											{order?.payments?.map((p, i) => (
												<div
													key={i}
													className={`row bg-light m-0 p-1 ${p.status !== "cancelled" ? "text-green" : "text-muted"}`}
												>
													<small className="col">{dt_tm(p.updated_at)}</small>
													<div className="col">{p.advance ? t("advance_amount") : t("remaining_amount")}</div>
													<div className="col text-right">
														<NumberFormat
															value={parseFloat(p.amount).toFixed(2)}
															displayType={"text"}
															thousandSeparator={true}
															prefix={"$ "}
														/>
													</div>
													<div className="col">{t(p.status)}</div>
												</div>
											))}
										</div>
										<hr />
										<div className="pt-2 pb-2 bg-white mb-3">
											<h5 className="font-weight-bold ml-3 d-flex align-items-center">
												{t(order?.logistics_info)}
												{((order?.logistics_info === "seller_will_handle_logistics" &&
													order?.seller?.id === user?.id) ||
													(order?.logistics_info === "buyer_will_handle_logistics" &&
														order?.buyer?.id === user?.id) ||
													(order?.logistics_info === "third_party_will_handle_logistics" && inAdmin)) &&
													order?.active &&
													order?.status === "readyfordelivery" && (
														<div
															className={`btn btn-sm btn-outline-primary ${
																general_language === "ar_eg" ? "mr-auto " : "ml-auto mr-3"
															}`}
															onClick={() => setShowLog(true)}
														>
															<span>{t("add")}</span>
														</div>
													)}
											</h5>
											{order?.logistics?.map((l, i) => (
												<div
													key={i}
													className={`row bg-light m-2 p-2 ${l.status !== "cancelled" ? "text-green" : "text-muted"}`}
												>
													<div className="col-6">{dt_tm(l.updated_at)}</div>
													<div className="col-6">{t(l.status)}</div>
													<div className="col-6">
														{t("from_date")} : {dt(l.pickup_date)}
													</div>
													<div className="col-6">
														{t("to_date")} : {dt(l.delivery_date)}
													</div>
													<div className="col-12"></div>
													<div className="col-6">{l.contact_name}</div>
													<div className="col-6">{l.contact_phone}</div>
													<small className="col-8">{l.tracking_id}</small>
													<div className="col">
														{l?.active &&
															((order?.logistics_info === "third_party_will_handle_logistics" && inAdmin) ||
																userId === l?.user?.id) && (
																<>
																{l.status === 'open' ? (
																	<>
																	<button
																	type="button"
																	className="btn btn-sm btn-block btn-outline-primary"
																	onClick={() =>
																		Swal.fire({
																			title: t("are_you_sure?"),
																			text: t("you_wont_be_able_to_revert!"),
																			icon: "warning",
																			showCancelButton: true,
																			confirmButtonText: t("yes"),
																			cancelButtonText: t("cancel"),
																		}).then((x) => {
																			if (x.value) {
																				updateLogistics({
																					variables: {
																						id: l?.id,
																						object: {
																							status: "inprogress",
																							active: true,
																						},
																					},
																				});
																			}
																		})
																	}
																>
																	{`${t("mark_as")} " ${t("inprogress")} "`}
																</button>
																<button
																type="button"
																className="btn btn-sm btn-block btn-outline-primary"
																onClick={() => {
																	setShowLog(true);
																	setCurrentLogistic(l);
																}}
															>
																{t("edit")}
															</button>
															</>
																) : (
																	<button
																		type="button"
																		className="btn btn-sm btn-block btn-outline-primary"
																		onClick={() =>
																			Swal.fire({
																				title: t("are_you_sure?"),
																				text: t("you_wont_be_able_to_revert!"),
																				icon: "warning",
																				showCancelButton: true,
																				confirmButtonText: t("yes"),
																				cancelButtonText: t("cancel"),
																			}).then((x) => {
																				if (x.value) {
																					updateLogistics({
																						variables: {
																							id: l?.id,
																							object: {
																								status: "completed",
																								active: false,
																							},
																						},
																					});
																				}
																			})
																		}
																	>
																		{`${t("mark_as")} " ${t("completed")} "`}
																	</button>
																)}

																	
																</>
															)}
													</div>
												</div>
											))}
										</div>
									</div>
									<aside className="offer-sidebar">
										{loading && (
											<div className="mb-2 p-4 bg-white">
												<Skeleton height={500} />
											</div>
										)}
										{!loading && order && (
											<div className="bg-white mb-2 p-4 border">
												<div>
													<div className="mb-2 d-flex justify-content-between">
														<span className="text-muted mb-0">{t("offer")}</span>
														<span className="font-weight-bold mb-0">
															<Link
																to={`${PATHS.trade_view_offer}/${
																	order?.offer?.request?.id || order?.offer?.id
																}`}
															>
																{`# ${order?.offer?.request?.uid || order?.offer?.uid}`}
															</Link>
														</span>
													</div>
													<hr />
													<div className="mb-2 d-flex justify-content-between">
														<span className="text-muted mb-0">{t("product")}</span>
														<span className="font-weight-bold mb-0">
															{t(order?.product_type?.product?.id, order?.product_type?.product?.name)}
														</span>
													</div>
													<div className="mb-2 d-flex justify-content-between">
														<span className="text-muted mb-0">{t("variety")}</span>
														<span className="font-weight-bold mb-0">
															{t(order?.product_type?.id, order?.product_type?.name)}
														</span>
													</div>
													<hr />
													<div className="mb-2 d-flex justify-content-between">
														<span className="text-muted mb-0">{t("unit_price_$")}</span>
														<span className="font-weight-bold mb-0">
															<NumberFormat
																value={parseFloat(order?.price_per_unit).toFixed(2)}
																displayType={"text"}
																thousandSeparator={true}
																prefix={"$ "}
															/>
														</span>
													</div>
													<div className="mb-2 d-flex justify-content-between">
														<span className="text-muted mb-0">{t("quantity_tonnes")}</span>
														<span className="font-weight-bold mb-0">
															<NumberFormat
																value={order?.number_of_units}
																displayType={"text"}
																thousandSeparator={true}
															/>
														</span>
													</div>
													<div className="mb-2 d-flex justify-content-between">
														<span className="text-muted mb-0">{`${t("advance")} %`}</span>
														<span className="font-weight-bold mb-0">
															<NumberFormat
																value={parseInt(order?.advance_payment_percentage)}
																displayType={"text"}
																thousandSeparator={true}
																suffix={" %"}
															/>
														</span>
													</div>
													<hr />
													<div className="mb-2 d-flex justify-content-between">
														<span className="text-muted mb-0">{t("total_amount")}</span>
														<span className="font-weight-bold mb-0">
															<NumberFormat
																value={parseFloat(order?.number_of_units * order?.price_per_unit).toFixed(2)}
																displayType={"text"}
																thousandSeparator={true}
																prefix={"$ "}
															/>
														</span>
													</div>
													<div className="mb-2 d-flex justify-content-between">
														<span className="text-muted mb-0">{t("advance_amount")}</span>
														<span className="font-weight-bold mb-0">
															<NumberFormat
																value={parseFloat(
																	(order?.advance_payment_percentage *
																		order?.number_of_units *
																		order?.price_per_unit) /
																		100
																).toFixed(2)}
																displayType={"text"}
																thousandSeparator={true}
																prefix={"$ "}
															/>
														</span>
													</div>
													<div className="mb-2 d-flex justify-content-between">
														<span className="text-muted mb-0">{t("remaining_amount")}</span>
														<span className="font-weight-bold mb-0">
															<NumberFormat
																value={parseFloat(
																	order?.number_of_units * order?.price_per_unit -
																		(order?.advance_payment_percentage *
																			order?.number_of_units *
																			order?.price_per_unit) /
																			100
																).toFixed(2)}
																displayType={"text"}
																thousandSeparator={true}
																prefix={"$ "}
															/>
														</span>
													</div>
													<hr />
													<div className="mb-3 d-flex justify-content-between">
														<span className="text-muted mb-0"> {t("dates")}</span>
														<span className="font-weight-bold mb-0">
															{dt(`${order?.from_date}`)}
															{" - "}
															{dt(`${order?.to_date}`)}
														</span>
													</div>

													<hr />
													<div className="mb-3 rtl-text-right">
														<p className="text-muted mb-0">{t("origin")}</p>
														{user?.id === order?.seller?.id && (
															<p className="font-weight-bold mb-0">{order?.from_location?.name}</p>
														)}
														<p className="font-weight-bold mb-0">
															{order?.from_location?.address_line_1}, {order?.from_location?.city},{" "}
															{order?.from_location?.state}, {order?.from_location?.country}{" "}
															{order?.from_location?.postcode}
														</p>
													</div>
													<hr />
													<div className="mb-3 rtl-text-right">
														<p className="text-muted mb-0">{t("destination")}</p>
														{user?.id === order?.buyer?.id && (
															<p className="font-weight-bold mb-0">{order?.to_location?.name}</p>
														)}
														<p className="font-weight-bold mb-0">
															{order?.to_location?.address_line_1}, {order?.to_location?.city},{" "}
															{order?.to_location?.state}, {order?.to_location?.country}{" "}
															{order?.to_location?.postcode}
														</p>
													</div>
													<hr />
													{!component && !inAdmin && (
														<>
															{order?.active && (
																<div className=" mb-3 bg-white rtl-text-right">
																	<p className="text-muted mb-0">{t("notes")}</p>
																	<TextArea
																		value={note}
																		name="note"
																		onChange={(e) => setNote(e.target.value)}
																		className="w-100 form-control"
																	/>
																	<div className="d-flex justify-content-end">
																		<div className="btn btn-sm btn-primary mt-2" onClick={onAddNote}>
																			<span>{t("add_note")}</span>
																		</div>
																	</div>
																</div>
															)}
															<div className="mb-3 rtl-text-right">
																<Steps direction="vertical" progressDot={true}>
																	{order?.notes.map((item, index) => (
																		<Step
																			className="custom-step"
																			key={item.text}
																			title={item.text}
																			description={
																				<small>{`${item.user.name} - ${dt_tm(item.created_at)}`}</small>
																			}
																			status={order?.notes.length - 1 === index ? "process" : "finish"}
																		/>
																	))}
																</Steps>
															</div>
														</>
													)}
												</div>
											</div>
										)}
									</aside>
								</div>
							</div>
						</div>
					) : (
						<Skelton height="100vh" />
					)}
				</Wrapper>
			</div>
		</>
	);
};

export default ViewTradeOrder;
