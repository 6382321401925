export const PATHS = {
	base: "/",
	//
	about: "/about-us",
	forgrowers: "/forgrowers",
	investors: "/investors",
	careers: "/careers",
	terms: "/terms",
	faq: "/faq",
	disclaimer: "/disclaimer",
	privacy: "/privacy",
	helpdesk: "/help-desk",
	partners: "/partners",
	termsOfService: "/terms-of-service",
	privacyPolicy: "/privacy-policy",

	//
	products: "/products",
	offerDetails: "/offer-details",

	forserviceproviders: "/forserviceproviders",

	support: "/support",
	signin: "/auth/signin",
	register: "/auth/register",
	authorize: "/authorize",
	confirmAuth: "/confirm-auth",
	resetPassword: "/auth/reset-password",
	defaultFarmSetup: "/registration/default-farm-setup",
	defaultDeliverySetup: "/registration/default-delivery-setup",
	manageOrder: "/transactions/trade-orders",
	manageServiceOrders: "/transactions/service-orders",
	createServiceOrder: "/transactions/create-service-order",
	createTradeOrder: "/transactions/create-trade-order",
	createListing: "/transactions/create-listing",
	serviceOrders: "/transactions/service-orders",
	serviceListings: "/transactions/service-listings",
	matchingConnections: "/transactions/matching-connections",
	watchlist: "/transactions/watchlist",
	account: "/account",
	settings: "/settings",
	settingsProfile: "/settings/",
	settingsSecurity: "/settings/security",
	settingsNotifications: "/settings/notifications",
	posts: "/posts",
	post: "/post",

	dashboard: "/dashboard",

	locations: "/locations",
	marketplace: "/marketplace",
	trade_offers: "/trade/offers",

	trade_new_offer: "/trade/offer/new",
	trade_edit_offer: "/trade/offer/edit",
	trade_view_offer: "/trade/offer/view",

	trade_active_offers: "/trade/offers/active",
	trade_attention_offers: "/trade/offers/attention",
	trade_submitted_bids: "/trade/offers/submitted-bids",
	trade_matching_connections: "/trade/offers/matches",
	trade_offer_history: "/trade/offers/history",
	trade_bid_history: "/trade/offers/bid-history",

	trade_orders: "/trade/orders",

	trade_new_order: "/trade/order/new",
	trade_view_order: "/trade/order/view",

	trade_active_orders: "/trade/orders/active",
	trade_attention_orders: "/trade/orders/attention",
	trade_order_history: "/trade/orders/history",

	services: "/services",
	service_offers: "/service/offers",

	service_new_offer: "/service/offer/new",
	service_edit_offer: "/service/offer/edit",
	service_view_offer: "/service/offer/view",

	service_active_offers: "/service/offers/active",
	service_matching_connections: "/service/offers/matches",
	service_offer_history: "/service/offers/history",

	service_orders: "/service/orders",

	service_new_order: "/service/order/new",
	service_view_order: "/service/order/view",

	service_active_orders: "/service/orders/active",
	service_attention_orders: "/service/orders/attention",
	service_order_history: "/service/orders/history",
};
