import { gql, useSubscription } from '@apollo/client';
import { useEffect, useState } from 'react';

export const useAggregateSubscription = (
  entity = 'products',
  where = {},
  field = 'uid',
  type = 'sum'
) => {
  const [aggregate, setAggregate] = useState(0);
  const { data, error, loading } = useSubscription(
    gql(
      `subscription ${entity}_aggregate($where: ${entity}_bool_exp) { totals: ${entity}_aggregate(where: $where) { aggregate { ${type} { ${field} } } } }`
    ),
    { variables: { where } }
  );

  useEffect(() => {
    if (!loading && !error && data?.totals?.aggregate?.[type]?.[field])
      setAggregate(data?.totals?.aggregate?.[type]?.[field]);
  }, [data, error, field, loading, type]);

  return aggregate;
};
