const getAddress = (formattedAddress, city) => {
	return formattedAddress;
};

const getCity = (addressArray) => {
	let city = "";
	for (let i = 0; i < addressArray.length; i++) {
		if (addressArray[i].types[0] && "administrative_area_level_2" === addressArray[i].types[0]) {
			city = addressArray[i].long_name;
		}
	}
	return city;
};

const getState = (addressArray) => {
	let state = "";
	for (let i = 0; i < addressArray.length; i++) {
		if (addressArray[i].types[0] && "administrative_area_level_1" === addressArray[i].types[0]) {
			state = addressArray[i].long_name;
		}
	}
	return state;
};

const getCountry = (addressArray) => {
	let country = "";
	for (let i = 0; i < addressArray.length; i++) {
		if (addressArray[i].types[0] && "country" === addressArray[i].types[0]) {
			country = addressArray[i].long_name;
		}
	}
	return country;
};

const getPostalCode = (addressArray) => {
	let postcode = "";
	for (let i = 0; i < addressArray.length; i++) {
		if (addressArray[i].types[0] && "postal_code" === addressArray[i].types[0]) {
			postcode = addressArray[i].long_name;
		}
	}
	return postcode;
};

export { getAddress, getCity, getState, getCountry, getPostalCode };
