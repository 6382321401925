import React from "react";
import Header from "./header";
import Sidebar from "./sidebar";
// import Footer from "./footer";
export const Layout = ({ children, title, activeCount = 0, totalCount = 0, activeAmount = 0, totalAmount = 0 }) => {
	document.body.style = "";
	return (
		<>
			<div id="main-wrapper" style={{ marginTop: 0 }}>
				<Sidebar />
				<div className="admin-container">
					<Header
						title={title}
						activeCount={activeCount}
						totalCount={totalCount}
						activeAmount={activeAmount}
						totalAmount={totalAmount}
					/>
					<div className="main-content">{children}</div>
				</div>
			</div>
		</>
	);
};
