import React, { useContext, useEffect, useState } from "react";
import { Sugar } from "react-preloaders";
import { Redirect } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { useLocalStorage } from "../../../../shared/helpers";
import { PATHS, webAuth } from "../../config";
import { logout } from "../../services/auth";
import { AuthContext } from "../../store";

export const Authorization = () => {
	const { addToast } = useToasts();
	const { authenticateUser, setIdToken, is_authenticated, logoutUser } = useContext(AuthContext);
	const [redirectToReferrer, setRedirectToReferrer] = useState(false);

	const [local_id, setLocalId] = useLocalStorage("id");

	useEffect(() => {
		if (window.location.hash) {
			try {
				webAuth.parseHash({ hash: window.location.hash }, (err, result) => {
					if (err) {
						addToast(`Wrong Credentials !`, { appearance: "error" });
						authenticateUser(false);
						logoutUser();
						logout();
						return;
					}
					setIdToken(result.idToken);
					setLocalId(result.idTokenPayload.sub);
					authenticateUser(true);
					return;
				});
			} catch (error) {}

			return;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (is_authenticated && local_id && !redirectToReferrer) setRedirectToReferrer(true);
	}, [is_authenticated, local_id, redirectToReferrer]);

	if (redirectToReferrer) return <Redirect to={PATHS.confirmAuth} />;

	return <Sugar color={"#ccd92b"} time={0} customLoading={true} />;
};
