import React from "react";

export const PageTitle = ({ title, rightElement, showLogo }) => {
	return (
		<>
			<div className="page-title dashboard">
				<div className="container-fluid">
					<div className="row">
						<div className="col">
							<div className="page-title-content">
								<p>{title}</p>
							</div>
						</div>
						{rightElement ? <div className="col-2">{rightElement}</div> : null}
						{showLogo ? (
							<div className="col-2">
								<img
									src="https://res.cloudinary.com/ampcome/image/upload/v1595015037/mahaseel/flat_logo.jpg"
									style={{ height: "50px" }}
									alt=""
								/>
							</div>
						) : null}
					</div>
				</div>
			</div>
		</>
	);
};
