import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache, split, useSubscription } from "@apollo/client";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";
import React, { useContext, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { ScrollIntoView } from "../../shared/components";
import { useLocalStorage } from "../../shared/helpers";
import { APP_CONFIG } from "./../../shared/app_config";
import { ProtectedRoute } from "./components";
import { PATHS } from "./config";
import {
	GET_GENERAL_CERTIFICATIONS,
	GET_GENERAL_COUNTRIES,
	GET_GENERAL_CURRENCIES,
	GET_GENERAL_LABELS,
	GET_GENERAL_LANGUAGES,
	GET_GENERAL_LOGISTICS,
	GET_GENERAL_ORDER_STATUS,
	GET_GENERAL_PRODUCTS,
	GET_GENERAL_PRODUCT_TYPES,
	GET_GENERAL_PRODUCT_UNTIS,
	GET_GENERAL_REQUEST_STATUS,
	GET_GENERAL_REQUEST_TYPES,
	GET_GENERAL_SERVICES,
	GET_GENERAL_SERVICE_TYPES,
	GET_GENERAL_SETTINGS,
	GET_GENERAL_TRANSLATIONS,
	GET_GENERAL_USER_ROLES,
	GET_STAFF_BY_ID,
} from "./gql";
import {
	Account,
	Authorization,
	AuthRedirect,
	BuyOffers,
	CreateSuperAdmin,
	Dashboard,
	Farms,
	InviteStaff,
	Invoices,
	LocationDetails,
	Locations,
	Logistics,
	LogisticsDetails,
	MasterData,
	MasterDataDetails,
	OfferDetails,
	Offers,
	Offices,
	OrderDetails,
	Orders,
	PaymentDetails,
	Payments,
	RequestOTP,
	SellOffers,
	ServiceOfferDetails,
	ServiceOffers,
	ServiceOrderDetails,
	ServiceOrders,
	Settings,
	Signin,
	Staff,
	StaffDetails,
	UserDetails,
	Users,
	VerifyOTP,
	Welcome,
} from "./pages";
import { AuthContext, GeneralContext } from "./store";

const createApolloClient = ({ authToken, role }) => {
	let headers = {
		"Content-Type": "application/json",
		"X-Hasura-Role": role || "anonymous",
	};
	if (authToken) headers.Authorization = `Bearer ${authToken}`;

	return new ApolloClient({
		cache: new InMemoryCache(),
		link: split(
			({ query }) => {
				const definition = getMainDefinition(query);
				return definition.kind === "OperationDefinition" && definition.operation === "subscription";
			},
			new WebSocketLink({
				uri: APP_CONFIG.REACT_APP_HASURA_URL_WS,
				options: {
					lazy: true,
					reconnect: true,
					connectionParams: async () => {
						return { headers };
					},
				},
			}),
			new HttpLink({
				uri: APP_CONFIG.REACT_APP_HASURA_URL,
				headers: headers,
			})
		),
	});
};

const AppBase = () => {
	const {
		setGeneralProducts,
		setGeneralProductTypes,
		setGeneralServices,
		setGeneralServiceTypes,
		setGeneralCertifications,
		setGeneralCurrencies,
		setGeneralLanguages,
		setGeneralOrderStatus,
		setGeneralProductUnits,
		setGeneralRequestStatus,
		setGeneralCountries,
		setGeneralSettings,
		setGeneralRoles,
		setGeneralTranslations,
		setGeneralRequestTypes,
		setGeneralLanguage,
		setGeneralLogistics,
		general_language,
	} = useContext(GeneralContext);
	const [local_language] = useLocalStorage("language");
	const { is_authenticated, authenticateUser, setUserData, setUserRole } = useContext(AuthContext);
	const [local_email] = useLocalStorage("email");

	const { loading, error, data } = useSubscription(GET_STAFF_BY_ID, {
		variables: { email: local_email },
	});
	useEffect(() => {
		if (local_language && local_language !== general_language) setGeneralLanguage(local_language);
		else if (data?.object?.language && data?.object?.language !== general_language) setGeneralLanguage(data?.object?.language);
		else if (!local_language) setGeneralLanguage(general_language);

		if (!loading && !error && data?.object && !is_authenticated) {
			authenticateUser(true);
			setUserData(data?.object);

			if (data?.object?.moderator_access) setUserRole("moderator");
			else setUserRole("agent");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loading, error, data]);

	const { data: data_translations } = useSubscription(GET_GENERAL_TRANSLATIONS, { variables: { language: general_language } });
	const { data: data_labels } = useSubscription(GET_GENERAL_LABELS);
	const { data: data_products } = useSubscription(GET_GENERAL_PRODUCTS);
	const { data: data_services } = useSubscription(GET_GENERAL_SERVICES);
	const { data: data_product_types } = useSubscription(GET_GENERAL_PRODUCT_TYPES);
	const { data: data_service_types } = useSubscription(GET_GENERAL_SERVICE_TYPES);
	const { data: data_certifications } = useSubscription(GET_GENERAL_CERTIFICATIONS);
	const { data: data_countries } = useSubscription(GET_GENERAL_COUNTRIES);
	const { data: data_logistics } = useSubscription(GET_GENERAL_LOGISTICS);
	const { data: data_currencies } = useSubscription(GET_GENERAL_CURRENCIES);
	const { data: data_languages } = useSubscription(GET_GENERAL_LANGUAGES);
	const { data: data_order_status } = useSubscription(GET_GENERAL_ORDER_STATUS);
	const { data: data_product_units } = useSubscription(GET_GENERAL_PRODUCT_UNTIS);
	const { data: data_request_status } = useSubscription(GET_GENERAL_REQUEST_STATUS);
	const { data: data_request_types } = useSubscription(GET_GENERAL_REQUEST_TYPES);
	const { data: data_user_roles } = useSubscription(GET_GENERAL_USER_ROLES);
	const { data: data_settings } = useSubscription(GET_GENERAL_SETTINGS);

	// SET STARTUP DATA
	useEffect(() => {
		if (data_translations?.translations?.length > 0 && data_labels?.labels?.length > 0)
			setGeneralTranslations(data_translations?.translations, data_labels?.labels, general_language);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data_labels, data_translations]);
	useEffect(() => {
		if (data_products?.products?.length > 0) setGeneralProducts(data_products?.products);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data_products]);
	useEffect(() => {
		if (data_product_types?.product_types?.length > 0)
			setGeneralProductTypes(data_product_types?.product_types.map((e) => ({ ...e, product_name: e.product.name })));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data_product_types]);
	useEffect(() => {
		if (data_services?.services?.length > 0) setGeneralServices(data_services?.services);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data_services]);
	useEffect(() => {
		if (data_service_types?.service_types?.length > 0)
			setGeneralServiceTypes(data_service_types?.service_types.map((e) => ({ ...e, service_name: e.service.name })));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data_service_types]);
	useEffect(() => {
		if (data_certifications?.certifications?.length > 0) setGeneralCertifications(data_certifications?.certifications);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data_certifications]);
	useEffect(() => {
		if (data_currencies?.enum_currencies?.length > 0) setGeneralCurrencies(data_currencies?.enum_currencies);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data_currencies]);
	useEffect(() => {
		if (data_languages?.enum_languages?.length > 0) setGeneralLanguages(data_languages?.enum_languages);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data_languages]);
	useEffect(() => {
		if (data_order_status?.enum_order_status?.length > 0) setGeneralOrderStatus(data_order_status?.enum_order_status);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data_order_status]);
	useEffect(() => {
		if (data_product_units?.enum_product_units?.length > 0) setGeneralProductUnits(data_product_units?.enum_product_units);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data_product_units]);
	useEffect(() => {
		if (data_request_types?.enum_request_types?.length > 0) setGeneralRequestTypes(data_request_types?.enum_request_types);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data_request_types]);
	useEffect(() => {
		if (data_request_status?.enum_request_status?.length > 0) setGeneralRequestStatus(data_request_status?.enum_request_status);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data_request_status]);
	useEffect(() => {
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data_countries]);
	useEffect(() => {
		if (data_settings?.settings?.length > 0) setGeneralSettings(data_settings?.settings);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data_settings]);
	useEffect(() => {
		if (data_user_roles?.enum_user_roles?.length > 0) setGeneralRoles(data_user_roles?.enum_user_roles);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data_user_roles]);
	useEffect(() => {
		if (data_logistics?.enum_logistics?.length > 0) setGeneralLogistics(data_logistics?.enum_logistics);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data_logistics]);

	useEffect(() => {
		if (data_countries?.enum_countries?.length > 0) setGeneralCountries(data_countries?.enum_countries);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data_countries]);

	return (
		<>
			<Router basename={"/"}>
				<Route
					render={({ location }) => (
						<TransitionGroup>
							<CSSTransition
								key={location.pathname.split("/")[1] || "/"}
								timeout={{ enter: 300, exit: 150 }}
								classNames="fade"
								appear
							>
								<ScrollIntoView>
									<Switch location={location}>
										<Route exact path={PATHS.signin} component={Signin} />
										<Route exact path={PATHS.confirmAuth} component={AuthRedirect} />
										<Route path={PATHS.authorize} component={Authorization} />
										<Route exact path={PATHS.base} component={Signin} />
										<ProtectedRoute exact path={PATHS.requestOtp} component={RequestOTP} />
										<ProtectedRoute exact path={PATHS.verifyOtp} component={VerifyOTP} />
										<ProtectedRoute exact path={PATHS.dashboard} component={Dashboard} />
										<ProtectedRoute exact path={PATHS.account} component={Account} />

										<ProtectedRoute exact={true} path={PATHS.orders} component={Orders} />
										<ProtectedRoute exact={true} path={PATHS.offers} component={Offers} />
										<ProtectedRoute exact={true} path={PATHS.buyOffers} component={BuyOffers} />
										<ProtectedRoute exact={true} path={PATHS.sellOffers} component={SellOffers} />
										<ProtectedRoute exact={true} path={PATHS.serviceOrders} component={ServiceOrders} />
										<ProtectedRoute exact={true} path={PATHS.serviceOffers} component={ServiceOffers} />
										<ProtectedRoute exact={true} path={PATHS.locations} component={Locations} />
										<ProtectedRoute exact={true} path={PATHS.offices} component={Offices} />
										<ProtectedRoute exact={true} path={PATHS.farms} component={Farms} />
										<ProtectedRoute exact={true} path={PATHS.payments} component={Payments} />
										<ProtectedRoute exact={true} path={PATHS.invoices} component={Invoices} />
										<ProtectedRoute exact={true} path={PATHS.logistics} component={Logistics} />

										<ProtectedRoute exact={false} path={PATHS.orderDetails} component={OrderDetails} />
										<ProtectedRoute exact={false} path={PATHS.offerDetails} component={OfferDetails} />
										<ProtectedRoute exact={false} path={PATHS.locationDetails} component={LocationDetails} />
										<ProtectedRoute exact={false} path={PATHS.farmDetails} component={LocationDetails} />
										<ProtectedRoute exact={false} path={PATHS.officeDetails} component={LocationDetails} />
										<ProtectedRoute exact={false} path={PATHS.productDetails} component={MasterDataDetails} />
										<ProtectedRoute exact={false} path={PATHS.productTypeDetails} component={MasterDataDetails} />
										<ProtectedRoute exact={false} path={PATHS.serviceDetails} component={MasterDataDetails} />
										<ProtectedRoute exact={false} path={PATHS.serviceTypeDetails} component={MasterDataDetails} />
										<ProtectedRoute exact={false} path={PATHS.certificationDetails} component={MasterDataDetails} />

										<Route exact={false} path={PATHS.serviceOfferDetails} component={ServiceOfferDetails} />
										<Route exact={false} path={PATHS.serviceOrderDetails} component={ServiceOrderDetails} />
										<Route exact={false} path={PATHS.paymentDetails} component={PaymentDetails} />
										{/* <Route exact={false} path={PATHS.invoiceDetails} component={InvoiceDetails} /> */}
										<Route exact={false} path={PATHS.logisticDetails} component={LogisticsDetails} />

										<ProtectedRoute exact={false} path={PATHS.settings} component={Settings} />
										<ProtectedRoute exact={false} path={PATHS.master} component={MasterData} />
										{/* <ProtectedRoute exact={true} path={PATHS.users} component={Users} /> */}
										<ProtectedRoute exact={true} path={PATHS.growers} component={Users} role="grower" />
										<ProtectedRoute exact={true} path={PATHS.buyers} component={Users} role="buyer" />
										<ProtectedRoute exact={true} path={PATHS.providers} component={Users} role="provider" />
										<ProtectedRoute exact={false} path={PATHS.userDetails} component={UserDetails} />
										<ProtectedRoute exact={true} path={PATHS.staff} component={Staff} />
										<ProtectedRoute exact={false} path={PATHS.staffDetails} component={StaffDetails} />
										<Route exact={true} path={PATHS.setupWelcome} component={Welcome} />
										<Route exact={true} path={PATHS.setupCreateSuperAdmin} component={CreateSuperAdmin} />
										<Route exact={true} path={PATHS.setupInviteStaff} component={InviteStaff} />
									</Switch>
								</ScrollIntoView>
							</CSSTransition>
						</TransitionGroup>
					)}
				></Route>
			</Router>
		</>
	);
};

const AdminRoot = () => {
	const { id_token: authToken, user_role: role } = useContext(AuthContext);
	const client = createApolloClient({ authToken, role });

	return (
		<>
			<ApolloProvider client={client}>
				<AppBase />
			</ApolloProvider>
		</>
	);
};

export default AdminRoot;
