import React from "react";
import { Container, Spinner } from "react-bootstrap";

export const Loader = () => {
  return (
    <Container style={{ height: "100vh" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Spinner animation="grow" />
      </div>
    </Container>
  );
};
