import { Document, Image, Page, StyleSheet } from "@react-pdf/renderer";
import React from "react";
import { APP_CONFIG } from "../../../../../shared/app_config";
import { useTranslatedLabel } from "../../../../../shared/hooks";
import BillTo from "./BillTo";
import InvoiceItemsTable from "./InvoiceItemsTable";
import InvoiceNo from "./InvoiceNo";
import InvoiceThankYouMsg from "./InvoiceThankYouMsg";
import InvoiceTitle from "./InvoiceTitle";
// import logo from "../../../src/logo.png";

const styles = StyleSheet.create({
	page: {
		fontFamily: "Helvetica",
		fontSize: 11,
		paddingTop: 30,
		paddingLeft: 60,
		paddingRight: 60,
		lineHeight: 1.5,
		flexDirection: "column",
	},
	logo: {
		width: 100,
		marginLeft: "auto",
		marginRight: "auto",
	},
});

const Invoice = ({ order, payment, service }) => {
	const { t, dt } = useTranslatedLabel();
	const invoice = {
		id: order.id,
		invoice_no: order.uid,

		advance_paid: order?.advance_amount,
		advance_paid_at: order?.advancepaid_at,
		remaning_paid: order?.total_amount - order?.advance_amount,
		remaining_paid_at: order?.fullypaid_at,
		total_paid: order?.total_amount,
		total_paid_at: order?.fullypaid_at,

		buyer_company: order?.buyer?.name,
		buyer_email: order?.buyer?.email,
		buyer_phone: order?.buyer?.phone_number,
		buyer_address: `${order?.to_location?.address_line_1}, ${order?.to_location?.city}, ${order?.to_location?.state}, ${order?.to_location?.country}, ${order?.to_location?.postcode}`,

		seller_company: order?.seller?.name,
		seller_email: order?.seller?.email,
		seller_phone: order?.seller?.phone_number,
		seller_address: `${order?.from_location?.address_line_1}, ${order?.from_location?.city}, ${order?.from_location?.state}, ${order?.from_location?.country}, ${order?.from_location?.postcode}`,

		trans_date: dt(order?.fullypaid_at),
		items: [
			{
				sno: 1,
				desc: `${service ? order?.service_type?.service?.name : order?.product_type?.product?.name} - ${
					service ? order?.service_type?.name : order?.product_type?.name
				}`,
				qty: order?.number_of_units,
				rate: order?.price_per_unit,
			},
		],
	};
	return (
		<Document>
			<Page size="A4" style={styles.page}>
				<Image style={styles.logo} src={APP_CONFIG.REACT_APP_LOGO_URL} />
				<InvoiceTitle title={t("invoice")} service={service} />
				<InvoiceNo invoice={invoice} service={service} />
				<BillTo invoice={invoice} service={service} />
				<InvoiceItemsTable invoice={invoice} service={service} />
				<InvoiceThankYouMsg service={service} />
			</Page>
		</Document>
	);
};

export default Invoice;
