import React from "react";
import { Link } from "react-router-dom";

import { Icon } from "../../../../shared/components";

export const CompletionMessage = ({
  appearance,
  heading,
  subheading,
  links,
  ...rest
}) => (
  <>
    <div className="bg-white completion-message">
      <div
        className="d-flex justify-content-center align-items-center py-5"
        {...rest}
      >
        {appearance === "success" && (
          <Icon
            type="tick"
            style={{
              width: "4rem",
              height: "4rem",
              fill: "currentColor",
            }}
            className="text-success mr-5"
          />
        )}
        {appearance === "warning" && (
          <Icon
            type="alert"
            style={{
              width: "4rem",
              height: "4rem",
              fill: "currentColor",
            }}
            className="text-warning mr-5"
          />
        )}
        <div>
          <p className={`text-${appearance} mb-0 text-heading`}>{heading}</p>
          {subheading && <p>{subheading}</p>}

          <div className="mt-4 d-flex justify-content-between">
            {links.map((link, index) => (
              <Link
                key={index}
                to={link.path}
                className="text-black font-weight-bold"
              >
                {link.text}
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  </>
);
