import { useMutation } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { DateRange } from "react-date-range";
import * as locales from "react-date-range/dist/locale";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { useTranslatedLabel } from "../../../../shared/hooks";
import NumberFormat from "react-number-format";
import { INSERT_ONE, UPDATE_ONE } from "../../gql";
import { GeneralContext } from "../../store";

export const LogisticModal = ({ show, onClose, order, current = null }) => {
  const { t, dt_db } = useTranslatedLabel();
  const { general_language } = useContext(GeneralContext);
  // const { user } = useContext(AuthContext);
  const start_date = new Date();
  start_date.setDate(start_date.getDate() + 1);
  const end_date = new Date();
  end_date.setDate(end_date.getDate() + 7);
  const baseDates = {
    startDate: new Date(start_date.getTime()),
    endDate: new Date(end_date.getTime()),
    key: "selection",
  };
  const [dates, setDates] = useState(baseDates);

  const baseState = {
    contact_name: "",
    contact_phone: "",
    tracking_id: "",
  };
  const [input, setInput] = useState(baseState);

  useEffect(() => {
    if (current) {
      const {
        pickup_date,
        delivery_date,
        contact_name,
        contact_phone,
        tracking_id,
      } = current;
      setInput({ contact_name, contact_phone, tracking_id });
      setDates({
        startDate: new Date(pickup_date),
        endDate: new Date(delivery_date),
        key: "selection",
      });
    }
    if (!show) {
      setInput(baseState);
      setDates(baseDates);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current, show]);

  const [insertLogistics] = useMutation(INSERT_ONE("logistics"), {
    onCompleted: (data) => {
      if (data?.object) {
        onClose(data.object);
      }
    },
    onError: (err) => {
      console.warn(err, "error");
    },
  });
  const [updateLogistics] = useMutation(UPDATE_ONE("logistics"), {
    onCompleted: (data) => {
      if (data?.object) {
        onClose(data.object);
      }
    },
    onError: (err) => {
      console.warn(err, "error");
    },
  });
  // useEffect(() => {
  // 	if (!loading && !error && data && data.object) ;
  // }, [data, error, loading, onClose]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInput((state) => ({ ...state, [name]: value }));
  };

  const handlePhone = (e) => {
    const { value } = e.target;
    if (value <= 9999999999999) {
      setInput((state) => ({ ...state, contact_phone: value }));
    } else {
      setInput((state) => ({ ...state }));
    }
  };

  return (
    <Modal
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={onClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {current ? t("edit_logistics") : t("add_logistics")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formBasicPassword">
            <Form.Label>{t("driver_name")} *</Form.Label>
            <Form.Control
              type="text"
              value={input.contact_name}
              name="contact_name"
              onChange={handleChange}
            />
            {!input.contact_name && (
              <p className="text-danger">Name is Mandatory</p>
            )}
          </Form.Group>
          <Form.Group controlId="formBasicPassword">
            <Form.Label>{t("phone")}</Form.Label>
            <NumberFormat
              allowedDecimalSeparators={false}
              min={1}
              maxLength={13}
              value={input.contact_phone}
              allowNegative={false}
              className="form-control"
              onChange={handlePhone}
            />
            {/* <Form.Control type="number" value={input.contact_phone} name="contact_phone" onChange={handlePhone} /> */}
            {!input.contact_phone ? (
              <p className="text-danger">Phone number is Mandatory</p>
            ) : (
              input?.contact_phone < 999999 && (
                <p className="text-danger">should atleast have 7 charcters</p>
              )
            )}
          </Form.Group>
          <Form.Group controlId="formBasicPassword">
            <Form.Label>{t("tracking_number")}</Form.Label>
            <Form.Control
              type="text"
              value={input.tracking_id}
              name="tracking_id"
              onChange={handleChange}
            />
            {!input.tracking_id && (
              <p className="text-danger">Tracking ID is mandatory</p>
            )}
          </Form.Group>
          <Form.Group controlId="formBasicPassword">
            <Form.Label>{t("pickup_date")}</Form.Label>
            <div className="row">
              <div className="col offset-1">
                <DateRange
                  minDate={new Date()}
                  editableDateInputs={true}
                  onChange={(item) => setDates(item.selection)}
                  moveRangeOnFirstSelection={false}
                  ranges={[dates]}
                  showSelectionPreview={true}
                  locale={locales[general_language.slice(0, 2)]}
                />
              </div>
            </div>
          </Form.Group>
          {/* <Form.Group controlId="formBasicPassword">
						<Form.Label>{t("delivery")}</Form.Label>
						<Calendar
							minDate={new Date()}
							editableDateInputs={true}
							onChange={(item) => setDeliveryDate(item)}
							moveRangeOnFirstSelection={false}
							date={delivery_date}
							showSelectionPreview={true}
							locale={locales[general_language.slice(0, 2)]}
						/>
					</Form.Group> */}

          <Button
            block
            disabled={
              !input.contact_name ||
              !input.contact_phone ||
              input?.contact_phone < 999999 ||
              !input.tracking_id
            }
            onClick={() => {
              if (current) {
                updateLogistics({
                  variables: {
                    id: current?.id,
                    object: {
                      ...input,
                      pickup_date: dt_db(dates.startDate),
                      delivery_date: dt_db(dates.endDate),
                      trade_order_id: order.id,
                      buyer_id: order.buyer.id,
                      seller_id: order.seller.id,
                      status: "open",
                    },
                  },
                });
              } else {
                insertLogistics({
                  variables: {
                    object: {
                      ...input,
                      pickup_date: dt_db(dates.startDate),
                      delivery_date: dt_db(dates.endDate),
                      trade_order_id: order.id,
                      buyer_id: order.buyer.id,
                      seller_id: order.seller.id,
                      status: "open",
                    },
                  },
                });
              }
            }}
          >
            {current ? t("update") : t("add")}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
