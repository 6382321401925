import React, { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AntdTable, MapView } from "../../../../shared/components";
import { useTranslatedLabel } from "../../../../shared/hooks";
import ViewServiceOffer from "../../../client/pages/services/view-offer";
import { useCountSubscription } from "../../../client/widgets/countSubscription";
import { Layout } from "../../components";
import { PATHS } from "../../config";
import { useAggregateSubscription } from "../../hooks";
import { TinyLineChart } from "../dashboard/tiny-line-chart";
import { Filters } from "./filters";

const data_fields = `id created_at updated_at  uid active service_type_id service_type { id name service_id service { id name } }
	price_per_unit location { name enum_country { comment } city  lat lng  id  is_farm 	user { id buyer_access grower_access provider_access } }
	pictures range_in_kms user { name email id photo_url }`;

const map_fields = `id uid active location { name city  lat lng  id  is_farm user { id buyer_access grower_access provider_access } }`;

export const ServiceOffers = ({ history, component = false, component_filters = {}, hideColumns = [] }) => {
	const { t } = useTranslatedLabel();
	const [filters, setFilters] = useState({});
	const [tab_id, setTabId] = useState("table");
	const COLUMNS = [
		{
			title: t("id"),
			key: "uid",
			render: (row) => <Link to={`${PATHS.serviceOffers}/${row.id}`}>{row?.uid}</Link>,
			sorter: AntdTable.GenericSorter("uid"),
		},
		{
			title: t("service"),
			key: "service",
			render: AntdTable.ServiceRenderer,
			sorter: AntdTable.ServiceSorter,
		},
		{
			title: "Category",
			key: "service_type",
			render: AntdTable.CategoryRenderer,
			sorter: AntdTable.CategorySorter,
		},
		{
			title: t("price_per_unit"),
			key: "price_per_unit",
			render: AntdTable.MoneyRenderer("price_per_unit"),
			sorter: AntdTable.GenericSorter("price_per_unit"),
		},
		{
			title: "Location",
			key: "location",
			render: AntdTable.LocationRenderer("location"),
			sorter: AntdTable.LocationSorter("location"),
		},
		{
			title: t("user"),
			key: "user",
			render: AntdTable.UserRenderer("user"),
			sorter: AntdTable.UserSorter("user"),
		},
		// {
		// 	title: t("created"),
		// 	key: "created_at",
		// 	render: AntdTable.DateRenderer("created_at"),
		// 	sorter: AntdTable.TextSorter("created_at"),
		// },
		// {
		// 	title: t("updated"),
		// 	key: "updated_at",
		// 	render: AntdTable.DateRenderer("updated_at"),
		// 	sorter: AntdTable.TextSorter("updated_at"),
		// },
		{
			title: t("active"),
			key: "active",
			dataIndex: "active",
			render: (text, record) => (text ? <i className="la la-check-circle la-lg text-success align-align-self-center" /> : ""),
			sorter: (a, b) => Number(a.active) - Number(b.active),
		},
		// {
		// 	title: t("viewed"),
		// 	key: "viewed",
		// 	render: (data) => data.views?.aggregate?.sum?.views || 0,
		// 	sorter: (a, b) => a?.views?.aggregate?.sum && b.views.aggregate.sum && a?.views?.aggregate?.sum.views - b.views.aggregate.sum.views,
		// },
		// {
		// 	title: t("searched"),
		// 	key: "searched",
		// 	render: (data) => data.views?.aggregate?.sum?.searches || 0,
		// 	sorter: (a, b) => a?.views?.aggregate?.sum && b.views.aggregate.sum && a?.views?.aggregate?.sum.views - b.views.aggregate.sum.views,
		// },
	];

	const content = (
		<>
			<Filters
				entity="provider_services"
				onChange={(e) => setFilters((state) => ({ ...e }))}
				fields={["service_type_id"]}
				number_fields={["amount"]}
			/>
			<Tabs activeKey={tab_id} onSelect={(e) => setTabId(e)} className="m-0 p-0">
				<Tab eventKey="table" title={t("table_view")}>
					<AntdTable
						entity="provider_services"
						columns={COLUMNS}
						where={{ ...component_filters }}
						order_by={{ uid: "desc" }}
						query_fields={data_fields}
						filters={filters}
						expandable={{
							expandedRowRender: (row) => <ViewServiceOffer component={true} inAdmin={true} offer_id={row.id} />,
							rowExpandable: (row) => !!row?.id,
						}}
					/>
				</Tab>
				<Tab eventKey="map" title={t("map_view")}>
					<MapView
						zoom={2}
						entity="provider_services"
						where={{ ...component_filters }}
						order_by={{ uid: "desc" }}
						query_fields={map_fields}
						filters={filters}
					/>
				</Tab>
				<Tab eventKey="chart" title={t("chart_view")}>
					<TinyLineChart
						filters={{ date: { _gte: filters.from_date, _lte: filters.to_date } }}
						title="Service Offers"
						entity="z_service_offers"
						userEntity="z_providers"
						userColumn="Providers"
						height={600}
						showXaxisLabels={true}
						enableTooltip={true}
					/>
				</Tab>
			</Tabs>
		</>
	);

	const active_count = useCountSubscription("provider_services", { active: { _eq: true } });
	const active_amount = useAggregateSubscription("provider_services", { active: { _eq: true } }, "price_per_unit");
	const total_count = useCountSubscription("provider_services", {});
	const total_amount = useAggregateSubscription("provider_services", "price_per_unit");

	return (
		<>
			{component ? (
				content
			) : (
				<Layout
					title={t("service_offers")}
					activeCount={active_count}
					activeAmount={active_amount}
					totalCount={total_count}
					totalAmount={total_amount}
				>
					<div>{content}</div>
				</Layout>
			)}
		</>
	);
};
