import React from "react";

export class DatePickerChild extends React.Component {
	// eslint-disable-next-line no-useless-constructor
	constructor(props) {
		super(props);
	}

	render() {
		const { value, onClick } = this.props;
		return (
			<button type="button" className="btn btn-light w-100 p-2" onClick={onClick}>
				<div className="d-flex align-items-center justify-content-between">
					<span>{value ? value : "dd/mm/yyyy"}</span>
					<img className="ml-2" style={{ width: "0.8rem" }} src={require("../../../images/calendar.png")} alt="calendar" />
				</div>
			</button>
		);
	}
}
