import React, { useContext, useState } from "react";
import { Badge } from "react-bootstrap";
import { Link, Redirect } from "react-router-dom";
import defaultServiceImage from "../../../../images/default-service-image.png";
import { Icon } from "../../../../shared/components";
import { useTranslatedLabel } from "../../../../shared/hooks";
import { PATHS } from "../../config";
import { AuthContext } from "../../store";

export const ServiceOfferListItem = ({
	data: { uid, id, location, user, price_per_unit, service_id, service_type_id, service, service_type, range_in_kms, images },
	onClick,
	isAccountView,
}) => {
	const { t } = useTranslatedLabel();
	const [newpath, setNewPath] = useState("");
	const { is_authenticated, user: current_user } = useContext(AuthContext);

	return (
		<>
			{newpath && <Redirect to={newpath} />}
			<div className={isAccountView ? "col-md-6 col-sm-6 col-lg-6 m-0 p-2" : "col-md-4 col-sm-6 col-lg-4 m-0 p-2"}>
				<div className="offer-item border p-2">
					<Link to={`${PATHS.service_view_offer}/${id}`} onClick={onClick}>
						<div
							className="offer-image mb-1"
							style={
								images && images.length > 0
									? { backgroundImage: `url(${images[0]})` }
									: { backgroundImage: `url(${defaultServiceImage})` }
							}
						>
							<p className="px-2 font-weight-bold mb-0">
								<Badge variant="danger">
									{t("offer")} # {uid}
								</Badge>
								<Badge variant="primary" className="float-right m-1 p-1">
									<span className="h5 text-white">$ {price_per_unit}</span>
								</Badge>
							</p>
						</div>
					</Link>
					<div className="px-2">
						<h5 className="offer-name mb-1 text-primary">
							<Icon
								type="package"
								style={{
									width: "0.8rem",
									height: "0.8rem",
									fill: "currentColor",
								}}
								className="mr-2"
							/>
							<span>
								{t(service_id, service)}, {t(service_type_id, service_type)}
							</span>
						</h5>
						{is_authenticated && user && !isAccountView && (
							<p className="offer-duration d-flex align-items-center text-dark mb-0">
								<Icon
									type="user"
									style={{
										width: "0.8rem",
										height: "0.8rem",
										fill: "currentColor",
									}}
									className="mr-2"
								/>
								<Link to={`${PATHS.account}/${user?.id}`}>{user?.name}</Link>
								<span className="float-right">
									{is_authenticated && current_user?.id === user.id && (
										<i
											className="la la-edit la-lg ml-2 btn text-primary float-right m-0 p-0"
											onClick={() => setNewPath(`${PATHS.service_edit_offer}/${id}`)}
										/>
									)}
								</span>
							</p>
						)}
						<p className="offer-location d-flex align-items-center mb-0 text-dark">
							<Icon
								type="location"
								style={{
									width: "0.8rem",
									height: "0.8rem",
									fill: "currentColor",
								}}
								className="mr-2"
							/>
							<span>
								{location.city}, {location.country}
							</span>
						</p>
						{/* <p className="offer-duration d-flex align-items-center text-dark mb-0">
							<Icon
								type="paperclip"
								style={{
									width: "0.8rem",
									height: "0.8rem",
									fill: "currentColor",
								}}
								className="mr-2"
							/>
							<span>{range_in_kms} Kms</span>
						</p> */}
					</div>
				</div>
			</div>
		</>
	);
};
