import React, { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AntdTable, MapView } from "../../../../shared/components";
import { useTranslatedLabel } from "../../../../shared/hooks";
import { Layout } from "../../components";
import { PATHS } from "../../config";
import { Filters } from "../transactions/filters";

const data_fields = `id created_at updated_at  name active user { name id email photo_url } uid is_farm user { id buyer_access grower_access provider_access } area crops certifications city state country enum_country { id: value value comment }`;
const map_fields = `id name city  lat lng   is_farm user { id buyer_access grower_access provider_access } `;

export const Locations = ({
  history,
  component = false,
  pagination,
  noFilter = false,
  limit = null,
  component_filters = {},
  hideColumns = [],
}) => {
  const { t } = useTranslatedLabel();
  const [filters, setFilters] = useState({});
  const [tab_id, setTabId] = useState("table");
  const COLUMNS = [
    {
      title: t("id"),
      key: "uid",
      render: (row) => (
        <Link to={`${PATHS.locations}/${row.id}`}>{row?.uid}</Link>
      ),
      sorter: AntdTable.GenericSorter("uid"),
    },
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
      sorter: AntdTable.TextSorter("name"),
    },
    {
      title: "City",
      key: "city",
      dataIndex: "city",
      sorter: AntdTable.TextSorter("city"),
    },
    {
      title: "State",
      key: "state",
      dataIndex: "state",
      sorter: AntdTable.TextSorter("state"),
    },
    {
      title: "Country",
      key: "country",
      dataIndex: "country",
      sorter: AntdTable.TextSorter("country"),
    },
    {
      title: t("user"),
      key: "user",
      render: AntdTable.UserRenderer("user"),
      sorter: AntdTable.UserSorter("user"),
    },
    {
      title: t("created"),
      key: "created_at",
      render: AntdTable.DateRenderer("created_at"),
      sorter: AntdTable.TextSorter("created_at"),
    },
    {
      title: t("updated"),
      key: "updated_at",
      render: AntdTable.DateRenderer("updated_at"),
      sorter: AntdTable.TextSorter("updated_at"),
    },
    {
      title: t("active"),
      key: "active",
      dataIndex: "active",
      render: (text, record) =>
        text ? (
          <i className="la la-check-circle la-lg text-success align-align-self-center" />
        ) : (
          ""
        ),
      sorter: (a, b) => Number(a.active) - Number(b.active),
    },
  ];

  const content = (
    <>
      {!noFilter && (
        <Filters entity="locations" onChange={(e) => setFilters(e)} />
      )}
      <Tabs
        activeKey={tab_id}
        onSelect={(e) => setTabId(e)}
        className="m-0 p-0"
      >
        <Tab eventKey="table" title={t("table_view")}>
          <AntdTable
            entity="locations"
            columns={COLUMNS}
            where={{
              is_farm: { _eq: false },
              user: { buyer_access: { _eq: true } },
              ...component_filters,
            }}
            order_by={{ uid: "desc" }}
            query_fields={data_fields}
            filters={filters}
            limit={limit}
          />
        </Tab>
        <Tab eventKey="map" title={t("map_view")}>
          <MapView
            zoom={2}
            entity="locations"
            where={{
              is_farm: { _eq: false },
              user: { buyer_access: { _eq: true } },
              ...component_filters,
            }}
            order_by={{ uid: "desc" }}
            query_fields={map_fields}
            filters={filters}
            limit={limit}
            pagination={pagination}
          />
        </Tab>
      </Tabs>
    </>
  );

  return (
    <>
      {component ? (
        content
      ) : (
        <Layout title={t("buyer_locations")}>
          <div>{content}</div>
        </Layout>
      )}
    </>
  );
};
