import React from "react";
import { Link } from "react-router-dom";

import { PATHS } from "../../config";

export const SettingsNav = () => {
  return (
    <>
      <div className="card settings_menu">
        <div className="card-header">
          <h4 className="card-title">Settings</h4>
        </div>
        <div className="card-body">
          <ul>
            <li className="nav-item">
              <Link to={PATHS.settingsProfile} className="nav-link active">
                <i className="la la-user"></i>
                <span>Account</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link to={PATHS.settingsSecurity} className="nav-link">
                <i className="la la-lock"></i>
                <span>Security</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link to={PATHS.settingsNotifications} className="nav-link">
                <i className="la la-bell"></i>
                <span>Notifications</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
