import { useMutation, useSubscription } from "@apollo/client";
import axios from "axios";
// import { Multiselect } from "multiselect-react-dropdown";
import Prismic from "prismic-javascript";
import React, { useContext, useEffect, useState, Fragment } from "react";
import { SketchPicker } from "react-color";
import { useParams } from "react-router-dom";
import reactCSS from "reactcss";
import defaultProductImage from "../../../../images/default-product-image.png";
import { PreviewMap } from "../../../../shared/helpers/utils";
import { useTranslatedLabel } from "../../../../shared/hooks";
import { AuthLayout, LocationWidget } from "../../components";
import { client } from "../../components/prismicHelpers";
import PostList from "../../components/UI/PostList2";
import { weathericons } from "../../config/weatherstack";
import { GET_LOCATION_BY_ID, UPDATE_LOCATION } from "../../gql";
import { AuthContext, GeneralContext } from "../../store";

const LocationDetails = ({ component = false, id, isFarm = false }) => {
	const { t } = useTranslatedLabel();
	let { locationId } = useParams();
	if (component) locationId = id;

	const { user_role } = useContext(AuthContext);
	const [location, setLocation] = useState(null);
	const [updateLocation] = useMutation(UPDATE_LOCATION);
	const [color, setColor] = useState(`#${Math.floor(Math.random() * 16777215).toString(16)}`);
	const [color_picker, setColorPicker] = useState(false);
	const styles = reactCSS({
		default: {
			color: {
				width: "60px",
				height: "20px",
				borderRadius: "2px",
				background: color,
			},
			swatch: {
				padding: "5px",
				background: "#fff",
				borderRadius: "1px",
				boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
				display: "inline-block",
				cursor: "pointer",
			},
			popover: {
				position: "absolute",
				zIndex: "2",
			},
			cover: {
				position: "fixed",
				top: "0px",
				right: "0px",
				bottom: "0px",
				left: "0px",
			},
		},
	});

	const [weatherdata, setWeatherData] = useState({});
	const [forecastData, setForecastData] = useState({});
	const [prismicData, setPrismicData] = useState({
		homeDoc: null,
		blogPosts: null,
	});
	const { general_product_types, general_certifications, general_language } = useContext(GeneralContext);
	const [location_data, setLocationData] = useState();
	const { loading, data, error } = useSubscription(GET_LOCATION_BY_ID, {
		variables: { id: locationId },
	});
	// Populate the Product && Product_Type
	useEffect(() => {
		if (location?.name) {
			setLocationData({
				...location,
				mapCenter: {
					lat: location?.lat || 0,
					lng: location?.lng || 0,
				},
				markerPosition: {
					lat: location?.lat || 0,
					lng: location?.lng || 0,
				},
			});
		}
		if (location?.color) setColor(location?.color);
	}, [location]);

	useEffect(() => {
		const getWeatherData = async ({ lat, lng }) => {
			try {
				const response = await axios({
					url: `https://api.weatherstack.com/current?access_key=64189fb34664aad5373fc1b58bc1d905&query=${lat || 0},${lng || 0}`,
					method: "GET",
					headers: {
						"content-type": "application/json",
					},
				});
				if (response && response.data && response.data.current) {
					setWeatherData(response.data.current);
				}
			} catch (error) {
				console.error(error);
			}
			try {
				const response = await axios({
					url: `https://api.weatherstack.com/forecast?access_key=64189fb34664aad5373fc1b58bc1d905&forecast_days=14&hourly=1&interval=24&query=${
						lat || 0
						},${lng || 0}`,
					method: "GET",
					headers: {
						"content-type": "application/json",
					},
				});
				if (response && response.data && response.data.forecast) {
					setForecastData(response.data.forecast);
				}
			} catch (error) {
				console.error(error);
			}
		};

		const fetchPrismicData = async () => {
			try {
				const homeDoc = await client.getSingle("posts_hom");
				const blogPosts = await client.query(Prismic.Predicates.at("document.type", "post"), {
					orderings: "[my.post.date desc]",
					lang: (general_language || "en_gb").replace("_", "-"),
				});

				if (homeDoc) {
					setPrismicData({ homeDoc, blogPosts: blogPosts.results });
				} else {
					console.warn("Blog Home document was not found. Make sure it exists in your Prismic repository");
				}
			} catch (error) {
				console.error(error);
			}
		};

		if (!loading && data?.object && !error) {
			getWeatherData(data?.object);
			fetchPrismicData();
			setLocation({
				...data?.object,
				crops: general_product_types.filter((e) => data?.object?.crops.includes(e.id)),
				certifications: general_certifications.filter((e) => data?.object?.certifications.includes(e.id)),
			});
		}
	}, [data, loading, error, general_product_types, general_certifications, general_language]);

	const Wrapper = component ? Fragment : AuthLayout

	return (
		<>
			<Wrapper>
				{location && (
					<div className="bg-light p-5 sm-px-3">
						<div className={component ? "" : "container"}>
							<div className="row ml-1">
								<div>
									<h3 className="page-title display-4 p-0">
										{location.name}{" "}
										{user_role === "grower" ? (
											<span>
												( {location.area} {t("acres")} )
											</span>
										) : (
												""
											)}
									</h3>
								</div>

								<div className="ml-auto mr-3">
									{location_data?.id && <LocationWidget data={location_data} />}
									<span className="float-right ml-3">
										<div style={styles.swatch} onClick={() => setColorPicker(true)}>
											<div style={styles.color} />
										</div>
										{color_picker && (
											<div style={styles.popover}>
												<div style={styles.cover} onClick={() => setColorPicker(false)} />
												<SketchPicker
													color={color}
													onChange={(e) => {
														setColor(e.hex);
														updateLocation({
															variables: {
																id: locationId,
																object: { color: e.hex },
															},
														});
													}}
												/>
											</div>
										)}
									</span>
								</div>
							</div>
							<div className="row">
								<div className="col-sm-12 col-md-12 col-lg-4">
									<div className="card bg-white">
										<div className="card-body p-3">
											<b>{location_data?.address_line_1}</b>
											<br />
											{t("city")}: <b>{location_data?.city}</b>
											<br />
											{t("state")}: <b>{location_data?.state}</b>
											<br />
											{t("country")}: <b>{location_data?.enum_country?.comment}</b>
											<br />
											{t("postcode")}: <b>{location_data?.postcode}</b>
											<br />
										</div>
									</div>
								</div>
								<div className="col-sm-12 col-md-12 col-lg-4">
									<div className="card bg-white">
										<div className="card-body p-3">
											<h4 className="mb-2">{t("current_weather")}</h4>

											{weatherdata && (
												<>
													<div className="d-flex justify-content-between align-items-center">
														<img
															src={weathericons[weatherdata.weather_code]}
															alt=""
															className="location-weather-icon"
														/>
														<br />

														<div style={{ textAlign: "center" }}>
															<p className="mb-0" style={{ fontSize: "12px" }}>
																{weatherdata.weather_descriptions}
															</p>
															<p className="m-0">
																<span className="farm-weather-temp">{weatherdata.temperature}°</span>
																<span>c</span>
															</p>
														</div>

														<div
															style={{
																fontSize: "12px",
																borderLeft: "1px dotted #dddddd",
																paddingLeft: "6px",
															}}
														>
															{t("wind")}: {weatherdata.wind_speed} kmph
															<br />
															{t("precipitation")}: {weatherdata.precip} mm
															<br />
															{t("pressure")}: {weatherdata.pressure} mb
															<br />
															{t("humidity")}: {weatherdata.humidity}
														</div>
													</div>
												</>
											)}
										</div>
									</div>
								</div>
								<div className="col-sm-12 col-md-12 col-lg-4">
									<div className="card p-2 bg-white">
										<PreviewMap
											data={location_data}
											isMarkerShown
											loadingElement={<div style={{ height: `10rem` }} />}
											containerElement={<div style={{ height: `10rem` }} />}
											mapElement={<div style={{ height: `100%` }} />}
										/>
									</div>
								</div>
							</div>

							{(user_role === "grower" || isFarm) && (
								<div className="row m-0 p-0">
									<div className="col-sm-12 col-md-12 col-lg-9">
										<div className="mb-3">
											<hr />
											<h3 className="p-2">{t("crops")}</h3>
											<div className="row m-0 p-1 bg-white">
												{location_data?.crops?.map((p, i) => (
													<div key={i} className={'col-sm-12 col-md-6 col-lg-4 p-1 border border-light'}>
														<div className="row">
															<div className="col-1">

																<img
																	style={{ height: "15px" }}
																	src={p.pictures?.[0] ?? defaultProductImage}
																	alt=""
																/>

															</div>
															<div className="col text-left text-dark ">{t(p.id, p.name)}</div>
														</div>
													</div>
												))}
											</div>
										</div>
										<div className="mb-3">
											<hr />
											<h3 className="p-2">{t("certifications")}</h3>
											<div className="row m-0 p-1 bg-white">
												{location_data?.certifications?.map((p, i) => (
													<div key={i} className={'col-sm-12 col-md-6 col-lg-6 p-1  border border-light'}>
														<div className="row">
															<div className="col text-left text-dark ">{t(p.id, p.name)}</div>
														</div>
													</div>
												))}
											</div>
										</div>
										<div className="mb-4">
											<h3 className="p-2">Forecast Weather ( 14 Days )</h3>
											<div className="row bg-white">
												<table className="table table-light">
													<thead>
														<tr>
															<th>Date</th>
															<th>Avg. Temp</th>
															<th>Min. Temp</th>
															<th>Max. Temp</th>
															<th>24 Hr Avg</th>
														</tr>
													</thead>
													<tbody>
														{Object.values(forecastData).map((forecast, i) => (
															<tr key={i}>
																<td>
																	{new Intl.DateTimeFormat("en-GB", {
																		year: "numeric",
																		month: "long",
																		day: "2-digit",
																	}).format(new Date(forecast.date))}
																</td>
																<td>{forecast.avgtemp} °C</td>
																<td>{forecast.mintemp} °C</td>
																<td>{forecast.maxtemp} °C</td>
																<td>
																	{" "}
																	<img
																		style={{ height: "40px", width: "40px" }}
																		src={weathericons[forecast.hourly[0].weather_code]}
																		alt=""
																		className="location-weather-icon"
																	/>
																	<br />
																</td>
															</tr>
														))}
													</tbody>
												</table>
											</div>
										</div>

									</div>
									<div className="col-sm-12 col-md-12 col-lg-3">
										<div className="card bg-white">
											<div className="card-body p-3">
												<h4 className="mb-2 mt-2">Crop Guides</h4>
												{prismicData && <PostList posts={prismicData.blogPosts} />}
											</div>
										</div>
									</div>
								</div>
							)}

							{/* {(user_role === "grower" || isFarm) && (
								<div className="row m-0 p-0">
									<div className="col-sm-12 col-md-12 col-lg-9">
										<div className="mb-4">
											<h3 className="p-2">{t("crops_min")}</h3>
											<div className="row bg-white">
												<Multiselect
													options={general_product_types.map((e) => {
														let newObj = { ...e };
														newObj.name = `${t(e.product_id, e.product_name)}, ${t(e.id, e.name)}`;
														newObj.product_name = `${t(e.product_id, e.product_name)}`;
														return newObj;
													})}
													disablePreSelectedValues={location_data?.crops?.length > 1 ? false : true}
													selectedValues={location?.crops ?? []}
													disabled={location?.crops?.length > 1 ? false : true}
													onSelect={(e) =>
														updateLocation({
															variables: {
																id: location.id,
																object: {
																	crops: e.map((e) => e.id),
																},
															},
														})
													}
													onRemove={(e) =>
														updateLocation({
															variables: {
																id: location.id,
																object: {
																	crops: e.map((e) => e.id),
																},
															},
														})
													}
													displayValue="name"
													groupBy="product_name"
													placeholder="Crops"
												/>
											</div>
										</div>
										<div className="mb-4">
											<h3 className="p-2">{t("certifications")}</h3>
											<div className="row bg-white">
												<Multiselect
													options={general_certifications.map((e) => ({ ...e, name: t(e.id, e.name) }))}
													selectedValues={location?.certifications ?? []}
													onSelect={(e) =>
														updateLocation({
															variables: {
																id: location.id,
																object: {
																	certifications: e.map((e) => e.id),
																},
															},
														})
													}
													onRemove={(e) =>
														updateLocation({
															variables: {
																id: location.id,
																object: {
																	certifications: e.map((e) => e.id),
																},
															},
														})
													}
													displayValue="name"
													placeholder={t("certifications")}
												/>
											</div>
										</div>
										<div className="mb-4">
											<h3 className="p-2">Forecast Weather ( 14 Days )</h3>
											<div className="row bg-white">
												<table className="table table-light">
													<thead>
														<tr>
															<th>Date</th>
															<th>Avg. Temp</th>
															<th>Min. Temp</th>
															<th>Max. Temp</th>
															<th>24 Hr Avg</th>
														</tr>
													</thead>
													<tbody>
														{Object.values(forecastData).map((forecast, i) => (
															<tr key={i}>
																<td>
																	{new Intl.DateTimeFormat("en-GB", {
																		year: "numeric",
																		month: "long",
																		day: "2-digit",
																	}).format(new Date(forecast.date))}
																</td>
																<td>{forecast.avgtemp} °C</td>
																<td>{forecast.mintemp} °C</td>
																<td>{forecast.maxtemp} °C</td>
																<td>
																	{" "}
																	<img
																		style={{ height: "40px", width: "40px" }}
																		src={weathericons[forecast.hourly[0].weather_code]}
																		alt=""
																		className="location-weather-icon"
																	/>
																	<br />
																</td>
															</tr>
														))}
													</tbody>
												</table>
											</div>
										</div>
									</div>
									<div className="col-sm-12 col-md-12 col-lg-3">
										<div className="card bg-white">
											<div className="card-body p-3">
												<h4 className="mb-2 mt-2">Crop Guides</h4>
												{prismicData && <PostList posts={prismicData.blogPosts} />}
											</div>
										</div>
									</div>
								</div>
							)} */}

							{/* <div className="mb-4">
								<h3 className="p-2">{t("active_trade_orders")}</h3>
								<div className="row">
									<ActiveOrders
										filter={{
											_or: [{ from_location_id: { _eq: locationId } }, { to_location_id: { _eq: locationId } }],
										}}
									/>
								</div>
							</div>

							<div className="mb-4">
								<h3 className="p-2">Bids Requiring Attention</h3>
								<div className="row">
									<AttentionOffers filter={{ location_id: { _eq: locationId } }} />
								</div>
							</div>

							<div className="mb-4">
								<h3 className="p-2">Active Offers</h3>
								<div className="row">
									<ActiveOffers
										hideColumns={["created_at", "updated_at", "type", "viewed", "searched", "status"]}
										filter={{ location_id: { _eq: locationId } }}
									/>
								</div>
							</div> */}
						</div>
					</div>
				)}
			</Wrapper>
		</>
	);
};

export default LocationDetails;
