// -- Weatherstack API endpoint
import sunny from "../../../icons/weather/day.svg";
import cloudyday1 from "../../../icons/weather/cloudy-day-1.svg";


export const accessToken = '64189fb34664aad5373fc1b58bc1d905'

export const apiEndpoint = 'https://api.weatherstack.com/current?access_key=64189fb34664aad5373fc1b58bc1d905&query='

export const forecastApiEndpoint = 'https://api.weatherstack.com/forecast?access_key=64189fb34664aad5373fc1b58bc1d905&forecast_days=14&query='

export const weathericons = {
    113:sunny,
    116:cloudyday1,
    119:cloudyday1,
    122:cloudyday1,
    143:cloudyday1,
    176:cloudyday1,
    179:cloudyday1,
    182:cloudyday1,
    185:cloudyday1,
    200:cloudyday1,
    227:cloudyday1,
    230:cloudyday1,
    248:cloudyday1,
    260:cloudyday1,
    263:cloudyday1,
    266:cloudyday1,
    281:cloudyday1,
    284:cloudyday1,
    293:cloudyday1,
    296:cloudyday1,
    299:cloudyday1,
    302:cloudyday1,
    305:cloudyday1,
    308:cloudyday1,
    311:cloudyday1,
    314:cloudyday1,
    317:cloudyday1,
    320:cloudyday1,
    323:cloudyday1,
    326:cloudyday1,
    329:cloudyday1,
    332:cloudyday1,
    335:cloudyday1,
    338:cloudyday1,
    350:cloudyday1,
    353:cloudyday1,
    356:cloudyday1,
    359:cloudyday1,
    362:cloudyday1,
    365:cloudyday1,
    368:cloudyday1,
    371:cloudyday1,
    374:cloudyday1,
    377:cloudyday1,
    386:cloudyday1,
    389:cloudyday1,
    392:cloudyday1,
    395:cloudyday1
}



