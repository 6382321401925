import React from "react";
// import { Table } from "antd";
import { Card, Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
// import { AntdTable } from "../../../../shared/components";
import { useTranslatedLabel } from "../../../../shared/hooks";
import { CustomLink, Layout, Loader } from "../../components";
import { PAYMENTS } from "../../gql";
import { useAllObjectsQuery } from "../../hooks/allObjectsQuery";

export function PaymentDetails() {
	const { id } = useParams();
	const { t } = useTranslatedLabel();
	const { data, loading } = useAllObjectsQuery({
		query: PAYMENTS,
		where: {
			id: { _eq: id },
		},
	});

	if (loading) {
		return (
			<Layout>
				<Loader />
			</Layout>
		);
	} else {
		const [current] = data.objects;
		const isTrade = !!current?.trade_order_id;
		const field = isTrade ? "trade_order" : "service_order";
		return (
			<Layout>
				<div className="" style={{ backgroundColor: "rgba(255,255,255, 0.3)" }}>
					<Row className="page-title">
						<Col>
							<h1>Payment Details</h1>
							<h3>#{current?.uid}</h3>
						</Col>
					</Row>
					<Row>
						<Col xs={8}>
							<Row>
								<Col>
									<Card>
										<Card.Body>
											<Row>
												<Col>
													<CustomLink page="users" id={current?.buyer?.id}>
														<h3>{current?.buyer?.name}</h3>
													</CustomLink>
													<p>Paid By</p>
												</Col>
												<Col>
													<CustomLink page="users" id={current?.seller?.id}>
														<h3>{current?.seller?.name}</h3>
													</CustomLink>
													<p>Received By</p>
												</Col>
												<Col>
													<h3>$ {current?.amount}</h3>
													<p>Transaction Amount</p>
												</Col>
												<Col>
													<div style={{ float: "right" }}>
														<h3 className="green">{isTrade ? "Trade Order" : "Service Order"}</h3>
														<p>{t("type")}</p>
													</div>
												</Col>
											</Row>
											{/* <Row>
                        <Col>
                          <Form.Group>
                            <Form.Control
                              as="textarea"
                              rows="3"
                              value={current?.notes?.text}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={{ span: 4, offset: 8 }}>
                          <Button block size="lg">
                            Add a Note
                          </Button>
                        </Col>
                      </Row> */}
										</Card.Body>
									</Card>
								</Col>
							</Row>
						</Col>
						<Col xs={4}>
							<Card>
								<Card.Body>
									<Row>
										<Col xs={8}>
											<h3>{t("details")}</h3>
										</Col>
									</Row>
									<Row>
										<Col>
											<table rowKey="id" className="custom-table">
												<tbody>
													<tr>
														<td>{t("id")}</td>
														<CustomLink page={isTrade ? "orders" : "serviceOrders"} id={current[field]?.id}>
															<td>{current[field]?.uid}</td>
														</CustomLink>
													</tr>
													{isTrade ? (
														<tr>
															<td>{t("product")}</td>
															<td>
																<CustomLink page="products" id={current[field]?.product_type?.product?.id}>
																	{t(
																		current[field]?.product_type?.product?.id,
																		current[field]?.product_type?.product?.name
																	)}
																</CustomLink>
															</td>
														</tr>
													) : (
														<tr>
															<td>{t("service")}</td>
															<td>
																<CustomLink page="services" id={current[field]?.service_type?.service?.id}>
																	{t(
																		current[field]?.service_type?.service?.id,
																		current[field]?.service_type?.service?.name
																	)}
																</CustomLink>
															</td>
														</tr>
													)}
													{isTrade ? (
														<tr>
															<td>{t("variety")}</td>
															<td>
																<CustomLink page="productTypes" id={current[field]?.product_type?.id}>
																	{t(current[field]?.product_type?.id, current[field]?.product_type.name)}
																</CustomLink>
															</td>
														</tr>
													) : (
														<tr>
															<td>{t("service_category")}</td>
															<td>
																<CustomLink page="serviceTypes" id={current[field]?.service_type?.id}>
																	{t(current[field]?.service_type?.id, current[field]?.service_type?.name)}
																</CustomLink>
															</td>
														</tr>
													)}

													<tr>
														<td>{t("status")}</td>
														<td>{current[field]?.enum_order_status?.comment}</td>
													</tr>
													{isTrade ? (
														<tr>
															<td>{t("price_per_unit")}</td>
															<td>{`$${current[field]?.price_per_unit} per unit`}</td>
														</tr>
													) : (
														<tr>
															<td>Service Fee</td>
															<td>{`$ {current[field]?.provider_service.amount}`}</td>
														</tr>
													)}
													{isTrade && (
														<tr>
															<td>{t("quantity")}</td>
															<td>{`${current[field]?.number_of_units} ${current[field]?.enum_product_unit.comment}`}</td>
														</tr>
													)}
													{isTrade ? (
														<>
															<tr>
																<td>{t("start_date")}</td>
																<td>{current[field]?.from_date}</td>
															</tr>
															<tr>
																<td>{t("end_date")}</td>
																<td>{current[field]?.to_date}</td>
															</tr>
														</>
													) : (
														<tr>
															<td>Date</td>
															<td>{current[field]?.date}</td>
														</tr>
													)}
													<tr>
														<td>Advance</td>
														<td>{`${current[field]?.advance_payment_percentage} %`}</td>
													</tr>
													<tr>
														<td>{t("origin")}</td>
														{isTrade ? (
															<td>
																<CustomLink page="locations" id={current[field]?.from_location?.id}>
																	{`${current[field]?.from_location.city}, ${current[field]?.from_location.enum_country.comment}`}
																</CustomLink>
															</td>
														) : (
															<td>
																<CustomLink
																	page="locations"
																	id={current[field]?.provider_service?.location?.id}
																>
																	{`${current[field]?.provider_service?.location?.city}, ${current?.provider_service?.location?.enum_country.comment}`}
																</CustomLink>
															</td>
														)}
													</tr>
												</tbody>
											</table>
										</Col>
									</Row>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</div>
			</Layout>
		);
	}
}
