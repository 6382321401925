import dayjs from "dayjs";
import React, { useState } from "react";
import Skelton from "react-loading-skeleton";
import { useParams } from "react-router-dom";
import { useTranslatedLabel } from "../../../../shared/hooks";
import ViewServiceOffer from "../../../client/pages/services/view-offer";
import { CustomLink, Layout } from "../../components";
import { Q_SERVICE_OFFERS } from "../../gql";
import { useAllObjectsQuery } from "../../hooks/allObjectsQuery";
import { ServiceOfferEditModal } from "./service-offer-edit";
import { ServiceOrders } from "./service-orders";

export const ServiceOfferDetails = () => {
	const { id } = useParams();

	return (
		<Layout title={`Service Offer Details`}>
			<div className="bg-light pb-5 order-details">
				<div className="container">
					<ViewServiceOffer component={true} inAdmin={true} offer_id={id} />
				</div>
			</div>
		</Layout>
	);
};

export const ServiceOfferDetailsOLD = () => {
	const { id } = useParams();
	const { t } = useTranslatedLabel();
	const [show, setShow] = useState(false);
	const { objects, loading, refetch, error } = useAllObjectsQuery({
		query: Q_SERVICE_OFFERS,
		where: {
			id: { _eq: id },
		},
	});

	function onEdit() {
		setShow(true);
	}

	function onClose() {
		refetch();
		setShow(false);
	}

	if (loading) {
		return <Skelton height="80vh" />;
	} else if (!error && objects) {
		const [current] = objects;
		return (
			<Layout>
				<ServiceOfferEditModal show={show} onClose={onClose} order={current} />
				<div className="bg-light pb-5 order-details">
					<div className="container">
						<div className="d-flex pt-5">
							<div className="order-details-main">
								<div className="mb-3">
									<div className="order-details-summary">
										<div className="d-flex justify-content-between  bg-white p-4">
											<div>
												<h3 className="display-4 font-weight-bold">
													<span className="mr-1">Service Offer #{current?.uid}</span>
													<i className="las la-clipboard"></i>
												</h3>
												<div className="d-flex sm-text">
													<p className="ml-1">
														<span className="font-weight-bold">Date:</span>{" "}
														<span>{dayjs(current?.created_at).format("MMMM DD, YYYY")}</span>
													</p>
												</div>
											</div>
											<div className="text-right">
												<h3 className="order-amount font-weight-bold">{`$ ${current?.amount}`}</h3>
											</div>
										</div>
									</div>
								</div>

								<div className="pt-2 pb-2 bg-white mb-3">
									<h5 className="font-weight-bold ml-3">Service Orders</h5>
									<ServiceOrders
										scroll={{ x: true }}
										tableOnly={true}
										component={true}
										noFilter={true}
										component_filters={{ id: { _eq: id } }}
									/>
								</div>
							</div>
							<div
								className="new-offer-preview bg-white p-3 d-flex flex-column justify-content-end align-self-start"
								style={{ minHeight: "40rem" }}
							>
								<div className="flex-grow-1 mb-3">
									<h4 className="font-weight-bold text-uppercase">
										Service Offer Details
										<div className="btn btn-outline-primary  float-right" onClick={onEdit}>
											<span>Edit</span>
										</div>
									</h4>
									<hr />
									<div className="mb-3">
										<span className="text-muted mb-0">{t("seller")}</span>
										<CustomLink page="users" id={current?.user?.id}>
											<span className="font-weight-bold mb-0 float-right">{current?.user?.name}</span>
										</CustomLink>
									</div>

									<hr />
									<div className="mb-3">
										<span className="text-muted mb-0">{t("service")}</span>
										<CustomLink page="products" id={current?.service_type?.service?.id}>
											<span className="font-weight-bold mb-0 float-right">
												{t(current?.service_type?.service?.id, current?.service_type?.service?.name)}
											</span>
										</CustomLink>
									</div>
									<div className="mb-3">
										<span className="text-muted mb-0">{t("variety")}</span>
										<CustomLink page="productTypes" id={current?.service_type?.id}>
											<span className="font-weight-bold mb-0 float-right">
												{t(current?.service_type?.id, current?.service_type?.name)}
											</span>
										</CustomLink>
									</div>
									<hr />

									<div className="mb-3">
										<span className="text-muted mb-0">Service Fee</span>
										<span className="font-weight-bold mb-0 float-right">{current?.amount}</span>
									</div>

									{/* <hr />

                  <div className="mb-3">
                    <span className="text-muted mb-0">Available Range</span>
                    <span className="font-weight-bold mb-0  float-right">{current?.range_in_kms}</span>
                  </div> */}
									<hr />
									<div className="mb-3">
										<p className="text-muted mb-0">{t("origin")}</p>
										<CustomLink pages="location" id={current?.location?.id}>
											<span className="font-weight-bold mb-0">
												{current?.location?.address_line_1},{current?.location?.address_line_2}
												{current?.location?.address_line_3}
											</span>
											<span className="font-weight-bold mb-0">{current?.location?.city}</span>
											<span className="font-weight-bold mb-0">{current?.location?.state},</span>
											<span className="font-weight-bold mb-0">{current?.location?.country},</span>
											<span className="font-weight-bold mb-0">{current?.location?.postcode}</span>
										</CustomLink>
									</div>
									<hr />
								</div>
							</div>
						</div>
					</div>
				</div>
			</Layout>
		);
	} else {
		return null;
	}
};

/*
if (loading) {
    return <Container>Loading, Please Wait...</Container>;
  } else {
    const [current] = objects;
    return (
      <Layout>
        <div
          className="admin-container"
          style={{ backgroundColor: "rgba(255,255,255, 0.3)" }}
        >
          <ServiceOfferEditModal show={show} data={current} onClose={onClose} />
          <Row className="page-title">
            <Col>
              <h1>Service Offer Details</h1>
              <h3 className="primary-text">{`#${current?.uid}`}</h3>
            </Col>
            <Col>
              <Row>
                <Col>
                  <Button size="lg" block>
                    Download Pdf
                  </Button>
                </Col>
                <Col>
                  <Button size="lg" block>
                    Email Invoice
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col xs={8}>
              <Row>
                <Col>
                  <Card>
                    <Card.Body>
                      <Row>
                        <Col>
                          <h3>{`${current?.location?.city}, ${current?.location?.enum_country.comment}`}</h3>
                          <p>Service Location</p>
                        </Col>
                        <Col>
                          <h3>$ {current?.amount}</h3>
                          <p>Service Fee</p>
                        </Col>
                        <Col></Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Card>
                    <Card.Body>
                      <Row>
                        <Col>
                          <h3>Orders</h3>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <ServiceOrders
                            scroll={{ x: true }}
                            component={true}
                            noFilter={true}
                            component_filters={{ id: { _eq: id } }}
                          />
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Col>


            <Col xs={4}>
              <Row>
                <Card>
                  <Card.Body>
                    <Row>
                      <Col xs={8}>
                        <h3>Service Details</h3>
                      </Col>
                      <Col>
                        <Button
                          variant="light"
                          className="float-right"
                          onClick={onEdit}
                        >
                          Edit
                        </Button>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <table rowKey="id" className="custom-table">
                          <tbody>
                            <tr>
                              <td>Provider</td>
                              <td>
                                <Link to={`${PATHS.users}${current?.user_id}`}>
                                  {current?.user?.name}
                                </Link>
                              </td>
                            </tr>
                            <tr>
                              <td>{t("service")}</td>
                              <td>
                                <Link
                                  to={`${PATHS.services}${current?.service_type?.service?.id}`}
                                >
                                  {current?.service_type?.service?.name}
                                </Link>
                              </td>
                            </tr>
                            <tr>
                              <td>{t("service_category")}</td>
                              <td>
                                <Link
                                  to={`${PATHS.serviceTypes}${current?.service_type?.id}`}
                                >
                                  {current?.service_type?.name}
                                </Link>
                              </td>
                            </tr>

                            <tr>
                              <td>{t("price_per_unit")}</td>
                              <td>{current?.amount}</td>
                            </tr>
                            <tr>
                              <td>Location</td>
                              <td>
                                <Link
                                  to={`${PATHS.locations}${current?.location?.id}`}
                                >
                                  {`${current?.location?.city}, ${current?.location?.enum_country.comment}`}
                                </Link>
                              </td>
                            </tr>
                            <tr>
                              <td>Range</td>
                              <td>{current?.range_in_kms} km</td>
                            </tr>
                          </tbody>
                        </table>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Row>
            </Col>
          </Row>
        </div>
      </Layout>
    );
  }

*/
