import React from "react";

export const SelectedImage = ({ removeImage, imageUrl }) => {
	return (
		<>
			<div className="selected-image-container mr-4">
				<div className="selected-image-border">
					<button type="button" className="remove-selected-btn" onClick={removeImage}>
						<i className="las la-minus"></i>
					</button>
				</div>
				<div className="selected-image" style={{ backgroundImage: `url(${imageUrl})`, maxHeight: 400 }}></div>
				<div className="selected-image-border" />
			</div>
		</>
	);
};
