import auth0 from "auth0-js";
import { APP_CONFIG } from "../../../shared/app_config";
import { PATHS } from "./routes";

export const AUTH_CONFIG = {
	responseType: APP_CONFIG.REACT_APP_AUTH0_RESPONSE_TYPE,
	clientID: APP_CONFIG.REACT_APP_AUTH0_ADMIN_CLIENT_ID,
	clientId: APP_CONFIG.REACT_APP_AUTH0_ADMIN_CLIENT_ID,
	domain: APP_CONFIG.REACT_APP_AUTH0_DOMAIN,
	redirectUri: `${window.location.origin}${PATHS.authorize}`,
	returnTo: `${window.location.origin}`,
};

console.log(JSON.stringify(AUTH_CONFIG), 'auth config')
console.log(APP_CONFIG.REACT_APP_AUTH0_DOMAIN, process.env.REACT_APP_AUTH0_DOMAIN, 'auth variable')

export const webAuth = new auth0.WebAuth(AUTH_CONFIG);
