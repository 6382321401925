import { useLazyQuery, useMutation } from "@apollo/client";
import { Multiselect } from "multiselect-react-dropdown";
import React, { useContext, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { SketchPicker } from "react-color";
import Autocomplete from "react-google-autocomplete";
import { useToasts } from "react-toast-notifications";
import reactCSS from "reactcss";
import { FullMap, getLocationData } from "../../../shared/helpers/utils";
import { useTranslatedLabel } from "../../../shared/hooks";
import { INSERT_LOCATION, SEARCH_COUNT_USERID, UPDATE_LOCATION, UPDATE_USER_LOCATION } from "../gql";
import { AuthContext, GeneralContext } from "../store";

export const LocationWidget = ({ modal = true, data = {}, onChange = () => { }, icon = false }) => {
	const { addToast } = useToasts();
	const { t } = useTranslatedLabel();
	const { user_role, user } = useContext(AuthContext);
	const { general_product_types, general_countries, general_certifications, general_countries_map, general_location } = useContext(
		GeneralContext
	);

	const [unique, setUnique] = useState(true);
	const [uniqueNameQuery, { data: uniqueData, loading, error }] = useLazyQuery(SEARCH_COUNT_USERID("locations"));

	useEffect(() => {
		if (!loading && !error && uniqueData && uniqueData.counts && uniqueData.counts.aggregate && uniqueData.counts.aggregate.count > 0)
			setUnique(false);
		else if (!unique) setUnique(true);
	}, [uniqueData, error, loading, unique]);

	const [open, setOpen] = useState(false);
	const [color, setColor] = useState(data.color || `#${Math.floor(Math.random() * 16777215).toString(16)}`);
	const [color_picker, setColorPicker] = useState(false);
	const styles = reactCSS({
		default: {
			color: {
				width: "90px",
				height: "40px",
				borderRadius: "2px",
				background: color,
			},
			swatch: {
				padding: "5px",
				background: "#fff",
				borderRadius: "1px",
				boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
				display: "inline-block",
				cursor: "pointer",
			},
			popover: {
				position: "absolute",
				zIndex: "2",
			},
			cover: {
				position: "fixed",
				top: "0px",
				right: "0px",
				bottom: "0px",
				left: "0px",
			},
		},
	});

	const base_location_data = {
		address_line_1: "",
		city: "",
		state: "",
		country: "",
		postcode: "",
		crops: [],
		certifications: [],
		mapCenter: {
			lat: 8.165975,
			lng: 77.445312,
		},
		markerPosition: {
			lat: 8.165975,
			lng: 77.445312,
		},
		area: 1000,
		...data,
	};

	const [location_data, setLocationData] = useState(base_location_data);
	const [updateUser] = useMutation(UPDATE_USER_LOCATION);
	const [insertLocation, { data: insertedData, loading: insertLoading }] = useMutation(INSERT_LOCATION);
	useEffect(() => {
		if (!insertLoading && insertedData?.object?.id) {
			if (onChange) onChange(insertedData?.object?.id);
			addToast(t("added_successfully"), { appearance: "success" });
			setOpen(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [insertedData, insertLoading]);

	const [updateLocation, { data: updatedData, loading: updateLoading }] = useMutation(UPDATE_LOCATION);
	useEffect(() => {
		if (!updateLoading && updatedData?.object?.id) {
			if (onChange) onChange(updatedData?.object?.id);
			addToast(t("edited_successfully"), { appearance: "success" });
			setOpen(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updateLoading, updatedData]);

	useEffect(() => {
		if (data && data.name) setLocationData({ ...location_data, ...data });
		else if (!location_data.name) setLocationData({ ...location_data, ...general_location });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [general_location]);
	useEffect(() => {
		if (data.color && color !== data.color) setColor(data.color);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data.color]);

	const onNameChange = (e) => {
		if (e.target.value && e.target.value !== data?.name)
			uniqueNameQuery({
				variables: { text: e.target.value, user_id: user?.id },
			});
		setLocationData({
			...location_data,
			name: e.target.value,
		});
	};

	const onAreaChange = (e) => {
		const { value } = e.target;
		if (parseInt(value) < 999999) {
			setLocationData({
				...location_data,
				area: value ?? 0,
			});
		}
	};

	const onCropsChange = (e) => {
		setLocationData({
			...location_data,
			crops: e,
		});
	};

	const onCertificationsChange = (e) => {
		setLocationData({
			...location_data,
			certifications: e,
		});
	};

	const onAddressChange = (e) => {
		setLocationData({
			...location_data,
			address_line_1: e.target.value ?? "",
		});
	};

	const onMarkerDragEnd = async (e) => {
		setLocationData({
			...location_data,
			...(await getLocationData(e.latLng.lat() || 0, e.latLng.lng() || 0, general_countries)),
		});
	};

	const onPlaceSelected = async (e) => {
		setLocationData({
			...location_data,
			...(await getLocationData(e.geometry.location.lat() || 0, e.geometry.location.lng() || 0, general_countries)),
		});
	};

	const body = () => (
		<div className="bg-white p-2">
			<div className="row m-0 p-0">
				<div className="col-sm-12 col-md-12 col-lg-6 m-0 p-0">
					<div className="mb-3">
						<label>
							{t("name")} <span className="text-danger">*</span>
						</label>
						<input
							type="text"
							placeholder={t("name")}
							className="form-control"
							name="name"
							value={location_data?.name}
							onChange={onNameChange}
						/>
						{!unique && <p className="text-danger "> {t("already_exists")}</p>}
					</div>
				</div>
				{user_role === "grower" && (
					<div className="col-sm-12 col-md-12 col-lg-3 m-0 p-0 pl-2 sm-pl-0 md-pl-0">
						<div className="mb-3">
							<label>{t("acres")}</label>
							<input
								type="number"
								min={1}
								className="form-control"
								name="area"
								value={location_data?.area}
								onChange={onAreaChange}
							/>
						</div>
					</div>
				)}

				<div className="col-sm-12 col-md-12 col-lg-2 m-0 p-0 pl-2 sm-pl-0 md-pl-0">
					<div className="mb-3">
						<label>{t("color")}</label>
						<div>
							<div style={styles.swatch} onClick={() => setColorPicker(true)}>
								<div style={styles.color} />
							</div>
							{color_picker && (
								<div style={styles.popover}>
									<div style={styles.cover} onClick={() => setColorPicker(false)} />
									<SketchPicker color={color} onChange={(e) => setColor(e.hex)} />
								</div>
							)}
						</div>
					</div>
				</div>
			</div>

			{user_role === "grower" && (
				<div className="mb-3">
					<label>
						{t("crops")} <span className="text-danger">*</span>
					</label>
					<Multiselect
						options={general_product_types.map((e) => {
							let newObj = { ...e };
							newObj.name = `${t(e.product_id, e.product_name)}, ${t(e.id, e.name)}`;
							newObj.product_name = `${t(e.product_id, e.product_name)}`;
							return newObj;
						})}
						selectedValues={location_data?.crops ?? []}
						disablePreSelectedValues={location_data?.crops?.length > 1 ? false : true}
						onSelect={onCropsChange}
						onRemove={onCropsChange}
						displayValue="name"
						groupBy="product_name"
						placeholder={t("crops")}
					/>
					{location_data.crops.length < 1 && <p className="text-danger">{t("crops_min")}</p>}
				</div>
			)}
			{user_role === "grower" && (
				<div className="mb-3">
					<label>{t("certifications")}</label>
					<Multiselect
						options={general_certifications.map((e) => {
							let newObj = { ...e };
							newObj.name = t(e.id, e.name);
							return newObj;
						})}
						selectedValues={location_data?.certifications ?? []}
						onSelect={onCertificationsChange}
						onRemove={onCertificationsChange}
						displayValue="name"
						placeholder={t("certifications")}
					/>
				</div>
			)}
			<div className="mb-3">
				<div className="mb-3">
					<label>
						{t("address")} <span className="text-danger">*</span>
					</label>
					<input
						type="text"
						placeholder={t("address")}
						className="form-control form-control-sm"
						name="address"
						value={location_data?.address_line_1}
						onChange={onAddressChange}
					/>
					{!location_data?.address_line_1 && <p className="text-danger">{t("address_mandatory")}</p>}
				</div>
				<div className="row m-0 p-0 mt-3">
					<div className="col-sm-12 col-md-12 col-lg-6 m-0 p-0 mt-2">
						<div className="mb-3">
							<p>
								{t("city")}: <b>{location_data?.city}</b>
							</p>
							<p>
								{t("state")}: <b>{location_data?.state}</b>
							</p>
							<p>
								{t("country")}: <b>{general_countries_map[location_data?.country]?.comment}</b>
							</p>
							<p>
								{t("postcode")}: <b>{location_data?.postcode}</b>
							</p>
						</div>
					</div>
					<div className="col-sm-12 col-md-12 col-lg-6 m-0 p-0 pl-2 sm-pl-0 md-pl-0">
						{/* <label>{t("get_location_map")}</label> */}
						<Autocomplete
							className="form-control form-control-sm"
							onPlaceSelected={(e) => onPlaceSelected(e)}
							types={["(regions)"]}
						/>
						<FullMap
							isMarkerShown
							loadingElement={<div style={{ height: `20rem` }} />}
							containerElement={<div style={{ height: `20rem` }} />}
							mapElement={<div style={{ height: `100%` }} />}
							data={location_data}
							onMarkerDragEnd={onMarkerDragEnd}
						/>
					</div>
				</div>
			</div>
		</div>
	);

	const footer = () =>
		((user_role === "grower" && location_data?.name && location_data?.crops?.length > 0 && location_data?.country) ||
			(user_role === "buyer" && location_data?.name && location_data?.country) ||
			(user_role === "provider" && location_data?.name && location_data?.country)) &&
		unique &&
		location_data.address_line_1 && (
			<Button
				variant="success"
				onClick={() => {
					if (!location_data.address_line_1) {
						addToast("Address line cannot be empty", { appearance: "error" });
						return null;
					}
					return location_data.id
						? updateLocation({
							variables: {
								id: location_data.id,
								object: {
									name: location_data.name,
									address_line_1: location_data.address_line_1 ?? "",
									city: location_data?.city ?? "",
									state: location_data?.state ?? "",
									country: location_data?.country ?? "",
									postcode: location_data?.postcode ?? "",
									is_farm: user_role === "grower" ? true : false,
									area: location_data.area,
									crops: location_data.crops.map((e) => e.id),
									certifications: location_data.certifications.map((e) => e.id),
									color: color,
									lng: location_data.markerPosition.lng || 0,
									lat: location_data.markerPosition.lat || 0,
								},
							},
						})
							.then(({ data }) => {
								if (data && data.object && data.object.id && !user.location) {
									updateUser({ variables: { id: user.id, location_id: data.object.id } }).catch((e) => console.warn(e));
								}
							})
							.catch((e) => console.warn(e))
						: insertLocation({
							variables: {
								object: {
									name: location_data.name,
									address_line_1: location_data.address_line_1 ?? "",
									city: location_data?.city ?? "Cairo",
									state: location_data?.state ?? "Cairo ",
									country: location_data?.country ?? "EGY",
									postcode: location_data?.postcode ?? "00000",
									is_farm: user_role === "grower" ? true : false,
									area: location_data.area,
									crops: location_data.crops.map((e) => e.id),
									certifications: location_data.certifications.map((e) => e.id),
									color: color,
									lng: location_data.markerPosition.lng || 0,
									lat: location_data.markerPosition.lat || 0,
								},
							},
						})
							.then(({ data }) => {
								if (data && data.object && data.object.id && !user.location) {
									updateUser({ variables: { id: user.id, location_id: data.object.id } }).catch((e) => console.warn(e));
								}
							})
							.catch((e) => console.warn(e));
				}}
				disabled={insertLoading || updateLoading}
			>
				{t("save")}
			</Button>
		);

	return (
		<>
			{general_location?.markerPosition ? (
				<>
					{modal ? (
						<>
							{icon ? (
								<i className="las la-edit la-lg ml-2 btn float-right text-primary m-0 p-0" onClick={() => setOpen(!open)} />
							) : (
									<div onClick={() => setOpen(!open)} className="btn btn-sm btn-outline-primary float-right ml-3 mb-5">
										{!location_data?.id
											? user_role === "grower"
												? t("add_new_farm")
												: t("add_new_delivery_location")
											: user_role === "grower"
												? t("edit_farm")
												: t("edit_delivery_location")}
									</div>
								)}
							<Modal centered size="lg" show={open} onHide={() => setOpen(false)}>
								<Modal.Header closeButton>
									<Modal.Title>
										{!location_data?.id
											? user_role === "grower"
												? t("add_new_farm")
												: t("add_new_delivery_location")
											: user_role === "grower"
												? t("edit_farm")
												: t("edit_delivery_location")}
									</Modal.Title>
								</Modal.Header>
								<Modal.Body>{body()}</Modal.Body>

								<Modal.Footer>
									<Button
										variant="warning"
										onClick={() => {
											setOpen(false);
											setLocationData(base_location_data);
										}}
									>
										{t("cancel")}
									</Button>
									{footer()}
								</Modal.Footer>
							</Modal>
						</>
					) : (
							<>
								<h3>
									{!location_data?.id
										? user_role === "grower"
											? t("add_new_farm")
											: t("add_new_delivery_location")
										: user_role === "grower"
											? t("edit_farm")
											: t("edit_delivery_location")}
								</h3>
								<hr />
								{body()}
								<br />
								<hr />
								<span className="float-right">{footer()}</span>
								<br />
							</>
						)}
				</>
			) : !icon ? (
				<small className="bg-danger p-2 font-weight-bold">{t("please_enable_location_tracking")}</small>
			) : (
						""
					)}
		</>
	);
};
