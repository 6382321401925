import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { APP_CONFIG } from "../../../shared/app_config";
import { useTranslatedLabel } from "../../../shared/hooks";
import { PATHS } from "../config";
import { logout } from "../services/auth";
import { AuthContext } from "../store/auth";

const CustomLink = (props) => <NavLink activeClassName="active-link" className="side-bar-link" {...props} />;

const Sidebar = () => {
	const { t } = useTranslatedLabel();
	const { logoutUser } = useContext(AuthContext);
	return (
		<>
			<div className="sidebar bg-primary">
				<div className="sidebar-logo">
					<img src={APP_CONFIG.REACT_APP_BLACK_FLAT_LOGO} alt="logo" height={50} />
				</div>
				<div className="menu">
					<ul>
						<li>
							<CustomLink to={PATHS.dashboard} data-toggle="tooltip" data-placement="right" title={t("home")}>
								<span>
									<i className="la la-home"></i> {t("dashboard")}
								</span>
							</CustomLink>
						</li>
						<hr className="m-0 p-1" />

						<li>
							<CustomLink to={PATHS.sellOffers} data-toggle="tooltip" data-placement="right" title={t("sell_offers")}>
								<span>
									<i className="la la-tag"></i> {t("sell_offers")}
								</span>
							</CustomLink>
						</li>
						<li>
							<CustomLink to={PATHS.buyOffers} data-toggle="tooltip" data-placement="right" title={t("buy_offers")}>
								<span>
									<i className="la la-tag"></i> {t("buy_offers")}
								</span>
							</CustomLink>
						</li>
						<li>
							<CustomLink to={PATHS.orders} data-toggle="tooltip" data-placement="right" title={t("trade_orders")}>
								<span>
									<i className="la la-box"></i> {t("trade_orders")}
								</span>
							</CustomLink>
						</li>
						<hr className="m-0 p-1" />
						<li>
							<CustomLink to={PATHS.serviceOffers} data-toggle="tooltip" data-placement="right" title={t("service_offers")}>
								<span>
									<i className="la la-tractor"></i> {t("service_offers")}
								</span>
							</CustomLink>
						</li>
						<li>
							<CustomLink to={PATHS.serviceOrders} data-toggle="tooltip" data-placement="right" title={t("service_orders")}>
								<span>
									<i className="la la-concierge-bell"></i> {t("service_orders")}
								</span>
							</CustomLink>
						</li>
						<hr className="m-0 p-1" />
						<li>
							<CustomLink to={PATHS.payments} data-toggle="tooltip" data-placement="right" title={t("payments")}>
								<span>
									<i className="la la-cash-register"></i> {t("payments")}
								</span>
							</CustomLink>
						</li>
						<li>
							<CustomLink to={PATHS.logistics} data-toggle="tooltip" data-placement="right" title={t("logistics")}>
								<span>
									<i className="la la-truck"></i> {t("logistics")}
								</span>
							</CustomLink>
						</li>
						{/* <li>
							<CustomLink to={PATHS.invoices} data-toggle="tooltip" data-placement="right" title={t("invoices")}>
								<span>
									<i className="la la-file-invoice-dollar"></i> {t("invoices")}
								</span>
							</CustomLink>
						</li> */}
						<hr className="m-0 p-1" />
						<li>
							<CustomLink to={`${PATHS.growers}`} data-toggle="tooltip" data-placement="right" title={t("growers")}>
								<span>
									<i className="la la-user"></i> {t("growers")}
								</span>
							</CustomLink>
						</li>
						<li>
							<CustomLink to={`${PATHS.buyers}`} data-toggle="tooltip" data-placement="right" title={t("buyers")}>
								<span>
									<i className="la la-user"></i> {t("buyers")}
								</span>
							</CustomLink>
						</li>
						<li>
							<CustomLink to={`${PATHS.providers}`} data-toggle="tooltip" data-placement="right" title={t("providers")}>
								<span>
									<i className="la la-user"></i> {t("providers")}
								</span>
							</CustomLink>
						</li>
						<hr className="m-0 p-1" />
						<li>
							<CustomLink to={PATHS.farms} data-toggle="tooltip" data-placement="right" title={t("farms")}>
								<span>
									<i className="la la-campground"></i> {t("grower_farms")}
								</span>
							</CustomLink>
						</li>
						<li>
							<CustomLink to={PATHS.locations} data-toggle="tooltip" data-placement="right" title={t("locations")}>
								<span>
									<i className="la la-store-alt"></i> {t("buyer_locations")}
								</span>
							</CustomLink>
						</li>
						<li>
							<CustomLink to={PATHS.offices} data-toggle="tooltip" data-placement="right" title={t("locations")}>
								<span>
									<i className="la la-building"></i> {t("provider_locations")}
								</span>
							</CustomLink>
						</li>
						<hr className="m-0 p-1" />
						<li>
							<CustomLink to={PATHS.products} data-toggle="tooltip" data-placement="right" title={t("master_data")}>
								<span>
									<i className="la la-server"></i> {t("master_data")}
								</span>
							</CustomLink>
						</li>
						<li className="d-none">
							<CustomLink
								to={"./logout"}
								onClick={(e) => {
									e.preventDefault();
									logout();
									logoutUser();
								}}
								data-toggle="tooltip"
								data-placement="right"
								title={t("logout")}
							>
								<span>
									<i className="la la-sign-out-alt"></i> {t("logout")}
								</span>
							</CustomLink>
						</li>
						{/*}
						<li>
							<CustomLink to={PATHS.staff} data-toggle="tooltip" data-placement="right" title={t("staff")}>
								<span>
									<i className="la la-user-cog"></i> {t("staff")}
								</span>
							</CustomLink>
						</li>
						*/}
					</ul>
				</div>
			</div>
		</>
	);
};

export default Sidebar;
