export const PATHS = {
	base: "/",
	about: "/about-us",
	careers: "/careers",
	terms: "/terms",
	faq: "/faq",
	disclaimer: "/disclaimer",
	privacy: "/privacy",
	helpdesk: "/help-desk",
	partners: "/partners",
	marketplace: "/marketplace",
	forgrowers: "/forgrowers",
	investors: "/investors",
	forserviceproviders: "/forserviceproviders",
	support: "/support",
	signin: "/signin",
	register: "/register",
	authorize: "/authorize",
	confirmAuth: "/confirm-auth",
	resetPassword: "/reset-password",
	requestOtp: "/request-otp",
	verifyOtp: "/verify-otp",
	transactions: "/transactions",
	messages: "/messages",
	account: "/account",
	earnings: "/earnings",
	settings: "/settings",
	settingsProfile: "/settings/",
	settingsPreference: "/settings/preferences",
	settingsSecurity: "/settings/security",
	settingsLinkedAccounts: "/settings/linked-accounts",
	/* admin routes */
	dashboard: "/dashboard",
	accounting: "/accounting",
	orders: "/orders",
	orderDetails: "/orders/:id",
	offers: "/offers",
	offerDetails: "/offers/:id",
	buyOffers: "/buy-offers",
	sellOffers: "/sell-offers",
	locations: "/locations",
	locationDetails: "/locations/:id",
	offices: "/offices/",
	officeDetails: "/offices/:id",
	payments: "/payments",
	paymentDetails: "/payments/:id",
	invoices: "/invoices",
	invoiceDetails: "/invoices/:id",
	logistics: "/logistics",
	logisticDetails: "/logistics/:id",
	farms: "/farms",
	farmDetails: "/farms/:id",
	mapView: "/mapview",
	users: "/users",
	growers: "/users/grower",
	buyers: "/users/buyer",
	providers: "/users/provider",
	userDetails: "/users/:id",
	// master: "/master",
	logins: "/logins/",
	master: "/master/:tabId",
	serviceOffers: "/service-offers",
	serviceOfferDetails: "/service-offers/:id",
	serviceOrders: "/service-orders",
	serviceOrderDetails: "/service-orders/:id",
	staff: "/staff",
	staffDetails: "/staff/:id",
	setupWelcome: "/setup/welcome",
	setupCreateSuperAdmin: "/setup/create-account",
	setupInviteStaff: "/setup/invite-staff",
	products: "/master/products",
	productDetails: "/master/products/:id",
	productTypes: "/master/product-types",
	productTypeDetails: "/master/product-types/:id",
	services: "/master/services",
	serviceDetails: "/master/services/:id",
	serviceTypes: "/master/service-types",
	serviceTypeDetails: "/master/service-types/:id",
	certifications: "/master/certifications",
	certificationDetails: "/master/certifications/:id",
};
