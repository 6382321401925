import { useQuery } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import { Sugar } from "react-preloaders";
import { Link, Redirect } from "react-router-dom";
import adminWelcomeImg from "../../../../images/admin-welcome.png";
import { PATHS } from "../../config";
import { CHECK_SUPERADMIN_EXIST } from "../../gql";
import { AuthContext } from "../../store";

export const Welcome = () => {
	const { is_authenticated } = useContext(AuthContext);
	const [superAdminExist, setSuperAdminExist] = useState(undefined);
	const { data: superAdminList, loading: superAdminListLoading } = useQuery(CHECK_SUPERADMIN_EXIST);

	// Check if Super Admin Exist
	useEffect(() => {
		if (superAdminList && !superAdminListLoading) {
			const exists = superAdminList.users.length > 0 ? true : false;
			setSuperAdminExist(exists);
		}
	}, [superAdminList, superAdminListLoading]);

	if (superAdminExist && !is_authenticated) return <Redirect to={PATHS.signin} />;

	if (superAdminExist && is_authenticated) return <Redirect to={PATHS.dashboard} />;

	if (superAdminListLoading) return <Sugar color={"#ccd92b"} time={0} />;

	return (
		<>
			<div className="authincation bg-white admin-auth">
				<div className="container">
					<div className="row justify-content-center align-items-center">
						<div className="col-xl-7 col-md-6">
							<div className="auth-logo text-center my-3">
								<Link to={PATHS.base}>
									<img
										src="https://res.cloudinary.com/ampcome/image/upload/c_scale,w_100/v1592541595/mahaseel/mahaseel_new_logo.jpg"
										alt="Mahaseel logo"
									/>
								</Link>
							</div>
							<div className="card">
								<div className="card-body">
									<h3 className="text-center display-4 mb-3">
										Welcome to Mahaseel <span className="font-weight-bold">Admin</span>
									</h3>
									<div className="welcome-image mt-4 mb-5" style={{ backgroundImage: `url(${adminWelcomeImg})` }}></div>
									<div className="text-center">
										<Link className="btn btn-success" to={PATHS.setupCreateSuperAdmin}>
											<span>Add Administrator</span> <i className="las la-angle-right"></i>
										</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
