import { useSubscription } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useTranslatedLabel } from "../../../../shared/hooks";
import { GET_ALL_OFFERS, GET_TRADE_OFFER_BY_ID, GET_TRADE_ORDERS, GET_USER_BY_ID } from "../../gql";
import { AuthContext } from "../../store";
import { useAllObjectsQuery } from "../../widgets/allObjectsQuery";
import { BidMessage } from "./message";

export const ChatBox = ({ offer_id, bidder_id, connection_id, admin }) => {
	const { t, dt_tm } = useTranslatedLabel();
	const { user } = useContext(AuthContext);
	const user_id = admin?.id || user?.id;
	const [offers, setOffers] = useState([]);
	const [offer, setOffer] = useState(null);
	const [connection, setConnection] = useState(null);
	const [bidder, setBidder] = useState(null);

	const { data: offerData, loading: offerLoading, error: offerError } = useSubscription(GET_TRADE_OFFER_BY_ID, {
		variables: { id: offer_id },
	});
	useEffect(() => {
		if (!offerLoading && offerData?.object && !offerError) setOffer(offerData?.object);
	}, [offerData, offerError, offerLoading]);

	const { data: connectionData, loading: connectionLoading, error: connectionError } = useSubscription(GET_TRADE_OFFER_BY_ID, {
		variables: { id: connection_id },
	});
	useEffect(() => {
		if (!connectionLoading && connectionData?.object && !connectionError) setConnection(connectionData?.object);
	}, [connectionData, connectionError, connectionLoading]);

	const { data: bidderData, loading: bidderLoading, error: bidderError } = useSubscription(GET_USER_BY_ID, {
		variables: { id: bidder_id },
	});
	useEffect(() => {
		if (!bidderLoading && bidderData?.object && !bidderError) setBidder(bidderData?.object);
	}, [bidderData, bidderError, bidderLoading]);

	const { objects, loading, error } = useAllObjectsQuery({
		entity: "requests",
		query: GET_ALL_OFFERS,
		table: "requests",
		where: {
			request_id: { _eq: connection_id },
			user_id: { _in: [bidder_id, user_id] },
			offer_user_id: { _in: [bidder_id, user_id] },
		},
		order_by: { timestamp: "asc" },
	});

	useEffect(() => {
		if (!loading && objects && !error) setOffers(objects);
	}, [loading, error, objects]);

	const { objects: orders } = useAllObjectsQuery({
		entity: "trade_orders",
		query: GET_TRADE_ORDERS,
		where: {
			_and: [
				{ _or: [{ request_id: { _eq: offer_id } }, { request: { request_id: { _eq: offer_id } } }] },
				{
					_or: [
						{ seller_id: { _eq: user_id }, buyer_id: { _eq: bidder_id } },
						{ buyer_id: { _eq: user_id }, seller_id: { _eq: bidder_id } },
					],
				},
			],
		},
		order_by: { uid: "desc" },
	});

	const is_loading = offerLoading || connectionLoading || loading;

	return (
		<>
			<div className="card p-1 mb-3 bg-secondary">
				<div className="card-header p-2 bg-white" style={{ flexDirection: "column", alignItems: "unset" }}>
					<div className="row m-1">
						<div className="col">
							<h5>{bidder?.name}</h5>
						</div>
						<div className="col text-right">
							<h5>{admin?.name}</h5>
						</div>
					</div>
					{orders.map((o) => (
						<div className="row p-1">
							<div className="col-sm-12 col-md-4">{`${t("order")} # ${o?.uid}`}</div>
							<div className="col-sm-12 col-md-4">{dt_tm(o.created_at)}</div>
							<div className="col-sm-12 col-md-4">{t(o.status)}</div>
						</div>
					))}
				</div>
				<div className="card-footer p-2 bg-white">
					{is_loading ? (
						<div className="d-flex align-items-center mb-3 w-50">
							<div className="mb-2">
								<Skeleton height={75} />
							</div>
							<div className="mb-2">
								<Skeleton height={25} />
							</div>
						</div>
					) : (
						<>
							<div className="bg-white div-1">
								<div key="main" className={["message", `${connection?.user_id === user_id ? "mine" : ""}`].join(" ")}>
									<BidMessage offer={offer} bid={connection} admin={admin} />
								</div>
								{offers.map((o, i) => (
									<div key={i} className={["message", `${o?.user_id === user_id ? "mine" : ""}`].join(" ")}>
										<BidMessage offer={offer} bid={o} admin={admin} />
									</div>
								))}
							</div>
						</>
					)}
				</div>
			</div>
		</>
	);
};
