import { useMutation } from "@apollo/client";
import { Steps, Table } from "antd";
import dayjs from "dayjs";
import React, { useContext, useState } from "react";
import { Badge } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Skelton from "react-loading-skeleton";
import { useParams } from "react-router-dom";
import ViewTradeOffer from "../../../../apps/client/pages/marketplace/view-offer";
import { AntdTable } from "../../../../shared/components";
import { useTranslatedLabel } from "../../../../shared/hooks";
import { CustomLink, Layout } from "../../components";
import { GET_REQUESTS, INSERT_ONE } from "../../gql";
import { useAllObjectsQuery } from "../../hooks/allObjectsQuery";
import { AuthContext } from "../../store";
import { OfferEditModal } from "./offer-edit-modal";

const { Step } = Steps;

const data_fields = ` created_at uid enum_request_type { comment } user { name email } enum_request_status { comment }
						advance_amount total_amount price_per_unit number_of_units advance_payment_percentage 	`;
export function OfferDetails() {
	const { id } = useParams();
	return (
		<Layout title={`Trade Offer Details`}>
			<div className="bg-light pb-5 order-details">
				<div className="container">
					<ViewTradeOffer offer_id={id} component={true} inAdmin={true} />
				</div>
			</div>
		</Layout>
	);
}

export function OfferDetailsOLD() {
	const { t, dt } = useTranslatedLabel();
	const { id } = useParams();
	const { user } = useContext(AuthContext);
	// const { general_request_status } = useContext(GeneralContext);

	const { register, handleSubmit } = useForm({
		mode: "onChange",
	});
	const [addNote] = useMutation(INSERT_ONE("notes"), {
		onCompleted: (data) => {
			refetch();
		},
		onError: (error) => {
			console.error(error.message);
		},
	});
	const { objects, loading, error, refetch } = useAllObjectsQuery({
		query: GET_REQUESTS,
		where: {
			id: { _eq: id },
		},
	});

	const onAddNote = (e) => {
		if (e.note) {
			addNote({
				variables: {
					object: {
						request_id: id,
						text: e.note,
						user_id: user.id,
					},
				},
			});
		}
	};

	const [show, setShow] = useState(false);

	const onEdit = () => setShow(true);
	const onClose = () => {
		setShow(false);
	};

	if (loading) {
		return <Skelton height="80vh" />;
	} else if (!error && objects) {
		const [current] = objects;
		return (
			<Layout>
				<OfferEditModal show={show} onClose={onClose} order={current} />
				<div className="bg-light pb-5 order-details">
					<div className="container">
						<div className="d-flex pt-5">
							<div className="order-details-main" style={{ flexGrow: 24 }}>
								<div className="mb-3">
									<div className="order-details-summary">
										<div className="d-flex justify-content-between  bg-white p-4">
											<div>
												<h3 className="display-5 font-weight-bold">
													<span className="mr-1">Offer #{current?.uid}</span>
													<i className="las la-clipboard"></i>
												</h3>
												<div className="d-flex sm-text">
													<p className="ml-1">
														<span className="font-weight-bold">Date:</span>{" "}
														<span>{dayjs(current?.created_at).format("MMMM DD, YYYY")}</span>
													</p>
												</div>
											</div>
											<div className="text-right">
												<h3 className="order-amount font-weight-bold">{`$ ${current?.total_amount}`}</h3>
												<Badge variant="success" className="text-capitalize">
													{current?.enum_request_status?.comment} <i className="las la-check-circle"></i>
												</Badge>
											</div>
										</div>
									</div>
									{/* <div className="pt-2 pb-2 bg-white mb-1">
										<h5 className="font-weight-bold ml-3">Order Status</h5>
										<div className="row my-4">
											<div className="col-12">
												<Steps
													direction="horizontal"
													size="small"
													current={general_request_status.findIndex((item) => item.value === current?.status)}
												>
													{general_request_status.map((item) => {
														if (current?.status !== "rejected" || current?.status !== "cancelled") {
															if (item.value !== "rejected" && item.value !== "cancelled") {
																return <Step key={item.value} title={item.comment} />;
															} else {
																return null;
															}
														} else {
															if (item.value !== "accepted") {
																return (
																	<Step
																		key={item.value}
																		title={item.comment}
																		status={item.value === "cancelled" && "error"}
																	/>
																);
															} else {
																return null;
															}
														}
													})}
												</Steps>
											</div>
										</div>
									</div> */}
								</div>
								<div className="pt-2 pb-2 bg-white mb-3">
									<h5 className="font-weight-bold ml-3">Bids</h5>
									<Table
										rowKey="id"
										columns={[
											{
												title: t("date"),
												key: "from_date",
												dataIndex: "from_date",
											},
											{
												title: t("id"),
												key: "uid",
												render: (row) => (
													<CustomLink page={"users"} id={row?.id}>
														{row?.uid}
													</CustomLink>
												),
											},
											{
												title: t("user"),
												key: "user",
												render: (row) => (
													<CustomLink page={"users"} id={row.user?.id}>
														{row.user?.email}
													</CustomLink>
												),
											},
											{
												title: "Location",
												key: "location",
												render: AntdTable.LocationRenderer("location"),
											},
										]}
										dataSource={current ? current?.similar_offers_by_type_user : []}
									/>
								</div>
								<div className="pt-2 pb-2 bg-white mb-3">
									<h5 className="font-weight-bold ml-3">{t("matching_connections")}</h5>
									<AntdTable
										columns={[
											{
												title: t("date"),
												key: "timestamp",
												render: AntdTable.DateRenderer("created_at"),
											},
											{
												title: t("id"),
												key: "uid",
												dataIndex: "uid",
											},
											{
												title: t("offer"),
												key: "enum_request_type.comment",
												render: (row) => row?.enum_request_type?.comment,
											},
											{
												title: t("user"),
												key: "user.name",
												render: (row) => row?.user?.name,
											},
											{
												title: t("price_per_unit"),
												key: "price_per_unit",
												render: AntdTable.MoneyRenderer("price_per_unit"),
											},
											{
												title: t("quantity"),
												key: "number_of_units",
												render: AntdTable.NumberRenderer("number_of_units"),
											},
											{
												title: "Total Amount",
												key: "total_amount",
												render: AntdTable.MoneyRenderer("total_amount"),
											},
											{
												title: `${t("advance")} %`,
												key: "advance_payment_percentage",
												dataIndex: "advance_payment_percentage",
											},
											{
												title: "Advance Amount",
												key: "advance_amount",
												render: AntdTable.MoneyRenderer("advance_amount"),
											},
											{
												title: t("status"),
												key: "enum_request_status?.comment",
												render: (row) => row.enum_request_status?.comment,
											},
										]}
										entity="requests"
										where={{
											request_id: { _eq: id },
										}}
										query_fields={data_fields}
									/>
								</div>
							</div>
							<div
								className="new-offer-preview bg-white p-3 d-flex flex-column justify-content-end align-self-start"
								style={{ minHeight: "40rem", flexBasis: "20rem" }}
							>
								<div className="flex-grow-1 mb-3">
									<h4 className="font-weight-bold text-uppercase">
										{t("offer_details")}
										<div className="btn btn-outline-primary  float-right" onClick={onEdit}>
											<span>{t("edit")}</span>
										</div>
									</h4>
									<hr />
									<div className="mb-3">
										<span className="text-muted mb-0">{t("seller")}</span>
										<CustomLink page="users" id={current?.seller?.id}>
											<span className="font-weight-bold mb-0 float-right">{current?.seller?.name}</span>
										</CustomLink>
									</div>
									<hr />
									<div className="mb-3">
										<span className="text-muted mb-0">{t("product")}</span>
										<CustomLink page="products" id={current?.product_type?.product?.id}>
											<span className="font-weight-bold mb-0 float-right">{current?.product_type?.product?.name}</span>
										</CustomLink>
									</div>
									<div className="mb-3">
										<span className="text-muted mb-0">{t("variety")}</span>
										<CustomLink page="productTypes" id={current?.product_type?.id}>
											<span className="font-weight-bold mb-0 float-right">{current?.product_type?.name}</span>
										</CustomLink>
									</div>
									<hr />
									<div className="mb-3">
										<span className="text-muted mb-0">{`${t("quantity")} (${t("tonne")})`}</span>
										<span className="font-weight-bold mb-0 float-right">{current?.number_of_units}</span>
									</div>
									<div className="mb-3">
										<span className="text-muted mb-0">{t("price_per_unit")}</span>
										<span className="font-weight-bold mb-0 float-right">{current?.price_per_unit}</span>
									</div>

									<div className="mb-3">
										<span className="text-muted mb-0">{`${t("advance")} %`}</span>
										<span className="font-weight-bold mb-0 float-right">{current?.advance_payment_percentage} %</span>
									</div>
									<hr />
									<div className="mb-3">
										<span className="text-muted mb-0">{t("total_amount")}</span>
										<span className="font-weight-bold mb-0 float-right">$ {current?.total_amount}</span>
									</div>
									<div className="mb-3">
										<span className="text-muted mb-0">{t("advance_amount")}</span>
										<span className="font-weight-bold mb-0 float-right">$ {current?.advance_amount}</span>
									</div>
									<div className="mb-3">
										<span className="text-muted mb-0">{t("remaining_amount")}</span>
										<span className="font-weight-bold mb-0 float-right">
											$ {parseFloat(current?.total_amount) - parseFloat(current?.advance_amount)}
										</span>
									</div>
									<hr />
									<div className="mb-3">
										<span className="text-muted mb-0">{t("start_date")}</span>
										<span className="font-weight-bold mb-0  float-right">{dt(current?.from_date)}</span>
									</div>
									<div className="mb-3">
										<span className="text-muted mb-0">{t("end_date")}</span>
										<span className="font-weight-bold mb-0  float-right">{dt(current?.to_date)}</span>
									</div>
									<div className="mb-3">
										<span className="text-muted mb-0">Days to Maturity</span>
										<span className="font-weight-bold mb-0  float-right">{current?.days_to_maturity}</span>
									</div>
									<hr />
									<div className="mb-3">
										<p className="text-muted mb-0">{t("origin")}</p>
										<CustomLink pages="location" id={current?.location?.id}>
											<span className="font-weight-bold mb-0">
												{current?.location?.address_line_1},{current?.location?.address_line_2}
												{current?.location?.address_line_3}
											</span>
											<span className="font-weight-bold mb-0">{current?.location?.city}</span>
											<span className="font-weight-bold mb-0">{current?.location?.state},</span>
											<span className="font-weight-bold mb-0">{current?.location?.country},</span>
											<span className="font-weight-bold mb-0">{current?.location?.postcode}</span>
										</CustomLink>
									</div>
									<hr />

									<hr />
									<div className=" mb-3 bg-white">
										<p className="text-muted mb-0">{t("notes")}</p>
										<form onSubmit={handleSubmit(onAddNote)}>
											<textarea
												name="note"
												ref={register({ required: "Required" })}
												placeholder="Add a Note"
												rows="5"
												class="ant-input"
											/>

											<div className="d-flex ">
												<input type="submit" className="btn btn-primary  mt-2 ml-auto" value="Add Note" />
											</div>
										</form>
									</div>
									<div className="mb-3">
										<div className=" m-2 p-4">
											{true ? (
												<Steps direction="vertical" progressDot={true}>
													{current?.notes?.map((item, index) => (
														<Step
															className="custom-step"
															key={item.id}
															title={item.text}
															description={item.user.email}
															status={current?.notes.length - 1 === index ? "process" : "finish"}
														/>
													))}
												</Steps>
											) : (
												<Steps direction="vertical" progressDot={true}>
													<Step className="custom-step" title={"No notes are added"} />
												</Steps>
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Layout>
		);
	} else {
		return null;
	}
}
