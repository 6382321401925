import { Input, Table, Pagination } from "antd";
import dayjs from "dayjs";
import React, { useState } from "react";
// import { Pagination } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";
import { CustomLink } from "../../apps/admin/components";
import { usePaginatedQuery } from "../../apps/client/widgets/dataQuery";
import { makeTree, updateKeyIndex } from "../helpers/utils";
import { useTranslatedLabel } from "../hooks";

export const AntdTable = (props) => {
  const { t } = useTranslatedLabel();
  const { tree, columns } = props;
  const [searchText, setSearchText] = useState("");

  let search_on = {};
  if (searchText && props.search_fields && props.search_fields.length > 0) {
    search_on["_or"] = props.search_fields.map((e) => {
      let x = {};
      x[e] = { _ilike: "%" + searchText + "%" };
      return x;
    });
  }

  const {
    objects,
    loading,
    refetch,
    setPage,
    limit,
    offset,
    page,
    total,
  } = usePaginatedQuery({
    ...props,
    search_on,
  });

  const items = updateKeyIndex(objects, refetch).map((e) => {
    let newObject = { ...e };
    if (e?.translations?.length > 0)
      e.translations.map(
        (translation) => (newObject[translation.language] = translation.text)
      );
    newObject.t = t;
    return newObject;
  });

  const paginationLine = () => (
    <div className="row mb-1">
      <div className="col-md-6 col-sm-6 col-lg-6">
        <div className="d-flex  rounded border border-light p-2">
          <h5>
            {offset + 1} - {offset + limit > total ? total : offset + limit} |{" "}
            {t("total")}: {total}
          </h5>
        </div>
      </div>
      <div className="col-md-6 col-sm-6 col-lg-6">
        <div className="d-flex justify-content-end rounded border border-light p-2">
          {loading ? (
            <div className="w-25">
              <Skeleton height={30} />
            </div>
          ) : (
            <Pagination
              total={total}
              pageSize={limit}
              current={page}
              onChange={(num) => setPage(num)}
              showSizeChanger={false}
            />
            // <>
            // 	<Pagination>
            // 		<Pagination.Prev disabled={page === 1} onClick={() => setPage(page - 1)} />
            // 		{Array(pages)
            // 			.fill()
            // 			.map((page, index) => (
            // 				<Pagination.Item
            // 					key={index}
            // 					children={index + 1}
            // 					active={page === index + 1}
            // 					onClick={() => setPage(index + 1)}
            // 				/>
            // 			))}
            // 		<Pagination.Next activeLabel="next" disabled={page === pages} onClick={() => setPage(page + 1)} />
            // 	</Pagination>
            // </>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <>
      {props.search_fields && props.search_fields.length > 0 && (
        <div className="p-2">
          <Input
            type="search"
            name="search"
            id="search"
            placeholder={t("search")}
            className="form-control"
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>
      )}
      <Table
        size="small"
        bordered
        loading={loading}
        columns={tree ? [{ key: "id" }, ...columns] : columns}
        dataSource={tree ? makeTree(items) : items}
        rowKey="id"
        pagination={false}
        {...props}
      />
      {paginationLine()}
    </>
  );
};

// Renderers
AntdTable.MoneyRenderer = (field, path) => (row) =>
  row ? (
    <span className="pull-right">
      {path ? (
        <Link to={path(row)}>
          <NumberFormat
            value={parseFloat(row[field]).toFixed(2)}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$ "}
          />
        </Link>
      ) : (
        <NumberFormat
          value={parseFloat(row[field]).toFixed(2)}
          displayType={"text"}
          thousandSeparator={true}
          prefix={"$ "}
        />
      )}
    </span>
  ) : (
    ""
  );
AntdTable.NumberRenderer = (field, path) => (row) =>
  row ? (
    <span className="pull-right">
      {path ? (
        <Link to={path(row)}>
          <NumberFormat
            value={row[field]}
            displayType={"text"}
            thousandSeparator={true}
          />
        </Link>
      ) : (
        <NumberFormat
          value={row[field]}
          displayType={"text"}
          thousandSeparator={true}
        />
      )}
    </span>
  ) : (
    ""
  );
AntdTable.AggregateCountRenderer = (field, path) => (row) =>
  row && row[field] ? (
    <span className="pull-right">
      {path ? (
        <Link to={path(row)}>
          <NumberFormat
            value={row[field]?.aggregate?.count}
            displayType={"text"}
            thousandSeparator={true}
          />
        </Link>
      ) : (
        <NumberFormat
          value={row[field]?.aggregate?.count}
          displayType={"text"}
          thousandSeparator={true}
        />
      )}
    </span>
  ) : (
    ""
  );
AntdTable.AggregateSumRenderer = (field, inner, path) => (row) =>
  row && row[field] ? (
    <span className="pull-right">
      {path ? (
        <Link to={path(row)}>
          <NumberFormat
            value={row[field]?.aggregate?.sum[inner]}
            displayType={"text"}
            thousandSeparator={true}
          />
        </Link>
      ) : (
        <NumberFormat
          value={row[field]?.aggregate?.sum[inner]}
          displayType={"text"}
          thousandSeparator={true}
        />
      )}
    </span>
  ) : (
    ""
  );
AntdTable.NameRenderer = (row) => row?.t(row?.id, row?.name);

AntdTable.TextRenderer = (field) => (row) => row?.[field];

AntdTable.ProductRenderer = (row) =>
  row?.t(row?.product_type?.product?.id, row?.product_type?.product?.name);
AntdTable.VarietyRenderer = (row) =>
  row?.t(row?.product_type?.id, row?.product_type?.name);
AntdTable.ServiceRenderer = (row) =>
  row?.t(row?.service_type?.service?.id, row?.service_type?.service?.name);
AntdTable.CategoryRenderer = (row) =>
  row?.t(row?.service_type?.id, row?.service_type?.name);

AntdTable.DateRenderer = (field) => (row) =>
  row && row[field] ? dayjs(row[field]).format("DD/MM/YYYY") : "";
AntdTable.UserRenderer = (field) => (row) =>
  row && row[field] ? (
    <CustomLink page="users" id={row[field]?.id}>
      {row[field]?.name}
    </CustomLink>
  ) : (
    ""
  );
AntdTable.LocationRenderer = (field) => (row) =>
  row && row[field] ? row[field]?.city : "";
AntdTable.RoleRenderer = (row) => {
  const {
    agent_access,
    buyer_access,
    grower_access,
    provider_access,
    moderator_access,
  } = row;
  if (agent_access) return "Agent";
  if (buyer_access) return "Buyer";
  if (grower_access) return "Grower";
  if (provider_access) return "Provider";
  if (moderator_access) return "Moderator";
};

// Sorters
AntdTable.GenericSorter = (field) => (a, b) => a[field] - b[field];
AntdTable.TextSorter = (field) => (a, b) =>
  a[field] && b[field] && a[field].localeCompare(b[field]);

AntdTable.ProductSorter = (a, b) =>
  a?.product_type?.product?.name?.localeCompare(b?.product_type?.product?.name);
AntdTable.VarietySorter = (a, b) =>
  a?.product_type?.name?.localeCompare(b?.product_type?.name);
AntdTable.ServiceSorter = (a, b) =>
  a?.service_type?.service?.name?.localeCompare(b?.service_type?.service?.name);
AntdTable.CategorySorter = (a, b) =>
  a?.service_type?.name?.localeCompare(b?.service_type?.name);
AntdTable.UserSorter = (field) => (a, b) =>
  a[field] &&
  b[field] &&
  `${a[field]?.name || ""}`.localeCompare(`${b[field]?.name || ""}`);
AntdTable.LocationSorter = (field) => (a, b) =>
  a[field] &&
  b[field] &&
  `${a[field]?.city || ""}`.localeCompare(`${b[field]?.city || ""}`);
AntdTable.DateSorter = (field) => (a, b) =>
  a[field] &&
  b[field] &&
  dayjs(a[field]).millisecond() - dayjs(b[field]).millisecond();
AntdTable.AggregateCountSorter = (field) => (a, b) =>
  a[field] &&
  b[field] &&
  a[field]?.aggregate?.count - b[field]?.aggregate?.count;
AntdTable.AggregateSumSorter = (field, inner) => (a, b) =>
  a[field] &&
  b[field] &&
  a[field]?.aggregate?.sum[inner] - b[field]?.aggregate?.sum[inner];
AntdTable.ActiveRenderer = (row) =>
  row.active ? (
    <i className="la la-check-circle la-lg text-success align-align-self-center" />
  ) : (
    ""
  );
