import React from "react";
import { ComposableMap, Geographies, Geography, Marker } from "react-simple-maps";
import { useTranslatedLabel } from "../hooks/useTranslatedLabel";

const geoUrl = "https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json";

const markers = [
	{
		markerOffset: -15,
		name: "Egypt",
		coordinates: [30.0595581, 31.2234449],
		type: "grower",
	},
	{
		markerOffset: -15,
		name: "Sudan",
		coordinates: [34.813903, 3.7309508],
		type: "grower",
	},
	{
		markerOffset: -15,
		name: "Jordan",
		coordinates: [36.0015083, 31.2773666],
		type: "grower",
	},
	{
		markerOffset: -15,
		name: "Lebananon",
		coordinates: [35.4867727, 33.8892527],
		type: "grower",
	},
	{
		markerOffset: -15,
		name: "South Africa",
		coordinates: [28.0578674, -25.7582737],
		type: "grower",
	},

	{
		markerOffset: -15,
		name: "Spain",
		coordinates: [-3.8196194, 40.4381311],
		type: "grower",
	},
	{
		markerOffset: -15,
		name: "Greece",
		coordinates: [22.238301, 38.1288336],
		type: "grower",
	},
	{
		markerOffset: -15,
		name: "Ankara, Turkey",
		coordinates: [32.622682, 39.9035557],
		type: "grower",
	},
	{
		markerOffset: -15,
		name: "Iran",
		coordinates: [49.1919767, 32.2241683],
		type: "grower",
	},
	{
		markerOffset: -15,
		name: "Delhi, India",
		coordinates: [76.9531797, 28.6471948],
		type: "grower",
	},
	{
		markerOffset: -15,
		name: "Morocoo",
		coordinates: [-8.0778929, 31.6347485],
		type: "grower",
	},

	{
		markerOffset: -15,
		name: "USA",
		coordinates: [-88.0121478, 41.8333925],
		type: "customer",
	},

	{
		markerOffset: -15,
		name: "UAE",
		coordinates: [55.0876629, 25.0755935],
		type: "customer",
	},
	{
		markerOffset: -15,
		name: "France",
		coordinates: [-2.4346075, 46.1314287],
		type: "customer",
	},
	{
		markerOffset: -15,
		name: "UK",
		coordinates: [-0.2416799, 51.5285582],
		type: "customer",
	},
	{
		markerOffset: -15,
		name: "Germany",
		coordinates: [5.9695978, 51.0899707],
		type: "customer",
	},
	{
		markerOffset: -15,
		name: "Czech",
		coordinates: [14.3255425, 50.0595854],
		type: "customer",
	},
	{
		markerOffset: -15,
		name: "Ukraine",
		coordinates: [30.3926092, 50.4019514],
		type: "customer",
	},
	{
		markerOffset: -15,
		name: "Malatya, Turkey",
		coordinates: [38.2059179, 38.3528872],
		type: "customer",
	},
	{
		markerOffset: -15,
		name: "Bangalore, India",
		coordinates: [77.4908548, 12.95396],
		type: "customer",
	},

	{
		markerOffset: -15,
		name: "Russia",
		coordinates: [68.8498354, 49.7653882],
		type: "customer",
	},
	{
		markerOffset: -15,
		name: "China",
		coordinates: [86.0847134, 34.4197637],
		type: "customer",
	},
	{
		markerOffset: -15,
		name: "Thailand",
		coordinates: [96.9946505, 13.0003412],
		type: "customer",
	},
	{
		markerOffset: -15,
		name: "Indonesia",
		coordinates: [108.8489346, -2.4153094],
		type: "customer",
	},

	{
		markerOffset: -15,
		name: "Saudi",
		coordinates: [40.5808227, 24.0133137],
		type: "customer",
	},
];

export const MapChart = ({ setTooltipContent }) => {
	const { t } = useTranslatedLabel();

	return (
		<>
			<ComposableMap
				height={300}
				projection="geoMercator"
				projectionConfig={{
					scale: 120,
				}}
			>
				<Geographies geography={geoUrl}>
					{({ geographies }) =>
						geographies.map((geo) => (
							<Geography key={geo.rsmKey} geography={geo} fill="#c0ca33" stroke="#ffffff" strokeWidth={0.35} />
						))
					}
				</Geographies>

				{markers.map(function ({ name, coordinates, markerOffset, type }) {
					const growercolor = "#0000000";
					const customercolor = "#FF004e";
					var color = growercolor;
					if (type === "customer") {
						color = customercolor;
					}
					return (
						<Marker
							key={name}
							coordinates={coordinates}
							style={{ cursor: "pointer" }}
							data-tip=""
							onMouseEnter={() => {
								setTooltipContent(`${name}`);
							}}
							onMouseLeave={() => {
								setTooltipContent("");
							}}
						>
							<circle r={5} fill={color} stroke="#fff" strokeWidth={0.5}>
								<animate attributeName="r" from="5" to="12" dur="1.5s" begin="0s" repeatCount="indefinite" />
								<animate attributeName="opacity" from="1" to="0" dur="1.5s" begin="0s" repeatCount="indefinite" />
							</circle>
							<circle r={5} fill="#ffffff" stroke={color} strokeWidth={2} />
						</Marker>
					);
				})}

				<Marker key="test" coordinates={[106.4751408, 34.0528941]}>
					<circle r={2} fill="#000000" stroke="#000000" strokeWidth={1} />
					<text x={5} y={2} style={{ fontSize: "8px" }}>
						{t("sellers")}
					</text>
				</Marker>

				<Marker key="test2" coordinates={[106.4751408, 40.861276]}>
					<circle r={2} fill="#FF004e" stroke="#FF004e" strokeWidth={1} />
					<text x={5} y={2} style={{ fontSize: "8px" }}>
						{t("buyers")}
					</text>
				</Marker>
			</ComposableMap>
		</>
	);
};
