import { gql } from "@apollo/client";

export const INSERT_ONE = (entity) =>
	gql`mutation insert_${entity}_one($object: ${entity}_insert_input!) { object: insert_${entity}_one(object: $object) { id } }`;

export const INSERT_MANY = (entity) =>
	gql`mutation insert_${entity}($objects: [${entity}_insert_input!]!) { objects: insert_${entity}(objects: $objects) { affected_rows } }`;

export const UPSERT_ONE = (entity, key, columns) =>
	gql`mutation insert_${entity}_one($object: ${entity}_insert_input!) { object: insert_${entity}_one(object: $object, on_conflict: {constraint: ${key}, update_columns: [${columns}]}) { id } }`;
export const UPSERT_MANY = (entity, key, columns) =>
	gql`mutation insert_${entity}($objects: [${entity}_insert_input!]!) { objects: insert_${entity}(objects: $objects, on_conflict: {constraint: ${key}, update_columns: [${columns}]}) { affected_rows } }`;

export const UPDATE_ONE = (entity) =>
	gql`mutation update_${entity}_by_pk($id: uuid!, $object: ${entity}_set_input) { object: update_${entity}_by_pk(pk_columns: {id : $id}, _set: $object) { id } }`;

export const UPDATE_ONE_BY_ID = (entity) =>
	gql`mutation update_${entity}_by_pk($id: String!, $object: ${entity}_set_input) { object: update_${entity}_by_pk(pk_columns: {id : $id}, _set: $object) { id } }`;

export const UPDATE_MANY = (entity) =>
	gql`mutation update_${entity}($where: ${entity}_bool_exp!, $object: ${entity}_set_input) { objects: update_${entity}(where: $where, _set: $object) { affected_rows } }`;

export const DELETE_ONE = (entity) => gql`mutation delete_${entity}_by_pk($id: uuid!) { object: delete_${entity}_by_pk(id : $id) { id } }`;
export const DELETE_MANY = (entity) =>
	gql`mutation delete_${entity}($where: ${entity}_bool_exp!) { object: delete_${entity}(where : $where) { affected_rows } }`;

export const INSERT_CERTIFICATE = gql`
	mutation insert_certifications_one($inputs: certifications_insert_input!) {
		insert_certifications_one(object: $inputs) {
			id
			name
			active
			uid
		}
	}
`;

export const UPDATE_CERTIFICATE = gql`
	mutation update_certifications_by_pk($pk_inputs: certifications_pk_columns_input!, $update_inputs: certifications_set_input) {
		update_certifications_by_pk(pk_columns: $pk_inputs, _set: $update_inputs) {
			id
			name
			active
			uid
		}
	}
`;

export const INSERT_PRODUCT = gql`
	mutation insert_product($inputs: products_insert_input!) {
		insert_products_one(object: $inputs) {
			id
			name
			active
			uid
		}
	}
`;

export const UPDATE_PRODUCT = gql`
	mutation update_products_by_pk($pk_inputs: products_pk_columns_input!, $update_inputs: products_set_input) {
		update_products_by_pk(pk_columns: $pk_inputs, _set: $update_inputs) {
			id
			name
			active
			uid
		}
	}
`;

export const INSERT_VARIETY = gql`
	mutation insert_product_types($inputs: product_types_insert_input!) {
		insert_product_types_one(object: $inputs) {
			id
			name
			active
			uid
			product_id
		}
	}
`;

export const UPDATE_VARIETY = gql`
	mutation update_product_types_by_pk($pk_inputs: product_types_pk_columns_input!, $update_inputs: product_types_set_input) {
		update_product_types_by_pk(pk_columns: $pk_inputs, _set: $update_inputs) {
			id
			name
			active
			uid
		}
	}
`;

export const INSERT_SERVICE_CATEGORY = gql`
	mutation insert_services($inputs: services_insert_input!) {
		insert_services_one(object: $inputs) {
			id
			name
			active
			uid
		}
	}
`;

export const UPDATE_SERVICE_CATEGORY = gql`
	mutation update_products_by_pk($pk_inputs: services_pk_columns_input!, $update_inputs: services_set_input) {
		update_services_by_pk(pk_columns: $pk_inputs, _set: $update_inputs) {
			id
			name
			active
			uid
		}
	}
`;

export const INSERT_SUBCATEGORY = gql`
	mutation insert_service_types($inputs: service_types_insert_input!) {
		insert_service_types_one(object: $inputs) {
			id
			name
			active
			uid
			service_id
		}
	}
`;

export const UPDATE_SUBCATEGORY = gql`
	mutation update_service_types_by_pk($pk_inputs: service_types_pk_columns_input!, $update_inputs: service_types_set_input) {
		update_service_types_by_pk(pk_columns: $pk_inputs, _set: $update_inputs) {
			id
			name
			active
			uid
		}
	}
`;

export const CREATE_NOTES = gql`
	mutation create_notes($input: notes_insert_input!) {
		insert_notes_one(object: $input) {
			id
			text
		}
	}
`;

export const EDIT_OFFER = gql`
	mutation editOffer($pk_col: requests_pk_columns_input!, $payload: requests_set_input) {
		update_requests_by_pk(pk_columns: $pk_col, _set: $payload) {
			product_type {
				name
				product {
					name
				}
			}
			enum_request_status {
				comment
			}
			enum_request_type {
				comment
			}
			product_id
			product_type_id
			status
			type
			price_per_unit
			number_of_units
			from_date
			to_date
			advance_payment_percentage
		}
	}
`;

export const EDIT_ORDER = gql`
	mutation editOrder($pk_col: trade_orders_pk_columns_input!, $payload: trade_orders_set_input) {
		update_trade_orders_by_pk(pk_columns: $pk_col, _set: $payload) {
			product_type {
				name
				product_id
				product {
					name
				}
			}
			enum_order_status {
				comment
			}
			product_id
			product_type_id
			status
			price_per_unit
			number_of_units
			from_date
			to_date
			advance_payment_percentage
		}
	}
`;

export const INSERT_LOGISTICS = gql`
	mutation insert_logistics($inputs: logistics_insert_input!) {
		insert_logistics_one(object: $inputs) {
			id
			active
			contact_name
			contact_phone
			delivery_date
			tracking_id
			status
		}
	}
`;

export const REQUEST_OTP = gql`
	mutation RequestOTP($phone: String!) {
		insert_otps(objects: [{ phone_number: $phone }]) {
			returning {
				id
				code
				phone_number
				issued_at
				expires_at
				is_used
			}
		}
	}
`;

export const VERIFY_PHONE_NUMBER = gql`
	mutation VerifyPhoneNumber($otp_id: uuid!, $is_used: Boolean!, $phone_number: String!, $user_id: String!) {
		update_otps(where: { id: { _eq: $otp_id } }, _set: { is_used: $is_used }) {
			affected_rows
		}
		update_users(where: { id: { _eq: $user_id } }, _set: { phone_number: $phone_number, otp_verified: true }) {
			returning {
				id
				name
				email
				currency
				language
				photo_url
				phone_number
				otp_verified
				email_verified
			}
		}
	}
`;
