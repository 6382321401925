import { Button, Drawer } from "antd";
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslatedLabel } from "../../../../shared/hooks";
import { PATHS } from "../../config";
import { logout } from "../../services";
import { AuthContext } from "../../store";

export const MobileDrawer = () => {
	const [visible, setVisible] = useState(false);
	const { user = {}, logoutUser, is_authenticated, user_role } = useContext(AuthContext);
	const { name, photo_url } = user;
	const { t } = useTranslatedLabel();
	const showDrawer = () => {
		setVisible(true);
	};

	const onClose = () => {
		setVisible(false);
	};
	const logoutClick = async (e) => {
		e.preventDefault();
		logoutUser();
		await logout();
	};

	return (
		<>
			<div className="navbar-toggler p-0 border-0 mr-2 ml-3">
				<Button onClick={showDrawer} className="p-1">
					<i className="las la-bars hambuger-menu"></i>
				</Button>
				<Drawer
					title={
						<span className="font-weight-bold">
							{is_authenticated ? (
								<>
									<img
										src={
											photo_url ||
											"https://res.cloudinary.com/ampcome/image/upload/c_thumb,e_colorize:100,g_face,q_100,w_200/v1595014484/mahaseel/avatar.png"
										}
										alt=""
										className="rounded-circle mr-2"
										style={{ width: "1.4rem", height: "1.4rem" }}
									/>
									<span>{name}</span>
								</>
							) : (
								<img
									src="https://res.cloudinary.com/ampcome/image/upload/v1595015037/mahaseel/flat_logo.jpg"
									style={{ height: "50px" }}
									alt=""
								/>
							)}
						</span>
					}
					placement="right"
					closable={false}
					onClose={onClose}
					visible={visible}
				>
					<div className="h-100 d-flex flex-column justify-content-between">
						<div>
							<div className="mb-3">
								{is_authenticated && (
									<p className="drawer-link">
										<Link to={PATHS.dashboard}>{t("dashboard")}</Link>
									</p>
								)}
								<p className="drawer-link">
									<Link to={PATHS.marketplace}>{t("marketplace")}</Link>
								</p>
								<p className="drawer-link">
									<Link to={PATHS.services}>{t("services")}</Link>
								</p>
								<p className="drawer-link">
									<Link to={PATHS.posts}>{t("posts")}</Link>
								</p>
							</div>
							{is_authenticated && user_role !== "provider" && (
								<>
									<div className="mb-3">
										<p className="text-gray text-uppercase sm-text border-bottom mb-2">{t("trade_offers")}</p>
										<p className="drawer-link">
											<Link to={PATHS.trade_active_offers}>{t("active_offers")}</Link>
										</p>
										<p className="drawer-link">
											<Link to={PATHS.trade_attention_offers}>{t("requires_attention")}</Link>
										</p>
										<p className="drawer-link">
											<Link to={PATHS.trade_submitted_bids}>{t("submitted_bids")}</Link>
										</p>
										<p className="drawer-link">
											<Link to={PATHS.trade_matching_connections}>{t("matching_connections")}</Link>
										</p>
										<p className="drawer-link">
											<Link to={PATHS.trade_offer_history}>{t("offer_history")}</Link>
										</p>
										<p className="drawer-link">
											<Link to={PATHS.trade_bid_history}>{t("bid_history")}</Link>
										</p>
									</div>
								</>
							)}
							{is_authenticated && user_role !== "provider" && (
								<>
									<div className="mb-3">
										<p className="text-gray text-uppercase sm-text border-bottom mb-2">{t("trade_orders")}</p>
										<p className="drawer-link">
											<Link to={PATHS.trade_active_orders}>{t("active_orders")}</Link>
										</p>
										<p className="drawer-link">
											<Link to={PATHS.trade_attention_orders}>{t("requires_attention")}</Link>
										</p>
										<p className="drawer-link">
											<Link to={PATHS.trade_order_history}>{t("order_history")}</Link>
										</p>
									</div>
								</>
							)}
							{is_authenticated && user_role === "provider" && (
								<>
									<div className="mb-3">
										<p className="text-gray text-uppercase sm-text border-bottom mb-2">{t("service_offers")}</p>
										<p className="drawer-link">
											<Link to={PATHS.service_active_offers}>{t("active_offers")}</Link>
										</p>
										<p className="drawer-link">
											<Link to={PATHS.service_offer_history}>{t("offer_history")}</Link>
										</p>
									</div>
								</>
							)}
							{is_authenticated && (
								<>
									<div className="mb-3">
										<p className="text-gray text-uppercase sm-text border-bottom mb-2">{t("service_orders")}</p>
										<p className="drawer-link">
											<Link to={PATHS.service_active_orders}>{t("active_orders")}</Link>
										</p>
										<p className="drawer-link">
											<Link to={PATHS.service_attention_orders}>{t("requires_attention")}</Link>
										</p>
										<p className="drawer-link">
											<Link to={PATHS.service_order_history}>{t("order_history")}</Link>
										</p>
									</div>
								</>
							)}
						</div>
						<div>
							<div className="d-flex justify-content-between text-center">
								{is_authenticated ? (
									<>
										<Link
											to={"./logout"}
											className="btn btn-light btn-block text-danger font-weight-bold"
											onClick={logoutClick}
										>
											<i className="la la-sign-out"></i> {t("logout")}
										</Link>
									</>
								) : (
									<>
										<Link to={PATHS.signin} className="btn btn-light btn-block border signin-btn">
											<i className="las la-sign-in-alt la-lg"></i> {t("login")}
										</Link>
										<Link className="ml-2 btn btn-primary btn-block mt-0" to={PATHS.register}>
											<i className="las la-user-plus la-lg"></i> {t("register")}
										</Link>
									</>
								)}
							</div>
						</div>
					</div>
				</Drawer>
			</div>
		</>
	);
};
