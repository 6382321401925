import React from "react";
import { GeneralContext, GeneralProvider } from "../../../shared/general";
import { AuthContext, AuthProvider } from "./auth";

const AdminGlobalProvider = ({ children }) => {
	return (
		<AuthProvider>
			<GeneralProvider> {children}</GeneralProvider>
		</AuthProvider>
	);
};

export { AuthContext, GeneralContext };

export default AdminGlobalProvider;
