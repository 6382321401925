import React from "react";
import { Form } from "react-bootstrap";
import { useTranslatedLabel } from "../../../shared/hooks";

export const DropDown = ({ label, onChange, value, name, dataSource = { data: [], text: "", value: "" } }) => {
	const { t } = useTranslatedLabel();
	const { data, text, value: valueField } = dataSource;
	return (
		<Form.Group>
			<Form.Label>{label}</Form.Label>
			<Form.Control as="select" onChange={onChange} value={value} name={name}>
				{data.map((item, i) => (
					<option key={i} value={item[valueField]}>
						{t(item[valueField], item[text])}
					</option>
				))}
			</Form.Control>
		</Form.Group>
	);
};
