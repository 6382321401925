import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";

export const LocaleSettings = ({ theme }) => {
  const [show, setShow] = useState(false);
  return (
    <>
      <Button
        onClick={() => setShow(true)}
        className="locale-settings d-flex align-items-center"
        variant={theme === "light" ? "outline-light" : "outline-dark"}
      >
        <span className="lang-icon d-flex align-items-center justify-content-center">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <image
              xlinkHref="https://i.postimg.cc/zfSsVxC2/english.png"
              height="20"
              width="20"
            ></image>
          </svg>
        </span>
        <span className="lang-label">English</span>
        <span className="locale-currency">$USD</span>
      </Button>

      <Modal
        show={show}
        onHide={() => setShow(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        className="locale-modal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <span className="locale-modal-title">Update Your Settings</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Choose your preferred language and currency</h4>
          <div className="card mb-0">
            <div className="card-body pb-0">
              <form action="#">
                <div className="form-row">
                  <div className="form-group col-12">
                    <label className="mr-sm-2">Language</label>
                    <select className="form-control">
                      <option>English</option>
                      <option>العربية</option>
                    </select>
                  </div>
                  <div className="form-group col-12">
                    <label className="mr-sm-2">Local Currency</label>
                    <select className="form-control">
                      <option>$ United State Dollar (USD)</option>
                      <option>€ Euro (EUR)</option>
                      <option>£ British Pound (GBP)</option>
                      <option>‎د.إ United Arab Emirates Dirham (AED)</option>
                    </select>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            onClick={() => setShow(false)}
            className="mr-2"
          >
            Close
          </Button>
          <Button onClick={() => setShow(false)}>Save Settings</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
