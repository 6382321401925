import React, { useContext, useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AntdTable, MapView } from "../../../../shared/components";
import { useDocTitle } from "../../../../shared/helpers";
import { useTranslatedLabel } from "../../../../shared/hooks";
import ViewTradeOrder from "../../../client/pages/user/trade/orders/view-order";
import { useCountSubscription } from "../../../client/widgets/countSubscription";
import { Layout } from "../../components";
import { PATHS } from "../../config";
import { useAggregateSubscription, useQueryParams } from "../../hooks";
import { AuthContext } from "../../store";
import { TinyLineChart } from "../dashboard/tiny-line-chart";
import { Filters } from "./filters";

const data_fields = `id created_at updated_at  uid active status product_type_id product_type { id name product_id product { id name } } status enum_order_status { comment value }
	price_per_unit number_of_units total_amount from_date to_date advance_amount advance_payment_percentage days_to_maturity request { id uid }
	from_location { name enum_country { comment } city  lat lng  id   is_farm user { id buyer_access grower_access provider_access } }
	to_location { name enum_country { comment } city  lat lng  id  is_farm user { id buyer_access grower_access provider_access } }
	seller { name email id } buyer { name email id } unit enum_product_unit { comment }`;

const map_fields = `id uid active from_location { name city  lat lng  id  is_farm user { id buyer_access grower_access provider_access } } to_location { name city  lat lng  id  is_farm user { id buyer_access grower_access provider_access } }`;

export const Orders = ({
	history,
	component = false,
	pagination,
	noFilter = false,
	limit = null,
	component_filters = {},
	hideColumns = [],
}) => {
	useDocTitle("trade_orders");

	const { t } = useTranslatedLabel();
	const { user } = useContext(AuthContext);
	const [filters, setFilters] = useState({});
	const queryParams = useQueryParams();
	useEffect(() => {
		queryParams.forEach((value, key) => {
			setFilters((state) => {
				state[key] = { _eq: value };
				return { ...state };
			});
		});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const [tab_id, setTabId] = useState("table");
	const COLUMNS = [
		{
			title: t("id"),
			key: "uid",
			render: (row) => <Link to={`${PATHS.orders}/${row.id}`}>{row?.uid}</Link>,
			sorter: AntdTable.GenericSorter("uid"),
		},
		{
			title: t("status"),
			key: "status",
			render: (row) => row.enum_order_status?.comment,
			sorter: AntdTable.TextSorter("status"),
		},
		{
			title: t("product"),
			key: "product",
			render: AntdTable.ProductRenderer,
			sorter: AntdTable.ProductSorter,
		},
		{
			title: t("variety"),
			key: "product_type",
			render: AntdTable.VarietyRenderer,
			sorter: AntdTable.VarietySorter,
		},
		{
			title: t("price_per_unit"),
			key: "price_per_unit",
			render: AntdTable.MoneyRenderer("price_per_unit"),
			sorter: AntdTable.GenericSorter("price_per_unit"),
		},
		{
			title: t("quantity"),
			key: "number_of_units",
			render: AntdTable.NumberRenderer("number_of_units"),
			sorter: AntdTable.GenericSorter("number_of_units"),
		},
		{
			title: t("value"),
			key: "total_amount",
			render: AntdTable.MoneyRenderer("total_amount"),
			sorter: AntdTable.GenericSorter("total_amount"),
		},
		{
			title: "Start Date",
			key: "from_date",
			render: AntdTable.DateRenderer("from_date"),
			sorter: AntdTable.TextSorter("from_date"),
		},
		{
			title: "End Date",
			key: "to_date",
			render: AntdTable.DateRenderer("to_date"),
			sorter: AntdTable.TextSorter("to_date"),
		},
		{
			title: "Days To Maturity",
			key: "days_to_maturity",
			render: AntdTable.NumberRenderer("days_to_maturity"),
			sorter: AntdTable.GenericSorter("days_to_maturity"),
		},
		{
			title: t("origin"),
			key: "from_location",
			render: AntdTable.LocationRenderer("from_location"),
			sorter: AntdTable.LocationSorter("from_location"),
		},
		{
			title: t("destination"),
			key: "to_location",
			render: AntdTable.LocationRenderer("to_location"),
			sorter: AntdTable.LocationSorter("to_location"),
		},
		{
			title: t("seller"),
			key: "seller",
			render: AntdTable.UserRenderer("seller"),
			sorter: AntdTable.UserSorter("seller"),
		},
		{
			title: t("buyer"),
			key: "buyer",
			render: AntdTable.UserRenderer("buyer"),
			sorter: AntdTable.UserSorter("buyer"),
		},
		// {
		// 	title: t("created"),
		// 	key: "created_at",
		// 	render: AntdTable.DateRenderer("created_at"),
		// 	sorter: AntdTable.TextSorter("created_at"),
		// },
		// {
		// 	title: t("updated"),
		// 	key: "updated_at",
		// 	render: AntdTable.DateRenderer("updated_at"),
		// 	sorter: AntdTable.TextSorter("updated_at"),
		// },
		{
			title: t("active"),
			key: "active",
			dataIndex: "active",
			render: (text, record) => (text ? <i className="la la-check-circle la-lg text-success align-align-self-center" /> : ""),
			sorter: (a, b) => Number(a.active) - Number(b.active),
		},
		{
			title: "Offer ID",
			key: "request_id",
			render: (row) => <Link to={`${PATHS.offers}/${row?.request?.id}`}>{row?.request?.uid}</Link>,
			sorter: AntdTable.TextSorter("request_id"),
		},
	];

	const active_count = useCountSubscription("trade_orders", { active: { _eq: true } });
	const active_amount = useAggregateSubscription("trade_orders", { active: { _eq: true } }, "total_amount");
	const total_count = useCountSubscription("trade_orders", { request_id: { _is_null: true } });
	const total_amount = useAggregateSubscription("trade_orders", {}, "total_amount");

	const content = (
		<>
			{!noFilter && (
				<Filters
					entity="trade_orders"
					onChange={(e) => setFilters((state) => ({ ...e }))}
					fields={["product_type_id", "from_to_dates", "order_status"]}
					number_fields={["price_per_unit", "number_of_units", "total_amount", "advance_payment_percentage", "advance_amount"]}
				/>
			)}
			<Tabs activeKey={tab_id} onSelect={(e) => setTabId(e)} className="m-0 p-0">
				<Tab eventKey="table" title={t("table_view")}>
					<AntdTable
						entity="trade_orders"
						columns={COLUMNS}
						where={{ ...component_filters }}
						order_by={{ uid: "desc" }}
						query_fields={data_fields}
						filters={filters}
						limit={limit}
						// pagination={pagination}
						expandable={{
							expandedRowRender: (row) => (
								<ViewTradeOrder component={true} inAdmin={true} order_id={row?.id} adminId={user?.id} />
							),
							rowExpandable: (row) => !!row?.id,
						}}
					/>
				</Tab>
				<Tab eventKey="map" title={t("map_view")}>
					<MapView
						zoom={2}
						entity="trade_orders"
						where={{ ...component_filters }}
						order_by={{ uid: "desc" }}
						query_fields={map_fields}
						filters={filters}
						limit={limit}
						pagination={pagination}
					/>
				</Tab>
				<Tab eventKey="chart" title={t("chart_view")}>
					<TinyLineChart
						filters={{ date: { _gte: filters.from_date, _lte: filters.to_date } }}
						title="Trade Orders"
						entity="z_trade_orders"
						height={600}
						showXaxisLabels={true}
						enableTooltip={true}
					/>
				</Tab>
			</Tabs>
		</>
	);

	return (
		<>
			{component ? (
				content
			) : (
				<Layout
					title={t("trade_orders")}
					activeCount={active_count}
					activeAmount={active_amount}
					totalCount={total_count}
					totalAmount={total_amount}
				>
					<div>{content}</div>
				</Layout>
			)}
		</>
	);
};
