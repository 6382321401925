import { useMutation } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { Link, Redirect } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { PATHS } from "../../config";
import { REQUEST_OTP, VERIFY_PHONE_NUMBER } from "../../gql";
import { AuthContext } from "../../store";

export const VerifyOTP = ({ location }) => {
	const [requestOtp] = useMutation(REQUEST_OTP);

	const [otpData, setOtpData] = useState(undefined);

	const {
		user: { id: user_id, phone_number: phoneNumber, otp_verified },
		setUserData,
	} = useContext(AuthContext);
	const [otpCode, setOtpCode] = useState("");
	const [verifyPhoneNumber] = useMutation(VERIFY_PHONE_NUMBER);
	const { addToast } = useToasts();
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const sendOtp = async () => {
			if (!(location.state && location.state.otp)) {
				try {
					const { data } = await requestOtp({
						variables: { phone: phoneNumber },
					});
					const { id, code, phone_number, issued_at, expires_at, is_used } = data.insert_otps.returning[0];
					const otp = {
						id,
						code,
						phone_number,
						issued_at,
						expires_at,
						is_used,
					};

					addToast(`OTP code sent to ${phoneNumber}`, {
						appearance: "success",
					});
					setOtpData(otp);
				} catch (err) {}
			}
		};
		sendOtp();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const otpFromProps = location.state && location.state.otp ? location.state.otp : null;

	const onChange = (e) => {
		setOtpCode(e.target.value);
	};

	const resendOtp = async () => {
		const phone = otpFromProps ? otpFromProps.phone_number : phoneNumber;
		if (phone) {
			try {
				const { data } = await requestOtp({
					variables: { phone },
				});
				const { id, code, phone_number, issued_at, expires_at, is_used } = data.insert_otps.returning[0];
				const otp = { id, code, phone_number, issued_at, expires_at, is_used };

				addToast(`OTP code sent to ${phoneNumber}`, { appearance: "success" });
				setOtpData(otp);
			} catch (err) {}
		}
	};

	const onSubmit = async (e) => {
		e.preventDefault();

		const otp = otpFromProps ? otpFromProps : otpData;

		if (otp) {
			const { code, id, phone_number, expires_at, is_used } = otp;

			if (parseInt(otpCode, 10) !== code) {
				addToast("OTP Code is incorrect", { appearance: "error" });
			}

			if (is_used === true) {
				addToast("OTP Code has been used", { appearance: "error" });
			}

			if (new Date(expires_at) < new Date()) {
				addToast("OTP Code has expired", { appearance: "error" });
			}

			if (parseInt(otpCode, 10) === code && is_used === false && new Date(expires_at) > new Date()) {
				try {
					setLoading(true);
					const { data, errors } = await verifyPhoneNumber({
						variables: {
							phone_number,
							user_id,
							otp_id: id,
							is_used: true,
						},
					});

					if (data && !errors) {
						const updatedUserData = data.update_users.returning[0];
						setUserData(updatedUserData);
						setLoading(false);
					}
				} catch (err) {
					const errors = err.graphQLErrors;
					for (const error of errors) {
						switch (error.message) {
							case `Uniqueness violation. duplicate key value violates unique constraint "users_phone_number_key"`:
								addToast("Phone number already in use", {
									appearance: "error",
								});
								break;
							default:
								break;
						}
					}
					setLoading(false);
				}
			}
		}
	};

	// if (!otpFromProps && !otpData) return <Redirect to={PATHS.requestOtp} />;

	if (otp_verified) return <Redirect to={PATHS.preferredRole} />;

	return (
		<>
			<div className="auth-top-border">&nbsp;</div>
			<div className="sign-in ">
				<div className="container">
					<div className="row justify-content-center align-items-center">
						<div className="col-xl-5 col-md-6">
							<div className="auth-logo text-center my-5">
								<Link to={PATHS.base}>
									<img
										src="https://res.cloudinary.com/ampcome/image/upload/c_scale,w_100/v1592541595/mahaseel/mahaseel_new_logo.jpg"
										alt="Mahaseel logo"
									/>
								</Link>
							</div>
							<div className="auth-form card">
								<div className="card-body">
									<Link className="page-back text-muted invisible" to={PATHS.requestOtp}>
										<span>
											<i className="fa fa-angle-left"></i>
										</span>{" "}
										Change phone number
									</Link>
									<h3 className="text-center">OTP Verification</h3>
									<p className="text-center mb-4">Please enter the one time code you received</p>

									<form onSubmit={onSubmit}>
										<div className=" form-group auth-form-group">
											<label>Your OTP Code</label>
											<input
												type="text"
												className="form-control text-center font-weight-bold"
												placeholder="112233"
												name="otp-code"
												value={otpCode}
												onChange={onChange}
											/>
										</div>
										<div className="text-center">
											<button type="submit" className="btn btn-auth btn-block" onClick={onSubmit} disabled={loading}>
												{loading ? (
													<Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
												) : (
													<span>Submit</span>
												)}
											</button>
										</div>
									</form>
									<div className="new-account mt-3 d-flex justify-content-between">
										<p>
											<button type="button" className="text-primary btn btn-link p-0" onClick={resendOtp}>
												Resend code
											</button>
										</p>
										<Link className="page-back text-muted" to={PATHS.requestOtp}>
											Change phone number
										</Link>
									</div>
								</div>
							</div>
							{otpData && (
								<p className="text-center">
									Dev Test Mode - Sent Code is <br /> {otpData.code}{" "}
								</p>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
