import Geocode from "react-geocode";
import { GOOGLE_MAPS_CONFIG } from "../../apps/client/config";

Geocode.setApiKey(GOOGLE_MAPS_CONFIG.API_KEY);

const getGeocodeData = async ({ lat = 0, lng = 0 }) => {
	try {
		const response = await Geocode.fromLatLng(lat, lng);
		return response;
	} catch (err) {}
};

export { getGeocodeData };
