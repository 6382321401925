import auth0 from "auth0-js";
import { APP_CONFIG } from "../../../shared/app_config";
import { PATHS } from "./routes";

export const AUTH_CONFIG = {
	responseType: APP_CONFIG.REACT_APP_AUTH0_RESPONSE_TYPE,
	clientID: APP_CONFIG.REACT_APP_AUTH0_CLIENT_ID,
	domain: APP_CONFIG.REACT_APP_AUTH0_DOMAIN,
	redirectUri: `${window.location.origin}${PATHS.authorize}`,
	returnTo: `${window.location.origin}`,
};
export const webAuth = new auth0.WebAuth(AUTH_CONFIG);
