import { gql } from "@apollo/client";

export const S_PRODUCTS = gql`
  subscription products(
    $where: products_bool_exp
    $distinct_on: [products_select_column!]
  ) {
    totals: products_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export const S_NOTIFICATIONS = gql`
  subscription getUserNotifications($recipient_id: String!) {
    notifications(where: { recipient_user_id: { _eq: $recipient_id } }) {
      id
      active
      header
      message
      type
      cta_link
      cta_title
      created_at
    }
  }
`;
