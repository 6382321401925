import { useMutation, useSubscription } from "@apollo/client";
import { loadStripe } from "@stripe/stripe-js";
import { Input, Steps } from "antd";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { default as Skeleton, default as Skelton } from "react-loading-skeleton";
import NumberFormat from "react-number-format";
import { Link, useParams } from "react-router-dom";
import Stepper from "react-stepper-horizontal";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import GreenMarker from "../../../../../../images/marker-green.png";
import RedMarker from "../../../../../../images/marker-red.png";
import YellowMarker from "../../../../../../images/marker-yellow.png";
import { getMapPoint, PreviewGoogleMap } from "../../../../../../shared/helpers";
import { useTranslatedLabel } from "../../../../../../shared/hooks";
import { AuthLayout } from "../../../../components";
import { PATHS } from "../../../../config";
import { CREATE_NOTES, GET_SERVICE_ORDER_BY_ID, UPDATE_ONE } from "../../../../gql";
import { createCheckoutSession } from "../../../../services";
import { AuthContext } from "../../../../store";
import { Invoice } from "../../invoice";

const { Step } = Steps;
const { TextArea } = Input;

const ViewServiceOrder = ({ component = false, inAdmin = false, order_id = "" }) => {
	const { t, dt_tm, dt } = useTranslatedLabel();
	const { orderId } = useParams();
	const id = component ? order_id : orderId;
	const { user } = useContext(AuthContext);
	const [note, setNote] = useState("");
	const [order, setOrder] = useState(null);

	const [updateOrder] = useMutation(UPDATE_ONE("service_orders"));
	const [addNote] = useMutation(CREATE_NOTES);

	const { data, loading, error } = useSubscription(GET_SERVICE_ORDER_BY_ID, {
		variables: { id },
	});
	useEffect(() => {
		if (!loading && data?.object && !error) setOrder(data?.object);
	}, [data, loading, error]);

	const onAddNote = () => {
		if (note) {
			addNote({
				variables: {
					input: {
						user_id: user.id,
						service_order_id: order?.id,
						text: note,
					},
				},
			}).then(() => setNote(""));
		}
	};
	const steps = ["open", "fullypaid", "inprogress", "serviced", "completed"];

	const [payment_in_progress, setPaymentInProgress] = useState(false);
	const stripePromise = (publishableKey) => loadStripe(publishableKey);

	const makePayment = async (advance) => {
		setPaymentInProgress(true);

		try {
			const response = await createCheckoutSession({
				offer_id: order?.offer?.id,
				from_location_id: order?.from_location?.id,
				to_location_id: order?.to_location?.id,
				seller_id: order?.seller?.id,
				buyer_id: order?.buyer?.id,
				trade: false,
				advance: false,
				offer: { ...order.offer },
			});
			const { sessionId, publishableKey } = response.data;
			const stripe = await stripePromise(publishableKey);
			const { error } = await stripe.redirectToCheckout({ sessionId });
			setPaymentInProgress(false);
			if (error) console.error(error);
		} catch (err) {
			console.error(err);
			setPaymentInProgress(false);
		}
	};

	const Wrapper = component ? Fragment : AuthLayout;

	return (
		<>
			<div id="main-wrapper" style={component ? { marginTop: "0px" } : {}}>
				<Wrapper>
					{order ? (
						<div className="offer-details-page bg-light">
							<div className="container pb-4 pt-5">
								<div className="row ml-1">
									<div>
										<h3 className="page-title display-4" style={{ padding: "0px" }}>
											{`${t("service_order")} # ${order?.uid} - ${t(order?.status)}`}
										</h3>
									</div>
									<div className="ml-auto mr-3">
										{!inAdmin && order && order?.active && order?.status === "open" && user?.id === order?.buyer?.id && (
											<button
												type="button"
												className="btn btn-sm btn-block btn-outline-primary ml-3"
												onClick={() => makePayment()}
											>
												{payment_in_progress ? (
													<Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
												) : (
													<span>
														<NumberFormat
															value={parseFloat(order.total_amount).toFixed(2)}
															displayType={"text"}
															thousandSeparator={true}
															prefix={"$ "}
														/>
													</span>
												)}
											</button>
										)}
										{!inAdmin && order && order?.active && order?.status === "fullypaid" && user?.id === order?.seller?.id && (
											<button
												type="button"
												className="btn btn-sm btn-block btn-outline-primary ml-3"
												onClick={() =>
													Swal.fire({
														title: t("are_you_sure?"),
														text: t("you_wont_be_able_to_revert!"),
														icon: "warning",
														showCancelButton: true,
														confirmButtonText: t("yes"),
													}).then((x) => {
														if (x.value) {
															updateOrder({
																variables: {
																	id: order?.id,
																	object: {
																		status: "inprogress",
																	},
																},
															});
														}
													})
												}
											>
												{`${t("mark_as")} " ${t("inprogress")} "`}
											</button>
										)}
										{!inAdmin &&
											order &&
											order?.active &&
											order?.status === "inprogress" &&
											user?.id === order?.seller?.id && (
												<button
													type="button"
													className="btn btn-sm btn-block btn-outline-primary ml-3"
													onClick={() =>
														Swal.fire({
															title: t("are_you_sure?"),
															text: t("you_wont_be_able_to_revert!"),
															icon: "warning",
															showCancelButton: true,
															confirmButtonText: t("yes"),
														}).then((x) => {
															if (x.value) {
																updateOrder({
																	variables: {
																		id: order?.id,
																		object: {
																			status: "serviced",
																		},
																	},
																});
															}
														})
													}
												>
													{`${t("mark_as")} " ${t("serviced")} "`}
												</button>
											)}
										{!inAdmin && order && order?.active && order?.status === "serviced" && user?.id === order?.buyer?.id && (
											<button
												type="button"
												className="btn btn-sm btn-block btn-outline-primary  ml-3"
												onClick={() =>
													Swal.fire({
														title: t("are_you_sure?"),
														text: t("you_wont_be_able_to_revert!"),
														icon: "warning",
														showCancelButton: true,
														confirmButtonText: t("yes"),
													}).then((x) => {
														if (x.value) {
															updateOrder({
																variables: {
																	id: order?.id,
																	object: {
																		status: "completed",
																		active: false,
																	},
																},
															});
														}
													})
												}
											>
												{`${t("mark_as")} " ${t("completed")} "`}
											</button>
										)}
									</div>
								</div>
								{order && order?.status !== "cancelled" && (
									<div className="row ml-1 mb-3 bg-white">
										<Stepper
											activeStep={steps.indexOf(order?.status)}
											steps={[
												{
													title: (
														<>
															<div>{t("open")}</div>
															{order?.open_at && <small>{dt_tm(`${order?.open_at}`)}</small>}
														</>
													),
												},
												{
													title: (
														<>
															<div>{t("fullypaid")}</div>
															{order?.fullypaid_at && <small>{dt_tm(`${order?.fullypaid_at}`)}</small>}
														</>
													),
												},
												{
													title: (
														<>
															<div>{t("inprogress")}</div>
															{order?.inprogress_at && <small>{dt_tm(`${order?.inprogress_at}`)}</small>}
														</>
													),
												},
												{
													title: (
														<>
															<div>{t("serviced")}</div>
															{order?.serviced_at && <small>{dt_tm(`${order?.serviced_at}`)}</small>}
														</>
													),
												},
												{
													title: (
														<>
															<div>{t("completed")}</div>
															{order?.completed_at && <small>{dt_tm(`${order?.completed_at}`)}</small>}
														</>
													),
												},
											]}
										/>
									</div>
								)}
								<div className="offer-details pb-5">
									<div className="offer-main bg-white p-4">
										{loading && (
											<div className="mb-2">
												<Skeleton height={30} />
											</div>
										)}
										{!loading && order && (
											<div className="row bg-white p-2 mb-3">
												<div className="col-sm-12 col-md-12 col-lg-6 text-left font-success">
													<p className="text-muted">{t("seller")}</p>
													<h5 className="d-inline-flex align-items-center">
														<img
															src={
																order?.seller?.photo_url ||
																"https://res.cloudinary.com/ampcome/image/upload/c_thumb,e_colorize:100,g_face,q_100,w_200/v1595014484/mahaseel/avatar.png"
															}
															style={{ height: "30px" }}
															alt=""
														/>
														<span className="ml-3 mr-3">{order?.seller?.name}</span>
													</h5>
													<p className="d-inline-flex align-items-center">
														<img src={YellowMarker} alt="" />
														<span className="ml-3 mr-3">
															{order?.from_location?.address_line_1}, {order?.from_location?.city},{" "}
															{order?.from_location?.state}, {order?.from_location?.country}{" "}
															{order?.from_location?.postcode}
														</span>
													</p>
												</div>
												<div className="col-sm-12 col-md-12 col-lg-6 text-right sm-text-left md-text-left font-danger">
													<p className="text-muted">{t("buyer")}</p>
													<h5 className="d-inline-flex align-items-center sm-flex-row-reverse md-flex-row-reverse md-d-flex md-justify-end">
														<span className="ml-3 mr-3">{order?.buyer?.name}</span>
														<img
															src={
																order?.buyer?.photo_url ||
																"https://res.cloudinary.com/ampcome/image/upload/c_thumb,e_colorize:100,g_face,q_100,w_200/v1595014484/mahaseel/avatar.png"
															}
															style={{ height: "30px" }}
															alt=""
														/>
													</h5>
													<p className="d-inline-flex align-items-center sm-flex-row-reverse md-flex-row-reverse">
														<span className="ml-3 mr-3">
															{order?.to_location?.address_line_1}, {order?.to_location?.city},{" "}
															{order?.to_location?.state}, {order?.to_location?.country}{" "}
															{order?.to_location?.postcode}
														</span>
														<img src={RedMarker} alt="" />
													</p>
												</div>
											</div>
										)}

										{loading && (
											<div className="mb-4">
												<Skeleton height={300} />
											</div>
										)}

										{loading && (
											<div className="mb-4">
												<div className="mb-2 w-50">
													<Skeleton height={25} />
												</div>
												<div className="mb-1">
													<Skeleton height={50} />
												</div>
											</div>
										)}

										<PreviewGoogleMap
											isMarkerShown
											zoom={3}
											positions={[
												getMapPoint({ ...order?.from_location, icon: GreenMarker }),
												getMapPoint({ ...order?.to_location, icon: YellowMarker }),
											]}
											loadingElement={<div style={{ height: `20rem` }} />}
											containerElement={<div style={{ height: `20rem` }} />}
											mapElement={<div style={{ height: `100%` }} />}
										/>
										<hr />
										<div className="pt-2 pb-2 bg-white mb-3">
											<h5 className="font-weight-bold ml-3 d-flex align-items-center">
												{t("payments")}
												{(order?.status === "fullypaid" ||
													order?.status === "inprogress" ||
													order?.status === "serviced" ||
													order?.status === "completed") && <Invoice order={order} service={true} />}
											</h5>
											{order?.payments?.map((p, i) => (
												<div
													key={i}
													className={`row bg-light m-0 p-1 ${p.status !== "cancelled" ? "text-green" : "text-muted"}`}
												>
													<small className="col">{dt_tm(p.updated_at)}</small>
													<div className="col">{p.advance ? t("advance_amount") : t("remaining_amount")}</div>
													<div className="col text-right">
														<NumberFormat
															value={parseFloat(p.amount).toFixed(2)}
															displayType={"text"}
															thousandSeparator={true}
															prefix={"$ "}
														/>
													</div>
													<div className="col">{t(p.status)}</div>
												</div>
											))}
										</div>
									</div>
									<aside className="offer-sidebar">
										{loading && (
											<div className="mb-2 p-4 bg-white">
												<Skeleton height={500} />
											</div>
										)}
										{!loading && order && (
											<div className="bg-white mb-2 p-4 border">
												<div>
													<div className="mb-2">
														<span className="text-muted mb-0">{t("offer")}</span>
														<span className="font-weight-bold mb-0 float-right">
															<Link to={`${PATHS.service_view_offer}/${order?.offer?.id}`}>
																{`# ${order?.offer?.uid}`}
															</Link>
														</span>
													</div>
													<hr />
													<div className="mb-2">
														<span className="text-muted mb-0">{t("service")}</span>
														<span className="font-weight-bold mb-0 float-right">
															{t(order?.service_type?.service?.id, order?.service_type?.service?.name)}
														</span>
													</div>
													<div className="mb-2">
														<span className="text-muted mb-0">{t("category")}</span>
														<span className="font-weight-bold mb-0 float-right">
															{t(order?.service_type?.id, order?.service_type?.name)}
														</span>
													</div>
													<hr />
													<div className="mb-2">
														<span className="text-muted mb-0">{t("price_per_unit")}</span>
														<span className="font-weight-bold mb-0 float-right">
															<NumberFormat
																value={parseFloat(order?.price_per_unit).toFixed(2)}
																displayType={"text"}
																thousandSeparator={true}
																prefix={"$ "}
															/>
														</span>
													</div>
													<div className="mb-2">
														<span className="text-muted mb-0">{`${t("quantity")}`}</span>
														<span className="font-weight-bold mb-0 float-right">{order?.number_of_units}</span>
													</div>
													<hr />
													<div className="mb-2">
														<span className="text-muted mb-0">{t("total_amount")}</span>
														<span className="font-weight-bold mb-0 float-right">
															<NumberFormat
																value={parseFloat(order?.number_of_units * order?.price_per_unit).toFixed(2)}
																displayType={"text"}
																thousandSeparator={true}
																prefix={"$ "}
															/>
														</span>
													</div>
													<hr />
													<div className="mb-3">
														<span className="text-muted mb-0"> {t("date")}</span>
														<span className="font-weight-bold mb-0 float-right">{dt(`${order?.date}`)}</span>
													</div>

													<hr />
													<div className="mb-3">
														<p className="text-muted mb-0">{t("origin")}</p>
														{user?.id === order?.seller?.id && (
															<p className="font-weight-bold mb-0">{order?.from_location?.name}</p>
														)}
														<p className="font-weight-bold mb-0">
															{order?.from_location?.address_line_1}, {order?.from_location?.city},{" "}
															{order?.from_location?.state}, {order?.from_location?.country}{" "}
															{order?.from_location?.postcode}
														</p>
													</div>
													<hr />
													<div className="mb-3">
														<p className="text-muted mb-0">{t("destination")}</p>
														{user?.id === order?.buyer?.id && (
															<p className="font-weight-bold mb-0">{order?.to_location?.name}</p>
														)}
														<p className="font-weight-bold mb-0">
															{order?.to_location?.address_line_1}, {order?.to_location?.city},{" "}
															{order?.to_location?.state}, {order?.to_location?.country}{" "}
															{order?.to_location?.postcode}
														</p>
													</div>
													<hr />
													{!inAdmin && (
														<>
															{order?.active && (
																<div className=" mb-3 bg-white">
																	<p className="text-muted mb-0">{t("notes")}</p>
																	<TextArea
																		value={note}
																		name="note"
																		onChange={(e) => setNote(e.target.value)}
																		className="w-100 form-control"
																	/>
																	<div className="d-flex ">
																		<div
																			className="btn btn-sm btn-primary  mt-2 ml-auto"
																			onClick={onAddNote}
																		>
																			<span>{t("add_note")}</span>
																		</div>
																	</div>
																</div>
															)}
															<div className="mb-3">
																<Steps direction="vertical" progressDot={true}>
																	{order?.notes.map((item, index) => (
																		<Step
																			className="custom-step"
																			key={item.text}
																			title={item.text}
																			description={
																				<small>{`${item.user.name} - ${dt_tm(item.created_at)}`}</small>
																			}
																			status={order?.notes.length - 1 === index ? "process" : "finish"}
																		/>
																	))}
																</Steps>
															</div>
														</>
													)}
												</div>
											</div>
										)}
									</aside>
								</div>
							</div>
						</div>
					) : (
						<Skelton height="80vh" />
					)}
				</Wrapper>
			</div>
		</>
	);
};

export default ViewServiceOrder;
