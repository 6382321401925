import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import { Link, Redirect } from "react-router-dom";
import { PATHS } from "../../config";
import { REQUEST_OTP } from "../../gql";

export const RequestOTP = () => {
	const [phone, setPhone] = useState("");
	const [requestOtp] = useMutation(REQUEST_OTP);
	const [otpData, setOtpData] = useState(undefined);
	const [loading, setLoading] = useState(false);

	const onChange = (e) => {
		e.preventDefault();
		setPhone(e.target.value);
	};

	const onSubmit = async (e) => {
		e.preventDefault();
		if (phone) {
			setLoading(true);
			try {
				const { data } = await requestOtp({ variables: { phone } });
				const { id, code, phone_number, issued_at, expires_at, is_used } = data.insert_otps.returning[0];
				const otp = { id, code, phone_number, issued_at, expires_at, is_used };
				setOtpData(otp);
				setLoading(false);
			} catch (err) {
				setLoading(false);
			}
		}
	};

	if (otpData) return <Redirect to={{ pathname: PATHS.verifyOtp, state: { otp: otpData } }} />;

	return (
		<>
			<div className="auth-top-border">&nbsp;</div>
			<div className="sign-in ">
				<div className="container ">
					<div className="row justify-content-center align-items-center">
						<div className="col-xl-5 col-md-6">
							<div className="auth-logo text-center my-5">
								<Link to={PATHS.base}>
									<img
										src="https://res.cloudinary.com/ampcome/image/upload/c_scale,w_100/v1592541595/mahaseel/mahaseel_new_logo.jpg"
										alt="Mahaseel logo"
									/>
								</Link>
							</div>
							<div className="auth-form card">
								<div className="card-body">
									<Link className="page-back text-muted invisible" to={PATHS.signin}>
										<span>
											<i className="fa fa-angle-left"></i>
										</span>{" "}
										Back
									</Link>
									<h3 className="text-center">OTP Verification</h3>
									<p className="text-center mb-4">We will send one time code on this number</p>
									<form onSubmit={onSubmit}>
										<div className=" form-group auth-form-group">
											<label>Your phone number</label>
											<div className="input-group mb-3">
												<div className="input-group-prepend">
													<span className="input-group-text pl-4 pr-4">
														<i className="fa fa-phone"></i>
													</span>
												</div>
												<input
													type="text"
													className="form-control"
													placeholder="+1 12365480"
													name="phone"
													value={phone}
													onChange={onChange}
												/>
											</div>
										</div>
										<div className="text-center mt-4">
											<button onClick={onSubmit} type="submit" className="btn btn-auth btn-block" disabled={loading}>
												{loading ? (
													<Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
												) : (
													<span>Send</span>
												)}
											</button>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
