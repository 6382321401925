import { StyleSheet, View } from "@react-pdf/renderer";
import React from "react";
import InvoiceTableBlankSpace from "./InvoiceTableBlankSpace";
import InvoiceTableFooter from "./InvoiceTableFooter";
import InvoiceTableHeader from "./InvoiceTableHeader";
import InvoiceTableRow from "./InvoiceTableRow";

const tableRowsCount = 11;

const styles = StyleSheet.create({
	tableContainer: {
		flexDirection: "row",
		flexWrap: "wrap",
		marginTop: 24,
		borderWidth: 1,
		borderColor: "#bff0fd",
	},
});

const InvoiceItemsTable = ({ invoice, payment, service }) => (
	<View style={styles.tableContainer}>
		<InvoiceTableHeader service={service} />
		<InvoiceTableRow items={invoice.items} service={service} />
		<InvoiceTableBlankSpace rowsCount={tableRowsCount - invoice.items.length} service={service} />
		<InvoiceTableFooter invoice={invoice} service={service} />
	</View>
);

export default InvoiceItemsTable;
