import React from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";

export const FilterDropdown = ({ data, title, nameField, valueField, onChange, value, name }) => {
	const handleClick = (item) => {
		onChange({ target: { name, value: item[valueField] } });
	};

	return (
		<DropdownButton id="products-sort" title={title} variant="light" className="m-0 p-2">
			{data.map((item, i) => (
				<Dropdown.Item key={item[valueField]} onClick={(e) => handleClick(item)}>
					{item[nameField]}
				</Dropdown.Item>
			))}
		</DropdownButton>
	);
};
