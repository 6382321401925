import { useSubscription } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import { Icon } from "../../../../shared/components";
import { useTranslatedLabel } from "../../../../shared/hooks";
import { PATHS } from "../../../admin/config";
import { Layout } from "../../components";
import { GET_USER_BY_ID } from "../../gql/queries";
import { AuthContext } from "../../store";

export const Account = () => {
	const { t, dt } = useTranslatedLabel();
	const { user: current_user, is_authenticated } = useContext(AuthContext);
	const [user, setUser] = useState();
	const [user_role, setUserRole] = useState();
	const { userId } = useParams();
	const user_id = userId || current_user?.id;

	const { loading, error, data } = useSubscription(GET_USER_BY_ID, {
		variables: { id: user_id },
	});
	useEffect(() => {
		if (!loading && !error && data?.object) {
			setUser(data?.object);
			const { buyer_access, grower_access, provider_access } = data?.object;
			if (buyer_access) setUserRole("buyer");
			else if (grower_access) setUserRole("grower");
			else if (provider_access) setUserRole("provider");
		}
	}, [loading, error, data]);

	if (!is_authenticated) return <Redirect to={PATHS.base} />;

	return (
		<>
			<Layout>
				<div className="bg-light py-5">
					<div className="container">
						<div className="profile-page">
							{!loading && (
								<div className="profile-summary bg-white p-3">
									<div className="profile-bio mb-3">
										<div className="profile-avatar" style={{ backgroundImage: `url(${user?.photo_url})` }}></div>
										<h4 className="font-weight-bold text-center mb-1">{user?.name}</h4>
										<p className="text-gray sm-text text-center text-capitalize mb-2">{user_role?.toUpperCase()}</p>
									</div>
									<div className="">
										<div className="mb-3">
											<p className="mb-0 d-flex align-items-center">
												<Icon
													type="location"
													style={{
														width: "1rem",
														height: "1rem",
														fill: "currentColor",
													}}
													className="mr-2"
												/>{" "}
												{t("location")}
											</p>
											<p className="mb-0 font-weight-bold">
												{user?.location?.city}, {user?.location?.country}
											</p>
										</div>
										<div className="mb-3">
											<p className="mb-0 d-flex align-items-center">
												<Icon
													type="user"
													style={{
														width: "1rem",
														height: "1rem",
														fill: "currentColor",
													}}
													className="mr-2"
												/>{" "}
												{t("registered")}
											</p>
											<p className="mb-0 font-weight-bold">{dt(user?.created_at)}</p>
										</div>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</Layout>
		</>
	);
};
