import { gql } from "@apollo/client";

export const INSERT_ONE = (entity) =>
	gql`mutation insert_${entity}_one($object: ${entity}_insert_input!) { object: insert_${entity}_one(object: $object) { id } }`;
export const INSERT_MANY = (entity) =>
	gql`mutation insert_${entity}($objects: [${entity}_insert_input!]!) { objects: insert_${entity}(objects: $objects) { affected_rows } }`;

export const UPSERT_ONE = (entity, key, columns) =>
	gql`mutation insert_${entity}_one($object: ${entity}_insert_input!) { object: insert_${entity}_one(object: $object, on_conflict: {constraint: ${key}, update_columns: [${columns}]}) { id } }`;
export const UPSERT_MANY = (entity, key, columns) =>
	gql`mutation insert_${entity}($objects: [${entity}_insert_input!]!) { objects: insert_${entity}(objects: $objects, on_conflict: {constraint: ${key}, update_columns: [${columns}]}) { affected_rows } }`;

export const UPDATE_ONE = (entity) =>
	gql`mutation update_${entity}_by_pk($id: uuid!, $object: ${entity}_set_input) { object: update_${entity}_by_pk(pk_columns: {id : $id}, _set: $object) { id } }`;
export const UPDATE_MANY = (entity) =>
	gql`mutation update_${entity}($where: ${entity}_bool_exp!, $object: ${entity}_set_input) { objects: update_${entity}(where: $where, _set: $object) { affected_rows } }`;

export const DELETE_ONE = (entity) => gql`mutation delete_${entity}_by_pk($id: uuid!) { object: delete_${entity}_by_pk(id : $id) { id } }`;
export const DELETE_MANY = (entity) =>
	gql`mutation delete_${entity}($where: ${entity}_bool_exp!) { object: delete_${entity}(where : $where) { affected_rows } }`;

export const REQUEST_OTP = gql`
	mutation RequestOTP($phone: String!) {
		insert_otps(objects: [{ phone_number: $phone }]) {
			returning {
				id
				code
				phone_number
				issued_at
				expires_at
				is_used
			}
		}
	}
`;

export const CREATE_NOTES = gql`
	mutation create_notes($input: notes_insert_input!) {
		insert_notes_one(object: $input) {
			id
			text
		}
	}
`;

export const VERIFY_PHONE_NUMBER = gql`
	mutation VerifyPhoneNumber($otp_id: uuid!, $is_used: Boolean!, $phone_number: String!, $user_id: String!) {
		update_otps(where: { id: { _eq: $otp_id } }, _set: { is_used: $is_used }) {
			affected_rows
		}
		update_users(where: { id: { _eq: $user_id } }, _set: { phone_number: $phone_number, otp_verified: true }) {
			returning {
				id
				name
				email
				currency
				language
				photo_url
				phone_number
				otp_verified
				email_verified
			}
		}
	}
`;

export const UPDATE_USER_ROLE = gql`
	mutation UpdateUserRole($id: String!, $buyer_access: Boolean!, $grower_access: Boolean!, $provider_access: Boolean!) {
		update_users(
			where: { id: { _eq: $id } }
			_set: { buyer_access: $buyer_access, grower_access: $grower_access, provider_access: $provider_access }
		) {
			returning {
				buyer_access
				grower_access
				provider_access
			}
		}
	}
`;

export const UPDATE_USER_LOCATION = gql`
	mutation UPDATE_USER_LOCATION($id: String!, $location_id: uuid!) {
		update_users(where: { id: { _eq: $id } }, _set: { location_id: $location_id }) {
			affected_rows
		}
	}
`;

export const INSERT_LOCATION = gql`
	mutation insert_locations_one($object: locations_insert_input!) {
		object: insert_locations_one(object: $object) {
			id
		}
	}
`;
export const UPDATE_LOCATION = gql`
	mutation update_locations_by_pk($id: uuid!, $object: locations_set_input!) {
		object: update_locations_by_pk(pk_columns: { id: $id }, _set: $object) {
			id
		}
	}
`;
export const INSERT_REQUEST = gql`
	mutation insert_requests_one($object: requests_insert_input!) {
		object: insert_requests_one(object: $object) {
			id
		}
	}
`;

export const UPDATE_REQUEST = gql`
	mutation update_requests_by_pk($object: requests_set_input!, $id: uuid!) {
		object: update_requests_by_pk(pk_columns: { id: $id }, _set: $object) {
			id
		}
	}
`;
export const INSERT_TRADE_VIEWS = gql`
	mutation insert_views($request_id: uuid!, $user_id: String!, $date: date!) {
		insert_views_one(
			object: { request_id: $request_id, user_id: $user_id, date: $date }
			on_conflict: { constraint: views_pkey, update_columns: [] }
		) {
			timestamp
		}
	}
`;
export const INCREMENT_TRADE_VIEWS = gql`
	mutation update_views($request_id: uuid!, $user_id: String!, $date: date!) {
		object: update_views(
			where: { request_id: { _eq: $request_id }, user_id: { _eq: $user_id }, date: { _eq: $date } }
			_inc: { views: 1 }
		) {
			affected_rows
		}
	}
`;

export const INSERT_TRADE_SEARCHES = gql`
	mutation insert_views($request_id: uuid!, $user_id: String!, $date: date!) {
		insert_views_one(
			object: { request_id: $request_id, user_id: $user_id, date: $date }
			on_conflict: { constraint: views_pkey, update_columns: [] }
		) {
			timestamp
		}
	}
`;
export const INCREMENT_TRADE_SEARCHES = gql`
	mutation update_views($request_id: uuid!, $user_id: String!, $date: date!) {
		object: update_views(
			where: { request_id: { _eq: $request_id }, user_id: { _eq: $user_id }, date: { _eq: $date } }
			_inc: { searches: 1 }
		) {
			affected_rows
		}
	}
`;

export const INSERT_SERVICE_VIEWS = gql`
	mutation insert_views($request_id: uuid!, $user_id: String!, $date: date!) {
		insert_views_one(
			object: { request_id: $request_id, user_id: $user_id, date: $date }
			on_conflict: { constraint: views_pkey, update_columns: [] }
		) {
			timestamp
		}
	}
`;
export const INCREMENT_SERVICE_VIEWS = gql`
	mutation update_views($request_id: uuid!, $user_id: String!, $date: date!) {
		object: update_views(
			where: { request_id: { _eq: $request_id }, user_id: { _eq: $user_id }, date: { _eq: $date } }
			_inc: { views: 1 }
		) {
			affected_rows
		}
	}
`;

export const INSERT_SERVICE_SEARCHES = gql`
	mutation insert_views($request_id: uuid!, $user_id: String!, $date: date!) {
		insert_views_one(
			object: { request_id: $request_id, user_id: $user_id, date: $date }
			on_conflict: { constraint: views_pkey, update_columns: [] }
		) {
			timestamp
		}
	}
`;
export const INCREMENT_SERVICE_SEARCHES = gql`
	mutation update_views($request_id: uuid!, $user_id: String!, $date: date!) {
		object: update_views(
			where: { request_id: { _eq: $request_id }, user_id: { _eq: $user_id }, date: { _eq: $date } }
			_inc: { searches: 1 }
		) {
			affected_rows
		}
	}
`;
export const UPDATE_ORDER = gql`
	mutation update_trade_order_by_pk($object: trade_orders_set_input!, $id: uuid!) {
		object: update_trade_orders_by_pk(pk_columns: { id: $id }, _set: $object) {
			id
		}
	}
`;

export const UPDATE_REQUESTS = gql`
	mutation update_requests($object: requests_set_input!, $where: requests_bool_exp!) {
		object: update_requests(where: $where, _set: $object) {
			affected_rows
		}
	}
`;

export const ADD_LOCATION = gql`
	mutation InsertLocation(
		$name: String
		$address_line_1: String
		$address_line_2: String
		$address_line_3: String
		$city: String
		$state: String
		$country: enum_countries_enum
		$postcode: String
		$is_farm: Boolean
		$lat: numeric
		$lng: numeric
	) {
		insert_locations(
			objects: {
				name: $name
				address_line_1: $address_line_1
				address_line_2: $address_line_2
				address_line_3: $address_line_3
				city: $city
				state: $state
				country: $country
				postcode: $postcode
				is_farm: $is_farm
				lat: $lat
				lng: $lng
			}
		) {
			returning {
				id
				name
				address_line_1
				address_line_2
				address_line_3
				city
				state
				country
				postcode
				is_farm
				enum_country {
					comment
				}
			}
		}
	}
`;

export const CREATE_OFFER = gql`
	mutation CreateOffer(
		$location_id: uuid
		$product_id: uuid
		$product_type_id: uuid
		$type: enum_request_types_enum
		$unit: enum_product_units_enum
		$price_per_unit: numeric
		$number_of_units: Int
		$advance_payment_percentage: Int
		$from_date: date
		$to_date: date
		$logistics: String
		$pictures: jsonb
	) {
		insert_requests(
			objects: {
				location_id: $location_id
				product_id: $product_id
				product_type_id: $product_type_id
				type: $type
				unit: $unit
				price_per_unit: $price_per_unit
				number_of_units: $number_of_units
				advance_payment_percentage: $advance_payment_percentage
				from_date: $from_date
				to_date: $to_date
				logistics: $logistics
				pictures: $pictures
			}
		) {
			affected_rows
			returning {
				id
			}
		}
	}
`;
