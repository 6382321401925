import React, { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AntdTable, MapView } from "../../../../shared/components";
import { useTranslatedLabel } from "../../../../shared/hooks";
import ViewServiceOrder from "../../../client/pages/user/service/orders/view-order";
import { useCountSubscription } from "../../../client/widgets/countSubscription";
import { Layout } from "../../components";
import { PATHS } from "../../config";
import { useAggregateSubscription } from "../../hooks";
import { TinyLineChart } from "../dashboard/tiny-line-chart";
import { Filters } from "./filters";

const data_fields = `id created_at updated_at  uid active status  date provider_service { id uid }
	service_type_id service_type { id name service_id service { id name } } status enum_order_status { comment value }
	price_per_unit number_of_units total_amount date
	from_location { name enum_country { comment } city  lat lng  id  is_farm user { id buyer_access grower_access provider_access } }
	to_location { name enum_country { comment } city  lat lng  id  is_farm user { id buyer_access grower_access provider_access } }
	seller { name email id } buyer { name email id } `;

const map_fields = `id uid active from_location { name city  lat lng  id  is_farm user { id buyer_access grower_access provider_access } } to_location { name city  lat lng  id  is_farm user { id buyer_access grower_access provider_access } }`;

export const ServiceOrders = ({
	history,
	component = false,
	tableOnly = false,
	pagination,
	noFilter = false,
	limit = null,
	component_filters = {},
	hideColumns = [],
}) => {
	const { t } = useTranslatedLabel();
	const [filters, setFilters] = useState({});
	const [tab_id, setTabId] = useState("table");
	const COLUMNS = [
		{
			title: t("id"),
			key: "uid",
			render: (row) => <Link to={`${PATHS.serviceOrders}/${row.id}`}>{row?.uid}</Link>,
			sorter: AntdTable.GenericSorter("uid"),
		},
		{
			title: t("status"),
			key: "status",
			render: (row) => row.enum_order_status?.comment,
			sorter: AntdTable.TextSorter("status"),
		},
		{
			title: t("service"),
			key: "service",
			render: AntdTable.ServiceRenderer,
			sorter: AntdTable.ServiceSorter,
		},
		{
			title: "Category",
			key: "service_type",
			render: AntdTable.CategoryRenderer,
			sorter: AntdTable.CategorySorter,
		},
		{
			title: t("price_per_unit"),
			key: "price_per_unit",
			render: AntdTable.MoneyRenderer("price_per_unit"),
			sorter: AntdTable.GenericSorter("price_per_unit"),
		},
		{
			title: t("quantity"),
			key: "number_of_units",
			render: AntdTable.NumberRenderer("number_of_units"),
			sorter: AntdTable.GenericSorter("number_of_units"),
		},
		{
			title: t("amount"),
			key: "total_amount",
			render: AntdTable.MoneyRenderer("total_amount"),
			sorter: AntdTable.GenericSorter("total_amount"),
		},
		{
			title: t("date"),
			key: "date",
			render: AntdTable.DateRenderer("date"),
			sorter: AntdTable.TextSorter("date"),
		},
		{
			title: t("origin"),
			key: "from_location",
			render: AntdTable.LocationRenderer("from_location"),
			sorter: AntdTable.LocationSorter("from_location"),
		},
		{
			title: t("destination"),
			key: "to_location",
			render: AntdTable.LocationRenderer("to_location"),
			sorter: AntdTable.LocationSorter("to_location"),
		},
		{
			title: t("seller"),
			key: "seller",
			render: AntdTable.UserRenderer("seller"),
			sorter: AntdTable.UserSorter("seller"),
		},
		{
			title: t("buyer"),
			key: "buyer",
			render: AntdTable.UserRenderer("buyer"),
			sorter: AntdTable.UserSorter("buyer"),
		},
		// {
		// 	title: t("created"),
		// 	key: "created_at",
		// 	render: AntdTable.DateRenderer("created_at"),
		// 	sorter: AntdTable.TextSorter("created_at"),
		// },
		// {
		// 	title: t("updated"),
		// 	key: "updated_at",
		// 	render: AntdTable.DateRenderer("updated_at"),
		// 	sorter: AntdTable.TextSorter("updated_at"),
		// },
		{
			title: t("active"),
			key: "active",
			dataIndex: "active",
			render: (text, record) => (text ? <i className="la la-check-circle la-lg text-success align-align-self-center" /> : ""),
			sorter: (a, b) => Number(a.active) - Number(b.active),
		},
		{
			title: "Offer ID",
			key: "provider_service_id",
			render: (row) => <Link to={`${PATHS.serviceOffers}/${row?.provider_service?.id}`}>{row?.provider_service?.uid}</Link>,
			sorter: AntdTable.TextSorter("provider_service_id"),
		},
	];

	const active_count = useCountSubscription("service_orders", { active: { _eq: true } });
	const active_amount = useAggregateSubscription("service_orders", { active: { _eq: true } }, "total_amount");
	const total_count = useCountSubscription("service_orders", { request_id: { _is_null: true } });
	const total_amount = useAggregateSubscription("service_orders", {}, "total_amount");

	if (component && tableOnly) {
		return (
			<AntdTable
				entity="service_orders"
				columns={COLUMNS}
				where={{ ...component_filters }}
				order_by={{ uid: "desc" }}
				query_fields={data_fields}
				filters={filters}
				limit={limit}
				// pagination={pagination}
			/>
		);
	}

	const content = (
		<>
			{!noFilter && (
				<Filters
					entity="service_orders"
					onChange={(e) => setFilters((state) => ({ ...e }))}
					fields={["service_type_id", "dates", "order_status"]}
					number_fields={["amount"]}
				/>
			)}
			<Tabs activeKey={tab_id} onSelect={(e) => setTabId(e)} className="m-0 p-0">
				<Tab eventKey="table" title={t("table_view")}>
					<AntdTable
						entity="service_orders"
						columns={COLUMNS}
						where={{ ...component_filters }}
						order_by={{ uid: "desc" }}
						query_fields={data_fields}
						filters={filters}
						limit={limit}
						// pagination={pagination}
						expandable={{
							expandedRowRender: (row) => <ViewServiceOrder component={true} inAdmin={true} order_id={row.id} />,
							rowExpandable: (row) => !!row?.id,
						}}
					/>
				</Tab>
				<Tab eventKey="map" title={t("map_view")}>
					<MapView
						zoom={2}
						entity="service_orders"
						where={{ ...component_filters }}
						order_by={{ uid: "desc" }}
						query_fields={map_fields}
						filters={filters}
						limit={limit}
						pagination={pagination}
					/>
				</Tab>
				<Tab eventKey="chart" title={t("chart_view")}>
					<TinyLineChart
						filters={{ date: { _gte: filters.from_date, _lte: filters.to_date } }}
						title="Service Orders"
						entity="z_service_orders"
						height={600}
						showXaxisLabels={true}
						enableTooltip={true}
					/>
				</Tab>
			</Tabs>
		</>
	);

	return (
		<>
			{component ? (
				content
			) : (
				<Layout
					title={t("service_orders")}
					activeCount={active_count}
					activeAmount={active_amount}
					totalCount={total_count}
					totalAmount={total_amount}
				>
					<div>{content}</div>
				</Layout>
			)}
		</>
	);
};
