// import ReactPdf from "react-to-pdf";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import { Modal } from "antd";
import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import { useTranslatedLabel } from "../../../../shared/hooks";
import InvoiceTem from "./reports/Invoice";

export function Invoice({ order, payment }) {
	const { t } = useTranslatedLabel();
	const [show, setShow] = useState(false);

	return (
		<>
			<div onClick={() => setShow(true)} className="btn btn-sm btn-outline-primary ml-auto mr-3">
				{t("invoice")}
			</div>
			<Modal
				visible={show}
				onCancel={() => setShow(false)}
				title={
					<div className="row">
						<div className="col">{t("invoice")}</div>
						<div className="col">
							<PDFDownloadLink document={<InvoiceTem order={order} />} fileName={`${order.uid}.pdf`}>
								{({ blob, url, loading, error }) =>
									loading ? (
										<div className="text-center">
											<Spinner as="span" animation="border" user_role="status" aria-hidden="true" />
										</div>
									) : (
											<div onClick={() => setShow(false)} className="btn btn-sm btn-outline-primary float-right ml-2">
												{t("download")}
											</div>
										)
								}
							</PDFDownloadLink>
						</div>
						<div className="col-1"></div>
					</div>
				}
				footer={false}
				width={900}
				height={1300}
			>
				<PDFViewer height={1191} width={842}>
					<InvoiceTem order={order} payment={payment} />
				</PDFViewer>
			</Modal>
		</>
	);
}
