import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useTranslatedLabel } from "../../../../shared/hooks";
import LocationDashboard from "../../../client/pages/user/location-details";
import { Layout } from "../../components";
import { Offers } from "../transactions/offers";
import { Orders } from "../transactions/orders";
import { ServiceOffers } from "../transactions/service-offers";
import { ServiceOrders } from "../transactions/service-orders";

export const LocationDetails = (props) => {
  const { t } = useTranslatedLabel();
  const { id } = useParams();
  const isFarm = props.location?.pathname?.includes("farms");

  return (
    <Layout title={isFarm ? "Farm" : "Location"}>
      <div>
        <Tabs
          defaultKey="profile"
          style={{ paddingTop: "0px", marginTop: "0px" }}
        >
          <Tab eventKey="dashboard" title={t("dashboard")}>
            <LocationDashboard isFarm={isFarm} component={true} id={id} />
          </Tab>

          <Tab eventKey="offers" title={t("trade_offers")}>
            <Offers
              component={true}
              component_filters={{
                location_id: { _eq: id },
              }}
            />
          </Tab>

          <Tab eventKey="orders" title={t("trade_orders")}>
            <Orders
              component={true}
              component_filters={{
                _or: [
                  { from_location_id: { _eq: id } },
                  { to_location_id: { _eq: id } },
                ],
              }}
            />
          </Tab>

          <Tab eventKey="service_offers" title={t("service_offers")}>
            <ServiceOffers
              component={true}
              component_filters={{
                location_id: { _eq: id },
              }}
            />
          </Tab>

          <Tab eventKey="service_orders" title={t("service_orders")}>
            <ServiceOrders
              component={true}
              component_filters={{
                location_id: { _eq: id },
              }}
            />
          </Tab>
        </Tabs>
      </div>
    </Layout>
  );
};
