import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AntdTable, MapView } from "../../../../shared/components";
import { useDocTitle } from "../../../../shared/helpers";
import { useTranslatedLabel } from "../../../../shared/hooks";
import ViewTradeOffer from "../../../client/pages/marketplace/view-offer";
import { useCountSubscription } from "../../../client/widgets/countSubscription";
import { Layout } from "../../components";
import { PATHS } from "../../config";
import { useAggregateSubscription, useQueryParams } from "../../hooks";
import { TinyLineChart } from "../dashboard/tiny-line-chart";
import { Filters } from "./filters";

const data_fields = `id created_at updated_at  uid active status type  product_type_id product_type { id name product_id product { id name } }
	enum_request_status { comment value } enum_request_type { comment value }
	price_per_unit number_of_units total_amount from_date to_date  days_to_maturity
	location { name city  lat lng  id  is_farm user { id buyer_access grower_access provider_access }  }  	user { name email id photo_url }
	views: views_aggregate { aggregate { sum { searches views } } }
	bids: requests_aggregate { aggregate { count } }`;

const map_fields = `id uid type active location { name city  lat lng  id  is_farm user { id buyer_access grower_access provider_access }  }`;

export const BuyOffers = ({
  history,
  component = false,
  pagination,
  noFilter = false,
  limit = null,
  component_filters = {},
  hideColumns = [],
}) => {
  useDocTitle("trade_offers");
  const { t } = useTranslatedLabel();
  const [filters, setFilters] = useState({});
  const queryParams = useQueryParams();
  useEffect(() => {
    queryParams.forEach((value, key) => {
      setFilters((state) => {
        state[key] = { _eq: value };
        return { ...state };
      });
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [tab_id, setTabId] = useState("table");
  const COLUMNS = [
    {
      title: t("id"),
      key: "uid",
      render: (row) => <Link to={`${PATHS.offers}/${row.id}`}>{row?.uid}</Link>,
      sorter: AntdTable.GenericSorter("uid"),
    },
    {
      title: t("type"),
      key: "type",
      dataIndex: "type",
      render: (text, record) => {
        return {
          props: {
            style: {
              background: text?.includes("sell") ? "lightgreen" : "orange",
            },
          },
          children: <div>{record.enum_request_type?.comment}</div>,
        };
      },
      sorter: AntdTable.TextSorter("type"),
    },
    {
      title: t("status"),
      key: "status",
      render: (row) => row.enum_request_status?.comment,
      sorter: AntdTable.TextSorter("status"),
    },
    {
      title: t("product"),
      key: "product",
      render: AntdTable.ProductRenderer,
      sorter: AntdTable.ProductSorter,
    },
    {
      title: t("variety"),
      key: "product_type",
      render: AntdTable.VarietyRenderer,
      sorter: AntdTable.VarietySorter,
    },
    {
      title: t("price_per_unit"),
      key: "price_per_unit",
      render: AntdTable.MoneyRenderer("price_per_unit"),
      sorter: AntdTable.GenericSorter("price_per_unit"),
    },
    {
      title: t("quantity"),
      key: "number_of_units",
      render: AntdTable.NumberRenderer("number_of_units"),
      sorter: AntdTable.GenericSorter("number_of_units"),
    },
    {
      title: t("value"),
      key: "total_amount",
      render: AntdTable.MoneyRenderer("total_amount"),
      sorter: AntdTable.GenericSorter("total_amount"),
    },
    {
      title: "Start Date",
      key: "from_date",
      render: AntdTable.DateRenderer("from_date"),
      sorter: AntdTable.TextSorter("from_date"),
    },
    {
      title: "End Date",
      key: "to_date",
      render: AntdTable.DateRenderer("to_date"),
      sorter: AntdTable.TextSorter("to_date"),
    },

    // {
    // 	title: "Days To Maturity",
    // 	key: "days_to_maturity",
    // render: CustomTable.NumberRenderer("days_to_maturity"),
    // 	sorter: AntdTable.GenericSorter("days_to_maturity"),
    // },
    {
      title: "Location",
      key: "location",
      render: AntdTable.LocationRenderer("location"),
      sorter: AntdTable.LocationSorter("location"),
    },
    {
      title: t("user"),
      key: "user",
      render: AntdTable.UserRenderer("user"),
      sorter: AntdTable.UserSorter("user"),
    },
    // {
    // 	title: t("created"),
    // 	key: "created_at",
    // 	render: AntdTable.DateRenderer("created_at"),
    // 	sorter: AntdTable.TextSorter("created_at"),
    // },
    // {
    // 	title: t("updated"),
    // 	key: "updated_at",
    // 	render: AntdTable.DateRenderer("updated_at"),
    // 	sorter: AntdTable.TextSorter("updated_at"),
    // },
    {
      title: t("active"),
      key: "active",
      dataIndex: "active",
      render: (text, record) =>
        text ? (
          <i className="la la-check-circle la-lg text-success align-align-self-center" />
        ) : (
          ""
        ),
      sorter: (a, b) => Number(a.active) - Number(b.active),
    },
    // {
    //   title: t("bids"),
    //   key: "active_bids",
    //   render: (data) => data?.active_bids?.aggregate.count,
    //   sorter: (a, b) =>
    //     a?.active_bids?.aggregate &&
    //     b.active_bids.aggregate &&
    //     a?.active_bids?.aggregate.count - b.active_bids.aggregate.count,
    // },
    // {
    // 	title: t("viewed"),
    // 	key: "viewed",
    // 	render: (data) => data.views?.aggregate?.sum?.views || 0,
    // 	sorter: (a, b) => a?.views?.aggregate?.sum && b.views.aggregate.sum && a?.views?.aggregate?.sum.views - b.views.aggregate.sum.views,
    // },
    // {
    // 	title: t("searched"),
    // 	key: "searched",
    // 	render: (data) => data.views?.aggregate?.sum?.searches || 0,
    // 	sorter: (a, b) => a?.views?.aggregate?.sum && b.views.aggregate.sum && a?.views?.aggregate?.sum.views - b.views.aggregate.sum.views,
    // },
  ];

  const content = (
    <>
      {!noFilter && (
        <Filters
          entity="requests"
          onChange={(e) => setFilters(e)}
          fields={["product_type_id", "from_to_dates", "request_status"]}
          number_fields={[
            "price_per_unit",
            "number_of_units",
            "total_amount",
            "advance_payment_percentage",
            "advance_amount",
          ]}
        />
      )}
      <Tabs
        activeKey={tab_id}
        onSelect={(e) => setTabId(e)}
        className="m-0 p-0"
      >
        <Tab eventKey="table" title={t("table_view")}>
          <AntdTable
            entity="requests"
            columns={COLUMNS.filter((c) => !hideColumns.includes(c.key))}
            where={{
              request_id: { _is_null: true },
              type: { _eq: "buy_request" },
              ...component_filters,
            }}
            order_by={{ uid: "desc" }}
            query_fields={data_fields}
            filters={filters}
            limit={limit}
            expandable={{
              expandedRowRender: (row) => (
                <div className="p-1 bg-primary">
                  <ViewTradeOffer
                    offer_id={row.id}
                    component={true}
                    inAdmin={true}
                  />
                </div>
              ),
              rowExpandable: (row) => !!row.id,
            }}
          />
        </Tab>
        <Tab eventKey="map" title={t("map_view")}>
          <MapView
            zoom={2}
            entity="requests"
            where={{
              request_id: { _is_null: true },
              type: { _eq: "buy_request" },
              ...component_filters,
            }}
            order_by={{ uid: "desc" }}
            query_fields={map_fields}
            filters={filters}
            limit={limit}
            pagination={pagination}
          />
        </Tab>
        <Tab eventKey="chart" title={t("chart_view")}>
          <TinyLineChart
            filters={{
              date: { _gte: filters.from_date, _lte: filters.to_date },
            }}
            title="Buy Offers"
            entity="z_buy_offers"
            userEntity="z_buyers"
            userColumn="Buyers"
            height={600}
            showXaxisLabels={true}
            enableTooltip={true}
          />
        </Tab>
      </Tabs>
    </>
  );

  const active_count = useCountSubscription("requests", {
    request_id: { _is_null: true },
    type: { _eq: "buy_request" },
    active: { _eq: true },
  });
  const active_amount = useAggregateSubscription(
    "requests",
    {
      active: { _eq: true },
      type: { _eq: "buy_request" },
      request_id: { _is_null: true },
    },
    "total_amount"
  );
  const total_count = useCountSubscription("requests", {
    type: { _eq: "buy_request" },
    request_id: { _is_null: true },
  });

  const total_amount = useAggregateSubscription(
    "requests",
    { type: { _eq: "buy_request" }, request_id: { _is_null: true } },
    "total_amount"
  );

  return (
    <>
      {component ? (
        content
      ) : (
        <Layout
          title={t("buy_offers")}
          activeCount={active_count}
          activeAmount={active_amount}
          totalCount={total_count}
          totalAmount={total_amount}
        >
          <div>{content}</div>
        </Layout>
      )}
    </>
  );
};
