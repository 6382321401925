import React from "react";
import { Button } from "react-bootstrap";

export function MapButton({ children, ...rest }) {
  return (
    <Button variant="light" className="map-btn" size="lg" block {...rest}>
      {children ? (
        children
      ) : (
        <>
          <i className="la la-map-marker"></i>
          View on Map
        </>
      )}
    </Button>
  );
}
