import React from "react";

export const Stepper = ({ steps, current }) => {
	return (
		<div style={{ width: "95%" }}>
			<div className={"arrow-steps clearfix row"}>
				{steps?.map((item, index) => (
					<div key={index} className={`col step ${item.value === current ? "current" : ""}`}>
						{item.comment}
					</div>
				))}
			</div>
		</div>
	);
};
