import React from "react";
import { Link } from "react-router-dom";
import { useTranslatedLabel } from "../../../shared/hooks";

// const Header = () => {
// 	// const { user = {}, logoutUser, is_authenticated, photo_url } = useContext(AuthContext);
// 	// const { name } = user;
// 	// const { t } = useTranslatedLabel();
// 	// const [, setPrismicData] = useState();
// 	// const { general_language, setGeneralLanguage, general_languages, general_languages_map } = useContext(GeneralContext);

// 	// // Get the homepage and blog post documents from Prismic
// 	// useEffect(() => {
// 	// 	const fetchPrismicData = async () => {
// 	// 		try {
// 	// 			if (general_language) {
// 	// 				const homeDoc = await webpageclient.query(Prismic.Predicates.at("document.type", "homepage"), {
// 	// 					lang: (general_language || "en_gb").replace("_", "-"),
// 	// 				});

// 	// 				if (homeDoc && homeDoc.results && homeDoc.results[0]) setPrismicData(homeDoc.results[0]);
// 	// 				else console.warn("Blog Home document was not found. Make sure it exists in your Prismic repository");
// 	// 			}
// 	// 		} catch (error) {
// 	// 			console.error(error);
// 	// 		}
// 	// 	};

// 	// 	fetchPrismicData();
// 	// }, [general_language]);

// 	// const logoutClick = (e) => {
// 	// 	e.preventDefault();
// 	// 	logoutUser();
// 	// 	logout();
// 	// };

// 	return (
// 		<>
// 			{/* <div className="header">
// 				<div className="row col bg-secondary m-0 p-0">
// 					<div className="container">
// 						<Navbar className="m-0 p-0">
// 							<Nav className="mx-auto"></Nav>
// 							{is_authenticated && (
// 								<Nav className="ml-3">
// 									<Nav.Item className="m-0 p-0 ml-3">
// 										<small className="transaction-dropdown">
// 											<p className="m-0 p-0" style={{ fontSize: 12 }}>
// 												<i className="las la-user la-lg"></i>
// 												{"  "}
// 												{name}
// 											</p>
// 											<div className="transaction-dropdown-menu">
// 												<>
// 													<Link
// 														to={`${PATHS.account}/${user?.id}`}
// 														className="dropdown-item"
// 														style={{ fontSize: 12 }}
// 													>
// 														<>
// 															<img
// 																src={
// 																	photo_url ||
// 																	"https://res.cloudinary.com/ampcome/image/upload/c_thumb,e_colorize:100,g_face,q_100,w_200/v1595014484/mahaseel/avatar.png"
// 																}
// 																alt=""
// 																className="rounded-circle mr-2"
// 																style={{ width: "1rem", height: "1rem" }}
// 															/>
// 															<span>{t("profile")}</span>
// 														</>
// 													</Link>
// 													<Link to={PATHS.support} className="dropdown-item" style={{ fontSize: 12 }}>
// 														<i className="la la-headset  mr-2"></i> {t("support")}
// 													</Link>
// 													<Link
// 														to={"./logout"}
// 														className="dropdown-item logout"
// 														onClick={logoutClick}
// 														style={{ fontSize: 12 }}
// 													>
// 														<i className="la la-sign-out  mr-2"></i> {t("logout")}
// 													</Link>
// 												</>
// 											</div>
// 										</small>
// 									</Nav.Item>
// 								</Nav>
// 							)}
// 							{general_languages_map[general_language]?.comment && (
// 								<Nav className="ml-3">
// 									<Nav.Item className="m-0 p-0 ml-3 pull-right">
// 										<small className="transaction-dropdown">
// 											<p className="m-0 p-0" style={{ fontSize: 12 }}>
// 												<i className="las la-language la-lg"></i>
// 												{"  "}
// 												{general_languages_map[general_language]?.comment || "English"}
// 											</p>
// 											<div className="transaction-dropdown-menu">
// 												<>
// 													{general_languages
// 														//.filter((l) => l.value !== general_language)
// 														.map((l, index) => (
// 															<Link
// 																to="#"
// 																className="dropdown-item"
// 																key={index}
// 																onClick={(e) => {
// 																	e.preventDefault();
// 																	setGeneralLanguage(l.value);
// 																}}
// 																style={{ fontSize: 12 }}
// 															>
// 																{l.comment}
// 															</Link>
// 														))}
// 												</>
// 											</div>
// 										</small>
// 									</Nav.Item>
// 								</Nav>
// 							)}
// 						</Navbar>
// 					</div>
// 				</div>
// 			</div> */}
// 		</>
// 	);
// };

const Header = ({ title, activeCount = 0, totalCount = 0, activeAmount = 0, totalAmount = 0 }) => {
	const { t } = useTranslatedLabel();
	return (
		<>
			<div className="header-admin d-flex justify-content-between align-items-center p-3 px-5">
				<p className="mb-0 page-title display-4 p-0 font-weight-bold">{title}</p>
				{/* {totalCount > 0 && (
					<p className="mb-0 page-title p-0">
						Count:{" "}
						<b className="text-success">
							<NumberFormat value={activeCount} displayType={"text"} thousandSeparator={true} />
						</b>{" "}
						/ <NumberFormat value={totalCount} displayType={"text"} thousandSeparator={true} />
					</p>
				)}
				{totalAmount > 0 && (
					<p className="mb-0 page-title p-0">
						Amount:{" "}
						<b className="text-success">
							<NumberFormat value={activeAmount} displayType={"text"} thousandSeparator={true} prefix={"$ "} />
						</b>{" "}
						/ <NumberFormat value={totalAmount} displayType={"text"} thousandSeparator={true} prefix={"$ "} />
					</p>
				)} */}
				<div className="transaction-dropdown">
					<p className="mb-0 d-flex align-items-center font-weight-bold">
						<span>Administrator</span>
					</p>
					<div className="transaction-dropdown-menu">
						{/* <Link to={PATHS.account} className="dropdown-item">
							<>
								<img
									src={
										"https://res.cloudinary.com/ampcome/image/upload/c_thumb,e_colorize:100,g_face,q_100,w_200/v1595014484/mahaseel/avatar.png"
									}
									alt=""
									className="rounded-circle mr-2"
									style={{ width: "1rem", height: "1rem" }}
								/>
								<span>{t("profile")}</span>
							</>
						</Link> */}
						<Link to={"./logout"} className="dropdown-item logout" onClick={() => null}>
							<i className="la la-sign-out  mr-2"></i> {t("logout")}
						</Link>
					</div>
				</div>
			</div>
		</>
	);
};

export default Header;
