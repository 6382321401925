import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Dropdown, Form, Row } from "react-bootstrap";
import { PageTitle } from "../../../../shared/components";
import { useTranslatedLabel } from "../../../../shared/hooks";
import { Layout } from "../../components";

export const StaffDetails = ({ location }) => {
	const { t } = useTranslatedLabel();
	const fileRef = useRef(null);
	const baseState = {
		name: "",
		role: "",
		email: "",
		password1: "",
		password2: "",
		photo_url: "https://s.gravatar.com/avatar/d416aad9f1b8d9ec3ab92c46af590118?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fm.png",
	};
	const [input, setInput] = useState(baseState);
	const { data } = location?.state || {};

	useEffect(() => {
		if (data) {
			setInput((state) => ({ ...state, ...data }));
		}
	}, [data]);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setInput((state) => ({ ...state, [name]: value }));
	};

	const handleFIle = (e) => {
		const { files } = e.target;
		const result = URL.createObjectURL(files[0]);
		setInput((state) => ({ ...state, photo_url: result, file_src: files[0] }));
	};

	const onImageClick = () => {
		fileRef.current.click();
	};

	return (
		<Layout>
			<PageTitle
				showLogo={true}
				title={t("staff_details")}
				rightElement={
					<div className="col-2">
						<Dropdown>
							<Dropdown.Toggle variant="success" id="dropdown-basic">
								<i className="la la-cog" /> More Options
							</Dropdown.Toggle>

							<Dropdown.Menu>
								<Dropdown.Item>Make Admin</Dropdown.Item>
								<Dropdown.Item>Suspend User</Dropdown.Item>
								<Dropdown.Item>Delete User</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					</div>
				}
			/>
			<div className="">
				<Row>
					<Col md={{ offset: 3, span: 6 }}>
						<Form>
							<Form.Group controlId="formGroupImg">
								<div className="center-img">
									<img src={input.photo_url} className="rounded-circle avatar-img-big" alt="avatar" />
									<div className="img-add" onClick={onImageClick}>
										<i className="la la-plus-circle" />
									</div>
								</div>
								<Form.Control type="file" ref={fileRef} name="photo_url" onChange={handleFIle} style={{ display: "none" }} />
							</Form.Group>
							<Form.Group controlId="formGroupName">
								<Form.Label>Full Name</Form.Label>
								<Form.Control
									type="text"
									placeholder="Enter User Name"
									name="name"
									value={input.name}
									onChange={handleChange}
								/>
							</Form.Group>
							<Form.Group controlId="formGroupEmail">
								<Form.Label>Email address</Form.Label>
								<Form.Control type="email" placeholder="Enter email" name="email" value={input.email} onChange={handleChange} />
							</Form.Group>
							<Form.Group controlId="exampleForm.ControlSelect1">
								<Form.Label>Role</Form.Label>
								<Form.Control as="select" name="role" value={input.role} onChange={handleChange}>
									<option value="Agent">Agent</option>
									<option value="Moderator">Moderator</option>
									<option value="Admin">Admin</option>
								</Form.Control>
							</Form.Group>
							<Form.Group controlId="formGroupNewPass">
								<Form.Label>New Password</Form.Label>
								<Form.Control type="text" name="password1" value={input.password1} onChange={handleChange} />
							</Form.Group>
							<Form.Group controlId="formGroupVerify">
								<Form.Label>Verify Password</Form.Label>
								<Form.Control type="text" name="password2" value={input.password2} onChange={handleChange} />
							</Form.Group>
						</Form>

						<Button variant="primary" size="lg">
							Save
						</Button>
					</Col>
				</Row>
			</div>
		</Layout>
	);
};
