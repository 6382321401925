/* eslint-disable no-undef */
import { useMutation } from "@apollo/client";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { SketchPicker } from "react-color";
import { Link } from "react-router-dom";
import reactCSS from "reactcss";
import { useTranslatedLabel } from "../../../../shared/hooks";
import { weathericons } from "../../config/weatherstack";
import { UPDATE_LOCATION } from "../../gql";
import { GeneralContext } from "../../store";
import { LocationWidget } from "../location-widget";

const WeatherCard = (props) => {
	const { general_product_types, general_certifications } = useContext(GeneralContext);
	const { color, lat, lng, name, id, city, is_verified, crops, certifications } = props;
	const { t } = useTranslatedLabel();
	const [weatherdata, setWeatherData] = useState({ homeDoc: null, blogPosts: null });
	const [updateLocation] = useMutation(UPDATE_LOCATION);
	const [location_data, setLocationData] = useState({ crops: [], certifications: [] });
	const [current_color, setCurrentColor] = useState(color || `#${Math.floor(Math.random() * 16777215).toString(16)}`);
	const [color_picker, setColorPicker] = useState(false);
	const styles = reactCSS({
		default: {
			color: {
				width: "20px",
				height: "20px",
				borderRadius: "2px",
				background: current_color,
			},
			swatch: {
				padding: "2px",
				background: "#fff",
				borderRadius: "1px",
				boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
				display: "inline-block",
				cursor: "pointer",
			},
			popover: {
				position: "absolute",
				zIndex: "2",
			},
			cover: {
				position: "fixed",
				top: "0px",
				right: "0px",
				bottom: "0px",
				left: "0px",
			},
		},
	});

	useEffect(() => {
		const getWeatherData = async () => {
			try {
				const response = await axios({
					url: `https://api.weatherstack.com/current?access_key=64189fb34664aad5373fc1b58bc1d905&query=${lat || 0},${lng || 0}`,
					method: "GET",
					headers: {
						"content-type": "application/json",
					},
				});
				if (response && response.data && response.data.current) {
					setWeatherData(response.data.current);
				}
			} catch (error) {
				console.error(error);
			}
		};

		getWeatherData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lat, lng]);

	useEffect(() => {
		const cropsData = general_product_types.filter((e) => crops?.includes(e.id));
		const certificationsData = general_certifications.filter((e) => certifications?.includes(e.id));
		setLocationData({ crops: cropsData, certifications: certificationsData });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [crops, certifications]);

	return (
		<>
			<div className={`row bg-white ${is_verified ? "" : "border border-danger border-3"}`}>
				<div className="col-12 p-2">
					{weatherdata && (
						<>
							<div className="d-flex justify-content-between align-items-center">
								<img src={weathericons[weatherdata.weather_code]} alt="" className="location-weather-icon" />

								<div style={{ textAlign: "center" }}>
									<small className="mb-0">{weatherdata.weather_descriptions}</small>
									<p className="m-0">
										<span className="location-weather-temp">{weatherdata.temperature} °C</span>
									</p>
								</div>

								<small
									style={{
										borderLeft: "1px dotted #dddddd",
										paddingLeft: "6px",
									}}
								>
									{t("wind")}: {weatherdata.wind_speed} kmph
									<br />
									{t("precipitation")}: {weatherdata.precip} mm
									<br />
									{t("pressure")}: {weatherdata.pressure} mb
									<br />
									{t("humidity")}: {weatherdata.humidity}
								</small>
							</div>
						</>
					)}
				</div>

				<div className="col-1">
					<div style={styles.swatch} onClick={() => setColorPicker(true)}>
						<div style={styles.color} />
					</div>
					{color_picker && (
						<div style={styles.popover}>
							<div style={styles.cover} onClick={() => setColorPicker(false)} />
							<SketchPicker
								color={current_color}
								onChange={(e) => {
									setCurrentColor(e.hex);
									updateLocation({ variables: { id: id, object: { color: e.hex } } });
								}}
							/>
						</div>
					)}
				</div>
				<div className="col text-left">
					<Link to={`/locations/${id}`}>
						<b>{name}</b>
					</Link>
					<small>
						{" "}
						( {city} ) {!is_verified && t("not_verified")}
					</small>
					<LocationWidget data={{ ...props, ...location_data }} icon={true} />
				</div>
			</div>
		</>
	);
};
export default WeatherCard;
