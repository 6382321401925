import React from "react";
import { Link } from "react-router-dom";
import { PATHS } from "../../config";

export const InviteStaff = ({ history }) => {
	const onClick = (e) => {
		e.preventDefault();
		history.push(PATHS.dashboard);
	};
	return (
		<>
			<div className="authincation bg-white admin-auth">
				<div className="container">
					<div className="row justify-content-center align-items-center">
						<div className="col-xl-5 col-md-6">
							<div className="auth-logo text-center my-3">
								<Link to={PATHS.base}>
									<img
										src="https://res.cloudinary.com/ampcome/image/upload/c_scale,w_100/v1592541595/mahaseel/mahaseel_new_logo.jpg"
										alt="Mahaseel logo"
									/>
								</Link>
							</div>
							<div className="auth-form card">
								<div className="card-body">
									<h3 className="text-center display-4 mb-3">Invite Staff Users</h3>
									<form name="create-super-admin-form">
										<div className="form-group">
											<label htmlFor=""></label>
											<textarea
												name="staff"
												cols="30"
												rows="5"
												className="form-control p-2"
												placeholder={`john@mahaseel.com\nfatimah@mahaseel.com`}
											></textarea>
										</div>
										<div className="text-center mt-5">
											<button className="btn btn-success btn-block" onClick={onClick}>
												<span>Send Invites</span>
											</button>
											<button className="btn btn-link text-muted sm-text" onClick={(e) => e.preventDefault()}>
												Skip this step for later
											</button>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
