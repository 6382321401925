import { gql } from "@apollo/client";

export const SEARCH_COUNT_USERID = (entity, field = "name") =>
	gql`query ${entity}($text:String!, $user_id: String!) {counts: ${entity}_aggregate(where:{user_id:{_eq:$user_id}, ${field}:{_ilike:$text}}) { aggregate {count} } }`;

export const SEARCH_COUNT = (entity, field = "name") =>
	gql`query ${entity}($text:String!) {counts: ${entity}_aggregate(where:{${field}:{_ilike:$text}}) { aggregate {count} } }`;

export const GET_TIME_SERIES = (entity = "timeseries", fields = " id: date date count amount quantity price ") =>
	gql`subscription ${entity}($where: timeseries_bool_exp, $order_by: [timeseries_order_by!], ) {objects: ${entity}(where: $where, order_by: $order_by) { ${fields} } }`;

export const GET_USER_BY_ID = gql`
	subscription GET_USER_BY_ID($id: String!) {
		object: users_by_pk(id: $id) {
			active
			id
			name
			email
			created_at
			photo_url
			currency
			language
			photo_url
			phone_number
			otp_verified
			email_verified
			buyer_access
			grower_access
			provider_access
			language
			location_id
			location {
				lat
				lng
				active
				id
				name
				address_line_1
				address_line_2
				address_line_3
				city
				color
				uid
				created_at
				updated_at
				state
				country
				postcode
				lat
				lng
				is_farm
				is_verified
				area
				crops
				certifications
				enum_country {
					id: value
					value
					comment
				}
				user {
					active
					id
					email
					photo_url
				}
			}
			locations {
				active
				id
				name
				address_line_1
				address_line_2
				address_line_3
				city
				color
				uid
				created_at
				updated_at
				state
				country
				postcode
				lat
				lng
				is_farm
				is_verified
				area
				crops
				certifications
				enum_country {
					id: value
					value
					comment
				}
				user {
					active
					id
					email
					photo_url
				}
			}
		}
	}
`;

export const GET_GENERAL_TRANSLATIONS = gql`
	subscription translations($language: enum_languages_enum!) {
		translations(where: { language: { _eq: $language } }) {
			id
			map_id
			text
		}
	}
`;
export const GET_GENERAL_LABELS = gql`
	subscription labels {
		labels(order_by: { name: asc }) {
			id
			name
			desc
			icon
		}
	}
`;
export const GET_GENERAL_PRODUCTS = gql`
	subscription products {
		products(where: { active: { _eq: true } }, order_by: { name: asc }) {
			name
			active
			id
			pictures
		}
	}
`;
export const GET_GENERAL_SERVICES = gql`
	subscription services {
		services(where: { active: { _eq: true } }, order_by: { name: asc }) {
			name
			id
			pictures
		}
	}
`;
export const GET_GENERAL_PRODUCT_TYPES = gql`
	subscription product_types {
		product_types(where: { active: { _eq: true } }, order_by: { name: asc }) {
			name
			id
			product_id
			pictures
			product {
				pictures
				id
				name
			}
		}
	}
`;
export const GET_GENERAL_SERVICE_TYPES = gql`
	subscription service_types {
		service_types(where: { active: { _eq: true } }, order_by: { name: asc }) {
			name
			id
			service_id
			pictures
			service {
				pictures
				id
				name
			}
		}
	}
`;
export const GET_GENERAL_CERTIFICATIONS = gql`
	subscription certifications {
		certifications(where: { active: { _eq: true } }, order_by: { name: asc }) {
			name
			id
		}
	}
`;
export const GET_GENERAL_COUNTRIES = gql`
	subscription enum_countries {
		enum_countries(order_by: { value: asc }) {
			id: value
			value
			comment
		}
	}
`;
export const GET_GENERAL_LOGISTICS = gql`
	subscription enum_logistics {
		enum_logistics(order_by: { value: asc }) {
			id: value
			value
			comment
		}
	}
`;
export const GET_GENERAL_CURRENCIES = gql`
	subscription enum_currencies {
		enum_currencies(order_by: { value: asc }) {
			id: value
			value
			comment
		}
	}
`;
export const GET_GENERAL_LANGUAGES = gql`
	subscription enum_languages {
		enum_languages(order_by: { value: asc }) {
			id: value
			value
			comment
		}
	}
`;
export const GET_GENERAL_ORDER_STATUS = gql`
	subscription enum_order_status {
		enum_order_status(order_by: { value: asc }) {
			id: value
			value
			comment
		}
	}
`;
export const GET_GENERAL_PRODUCT_UNTIS = gql`
	subscription enum_product_units {
		enum_product_units(order_by: { value: asc }) {
			id: value
			value
			comment
		}
	}
`;
export const GET_GENERAL_REQUEST_STATUS = gql`
	subscription enum_request_status {
		enum_request_status(order_by: { value: asc }) {
			id: value
			value
			comment
		}
	}
`;
export const GET_GENERAL_REQUEST_TYPES = gql`
	subscription enum_request_types {
		enum_request_types(order_by: { value: asc }) {
			id: value
			value
			comment
		}
	}
`;
export const GET_GENERAL_USER_ROLES = gql`
	subscription enum_user_roles {
		enum_user_roles(order_by: { value: asc }) {
			id: value
			value
			comment
		}
	}
`;
export const GET_GENERAL_SETTINGS = gql`
	subscription settings {
		settings(order_by: { label: asc }) {
			id
			label
			is_numeric
			text
			numeric
		}
	}
`;

export const GET_CERTIFICATIONS = gql`
	query get_certifications {
		certifications(order_by: { uid: asc }) {
			active
			name
			id
			uid
		}
	}
`;

export const GET_STAFF_BY_ID = gql`
	subscription GET_STAFF_BY_ID($email: String!) {
		object: admins_by_pk(email: $email) {
			active
			name
			email
			language
			photo_url
			moderator_access
		}
	}
`;

export const GET_STARTUP_DATA = gql`
	query GetStartupData {
		translations(order_by: { map_id: asc, language: asc }) {
			id
			map_id
			language
			text
		}
		labels(order_by: { name: asc }) {
			id
			name
			desc
			icon
		}
		products(where: { active: { _eq: true } }, order_by: { name: asc }) {
			name
			active
			id
			pictures
		}
		services(where: { active: { _eq: true } }, order_by: { name: asc }) {
			name
			id
			pictures
		}
		product_types(where: { active: { _eq: true } }, order_by: { name: asc }) {
			name
			id
			product_id
			pictures
			product {
				pictures
				id
				name
			}
		}
		service_types(where: { active: { _eq: true } }, order_by: { name: asc }) {
			name
			id
			service_id
			pictures
			service {
				pictures
				id
				name
			}
		}
		certifications(where: { active: { _eq: true } }, order_by: { name: asc }) {
			name
			id
		}
		enum_countries(order_by: { value: asc }) {
			id: value
			value
			comment
		}
		enum_logistics(order_by: { value: asc }) {
			id: value
			value
			comment
		}
		enum_currencies(order_by: { value: asc }) {
			id: value
			value
			comment
		}
		enum_languages(order_by: { value: asc }) {
			id: value
			value
			comment
		}
		enum_order_status(order_by: { value: asc }) {
			id: value
			value
			comment
		}
		enum_product_units(order_by: { value: asc }) {
			id: value
			value
			comment
		}
		enum_request_status(order_by: { value: asc }) {
			id: value
			value
			comment
		}
		enum_request_types(order_by: { value: asc }) {
			id: value
			value
			comment
		}
		enum_user_roles(order_by: { value: asc }) {
			id: value
			value
			comment
		}
		settings(order_by: { label: asc }) {
			id
			label
			is_numeric
			text
			numeric
		}
	}
`;
export const GET_PRODUCTS = gql`
	query get_products {
		products(order_by: { uid: asc }) {
			active
			uid
			name
			id
			product_types_aggregate {
				aggregate {
					count
				}
			}
		}
	}
`;

export const GET_PRODUCT_TYPES = gql`
	query get_product_types($product_id: uuid!) {
		product_types(where: { product_id: { _eq: $product_id } }) {
			active
			id
			name
			uid
			product_id
		}
	}
`;

export const GET_SERVICE_CATEGORIES = gql`
	query get_services {
		services {
			active
			id
			name
			uid
			service_types_aggregate {
				aggregate {
					count
				}
			}
		}
	}
`;

export const GET_SERVICE_SUBCATEGORIES = gql`
	query get_service_types($service_id: uuid!) {
		service_types(where: { service_id: { _eq: $service_id } }) {
			active
			id
			name
			uid
			service_id
		}
	}
`;

export const GET_USERS = gql`
	query get_users($where: users_bool_exp, $limit: Int, $offset: Int, $order_by: [users_order_by!], $distinct_on: [users_select_column!]) {
		objects: users(where: $where, limit: $limit, offset: $offset, order_by: $order_by, distinct_on: $distinct_on) {
			id
			active
			email
			name
			photo_url
			phone_number
			created_at
			agent_access
			buyer_access
			grower_access
			provider_access
			requests_aggregate {
				aggregate {
					count
					sum {
						total_amount
					}
				}
			}
			tradeOrdersByBuyerId_aggregate {
				aggregate {
					count
					sum {
						total_amount
					}
				}
			}
			tradeOrdersBySellerId_aggregate {
				aggregate {
					count
					sum {
						total_amount
					}
				}
			}
			location {
				lat
				lng
				active
				address_line_1
				address_line_2
				address_line_3
				city
				name
				enum_country {
					comment
				}
			}
		}
	}
`;

export const GET_FARMS = gql`
	query get_farms {
		farms: locations(where: { is_farm: { _eq: true } }) {
			id
			name
			active
			lat
			lng
			user {
				id
				email
			}
			uid
			area
			crops
			certifications
			city
			state
			country
			enum_country {
				id: value
				value
				comment
			}
		}
	}
`;

export const GET_REQUESTS = gql`
	query get_requests($where: requests_bool_exp, $order_by: [requests_order_by!]) {
		objects: requests(where: $where, order_by: $order_by) {
			uid
			product_type {
				id
				name
				product {
					id
					name
				}
			}
			type
			days_to_maturity
			enum_request_type {
				comment
				value
			}
			enum_request_status {
				comment
				value
			}
			price_per_unit
			number_of_units
			enum_product_unit {
				comment
				value
			}
			total_amount
			advance_amount
			from_date
			to_date
			location {
				lat
				lng
				id

				enum_country {
					comment
				}
				city
			}
			logistics
			status
			advance_payment_percentage
			id

			notes {
				created_at
				updated_at
				text
			}
			user {
				id
				name
				email
			}

			similar_offers_by_type_user {
				id
				from_date
				user {
					email
					id
				}
				uid
				location {
					lat
					lng
					id
					enum_country {
						comment
					}
					city
				}
			}
		}
	}
`;

export const GET_SIMILAR_REQUEST = gql`
	query get_similar_requests($where: requests_bool_exp, $order_by: [requests_order_by!]) {
		requests(where: $where, order_by: $order_by) {
			id

			similar_offers_by_type_user {
				from_date
				user {
					email
				}
				uid
				location {
					lat
					lng
					enum_country {
						comment
					}
					city
				}
			}
		}
	}
`;

export const GET_TRADE_ORDERS = gql`
	query GET_TRADE_ORDERS($where: trade_orders_bool_exp, $order_by: [trade_orders_order_by!]) {
		objects: trade_orders(where: $where, order_by: $order_by) {
			id
			uid
			product_type {
				name
				id
				product {
					id
					name
				}
			}
			enum_order_status {
				comment
				value
			}
			price_per_unit
			number_of_units
			total_amount
			from_date
			active
			to_date
			advance_amount
			advance_payment_percentage
			status
			days_to_maturity
			from_location {
				lat
				lng
				address_line_1
				address_line_2
				address_line_3
				postcode
				enum_country {
					comment
				}
				city

				id
			}
			to_location {
				lat
				lng
				address_line_1
				address_line_2
				address_line_3
				postcode
				enum_country {
					comment
				}
				city

				id
			}
			logistics_aggregate {
				aggregate {
					count
				}
			}
			notes {
				created_at
				updated_at
				id
				text
				user {
					email
				}
			}
			seller {
				name
				email
				id
			}
			buyer {
				name
				email
				id
			}
			payments {
				created_at
				updated_at
				advance
				amount
				status
				timestamp
				id
				created_at
			}
			unit
			enum_product_unit {
				comment
			}
		}
	}
`;
export const GET_SERVICE_ORDERS = gql`
	query get_service_orders {
		service_orders {
			id
			created_at
			active
			uid
			service_type {
				name
				service {
					name
				}
			}
			buyer {
				email
				phone_number
			}
			provider_service {
				amount
				user {
					name
					email
				}
				location {
					lat
					lng
					id
					city
					enum_country {
						comment
					}
				}
			}

			payments {
				created_at
				updated_at
				advance
				amount
				status
				timestamp
				id
				created_at
			}

			enum_order_status {
				comment
				value
			}

			status
			logistics_aggregate {
				aggregate {
					count
				}
			}
		}
		enum_order_status {
			comment
			value
		}
	}
`;

export const GET_REQUEST_OVERVIEW = gql`
	query get_request_dashboard {
		buy_count: requests_aggregate(where: { type: { _eq: buy_request } }) {
			aggregate {
				count
			}
		}
		sell_count: requests_aggregate(where: { type: { _eq: sell_offer } }) {
			aggregate {
				count
			}
		}
		total_amount: requests_aggregate {
			aggregate {
				sum {
					total_amount
				}
			}
		}
		total_count: requests_aggregate {
			aggregate {
				count
			}
		}
		total_users: users_aggregate {
			aggregate {
				count
			}
		}
		total_orders: trade_orders_aggregate {
			aggregate {
				count
			}
		}
	}
`;

export const REQUEST_TYPES = gql`
	query request_types {
		enum_request_types {
			value
			comment
		}
	}
`;

export const REQUEST_STATUS = gql`
	query request_types {
		enum_request_status {
			value
			comment
		}
	}
`;

export const EDIT_MODAL_DATA = gql`
	query edit_modal_data {
		enum_request_status {
			value
			comment
		}
		types: enum_request_types {
			value
			comment
		}
		products(where: { active: { _eq: true } }) {
			id
			name
		}
	}
`;

export const EDIT_ORDER_MODAL_DATA = gql`
	query edit_modal_data {
		enum_order_status {
			value
			comment
		}
		products(where: { active: { _eq: true } }) {
			id
			name
		}
		locations(where: { active: { _eq: true } }) {
			id
			name
		}
	}
`;

export const Q_DUMMY = gql`
	query DUMMY(
		$where: locations_bool_exp
		$filter: locations_bool_exp
		$limit: Int
		$offset: Int
		$order_by: [locations_order_by!]
		$distinct_on: [locations_select_column!]
	) {
		objects: locations(where: $where, limit: $limit, offset: $offset, order_by: $order_by, distinct_on: $distinct_on) {
			id
		}
		totals: locations_aggregate(where: $where) {
			aggregate {
				count
			}
		}
	}
`;

export const Q_REQUESTS = gql`
	query GetOffers(
		$where: requests_bool_exp
		$limit: Int
		$offset: Int
		$order_by: [requests_order_by!]
		$distinct_on: [requests_select_column!]
	) {
		objects: requests(where: $where, limit: $limit, offset: $offset, order_by: $order_by, distinct_on: $distinct_on) {
			product_id
			product_type_id
			product {
				name
			}
			product_type {
				name
				product {
					name
				}
			}
			type
			enum_request_type {
				comment
				value
			}
			enum_request_status {
				comment
				value
			}
			price_per_unit
			number_of_units
			enum_product_unit {
				comment
				value
			}
			total_amount
			from_date
			to_date
			location {
				lat
				lng

				enum_country {
					comment
				}
				city
			}
			logistics
			days_to_maturity
			advance_payment_percentage
			id
			uid
			notes {
				created_at
				updated_at
				text
			}
			user {
				email
			}
		}
		totals: requests_aggregate(where: $where) {
			aggregate {
				count
			}
		}
	}
`;

export const Q_TRADE_ORDERS = gql`
	query GetOrders(
		$where: trade_orders_bool_exp
		$limit: Int
		$offset: Int
		$order_by: [trade_orders_order_by!]
		$distinct_on: [trade_orders_select_column!]
	) {
		objects: trade_orders(where: $where, limit: $limit, offset: $offset, order_by: $order_by, distinct_on: $distinct_on) {
			id
			uid
			product_type_id
			product_type {
				name
				product_id
				product {
					name
				}
			}
			enum_order_status {
				comment
				value
			}
			price_per_unit
			number_of_units
			total_amount
			from_date
			active
			to_date
			advance_amount
			advance_payment_percentage
			status
			days_to_maturity
			from_location {
				lat
				lng
				enum_country {
					comment
				}
				city

				id
			}
			to_location {
				lat
				lng
				enum_country {
					comment
				}
				city

				id
			}
			logistics {
				created_at
				updated_at
				active
				contact_name
				contact_phone
				delivery_date
				tracking_id
				status
			}
			logistics_aggregate {
				aggregate {
					count
				}
			}
			notes {
				created_at
				updated_at
				text
			}
			seller {
				email
				id
			}
			buyer {
				email
				id
			}
			payments {
				created_at
				updated_at
				advance
				amount
				status
				timestamp
				id
				created_at
			}
			unit
			enum_product_unit {
				comment
			}
		}
		enum_order_status {
			comment
			value
		}
		totals: trade_orders_aggregate(where: $where) {
			aggregate {
				count
			}
		}
	}
`;

export const Q_SERVICE_ORDERS = gql`
	query GetServiceOrders(
		$where: service_orders_bool_exp
		$limit: Int
		$offset: Int
		$order_by: [service_orders_order_by!]
		$distinct_on: [service_orders_select_column!]
	) {
		objects: service_orders(where: $where, limit: $limit, offset: $offset, order_by: $order_by, distinct_on: $distinct_on) {
			id
			date
			created_at
			active
			uid
			service_type {
				name
				id
				service {
					name
					id
				}
			}

			location {
				lat
				lng
				id
				city
				enum_country {
					comment
				}
			}
			buyer {
				id
				email
				name
				phone_number
			}
			provider_service {
				amount
				range_in_kms
				user {
					id
					name
					email
				}
				location {
					lat
					lng
					id
					city
					enum_country {
						comment
					}
				}
			}

			payments {
				created_at
				updated_at
				advance
				amount
				status
				timestamp
				id
				created_at
			}

			enum_order_status {
				comment
				value
			}
			status
			logistics_aggregate {
				aggregate {
					count
				}
			}
		}
		enum_order_status {
			comment
			value
		}
		totals: service_orders_aggregate(where: $where) {
			aggregate {
				count
			}
		}
	}
`;

export const SERVICE_FILTER_DATA = gql`
	query edit_modal_data {
		enum_order_status {
			value
			comment
		}
		services(where: { active: { _eq: true } }) {
			id
			name
		}
	}
`;

export const Q_USERS = gql`
	query GetUsers($where: users_bool_exp, $limit: Int, $offset: Int, $order_by: [users_order_by!], $distinct_on: [users_select_column!]) {
		objects: users(where: $where, limit: $limit, offset: $offset, order_by: $order_by, distinct_on: $distinct_on) {
			id
			agent_access
			buyer_access
			grower_access
			provider_access
			moderator_access
			active
			email
			name
			photo_url
			phone_number
			created_at
			requests_aggregate {
				aggregate {
					count
					sum {
						total_amount
					}
				}
			}
			tradeOrdersByBuyerId_aggregate {
				aggregate {
					count
					sum {
						total_amount
					}
				}
			}
			tradeOrdersBySellerId_aggregate {
				aggregate {
					count
					sum {
						total_amount
					}
				}
			}
			location {
				lat
				lng
				active
				address_line_1
				address_line_2
				address_line_3
				city
				name
				enum_country {
					comment
				}
			}
		}
		totals: users_aggregate(where: $where) {
			aggregate {
				count
			}
		}
	}
`;

export const Q_FARMS = gql`
	query GetFarms(
		$where: locations_bool_exp
		$limit: Int
		$offset: Int
		$order_by: [locations_order_by!]
		$distinct_on: [locations_select_column!]
	) {
		objects: locations(where: $where, limit: $limit, offset: $offset, order_by: $order_by, distinct_on: $distinct_on) {
			id
			name
			active
			user {
				id
				email
			}
			lat
			lng
			uid
			area
			crops
			certifications
			city
			state
			country
			enum_country {
				id: value
				value
				comment
			}
		}

		totals: locations_aggregate(where: $where) {
			aggregate {
				count
			}
		}
	}
`;

export const CHECK_SUPERADMIN_EXIST = gql`
	query CheckIfSuperAdminExist {
		users(where: { moderator_access: { _eq: true } }) {
			moderator_access
		}
	}
`;

export const Q_SERVICE_OFFERS = gql`
	query GET_SERVICE_OFFERS(
		$where: provider_services_bool_exp
		$limit: Int
		$offset: Int
		$order_by: [provider_services_order_by!]
		$distinct_on: [provider_services_select_column!]
	) {
		objects: provider_services(where: $where, limit: $limit, offset: $offset, order_by: $order_by, distinct_on: $distinct_on) {
			active
			price_per_unit
			created_at
			id
			location {
				lat
				lng
				id
				name
				city
				enum_country {
					comment
				}
			}
			location_id
			pictures
			range_in_kms
			service_type {
				name
				id
				service {
					name
					id
				}
			}
			uid
			user_id
			user {
				name
			}
			service_orders_aggregate {
				aggregate {
					count
				}
			}
		}
	}
`;

export const MONTHLY_SIGNUPS = (role) => gql`
subscription view_${role}_signups($where: view_${role}_signups_by_month_bool_exp!) {
  stats: view_${role}_signups_by_month(where: $where) {
    year
    month
    count
  }
}
`;

export const MONTHLY_COUNT = (entity) => gql`
subscription view_${entity}_by_month($where: view_${entity}_by_month_bool_exp!) {
  stats: view_${entity}_by_month(where: $where) {
    year
    month
    count
    amount
  }
}
`;

export const PAYMENTS = gql`
	query get_payments($where: payments_bool_exp, $order_by: [payments_order_by!]) {
		objects: payments(where: $where) {
			id
			created_at
			updated_at
			trade_order_id
			service_order_id
			created_at
			updated_at
			uid
			active
			status
			enum_order_status {
				comment
				value
			}
			amount
			advance
			buyer {
				name
				email
				id
			}
			seller {
				name
				email
				id
			}
			trade_order {
				id
				uid
				product_type {
					name
					id
					product {
						id
						name
					}
				}
				enum_order_status {
					comment
					value
				}
				price_per_unit
				number_of_units
				total_amount
				from_date
				active
				to_date
				advance_amount
				advance_payment_percentage
				status
				days_to_maturity
				from_location {
					lat
					lng
					enum_country {
						comment
					}
					city
					id
				}
				to_location {
					lat
					lng
					enum_country {
						comment
					}
					city
					id
				}
				seller {
					name
					email
					id
				}
				buyer {
					name
					email
					id
				}
				unit
				enum_product_unit {
					comment
				}
			}
			service_order {
				id
				created_at
				active
				uid

				service_type {
					name
					id
					service {
						name
						id
					}
				}
				buyer {
					id
					email
					phone_number
				}
				provider_service {
					price_per_unit
					user {
						id
						name
						email
					}
					location {
						lat
						lng
						id
						city
						enum_country {
							comment
						}
					}
				}

				enum_order_status {
					comment
					value
				}
				status
			}
		}
	}
`;

export const LOGISTICS = gql`
	query get_logistics($where: logistics_bool_exp, $order_by: [logistics_order_by!]) {
		objects: logistics(where: $where) {
			id
			created_at
			updated_at
			tracking_id
			contact_name
			contact_phone
			contact_phone
			delivery_date
			trade_order_id
			service_order_id
			created_at
			updated_at
			uid
			active
			status
			enum_order_status {
				comment
				value
			}
			buyer {
				name
				email
				id
			}
			seller {
				name
				email
				id
			}
			trade_order {
				id
				uid
				product_type {
					name
					id
					product {
						id
						name
					}
				}
				enum_order_status {
					comment
					value
				}
				price_per_unit
				number_of_units
				total_amount
				from_date
				active
				to_date
				advance_amount
				advance_payment_percentage
				status
				days_to_maturity
				from_location {
					lat
					lng
					enum_country {
						comment
					}
					city
					id
				}
				to_location {
					lat
					lng
					enum_country {
						comment
					}
					city
					id
				}
				seller {
					name
					email
					id
				}
				buyer {
					name
					email
					id
				}
				unit
				enum_product_unit {
					comment
				}
			}
			service_order {
				id
				created_at
				active
				uid

				service_type {
					name
					id
					service {
						name
						id
					}
				}
				buyer {
					id
					email
					phone_number
				}
				provider_service {
					price_per_unit
					user {
						id
						name
						email
					}
					location {
						lat
						lng
						id
						city
						enum_country {
							comment
						}
					}
				}

				enum_order_status {
					comment
					value
				}
				status
			}
		}
	}
`;
