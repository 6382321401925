import React, { useState } from "react";
import { Badge, Button, Col, ListGroup, Row } from "react-bootstrap";
import { PageTitle } from "../../../../shared/components";
import { useTranslatedLabel } from "../../../../shared/hooks";
import { Layout } from "../../components";
import { PATHS } from "../../config";
import { InvitePeople } from "./invite-modal";

export const Staff = ({ history, component = false, pagination, noFilter = false, limit = null, component_filters = {} }) => {
	const { t } = useTranslatedLabel();
	const [visible, setVisible] = useState(false);
	const DATA = [
		{
			id: 1,
			name: "Raj",
			role: "Admin",
			last_seen: "30 mins ago",
		},
		{
			id: 2,
			name: "Micheal",
			role: "Agent",
			last_seen: "1 hour ago",
		},
		{
			id: 3,
			name: "Fed",
			role: "Moderator",
			last_seen: "7 hours ago",
		},
		{
			id: 4,
			name: "Ghost",
			role: "Agent",
			last_seen: "Online",
		},
	];

	const onRowClick = (data) => {
		history.push({
			pathname: `${PATHS.staff}${data.id}`,
			state: { data },
		});
	};

	return (
		<Layout>
			<div className="">
				<PageTitle
					showLogo={true}
					title={t("staff")}
					rightElement={
						<div className="col-2">
							<Button onClick={() => setVisible(true)}>Invite People</Button>
						</div>
					}
				/>
				<InvitePeople show={visible} onClose={() => setVisible(false)} />
				<Row>
					<Col sm={12}>
						<ListGroup>
							{DATA.map((item) => (
								<ListGroup.Item action onClick={() => onRowClick(item)} variant="light" key={item.id} className="listitem-pad">
									<div className="row">
										<img
											src="https://s.gravatar.com/avatar/d416aad9f1b8d9ec3ab92c46af590118?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fm.png"
											className="rounded-circle avatar-img"
											alt="avatar"
										/>

										<div className="col">
											<h5>{item.name}</h5>
											<p className="m-0">Status: {item.last_seen}</p>
										</div>
										<div className="col">
											<div className="float-right">
												<div className="center-badge">
													<Badge variant="secondary">{item.role}</Badge>
													<i className="la la-chevron-circle-right" style={{ fontSize: "2rem" }} />
												</div>
											</div>
										</div>
									</div>
								</ListGroup.Item>
							))}
						</ListGroup>
					</Col>
				</Row>
			</div>
		</Layout>
	);
};
