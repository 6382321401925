import React, { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AntdTable } from "../../../../shared/components";
import { useTranslatedLabel } from "../../../../shared/hooks";
import { Layout } from "../../components";
import { PATHS } from "../../config";
import { Filters } from "./filters";

const data_fields = `
	id created_at updated_at uid active status enum_order_status { comment value }
	buyer { name email id photo_url } seller { name email id photo_url } trade_order { id uid } service_order { id uid }
	tracking_id contact_name  contact_phone  delivery_date
	`;

export const Logistics = ({
  history,
  component = false,
  pagination,
  noFilter = false,
  limit = null,
  component_filters = {},
  hideColumns = [],
}) => {
  const { t } = useTranslatedLabel();
  const [filters, setFilters] = useState({});
  const [tab_id, setTabId] = useState("table");
  const COLUMNS = [
    {
      title: t("id"),
      key: "uid",
      dataIndex: "uid",
      sorter: AntdTable.GenericSorter("uid"),
    },
    {
      title: t("status"),
      key: "status",
      render: (row) => row.enum_order_status?.comment,
      sorter: AntdTable.TextSorter("status"),
    },
    {
      title: "Delivery Date",
      key: "delivery_date",
      render: AntdTable.DateRenderer("delivery_date"),
      sorter: AntdTable.TextSorter("delivery_date"),
    },
    {
      title: t("buyer"),
      key: "buyer",
      render: AntdTable.UserRenderer("buyer"),
      sorter: AntdTable.UserSorter("buyer"),
    },
    {
      title: t("seller"),
      key: "seller",
      render: AntdTable.UserRenderer("seller"),
      sorter: AntdTable.UserSorter("seller"),
    },
    {
      title: "Tracking ID",
      key: "tracking_id",
      dataIndex: "tracking_id",
      sorter: AntdTable.TextSorter("tracking_id "),
    },
    {
      title: "Contact Name",
      key: "contact_name",
      dataIndex: "contact_name",
      sorter: AntdTable.TextSorter("contact_name "),
    },
    {
      title: "Contact Phone",
      key: "contact_phone",
      dataIndex: "contact_phone",
      sorter: AntdTable.TextSorter("contact_phone "),
    },
    {
      title: t("trade_order_id"),
      key: "trade_order_id",
      render: (row) => (
        <Link to={`${PATHS.orders}/${row?.trade_order?.id}`}>
          {row?.trade_order?.uid}
        </Link>
      ),
      sorter: AntdTable.TextSorter("trade_order_id"),
    },
    {
      title: t("service_order_id"),
      key: "service_order_id",
      render: (row) => (
        <Link to={`${PATHS.serviceOrders}/${row?.service_order?.id}`}>
          {row?.service_order?.uid}
        </Link>
      ),
      sorter: AntdTable.TextSorter("service_order_id"),
    },
    // {
    // 	title: t("created"),
    // 	key: "created_at",
    // 	render: AntdTable.DateRenderer("created_at"),
    // 	sorter: AntdTable.TextSorter("created_at"),
    // },
    // {
    // 	title: t("updated"),
    // 	key: "updated_at",
    // 	render: AntdTable.DateRenderer("updated_at"),
    // 	sorter: AntdTable.TextSorter("updated_at"),
    // },
    {
      title: t("active"),
      key: "active",
      dataIndex: "active",
      render: (text, record) =>
        text ? (
          <i className="la la-check-circle la-lg text-success align-align-self-center" />
        ) : (
            ""
          ),
      sorter: (a, b) => Number(a.active) - Number(b.active),
    },
  ];

  const content = (
    <>
      {!noFilter && (
        <Filters
          entity="trade_orders"
          onChange={(e) => setFilters(e)}
          fields={["order_status"]}
        />
      )}
      <Tabs
        activeKey={tab_id}
        onSelect={(e) => setTabId(e)}
        className="m-0 p-0"
      >
        <Tab eventKey="table" title={t("table_view")}>
          <AntdTable
            entity="logistics"
            columns={COLUMNS}
            where={{ ...component_filters }}
            order_by={{ uid: "desc" }}
            query_fields={data_fields}
            filters={filters}
            limit={limit}
          // pagination={pagination}
          />
        </Tab>
      </Tabs>
    </>
  );
  return (
    <>
      {component ? (
        content
      ) : (
          <Layout title={t("logistics")}>
            <div>{content}</div>
          </Layout>
        )}
    </>
  );
};
