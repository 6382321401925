import Prismic from "prismic-javascript";
import React, { useContext, useEffect, useState } from "react";
import { Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useLocalStorage } from "../../../../shared/helpers";
import { useTranslatedLabel } from "../../../../shared/hooks";
import { webpageclient } from "../../components/prismicHelpers";
import { PATHS } from "../../config";
import { logout } from "../../services";
import { AuthContext, GeneralContext } from "../../store";
import { Notifications } from "../notifications";
import { MobileDrawer } from "./mobile-drawer";

const AuthHeader = () => {
	const { general_language, setGeneralLanguage, general_languages, general_languages_map } = useContext(GeneralContext);
	const { user = {}, logoutUser, is_authenticated, user_role } = useContext(AuthContext);
	const { name, photo_url } = user;
	const { t } = useTranslatedLabel();
	const [, setPrismicData] = useState();
	const [, setPath] = useLocalStorage("path", window.location.pathname);

	// Get the homepage and blog post documents from Prismic
	useEffect(() => {
		const fetchPrismicData = async () => {
			try {
				const homeDoc = await webpageclient.query(Prismic.Predicates.at("document.type", "homepage"), {
					lang: (general_language || "en_gb").replace("_", "-"),
				});

				if (homeDoc && homeDoc.results && homeDoc.results[0]) {
					setPrismicData(homeDoc.results[0]);
				} else {
					console.warn("Blog Home document was not found. Make sure it exists in your Prismic repository");
				}
			} catch (error) {
				console.error(error);
			}
		};

		fetchPrismicData();
	}, [general_language]);

	const logoutClick = async (e) => {
		e.preventDefault();
		logoutUser();
		await logout();
	};

	return (
		<>
			<header className="header" style={{ padding: "0px", marginBottom: 10 }}>
				<div className="row col bg-secondary m-0 p-0">
					<div className="container">
						<Navbar className="m-0 p-0">
							<Nav className="mx-auto"></Nav>
							{is_authenticated && (
								<Nav className="ml-2">
									<Nav.Item className="m-0 p-0 ml-2">
										<Link className="text-primary" to={"/"} style={{ fontSize: 12 }}>
											{t("home")}
										</Link>
									</Nav.Item>
								</Nav>
							)}
							{is_authenticated && (
								<Nav className="ml-2">
									<Nav.Item className="m-0 p-0 ml-2">
										<small className="transaction-dropdown">
											<p className="m-0 p-0" style={{ fontSize: 12 }}>
												{t("about_us")}
											</p>
											<div className="transaction-dropdown-menu">
												<>
													<Link to={PATHS.forgrowers} className="dropdown-item" style={{ fontSize: 12 }}>
														{t("growers")}
													</Link>
													<Link to={PATHS.investors} className="dropdown-item" style={{ fontSize: 12 }}>
														{t("investors")}
													</Link>
													<Link to={PATHS.about} className="dropdown-item" style={{ fontSize: 12 }}>
														{t("our_story")}
													</Link>
												</>
											</div>
										</small>
									</Nav.Item>
								</Nav>
							)}

							{general_languages_map[general_language]?.comment && (
								<Nav className="ml-2">
									<Nav.Item className="m-0 p-0 ml-2 pull-right">
										<small className="transaction-dropdown">
											<p className="m-0 p-0" style={{ fontSize: 12 }}>
												<i className="las la-language la-lg"></i>
												{"  "}
												{general_languages_map[general_language]?.comment || "English"}
											</p>
											<div className="transaction-dropdown-menu">
												<>
													{general_languages
														//.filter((l) => l.value !== general_language)
														.map((l, index) => (
															<Link
																to="#"
																className="dropdown-item"
																key={index}
																onClick={(e) => {
																	e.preventDefault();
																	setGeneralLanguage(l.value);
																}}
																style={{ fontSize: 12 }}
															>
																{l.comment}
															</Link>
														))}
												</>
											</div>
										</small>
									</Nav.Item>
								</Nav>
							)}
							{is_authenticated && (
								<Nav className="ml-2">
									<Nav.Item className="m-0 p-0 ml-2">
										<small className="transaction-dropdown">
											<p className="m-0 p-0" style={{ fontSize: 12 }}>
												<i className="las la-user la-lg"></i>
												{"  "}
												{name}
											</p>
											<div className="transaction-dropdown-menu">
												<>
													<Link to={PATHS.account} className="dropdown-item" style={{ fontSize: 12 }}>
														<>
															<img
																src={
																	photo_url ||
																	"https://res.cloudinary.com/ampcome/image/upload/c_thumb,e_colorize:100,g_face,q_100,w_200/v1595014484/mahaseel/avatar.png"
																}
																alt=""
																className="rounded-circle mr-2"
																style={{ width: "1rem", height: "1rem" }}
															/>
															<span>{t("profile")}</span>
														</>
													</Link>
													<Link to={PATHS.support} className="dropdown-item" style={{ fontSize: 12 }}>
														<i className="la la-headset  mr-2"></i> {t("support")}
													</Link>
													<Link
														to={"./logout"}
														className="dropdown-item logout"
														onClick={logoutClick}
														style={{ fontSize: 12 }}
													>
														<i className="la la-sign-out  mr-2"></i> {t("logout")}
													</Link>
												</>
											</div>
										</small>
									</Nav.Item>
								</Nav>
							)}
							<Nav className="ml-2"></Nav>
						</Navbar>
					</div>
				</div>
				<div className="container">
					<div className="row">
						<div className="col-xl-12 m-0 p-0">
							<div className="navigation">
								<Navbar bg="light" expand="lg">
									<Link className="navbar-brand" to={"/"}>
										<img
											src="https://res.cloudinary.com/ampcome/image/upload/v1595015037/mahaseel/flat_logo.jpg"
											style={{ height: "50px" }}
											alt=""
										/>
									</Link>

									{is_authenticated && (
										<div className="d-lg-none d-xl-none">
											<Notifications recipient_id={user.id} />
										</div>
									)}
									<MobileDrawer />

									{is_authenticated ? (
										<>
											<Navbar.Collapse>
												<Nav className="mx-auto">
													<Nav.Item>
														<Link to={PATHS.dashboard}>{t("dashboard")}</Link>
													</Nav.Item>
													{user_role !== "provider" && (
														<Nav.Item className="ml-0">
															<Link to={PATHS.marketplace}>{t("marketplace")}</Link>
														</Nav.Item>
													)}
													<Nav.Item className="ml-0">
														<Link to={PATHS.services}>{t("services")}</Link>
													</Nav.Item>
													<Nav.Item className="ml-0">
														<Link to={PATHS.posts}>{t("posts")}</Link>
													</Nav.Item>

													{user_role !== "provider" && (
														<Nav.Item className="ml-2">
															<div className="transaction-dropdown">
																<p className="mb-1 pt-1">{t("trade_offers")}</p>
																<div className="transaction-dropdown-menu">
																	<Link to={PATHS.trade_active_offers} className="dropdown-item">
																		{t("active_offers")}
																	</Link>
																	<Link to={PATHS.trade_attention_offers} className="dropdown-item">
																		{t("requires_attention")}
																	</Link>
																	<Link to={PATHS.trade_submitted_bids} className="dropdown-item">
																		{t("submitted_bids")}
																	</Link>
																	<Link to={PATHS.trade_matching_connections} className="dropdown-item">
																		{t("matching_connections")}
																	</Link>
																	<Link to={PATHS.trade_offer_history} className="dropdown-item">
																		{t("offer_history")}
																	</Link>
																	<Link to={PATHS.trade_bid_history} className="dropdown-item">
																		{t("bid_history")}
																	</Link>
																</div>
															</div>
														</Nav.Item>
													)}
													{user_role !== "provider" && (
														<Nav.Item className="ml-2">
															<div className="transaction-dropdown">
																<p className="mb-1 pt-1">{t("trade_orders")}</p>
																<div className="transaction-dropdown-menu">
																	<Link to={PATHS.trade_active_orders} className="dropdown-item">
																		{t("active_orders")}
																	</Link>
																	{/* <Link to={PATHS.trade_attention_orders} className="dropdown-item">
																		{t("requires_attention")}
																	</Link> */}
																	<Link to={PATHS.trade_order_history} className="dropdown-item">
																		{t("order_history")}
																	</Link>
																</div>
															</div>
														</Nav.Item>
													)}

													{user_role === "provider" && (
														<Nav.Item className="ml-2">
															<div className="transaction-dropdown">
																<p className="mb-1 pt-1">{t("service_offers")}</p>
																<div className="transaction-dropdown-menu">
																	<Link to={PATHS.service_active_offers} className="dropdown-item">
																		{t("active_offers")}
																	</Link>
																	<Link to={PATHS.service_offer_history} className="dropdown-item">
																		{t("offer_history")}
																	</Link>
																</div>
															</div>
														</Nav.Item>
													)}
													<Nav.Item className="ml-2">
														<div className="transaction-dropdown">
															<p className="mb-1 pt-1">{t("service_orders")}</p>
															<div className="transaction-dropdown-menu">
																<Link to={PATHS.service_active_orders} className="dropdown-item">
																	{t("active_orders")}
																</Link>
																<Link to={PATHS.service_attention_orders} className="dropdown-item">
																	{t("requires_attention")}
																</Link>
																<Link to={PATHS.service_order_history} className="dropdown-item">
																	{t("order_history")}
																</Link>
															</div>
														</div>
													</Nav.Item>
												</Nav>
											</Navbar.Collapse>
											<div className="d-none d-lg-block d-xl-block">
												<Notifications recipient_id={user.id} />
											</div>
										</>
									) : (
											<Navbar.Collapse>
												<Nav className="mx-auto">
													<Nav.Item className="mx-auto">
														<Link to={"/"}>{t("home")}</Link>
													</Nav.Item>
													<Nav.Item className="mx-auto">
														<Link to={PATHS.marketplace}>{t("marketplace")}</Link>
													</Nav.Item>
													<Nav.Item className="mx-auto">
														<Link to={PATHS.services}>{t("services")}</Link>
													</Nav.Item>
													<Nav.Item className="mx-auto">
														<Link to={PATHS.forgrowers}>{t("growers")}</Link>
													</Nav.Item>
													<Nav.Item className="mx-auto">
														<Link to={PATHS.investors}>{t("investors")}</Link>
													</Nav.Item>
													<Nav.Item className="mx-auto">
														<Link to={PATHS.about}>{t("our_story")}</Link>
													</Nav.Item>
													<Nav.Item className="mx-auto">
														<Link to={PATHS.posts}>{t("posts")}</Link>
													</Nav.Item>
												</Nav>
											</Navbar.Collapse>
										)}
									<Nav className="mx-auto">
										{!is_authenticated && (
											<>
												<Nav.Item className="mx-auto">
													<Link
														to={PATHS.signin}
														className="btn btn-sm btn-light border signin-btn d-none d-lg-block"
														onClick={() => setPath(`${PATHS.dashboard}`)}
													>
														<i className="las la-sign-in-alt la-lg"></i> {t("login")}
													</Link>
												</Nav.Item>
												<Nav.Item className="mx-auto">
													<Link
														className="btn btn-sm btn-light border signin-btn d-none d-lg-block"
														to={PATHS.register}
														onClick={() => setPath(`${PATHS.marketplace}`)}
													>
														<i className="las la-user-plus la-lg"></i> {t("register")}
													</Link>
												</Nav.Item>
											</>
										)}
									</Nav>
								</Navbar>
							</div>
						</div>
					</div>
				</div>
				{user?.account_verified === false && (
					<div className="row col m-0 p-0" style={{ backgroundColor: '#2b77d9', color: '#fff' }}>
						<div className="container">
							<Navbar className="m-0 p-0">
								<Nav className="mr-auto"></Nav>
								<Nav.Item className="m-0 p-0 ml-2">
									{t("account_verification_pending")}
								</Nav.Item>
								<Nav className="ml-auto"></Nav>
							</Navbar>
						</div>
					</div>
				)}
			</header>
		</>
	);
};

export default AuthHeader;
