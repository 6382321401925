import React from "react";
import GreenMarker from "../../apps/admin/assets/marker-green.png";
import RedMarker from "../../apps/admin/assets/marker-red.png";
import YellowMarker from "../../apps/admin/assets/marker-yellow.png";
// import { Loader } from "../../apps/admin/components";
import {Spin} from 'antd'
import { useMapQuery } from "../../apps/client/widgets/mapQuery";
import { getMapPoint, PreviewGoogleMap } from "../helpers";

export const MapView = (props) => {
	const { objects, loading } = useMapQuery(props);
	let positions = [];
	if (props.entity === "trade_orders") {
		positions.push(...objects.map((o) => getMapPoint({ ...o?.from_location, icon: GreenMarker })));
		positions.push(...objects.map((o) => getMapPoint({ ...o?.to_location, icon: RedMarker })));
	} else if (props.entity === "service_orders") {
		positions.push(...objects.map((o) => getMapPoint({ ...o?.from_location, icon: o?.from_location?.is_farm ? GreenMarker : RedMarker })));
		positions.push(...objects.map((o) => getMapPoint({ ...o?.to_location, icon: YellowMarker })));
	} else if (props.entity === "requests") {
		positions.push(
			...objects.map((o) =>
				getMapPoint({
					...o?.location,
					icon: o?.location?.is_farm
						? GreenMarker
						: o?.location?.user && o?.location?.user.provider_access
						? YellowMarker
						: RedMarker,
				})
			)
		);
	} else if (props.entity === "provider_services") {
		positions.push(...objects.map((o) => getMapPoint({ ...o?.location, icon: YellowMarker })));
	} else if (props.entity === "locations") {
		positions.push(
			...objects.map((o) =>
				getMapPoint({ ...o, icon: o?.is_farm ? GreenMarker : o?.user && o?.user.provider_access ? YellowMarker : RedMarker })
			)
		);
	} else if (props.map_field) {
		positions.push(
			...objects.map((o) =>
				getMapPoint({
					...o[props.map_field],
					icon: o[props.map_field]?.is_farm
						? GreenMarker
						: o[props.map_field]?.user && o[props.map_field]?.user?.provider_access
						? YellowMarker
						: RedMarker,
				})
			)
		);
	} else {
		positions.push(
			...objects.map((o) =>
				o?.location
					? getMapPoint({
							...o?.location,
							icon: o?.location?.is_farm
								? GreenMarker
								: o?.location?.user && o?.location?.user.provider_access
								? YellowMarker
								: RedMarker,
					  })
					: null
			)
		);
	}
	positions = positions.filter(Boolean);
	if (loading) return <Spin size="small"  />;
	if (positions.length === 0) return <h4>No Data Points to Display</h4>;
	else
		return (
			<PreviewGoogleMap
				isMarkerShown
				zoom={props.zoom}
				positions={positions}
				loadingElement={<Spin spinning style={{ height: props.height ? props.height : "60vh" }} />}
				containerElement={<div style={{ height: props.height ? props.height : "60vh" }} />}
				mapElement={<div style={{ height: "100%" }} />}
			/>
		);
};
