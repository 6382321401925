import { StyleSheet, Text, View } from "@react-pdf/renderer";
import React from "react";
import { useTranslatedLabel } from "../../../../../shared/hooks";

const borderColor = "#90e5fc";
const styles = StyleSheet.create({
	row: {
		flexDirection: "row",
		borderBottomColor: "#bff0fd",
		borderBottomWidth: 1,
		alignItems: "center",
		height: 24,
		fontSize: 12,
		fontStyle: "bold",
	},
	date: {
		width: "30%",
		textAlign: "left",
		borderRightColor: borderColor,
		borderRightWidth: 1,
		paddingRight: 8,
	},
	description: {
		width: "50%",
		textAlign: "right",
		borderRightColor: borderColor,
		borderRightWidth: 1,
		paddingRight: 8,
	},
	total: {
		width: "20%",
		textAlign: "right",
		paddingRight: 8,
	},
});

const InvoiceTableFooter = ({ invoice, service }) => {
	const { items } = invoice;
	const total = items.map((item) => item.qty * item.rate).reduce((accumulator, currentValue) => accumulator + currentValue, 0);
	const { dt_tm } = useTranslatedLabel();
	return (
		<>
			{!service && (
				<View style={styles.row}>
					<Text style={styles.date}>{dt_tm(invoice.advance_paid_at)}</Text>
					<Text style={styles.description}>ADVANCE AMOUNT PAID</Text>
					<Text style={styles.total}>$ {Number.parseFloat(invoice?.advance_paid).toFixed(2)}</Text>
				</View>
			)}
			{!service && (
				<View style={styles.row}>
					<Text style={styles.date}>{dt_tm(invoice.remaining_paid_at)}</Text>
					<Text style={styles.description}>REMAINING AMOUNT PAID</Text>
					<Text style={styles.total}>$ {Number.parseFloat(invoice?.remaning_paid).toFixed(2)}</Text>
				</View>
			)}
			{service && (
				<View style={styles.row}>
					<Text style={styles.date}>{dt_tm(invoice.total_paid_at)}</Text>
					<Text style={styles.description}>AMOUNT PAID</Text>
					<Text style={styles.total}>$ {Number.parseFloat(invoice?.total_paid).toFixed(2)}</Text>
				</View>
			)}
			<View style={styles.row}>
				<Text style={styles.date}></Text>
				<Text style={styles.description}>TOTAL AMOUNT PAID</Text>
				<Text style={styles.total}>$ {Number.parseFloat(total).toFixed(2)}</Text>
			</View>
		</>
	);
};

export default InvoiceTableFooter;
