import React, { createContext, useReducer } from "react";

const initialState = {
	services_offers: [],
	services_services: [],
	services_service_types: [],
	services_countries: [],
};

const servicesActionTypes = {
	SET_OFFERS: "SET_OFFERS",
	SET_SERVICES: "SET_SERVICES",
	SET_SERVICE_TYPES: "SET_SERVICE_TYPES",
	SET_COUNTRIES: "SET_COUNTRIES",
};

const servicesReducer = (state, action) => {
	switch (action.type) {
		case servicesActionTypes.SET_OFFERS:
			return {
				...state,
				services_offers: action.payload,
			};
		case servicesActionTypes.SET_SERVICES:
			return {
				...state,
				services_services: action.payload,
			};
		case servicesActionTypes.SET_SERVICE_TYPES:
			return {
				...state,
				services_service_types: action.payload,
			};
		case servicesActionTypes.SET_OFFER_COUNTRIES:
			return {
				...state,
				services_countries: action.payload,
			};
		default:
			return state;
	}
};

const ServicesContext = createContext(initialState);
const ServicesProvider = ({ children }) => {
	const [state, dispatch] = useReducer(servicesReducer, initialState);

	const dispatches = {
		setServicesOffers(e) {
			dispatch({
				type: servicesActionTypes.SET_OFFERS,
				payload: e,
			});
		},
		setServicesServices(e) {
			dispatch({
				type: servicesActionTypes.SET_SERVICE_TYPES,
				payload: e,
			});
		},
		setServicesServiceTypes(e) {
			dispatch({
				type: servicesActionTypes.SET_SERVICE_TYPES,
				payload: e,
			});
		},
		setServicesCountries(e) {
			dispatch({
				type: servicesActionTypes.SET_OFFER_COUNTRIES,
				payload: e,
			});
		},
	};

	return <ServicesContext.Provider value={{ ...state, ...dispatches }}>{children}</ServicesContext.Provider>;
};

export { ServicesContext, ServicesProvider };
